import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';


import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import _ from 'lodash';

const NeedJobReqsProfilePropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    progress: PropTypes.number,
    postMountCallback: PropTypes.func,
    selectedItems: PropTypes.array.isRequired
}




class NeedJobReqsProfile extends Component {

    constructor(props) {
        super(props);


        this.state = {
            selected:this.props.selectedItems ? this.props.selectedItems : [],
            showModal: false,
            modalText: '',
            modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(name, txt) {
        this.setState({showModal: true, modalTitle: name, modalText: txt});
    }




    // componentDidMount() {
    componentDidMount = async () => {
        console.log("componentDidMount");

        if(this.props.postMountCallback)
            await this.props.postMountCallback();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if( !_.isEqual(this.props.selectedItems, prevProps.selectedItems))
            this.setState({selected:this.props.selectedItems ? this.props.selectedItems : []})
    }


    render() {


        console.log("SELECT_ITEMS: " + this.props.selectedItems);
        console.log("state selected: " + this.state.selected);

        let props = this.props;

        let diffType =
            {
                //name: 'Which job requirements categories are you interested in exploring?',
                name: 'Which job activities are you interested in exploring?',
                levels: [
                    {code: '/job_req_fac', text: 'Facility Use (e.g., Moving around, Using Doors, Navigation)', descript: 'Includes moving around the workplace (e.g., navigation, using doors, moving between floors) and using work areas (e.g. meeting room, break room)'},

                    {code: '/job_req_workstation', text: 'Workstation Use (e.g. Sitting/Standing at a Workstation)', descript: 'Includes sitting or standing at a workstation.'},

                    {code: '/job_req_org', text: 'Organization (e.g., Learning Tasks, Time Management)', descript: 'Includes planning the work day, learning and remembering work tasks, and staying on task'},

                    {code: '/job_req_comm', text: 'Communication (e.g., Spoken, Written)', descript: 'Includes communicating by voice or writing text.'},

                    {code: '/job_req_equip', text: 'Equipment Use (e.g., Computer, Phone)', descript: 'Includes the use of any equipment needed for completing tasks.'},

                    // {code: '/job_req_workplace', text: 'Workplace', descript: 'Includes anything pertaining to workplace access.'},

                    {code: '/job_req_wellbeing', text: 'Maintaining Well-Being (e.g., Managing Anxiety, Fatigue', descript: 'Managing Anxiety or Stress)'},
                ]
            }


        let frm =
            <div>
                <h2 class="nav-sub-heading">{diffType.name}</h2>
                <Form>
                    {diffType.levels.map(
                        (opt, j) => {
                            return <div style={{display: 'flex', flexDirection: 'row'}} key={`default-checkbox-${this.props.section}_${j}`} className='mb-3'>
                                <Form.Check
                                    type='checkbox'
                                    id={`default-checkbox-${opt.code}`}
                                    label={opt.text}
                                    value={opt.code}
                                    checked={this.props.selectedItems.includes(opt.code)}
                                    //checked={this.state.checkboxChecked}
                                    onChange={(e) => this.handleChange(e, this.props.updateConfig)}
                                />

                                <div style={{display: 'flex'}} >
                                    <div style={{cursor:'pointer'}} onClick={(e)=>{e.stopPropagation();e.nativeEvent.stopImmediatePropagation(); this.showModal(opt.text, opt.descript);}}>
                                        <MdInfo/>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flex:1}}></div>

                            </div>;
                        }
                    )}
                </Form>
            </div>








        //let frm = <div>We need to first collect some information about your job requirements. Click next to continue...</div>




        return (

            <div>

                        <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                            <Modal.Header closeButton>
                                <Modal.Title>

                                    {this.state.modalTitle}

                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                {this.state.modalText}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.closeModal()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>




                    <NavPanel title="Step 1: Select Job Activity Group" content={frm} progress={this.props.progress} prev={this.props.prev}
                              //next={this.props.selectedItems.length > 0 ? this.props.selectedItems[0] : null}
                              next={this.props.next}
                    />

            </div>

        );
    }


    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if(!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        }
        else
        {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        if(updateConfig)
        {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }






}


NeedJobReqsProfile.propTypes = NeedJobReqsProfilePropTypes;

export default NeedJobReqsProfile;
