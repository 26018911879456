const rules = {
    "1010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Lights along path are difficult to turn on",
        "solution": "Install light switches",
        "solution_long": "Install light switches so they are near the entrance to the space and are placed in a consistent location in relation to that entrance. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 1010101,
        "strategy": "Make it easier to turn on lights",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U2 || a.U3 || a.V3 || a.V4 || a.C2) && (a.LOW) && (a.T0101)"
    },
    "1010102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Lights along path are difficult to turn on",
        "solution": "Move obstacles blocking view",
        "solution_long": "Move obstacles, including furniture, that are blocking the view of light switches.",
        "solution_number": 1010102,
        "strategy": "Make it easier to turn on lights",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V3 || a.V4 || a.C2) && (a.LOW) && (a.T0101)"
    },
    "1010103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Lights along path are difficult to turn on",
        "solution": "Move obstacles blocking reach",
        "solution_long": "Move obstacles, including furniture, that prevent the person from reaching the switch.",
        "solution_number": 1010103,
        "strategy": "Make it easier to turn on lights",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U2 || a.U3) && (a.LOW) && (a.T0101)"
    },
    "1010104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Lights along path are difficult to turn on",
        "solution": "Use a light switch extension",
        "solution_long": "Use a light switch extension",
        "solution_number": 1010104,
        "strategy": "Make it easier to turn on lights",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U2 || a.U3) && (a.LOW) && (a.T0101)"
    },
    "1010105": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Lights along path are difficult to turn on",
        "solution": "Use lighted switch plates",
        "solution_long": "Use switch plates that light up or glow. ",
        "solution_number": 1010105,
        "strategy": "Make it easier to turn on lights",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0101)"
    },
    "1010106": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Lights along path are difficult to turn on",
        "solution": "Provide a tactile guide ",
        "solution_long": "Provide a tactile guide (e.g., strip of tape) that leads from the door frame to the light switch.",
        "solution_number": 1010106,
        "strategy": "Make it easier to turn on lights",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0101)"
    },
    "1010107": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: An electrician might be helpful",
        "gap": "Lights along path are difficult to turn on",
        "solution": "Use motion-activated lights",
        "solution_long": "Use motion-activated lights. ",
        "solution_number": 1010107,
        "strategy": "Make it easier to turn on lights",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U2 || a.U3) && (a.LOW) && (a.T0101)"
    },
    "1010108": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: An electrician might be helpful",
        "gap": "Lights along path are difficult to turn on",
        "solution": "Use voice-activation to control lights",
        "solution_long": "Use voice-activated lights.  ",
        "solution_number": 1010108,
        "strategy": "Make it easier to turn on lights",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U2 || a.U3) && (a.LOW) && (a.T0101)"
    },
    "1020101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: An electrician might be helpful",
        "gap": "Path is difficult to see",
        "solution": "Add or move lighting",
        "solution_long": "Add spotlights or move the lighting toward the path of travel. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 1020101,
        "strategy": "Increase the amount of lighting",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is difficult to see",
        "solution": "Change the light bulb",
        "solution_long": "Change the light bulb type / wattage / color to increase the amount of light.",
        "solution_number": 1020102,
        "strategy": "Increase the amount of lighting",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is difficult to see",
        "solution": "Open curtains or blinds",
        "solution_long": "Open curtains or blinds to adjust the amount and direction of light.",
        "solution_number": 1020103,
        "strategy": "Increase the amount of lighting",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: An electrician might be helpful",
        "gap": "Path is difficult to see",
        "solution": "Remove or move lighting",
        "solution_long": "Remove some lights or move the lighting away from the path of travel.",
        "solution_number": 1020201,
        "strategy": "Reduce glare from lighting",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is difficult to see",
        "solution": "Change the light bulb",
        "solution_long": "Change the light bulb type / wattage / color to decrease the amount of light.",
        "solution_number": 1020202,
        "strategy": "Reduce glare from lighting",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is difficult to see",
        "solution": "Dim or filter existing lights",
        "solution_long": "Dim existing lights by installing dimmer switches on the lighting or by placing a shade or filtering cover on the lighting.",
        "solution_number": 1020203,
        "strategy": "Reduce glare from lighting",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is difficult to see",
        "solution": "Open / close curtains or blinds",
        "solution_long": "Close curtains or blinds to adjust the amount and direction of light.",
        "solution_number": 1020204,
        "strategy": "Reduce glare from lighting",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020205": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is difficult to see",
        "solution": "Apply tinted film on windows",
        "solution_long": "Apply tinted or opaque film on windows.",
        "solution_number": 1020205,
        "strategy": "Reduce glare from lighting",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is difficult to see",
        "solution": "Wear tinted glasses or a visor",
        "solution_long": "Allow the employee to wear tinted glasses (e.g., sunglasses) or a hat with a visor.",
        "solution_number": 1020301,
        "strategy": "Shade employee's eyes from bright lights",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is difficult to see",
        "solution": "Reduce the glare from walls",
        "solution_long": "Reduce the glare from walls by adding a non-glare wall covering (wallpaper, curtain, or hanging) or painting the walls with matte paint that does not produce glare. Avoid high gloss paint. Certain wall colors (e.g., green) can also reduce glare.<ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 1020401,
        "strategy": "Change wall/floor surfaces to reduce glare",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is difficult to see",
        "solution": "Replace with a non-glare flooring",
        "solution_long": "Reduce the glare from floors by replacing the flooring with a non-glare, hard surface or carpet or by adding satin or matte finish to the flooring surface.<ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 1020402,
        "strategy": "Change wall/floor surfaces to reduce glare",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is difficult to see",
        "solution": "Use contrasting colors on floor/walls",
        "solution_long": "Choose building colors so that there is a high contrast between the floor and walls. ",
        "solution_number": 1020501,
        "strategy": "Make the path stand out from the surrounding walls",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1020601": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is difficult to see",
        "solution": "Use solid-color flooring  ",
        "solution_long": "Use solid-color flooring with minimal pattern. In particular, avoid patterns that might suggest an uneven surface.<ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 1020601,
        "strategy": "Reduce visual distractions on the path",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V1 || a.V3) && (a.LOW) && (a.T0102)"
    },
    "1030101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is sloped or uneven",
        "solution": "Replace the floor surface",
        "solution_long": "Replace the floor surface with a solid, level flooring.<ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 1030101,
        "strategy": "Reduce the floor slope",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0103)"
    },
    "1030201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is sloped or uneven",
        "solution": "Add a handrail",
        "solution_long": "Add a handrail in areas where the floor slopes.",
        "solution_number": 1030201,
        "strategy": "Provide support in areas with noticeable floor slope",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0103)"
    },
    "1030301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is sloped or uneven",
        "solution": "Remove small throw rugs",
        "solution_long": "Remove small throw rugs.",
        "solution_number": 1030301,
        "strategy": "Reduce changes in the floor surface",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0103)"
    },
    "1030302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is sloped or uneven",
        "solution": "Repair any loose or damaged flooring",
        "solution_long": "Repair loose carpets, linoleum, or floorboards.",
        "solution_number": 1030302,
        "strategy": "Reduce changes in the floor surface",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0103)"
    },
    "1030303": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is sloped or uneven",
        "solution": "Avoid or ramp changes in floor level",
        "solution_long": "Vertical changes in level should be limited to 1/4 inch high maximum.  Changes greater than 1/2 inch high should be ramped [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#303-changes-in-level\" target=\"_blank\">ADAAS: 303.2 and 303.4</a>].",
        "solution_number": 1030303,
        "strategy": "Reduce changes in the floor surface",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0103)"
    },
    "1030401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is sloped or uneven",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem. ",
        "solution_number": 1030401,
        "strategy": "Avoid the barrier",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0103)"
    },
    "1040101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is not wide enough",
        "solution": "Remove obstacles",
        "solution_long": "Remove obstacles from the path of travel.  This might include moving lamps, trash cans, plants, or seasonal decorations out of the way.",
        "solution_number": 1040101,
        "strategy": "Widen the path",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0104)"
    },
    "1040102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is not wide enough",
        "solution": "Move furniture to widen the pathway",
        "solution_long": "Move furniture to widen the pathway and allow the employee to pass through comfortably (the clear width of walking surfaces should be 36 inches minimum) [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#403-walking-surfaces\" target=\"_blank\">ADAAS: 403.5.1</a>]. ",
        "solution_number": 1040102,
        "strategy": "Widen the path",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0104)"
    },
    "1040103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is not wide enough",
        "solution": "Reposition wall or panels ",
        "solution_long": "Reposition walls or panels to widen the path of travel.  Under the ADA Accessibility Standards, hallways should be a minimum of 36 inches wide [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#403-walking-surfaces\" target=\"_blank\">ADAAS: 403.5.1</a>]. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1040103,
        "strategy": "Widen the path",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0104)"
    },
    "1040104": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is not wide enough",
        "solution": "Provide extra turning space at doors",
        "solution_long": "Extra space may be needed at doorways. When wheelchairs make tight turns, they can collide with the door frame and possibly damage it.",
        "solution_number": 1040104,
        "strategy": "Widen the path",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0104)"
    },
    "1040201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is not wide enough",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem. ",
        "solution_number": 1040201,
        "strategy": "Avoid the barrier",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0104)"
    },
    "1040202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is not wide enough",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to avoid the access problem. If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 1040202,
        "strategy": "Avoid the barrier",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0104)"
    },
    "1040203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is not wide enough",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 1040203,
        "strategy": "Avoid the barrier",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0104)"
    },
    "1040204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is not wide enough",
        "solution": "Allow telework ",
        "solution_long": "If it is impossible or impractical to modify the facility, consider allowing the employee to telework as an accommodation.",
        "solution_number": 1040204,
        "strategy": "Avoid the barrier",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0104)"
    },
    "1050101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is obstructed",
        "solution": "Remove obstacles from the path",
        "solution_long": "Remove obstacles from the path of travel (e.g., move boxes out of the way). Free-standing objects with leading edges 27-80 inches high that are mounted on posts or pylons cannot protrude more than 12 inches into circulation paths. The 12 inch limit also applies to the clearance between multiple posts (excluding the sloping portions of handrails) [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#307-protruding-objects\" target=\"_blank\">ADAAS: 307.3</a>].",
        "solution_number": 1050101,
        "strategy": "Remove obstacles",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0105)"
    },
    "1050102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is obstructed",
        "solution": "Move furniture to widen the pathway\r\n",
        "solution_long": "Move furniture, plants, signs, etc. to widen the pathway and allow the employee to pass through comfortably (typically a minimum width of 36 inches throughout the entire path) [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#403-walking-surfaces\" target=\"_blank\">ADAAS: 403.5.1</a>].",
        "solution_number": 1050102,
        "strategy": "Remove obstacles",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0105)"
    },
    "1050103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is obstructed",
        "solution": "Remove or move power cords",
        "solution_long": "Remove or move power cords from the path of travel or run them under the flooring.",
        "solution_number": 1050103,
        "strategy": "Remove obstacles",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0105)"
    },
    "1050104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is obstructed",
        "solution": "Set policies to keep paths clear",
        "solution_long": "Institute policies/practices to keep travel paths free of obstacles (e.g., boxes). ",
        "solution_number": 1050104,
        "strategy": "Remove obstacles",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0105)"
    },
    "1050105": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is obstructed",
        "solution": "Provide sufficient headroom along path",
        "solution_long": "Headroom clearance of at least 80 inches high is required along all circulation paths (except at doorways where a 78 inch minimum clearance is permitted to accommodate door stops and closers). Free-standing objects with leading edges 27-80 inches high that are mounted on posts or pylons cannot protrude more than 12 inches into circulation paths. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#307-protruding-objects\" target=\"_blank\">ADAAS: 307.3 and 307.4</a>]",
        "solution_number": 1050105,
        "strategy": "Remove obstacles",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0105)"
    },
    "1050201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is obstructed",
        "solution": "Use floor with solid, contrasting color",
        "solution_long": "Provide a floor surface (via paint, carpet, etc.) that has a solid, contrasting color.<ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1050201,
        "strategy": "Make obstacles more visible",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0105)"
    },
    "1060101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is too slippery",
        "solution": "Apply slip resistant coating or texture",
        "solution_long": "Add slip resistant coating or texture (e.g., sand added to finish). <ul><li>Finishes used to increase a surface material's slip resistance may require continued maintenance or re-application. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#302-floor-or-ground-surfaces\" target=\"_blank\">ADAAS: 302.1</a>] </li> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1060101,
        "strategy": "Change the flooring surface ",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0106)"
    },
    "1060102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is too slippery",
        "solution": "Add a slip resistant rug or mat",
        "solution_long": "Add a low-pile area rug with a slip resistant backing.",
        "solution_number": 1060102,
        "strategy": "Change the flooring surface ",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0106)"
    },
    "1060103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is too slippery",
        "solution": "Replace with a low pile carpet",
        "solution_long": "Replace the existing flooring with a low-pile carpet.<ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1060103,
        "strategy": "Change the flooring surface ",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0106)"
    },
    "1060104": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is too slippery",
        "solution": "Replace with slip resistant flooring",
        "solution_long": "Replace the existing flooring with slip resistant flooring.<ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1060104,
        "strategy": "Change the flooring surface ",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0106)"
    },
    "1060201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is too slippery",
        "solution": "Provide floor mats at exterior doors",
        "solution_long": "Provide floor mats at exterior doors to remove dirt, moisture and debris from the exterior. ",
        "solution_number": 1060201,
        "strategy": "Keep the floor dry",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0106)"
    },
    "1060202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is too slippery",
        "solution": "Provide bags for wet umbrellas",
        "solution_long": "Provide bags for wet umbrellas.",
        "solution_number": 1060202,
        "strategy": "Keep the floor dry",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0106)"
    },
    "1070101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path offers resistance to movement",
        "solution": "Use a vinyl runner ",
        "solution_long": "Use a vinyl runner that provides a firmer floor surface.",
        "solution_number": 1070101,
        "strategy": "Change the flooring surface",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0107)"
    },
    "1070102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path offers resistance to movement",
        "solution": "Remove throw rugs and thicker mats",
        "solution_long": "Remove throw rugs and thicker mats.",
        "solution_number": 1070102,
        "strategy": "Change the flooring surface",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0107)"
    },
    "1070103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path offers resistance to movement",
        "solution": "Remove or use minimal carpet padding",
        "solution_long": "Remove or use minimal carpet padding. <ul><li>Carpeting must be securely attached so that it does not shift or buckle against wheeled traffic. Cushions or pads, if used, also must be properly secured to resist movement.</li> <li>Exposed edges must have trim on the entire length of the exposed edge and be fastened to the floor to prevent curling.</li> <li>[<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#302-floor-or-ground-surfaces\" target=\"_blank\">ADAAS: 302.2</a>] </li> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1070103,
        "strategy": "Change the flooring surface",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0107)"
    },
    "1070104": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path offers resistance to movement",
        "solution": "Replace the existing carpet",
        "solution_long": "Replace the existing carpet with tile, linoleum, or other hard surface flooring. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1070104,
        "strategy": "Change the flooring surface",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0107)"
    },
    "1070105": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path offers resistance to movement",
        "solution": "Replace with a low-pile carpet",
        "solution_long": "Replace the existing carpet with a low-pile carpet.  A pile thickness up to 1/2 inch is allowed, but a lower pile provides easier wheelchair maneuvering. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1070105,
        "strategy": "Change the flooring surface",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0107)"
    },
    "1070106": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path offers resistance to movement",
        "solution": "Limit size of grates / floor openings",
        "solution_long": "Openings in ground and floor surfaces, such as grates, should be limited in width to prevent passage of a ½ inch diameter sphere.  Wheelchair casters can get wedged into wider openings. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#302-floor-or-ground-surfaces\" target=\"_blank\">ADAAS: 302.3</a>] <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 1070106,
        "strategy": "Change the flooring surface",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0107)"
    },
    "1080101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Route is too long",
        "solution": "Add rest point along the path",
        "solution_long": "Add a stopping / rest point along the path of travel.",
        "solution_number": 1080101,
        "strategy": "Enable shorter periods of travel",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c) && (a.LOW) && (a.T0108)"
    },
    "1080201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Route is too long",
        "solution": "Reduce travel distance",
        "solution_long": "Reduce the distance that needs to be traveled.  <ul><li>For example, have the person enter the workplace at a point closer to his or her work area. </li></ul>",
        "solution_number": 1080201,
        "strategy": "Shorten the travel route",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c) && (a.LOW) && (a.T0108)"
    },
    "1080202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Route is too long",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to avoid the access problem. If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 1080202,
        "strategy": "Shorten the travel route",
        "task": "Moving Through the Workplace",
        "trigger": "(a.L8a || a.L8b || a.L8c) && (a.LOW) && (a.T0108)"
    },
    "1090101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path provides visual distractions",
        "solution": "Use flooring with minimal pattern",
        "solution_long": "Use solid-color flooring with minimal pattern. In particular, avoid patterns that might suggest an uneven surface. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1090101,
        "strategy": "Remove visual distractions",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.A1) && (a.LOW) && (a.T0109)"
    },
    "1090201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is noisy (auditory distractions, auditory cues masked)",
        "solution": "Change the flooring to reduce echo",
        "solution_long": "Change the flooring to reduce echo, perhaps by installing sound dampening, low pile carpet. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1090201,
        "strategy": "Provide sound dampening",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.C1 || a.A1) && (a.LOW) && (a.T0109)"
    },
    "1090202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is noisy (auditory distractions, auditory cues masked)",
        "solution": "Add sound absorbing panels",
        "solution_long": "Install sound absorbing panels on the walls and ceiling. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1090202,
        "strategy": "Provide sound dampening",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.C1 || a.A1) && (a.LOW) && (a.T0109)"
    },
    "1090203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is noisy (auditory distractions, auditory cues masked)",
        "solution": "Remove fluorescent lights",
        "solution_long": "Change lighting to minimize the humming of fluorescent lights. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1090203,
        "strategy": "Provide sound dampening",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.C1 || a.A1) && (a.LOW) && (a.T0109)"
    },
    "1090204": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is noisy (auditory distractions, auditory cues masked)",
        "solution": "Add acoustic ceiling tiles",
        "solution_long": "Install acoustic ceiling tiles to improve the acoustical qualities of the space. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 1090204,
        "strategy": "Provide sound dampening",
        "task": "Moving Through the Workplace",
        "trigger": "(a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.C1 || a.A1) && (a.LOW) && (a.T0109)"
    },
    "2010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Correct door key is difficult to identify",
        "solution": "Mark the key for easier identification",
        "solution_long": "Mark the key for easier identification.",
        "solution_number": 2010101,
        "strategy": "Use reminders / cues",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6 || a.H6 || a.C2) && (a.LOW) && (a.T0201)"
    },
    "2020101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door lock is difficult to locate",
        "solution": "Remove obstacles",
        "solution_long": "Remove obstacles that may be blocking view of the lock.",
        "solution_number": 2020101,
        "strategy": "Remove blocking obstacles",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0202)"
    },
    "2020201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door lock is difficult to locate",
        "solution": "Use contrasting colors for door/lock",
        "solution_long": "Change the color of the lock or of the door to increase contrast.",
        "solution_number": 2020201,
        "strategy": "Increase the contrast between lock and door",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0202)"
    },
    "2020301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door lock is difficult to locate",
        "solution": "Use an electric or remote-control lock",
        "solution_long": "Install an electric or remote-control lock. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2020301,
        "strategy": "Eliminate the need to operate a lock",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6) && (a.LOW) && (a.T0202)"
    },
    "2020302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door lock is difficult to locate",
        "solution": "Remove the lock / unlock the door",
        "solution_long": "Remove the lock (interior doors) or keep the door unlocked during the day (interior or exterior).",
        "solution_number": 2020302,
        "strategy": "Eliminate the need to operate a lock",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0202)"
    },
    "2030101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door lock is difficult to reach",
        "solution": "Provide a clear floor space near lock",
        "solution_long": "Provide a clear space near the lock with a 5-foot <a href=\"/glossary#turning radius\" target=\"_blank\">turning radius</a>.",
        "solution_number": 2030101,
        "strategy": "Enable the employee to get closer to the lock",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0203)"
    },
    "2030102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door lock is difficult to reach",
        "solution": "Remove nearby obstacles",
        "solution_long": "Remove nearby obstacles.",
        "solution_number": 2030102,
        "strategy": "Enable the employee to get closer to the lock",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0203)"
    },
    "2030201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A locksmith or licensed contractor might be helpful",
        "gap": "Door lock is difficult to reach",
        "solution": "Move lock to the opposite side",
        "solution_long": "Move the lock to the opposite side of the door.",
        "solution_number": 2030201,
        "strategy": "Change the lock position",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0203)"
    },
    "2030202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A locksmith or licensed contractor might be helpful",
        "gap": "Door lock is difficult to reach",
        "solution": "Adjust the height of the door lock",
        "solution_long": "Raise / lower the door lock to an appropriate height. The lock should be located between 34 and 48 inches from the ground, based on the ADA's maximum high forward reach of 48 inches (1220 mm) [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#308-reach-ranges\" target=\"_blank\">ADAAS: 308.2.1</a>]",
        "solution_number": 2030202,
        "strategy": "Change the lock position",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0203)"
    },
    "2030301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door lock is difficult to reach",
        "solution": "Use an electric or remote-control lock",
        "solution_long": "Install an electric or remote-control lock. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2030301,
        "strategy": "Eliminate the need to operate a lock",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3) && (a.LOW) && (a.T0203)"
    },
    "2030302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door lock is difficult to reach",
        "solution": "Remove the lock / unlock the door",
        "solution_long": "Remove the lock (interior doors) or keep the door unlocked during the day (interior or exterior).",
        "solution_number": 2030302,
        "strategy": "Eliminate the need to operate a lock",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3) && (a.LOW) && (a.T0203)"
    },
    "2040101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key is difficult to grip, hold",
        "solution": "Replace it with another key",
        "solution_long": "Replace it with another key.",
        "solution_number": 2040101,
        "strategy": "Change the key's size / shape",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0204)"
    },
    "2040102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: An OT may be helpful in finding or making an appropriate gripping aid.",
        "gap": "Key is difficult to grip, hold",
        "solution": "Use a key with a larger gripping area",
        "solution_long": "Use an <a href=\"/glossary#adapted\" target=\"_blank\">adapted</a> key aid or build up the key to make it easier to grip.",
        "solution_number": 2040102,
        "strategy": "Change the key's size / shape",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0204)"
    },
    "2040201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key is difficult to grip, hold",
        "solution": "Change the key texture",
        "solution_long": "Change the texture of the existing key by using key covers or key caps.",
        "solution_number": 2040201,
        "strategy": "Change the key's texture",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0204)"
    },
    "2040202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key is difficult to grip, hold",
        "solution": "Replace it with another key",
        "solution_long": "Replace it with another key.",
        "solution_number": 2040202,
        "strategy": "Change the key's texture",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0204)"
    },
    "2040203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key is difficult to grip, hold",
        "solution": "Wear a glove to improve grip",
        "solution_long": "Wear a glove to improve grip.",
        "solution_number": 2040203,
        "strategy": "Change the key's texture",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0204)"
    },
    "2040301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A locksmith or licensed contractor might be helpful ",
        "gap": "Key is difficult to grip, hold",
        "solution": "Replace the lock",
        "solution_long": "Replace the lock with one that does not use a key.",
        "solution_number": 2040301,
        "strategy": "Eliminate the need to use a key",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0204)"
    },
    "2040302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Key is difficult to grip, hold",
        "solution": "Use an electric or remote-control lock",
        "solution_long": "Install an electric or remote-control lock. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2040302,
        "strategy": "Eliminate the need to use a key",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0204)"
    },
    "2040303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key is difficult to grip, hold",
        "solution": "Remove the lock / unlock the door",
        "solution_long": "Remove the lock (interior doors) or keep the door unlocked during the day (interior or exterior).",
        "solution_number": 2040303,
        "strategy": "Eliminate the need to use a key",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0204)"
    },
    "2050101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key is difficult to turn, push, pull",
        "solution": "Replace it with another key",
        "solution_long": "Replace it with another key.",
        "solution_number": 2050101,
        "strategy": "Change the key's size / shape",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0205)"
    },
    "2050102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: An OT may be helpful in finding or making an appropriate gripping aid.",
        "gap": "Key is difficult to turn, push, pull",
        "solution": "Use a key with a larger gripping area",
        "solution_long": "Use an <a href=\"/glossary#adapted\" target=\"_blank\">adapted</a> key aid or build up the key to make it easier to grip.",
        "solution_number": 2050102,
        "strategy": "Change the key's size / shape",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0205)"
    },
    "2050201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A locksmith or licensed contractor might be helpful",
        "gap": "Key is difficult to turn, push, pull",
        "solution": "Move the lock to the opposite side",
        "solution_long": "Move the lock to the opposite side.",
        "solution_number": 2050201,
        "strategy": "Change the position of the lock",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0205)"
    },
    "2050301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key is difficult to turn, push, pull",
        "solution": "Adjust the strike plate",
        "solution_long": "Adjust the <a href=\"/glossary#strike plate\" target=\"_blank\">strike plate</a> to align better with the lock.",
        "solution_number": 2050301,
        "strategy": "Reduce the force needed to insert / turn the key",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0205)"
    },
    "2050302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key is difficult to turn, push, pull",
        "solution": "Lubricate the lock mechanism",
        "solution_long": "Put lubricant in the lock mechanism to make it easier to operate.",
        "solution_number": 2050302,
        "strategy": "Reduce the force needed to insert / turn the key",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0205)"
    },
    "2050303": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A locksmith or licensed contractor might be helpful",
        "gap": "Key is difficult to turn, push, pull",
        "solution": "Replace the lock",
        "solution_long": "Replace the lock with another type of key lock. It should be operable with one hand and should not require tight grasping, pinching, or twisting of the wrist. The force required to activate it should be 5 pounds (22.2 N) maximum. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#309-operable-parts target=\"_blank\">ADAAS: 309.4</a>]",
        "solution_number": 2050303,
        "strategy": "Reduce the force needed to insert / turn the key",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0205)"
    },
    "2050401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A locksmith or licensed contractor might be helpful",
        "gap": "Key is difficult to turn, push, pull",
        "solution": "Replace the lock",
        "solution_long": "Replace the lock with one that does not use a key.",
        "solution_number": 2050401,
        "strategy": "Eliminate the need to use a key",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0205)"
    },
    "2050402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Key is difficult to turn, push, pull",
        "solution": "Use an electric or remote-control lock",
        "solution_long": "Install an electric or remote-control lock. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2050402,
        "strategy": "Eliminate the need to use a key",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0205)"
    },
    "2050403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key is difficult to turn, push, pull",
        "solution": "Remove the lock / unlock the door",
        "solution_long": "Remove the lock (interior doors) or keep the door unlocked during the day (interior or exterior).",
        "solution_number": 2050403,
        "strategy": "Eliminate the need to use a key",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0205)"
    },
    "2060101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door access button or key reader is difficult to locate ",
        "solution": "Remove obstacles blocking view",
        "solution_long": "Remove obstacles that may be blocking view of the access button or key card reader.",
        "solution_number": 2060101,
        "strategy": "Remove blocking obstacles",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0206)"
    },
    "2060201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door access button or key reader is difficult to locate ",
        "solution": "Use contrasting colors on system/walls",
        "solution_long": "Change the color of the access system or of the background (door, wall) to increase contrast.",
        "solution_number": 2060201,
        "strategy": "Increase the contrast between the system and the wall / door",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0206)"
    },
    "2060301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door access button or key reader is difficult to locate ",
        "solution": "Move security devices closer to door",
        "solution_long": "Move security access buttons or key card readers closer to the door.  <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2060301,
        "strategy": "Reposition the door security controls",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0206)"
    },
    "2070101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door access button or key reader is difficult to reach",
        "solution": "Provide clear space by security system",
        "solution_long": "Provide a clear space near the access button or key card reader with a 5-foot <a href=\"/glossary#turning radius\" target=\"_blank\">turning radius</a>.",
        "solution_number": 2070101,
        "strategy": "Enable employee to get closer to the system",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0207)"
    },
    "2070102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door access button or key reader is difficult to reach",
        "solution": "Remove nearby obstacles",
        "solution_long": "Remove nearby obstacles. ",
        "solution_number": 2070102,
        "strategy": "Enable employee to get closer to the system",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0207)"
    },
    "2070201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door access button or key reader is difficult to reach",
        "solution": "Change height of security devices",
        "solution_long": "Raise / lower the door access button or card reader to an appropriate height.  The control should be located between 34 and 48 inches from the ground, based on the ADA's maximum high forward reach of 48 inches (1220 mm).  [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#308-reach-ranges target=\"_blank\">ADAAS: 308.2.1</a>] <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2070201,
        "strategy": "Change position of access button or card reader",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0207)"
    },
    "2070202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door access button or key reader is difficult to reach",
        "solution": "Move security system to opposite side",
        "solution_long": "Move the security system to the opposite side of the door. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2070202,
        "strategy": "Change position of access button or card reader",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0207)"
    },
    "2070301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door access button or key reader is difficult to reach",
        "solution": "Reposition the card reader",
        "solution_long": "Reposition the card reader so that the swipe direction is changed from vertical to horizontal (or the reverse). <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2070301,
        "strategy": "Change the card reader swipe direction",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0207)"
    },
    "2070401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door access button or key reader is difficult to reach",
        "solution": "Install a proximity card reader",
        "solution_long": "Install a <a href=\"/glossary#proximity card reader\" target=\"_blank\">proximity card reader</a>. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2070401,
        "strategy": "Eliminate the need to operate an access button or card reader",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3) && (a.LOW) && (a.T0207)"
    },
    "2070402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door access button or key reader is difficult to reach",
        "solution": "Use an electric or remote-control lock",
        "solution_long": "Install an electric or remote-control lock. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2070402,
        "strategy": "Eliminate the need to operate an access button or card reader",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3) && (a.LOW) && (a.T0207)"
    },
    "2070403": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door access button or key reader is difficult to reach",
        "solution": "Remove security system",
        "solution_long": "Remove the door access button or card reader. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2070403,
        "strategy": "Eliminate the need to operate an access button or card reader",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3) && (a.LOW) && (a.T0207)"
    },
    "2070501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door access button or key reader is difficult to reach",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.",
        "solution_number": 2070501,
        "strategy": "Avoid the barrier",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3) && (a.LOW) && (a.T0207)"
    },
    "2070502": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door access button or key reader is difficult to reach",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to avoid the access problem. If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 2070502,
        "strategy": "Avoid the barrier",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3) && (a.LOW) && (a.T0207)"
    },
    "2080101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door access button is difficult to operate due to size, shape, or force",
        "solution": "Use a hand-held stick",
        "solution_long": "Use a hand-held stick to activate the access button.",
        "solution_number": 2080101,
        "strategy": "Use a stick to extend reach",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U1 || a.U2) && (a.LOW) && (a.T0208)"
    },
    "2080201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door access button is difficult to operate due to size, shape, or force",
        "solution": "Use lower-force security device",
        "solution_long": "Replace it with another type of access button or security device that requires less force. <ul><wafactors RNT><li>If the workspace leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2080201,
        "strategy": "Change the force needed to activate the access button ",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U1 || a.U2 || a.U10 || a.U11) && (a.LOW) && (a.T0208)"
    },
    "2080301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door access button is difficult to operate due to size, shape, or force",
        "solution": "Use larger-button security device",
        "solution_long": "Replace it with another type of access button or security device that has larger controls. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2080301,
        "strategy": "Change the access button's size or shape",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0208)"
    },
    "2080401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door access button is difficult to operate due to size, shape, or force",
        "solution": "Install a proximity card reader",
        "solution_long": "Install a <a href=\"/glossary#proximity card reader\" target=\"_blank\">proximity card reader</a>. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2080401,
        "strategy": "Eliminate the need to operate an access button",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0208)"
    },
    "2080402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door access button is difficult to operate due to size, shape, or force",
        "solution": "Use an electric or remote-control lock",
        "solution_long": "Install an electric or remote-control lock. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2080402,
        "strategy": "Eliminate the need to operate an access button",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0208)"
    },
    "2090101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door key card reader is difficult to operate due to shape or swiping movement",
        "solution": "Use different type of security device",
        "solution_long": "Replace it with another type of access button or security device. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2090101,
        "strategy": "Change the card reader force and/or shape",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U1 || a.U2 || a.U10 || a.U11) && (a.LOW) && (a.T0209)"
    },
    "2090201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door key card reader is difficult to operate due to shape or swiping movement",
        "solution": "Reposition the card reader",
        "solution_long": "Remount the card reader in another direction.",
        "solution_number": 2090201,
        "strategy": "Change the swipe direction of the card reader",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U1 || a.U2 || a.U10 || a.U11) && (a.LOW) && (a.T0209)"
    },
    "2090301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door key card reader is difficult to operate due to shape or swiping movement",
        "solution": "Install a proximity card reader",
        "solution_long": "Install a <a href=\"/glossary#proximity card reader\" target=\"_blank\">proximity card reader</a>. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2090301,
        "strategy": "Eliminate the need to swipe an access card",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0209)"
    },
    "2090302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door key card reader is difficult to operate due to shape or swiping movement",
        "solution": "Remove the card reader",
        "solution_long": "Remove the card reader. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2090302,
        "strategy": "Eliminate the need to swipe an access card",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0209)"
    },
    "2090401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door key card reader is difficult to operate due to shape or swiping movement",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.",
        "solution_number": 2090401,
        "strategy": "Avoid the barrier",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0209)"
    },
    "2090402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door key card reader is difficult to operate due to shape or swiping movement",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to avoid the access problem. If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 2090402,
        "strategy": "Avoid the barrier",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0209)"
    },
    "2100101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Unlocking process is difficult to remember ",
        "solution": "Place directions on the system",
        "solution_long": "Place directions on the system and/or card to show proper <a href=\"/glossary#orientation\" target=\"_blank\">orientation</a> and swiping movement of card.",
        "solution_number": 2100101,
        "strategy": "Use reminders that show access card operation",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.C2) && (a.LOW) && (a.T0210)"
    },
    "2100102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Unlocking process is difficult to remember ",
        "solution": "Carry a reminder",
        "solution_long": "Carry a reminder with the correct access code.",
        "solution_number": 2100102,
        "strategy": "Use reminders that show access code operation",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.C2) && (a.LOW) && (a.T0210)"
    },
    "2110101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door is difficult to see ",
        "solution": "Outline the door with tape",
        "solution_long": "Outline the door frame with contrasting tape.",
        "solution_number": 2110101,
        "strategy": "Increase the contrast between the door and wall",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0211)"
    },
    "2110102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door is difficult to see ",
        "solution": "Use contrasting colors on door/wall",
        "solution_long": "Paint the door and frame / wall contrasting colors.",
        "solution_number": 2110102,
        "strategy": "Increase the contrast between the door and wall",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.V2 || a.V3 || a.V4) && (a.LOW) && (a.T0211)"
    },
    "2110201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door is difficult to see ",
        "solution": "Increase texture of door/ door frame",
        "solution_long": "Increase the texture of the door / door frame.",
        "solution_number": 2110201,
        "strategy": "Provide tactile cues",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0211)"
    },
    "2120101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Change type of door handle",
        "solution_long": "Replace the door handle with one that operates differently (e.g., lever vs. knob).",
        "solution_number": 2120101,
        "strategy": "Change the process by which the door handle operates",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0212)"
    },
    "2120201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Change texture of the door handle",
        "solution_long": "Change the texture of the door handle (e.g., add gripping aid).",
        "solution_number": 2120201,
        "strategy": "Change the door handle's size / shape / texture",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0212)"
    },
    "2120202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Use easier-to-grip door handle",
        "solution_long": "Replace the door handle with one that is easier to grip.",
        "solution_number": 2120202,
        "strategy": "Change the door handle's size / shape / texture",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0212)"
    },
    "2120203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Add bigger gripping surface to handle",
        "solution_long": "Use an <a href=\"/glossary#adapted\" target=\"_blank\">adapted</a>door handle aid or build up the handle.",
        "solution_number": 2120203,
        "strategy": "Change the door handle's size / shape / texture",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0212)"
    },
    "2120301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Adjust the strike plate",
        "solution_long": "Adjust the <a href=\"/glossary#strike plate\" target=\"_blank\">strike plate</a> to line up better with the door latch.",
        "solution_number": 2120301,
        "strategy": "Reduce the force needed to operate the door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0212)"
    },
    "2120302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Lubricate the door handle mechanism",
        "solution_long": "Put lubricant in the door handle mechanism to make it easier to operate.",
        "solution_number": 2120302,
        "strategy": "Reduce the force needed to operate the door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0212)"
    },
    "2120303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Replace with lower-force door handle",
        "solution_long": "Replace the door handle with one that requires less force.",
        "solution_number": 2120303,
        "strategy": "Reduce the force needed to operate the door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0212)"
    },
    "2120401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Move the door handle",
        "solution_long": "Move the door handle farther away from the door frame.",
        "solution_number": 2120401,
        "strategy": "Increase the distance of the door handle from the door frame",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0212)"
    },
    "2120402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Replace the door handle",
        "solution_long": "Replace the door handle with one that sits further from the door frame.",
        "solution_number": 2120402,
        "strategy": "Increase the distance of the door handle from the door frame",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0212)"
    },
    "2120501": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Use an automatic door opener / closer",
        "solution_long": "Install an automatic door opener / closer. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2120501,
        "strategy": "Eliminate the need to use a door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0212)"
    },
    "2120502": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Prop the door open (interior doors)",
        "solution_long": "Prop interior doors open.  However, this option is not allowed for fire doors.",
        "solution_number": 2120502,
        "strategy": "Eliminate the need to use a door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0212)"
    },
    "2120503": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to operate (e.g., grip, turn)",
        "solution": "Remove the door handle / latch ",
        "solution_long": "Remove the door handle / latch so that the door can simply be pushed open (interior, non-fire doors).",
        "solution_number": 2120503,
        "strategy": "Eliminate the need to use a door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0212)"
    },
    "2130101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to reach",
        "solution": "Provide a clear floor space near door",
        "solution_long": "Provide a clear space near the door with a 5 foot <a href=\"/glossary#turning radius\" target=\"_blank\">turning radius</a>.",
        "solution_number": 2130101,
        "strategy": "Enable the employee to get closer to the door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0213)"
    },
    "2130102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to reach",
        "solution": "Remove nearby obstacles",
        "solution_long": "Remove nearby obstacles.",
        "solution_number": 2130102,
        "strategy": "Enable the employee to get closer to the door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0213)"
    },
    "2130201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door handle is difficult to reach",
        "solution": "Adjust the height of the door handle",
        "solution_long": "Raise / lower the door handle to an appropriate height.  The door handle should be located between 34 and 48 inches from the ground, based on the ADA's maximum high forward reach of 48 inches (1220 mm) [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#308-reach-ranges\" target=\"_blank\">ADAAS: 308.2.1</a>]",
        "solution_number": 2130201,
        "strategy": "Change the height of the door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0213)"
    },
    "2130301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door handle is difficult to reach",
        "solution": "Move handle to the opposite side",
        "solution_long": "Move the handle to the opposite side of the door.",
        "solution_number": 2130301,
        "strategy": "Change which side the door handle is on",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0213)"
    },
    "2130401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door handle is difficult to reach",
        "solution": "Use an automatic door opener / closer",
        "solution_long": "Use an automatic door opener / closer. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2130401,
        "strategy": "Eliminate the need to use a door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3) && (a.LOW) && (a.T0213)"
    },
    "2130402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to reach",
        "solution": "Prop the door open (interior doors)",
        "solution_long": "Prop interior doors open.  Note that this option is not allowed for fire doors.",
        "solution_number": 2130402,
        "strategy": "Eliminate the need to use a door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3) && (a.LOW) && (a.T0213)"
    },
    "2130403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door handle is difficult to reach",
        "solution": "Remove the door handle / latch",
        "solution_long": "Remove the door handle / latch so that the door can simply be pushed open (interior, non-fire  doors).",
        "solution_number": 2130403,
        "strategy": "Eliminate the need to use a door handle",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3) && (a.LOW) && (a.T0213)"
    },
    "2140101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door is too heavy",
        "solution": "Adjust the swing arm force",
        "solution_long": "Adjust the swing arm force so that it provides less resistance.  Interior hinged doors and gates other than fire doors should require a maximum pushing/pulling force of 5 pounds (22.2 N).  [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#404-doors-doorways-and-gates target=\"_blank\">ADAAS: 404.2.9</a>].  Fire doors should have a minimum opening force allowable by the appropriate administrative authority.  ",
        "solution_number": 2140101,
        "strategy": "Reduce the door's weight / resistance",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0214)"
    },
    "2140102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door is too heavy",
        "solution": "Install a light-weight hinged door ",
        "solution_long": "Install a light-weight hinged door.  Interior hinged doors and gates other than fire doors should require a maximum pushing/pulling force of 5 pounds (22.2 N).  [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#404-doors-doorways-and-gates target=\"_blank\">ADAAS: 404.2.9</a>].  Fire doors should have a minimum opening force allowable by the appropriate administrative authority.  ",
        "solution_number": 2140102,
        "strategy": "Reduce the door's weight / resistance",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0214)"
    },
    "2140201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door is too heavy",
        "solution": "Prop the door open (interior doors)",
        "solution_long": "Prop interior doors open.  Note that this option is not allowed for fire doors.",
        "solution_number": 2140201,
        "strategy": "Eliminate the need to push or pull the door",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0214)"
    },
    "2140202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door is too heavy",
        "solution": "Use power assist door opener / closer",
        "solution_long": "Install a power assist door opener / closer. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2140202,
        "strategy": "Eliminate the need to push or pull the door",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0214)"
    },
    "2140203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door is too heavy",
        "solution": "Use an automatic door opener / closer",
        "solution_long": "Install an automatic door opener / closer. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2140203,
        "strategy": "Eliminate the need to push or pull the door",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0214)"
    },
    "2150101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door closes too fast",
        "solution": "Adjust the swing arm timing ",
        "solution_long": "Door closers and gate closers should be adjusted so that from an open position of 90 degrees, the time required to move the door to a position of 12 degrees from the latch is 5 seconds minimum [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#404-doors-doorways-and-gates\" target=\"_blank\">ADAAS: 404.2.8</a>].",
        "solution_number": 2150101,
        "strategy": "Increase the door's closure time",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0215)"
    },
    "2150102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door closes too fast",
        "solution": "Adjust automatic door swing timing",
        "solution_long": "For a door with an automatic closer, adjust the swing timing on the door.",
        "solution_number": 2150102,
        "strategy": "Increase the door's closure time",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0215)"
    },
    "2150201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door closes too fast",
        "solution": "Move door controls closer to the door",
        "solution_long": "Move the automatic door opener controls closer to the door so that the employee will have less distance to cover while the door is still open.  Make sure that the control is still far enough from the door so that people operating it are outside the arc of the door swing. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#404-doors-doorways-and-gates\" target=\"_blank\">ADAAS: 404.3.5</a>]. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2150201,
        "strategy": "Reposition the door opener controls",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0215)"
    },
    "2150301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door closes too fast",
        "solution": "Increase time that door is unlocked",
        "solution_long": "If door security controls are far from the door, keep the door unlocked longer to give the employee sufficient time to reach and open the door.",
        "solution_number": 2150301,
        "strategy": "Increase the unlocking time on door security controls",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0215)"
    },
    "2150401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door closes too fast",
        "solution": "Move security devices closer to door",
        "solution_long": "Move security access buttons or key card readers closer to the door so that the  employee will have less distance to cover to reach the door.  Make sure that the control is still far enough from the door so that people operating the door control are outside the arc of the door swing [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#404-doors-doorways-and-gates\" target=\"_blank\">ADAAS: 404.3.5</a>]. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2150401,
        "strategy": "Reposition the door security controls",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0215)"
    },
    "2150501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door closes too fast",
        "solution": "Prop the door open (interior doors)",
        "solution_long": "Prop interior doors open.  Note that this option is not allowed for fire doors.",
        "solution_number": 2150501,
        "strategy": "Eliminate the need to push or pull the door open",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0215)"
    },
    "2150502": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door closes too fast",
        "solution": "Remove the door",
        "solution_long": "Remove the door.  However, this option is not allowed for fire doors.",
        "solution_number": 2150502,
        "strategy": "Eliminate the need to push or pull the door open",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0215)"
    },
    "2160101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door opening is too narrow",
        "solution": "Change the direction of the door swing",
        "solution_long": "Change the direction of the door swing (inward vs. outward). Door openings should provide a clear width of 32 inches (815 mm) minimum [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#404-doors-doorways-and-gates\" target=\"_blank\">ADAAS: 404.2.3</a>].",
        "solution_number": 2160101,
        "strategy": "Increase the amount that the door can open",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0216)"
    },
    "2160102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door opening is too narrow",
        "solution": "Remount the hinges",
        "solution_long": "Remount the hinges on the opposite side of the doorframe (right vs. left). Remount the hinges on the opposite side of the doorframe (right vs. left).",
        "solution_number": 2160102,
        "strategy": "Increase the amount that the door can open",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0216)"
    },
    "2160103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door opening is too narrow",
        "solution": "Use off-set hinges",
        "solution_long": "Replace standard hinges with off-set hinges",
        "solution_number": 2160103,
        "strategy": "Increase the amount that the door can open",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0216)"
    },
    "2160104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door opening is too narrow",
        "solution": "Remove obstacles",
        "solution_long": "Remove obstacles that may be blocking the door swing.",
        "solution_number": 2160104,
        "strategy": "Increase the amount that the door can open",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0216)"
    },
    "2160201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door opening is too narrow",
        "solution": "Remove the door",
        "solution_long": "Remove the door (interior, non-fire doors only).  Make sure that doorways less than 36 inches (915 mm) wide without doors or gates follow the appropriate rules regarding maneuvering clearances.  [<a href=https://www.ada.gov/law-and-regs/design-standards/2010-stds/#404-doors-doorways-and-gates target=\"_blank\">ADAAS: 404.2.4.2</a>].",
        "solution_number": 2160201,
        "strategy": "Increase the width of the doorway",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0216)"
    },
    "2160202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Door opening is too narrow",
        "solution": "Replace with a pocket or sliding door",
        "solution_long": "Replace a hinged door with a pocket or sliding door (interior door). <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2160202,
        "strategy": "Increase the width of the doorway",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0216)"
    },
    "2160203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door opening is too narrow",
        "solution": "Use off-set hinges on the door ",
        "solution_long": "Use off-set hinges on the door to create a wider doorway.",
        "solution_number": 2160203,
        "strategy": "Increase the width of the doorway",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0216)"
    },
    "2160204": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: A licensed contractor should be consulted",
        "gap": "Door opening is too narrow",
        "solution": "Install a wider doorway",
        "solution_long": "Install a wider doorway. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 2160204,
        "strategy": "Increase the width of the doorway",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0216)"
    },
    "2160301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door opening is too narrow",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem. ",
        "solution_number": 2160301,
        "strategy": "Avoid the barrier",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0216)"
    },
    "2160302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door opening is too narrow",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to avoid the access problem. If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 2160302,
        "strategy": "Avoid the barrier",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0216)"
    },
    "2160303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Door opening is too narrow",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 2160303,
        "strategy": "Avoid the barrier",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0216)"
    },
    "2170101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Threshold is too high",
        "solution": "Install a threshold ramp",
        "solution_long": "Install a threshold ramp (a small incline on either side of the <a href=\"/glossary#threshold\" target=\"_blank\">threshold</a>) or replace the threshold plates with one with <a href=\"/glossary#beveled\" target=\"_blank\">beveled</a> edges.  Provide the shallowest slope that the space allows. Thresholds, if provided at doorways, should a maximum of 1/2 inch (13 mm) high.  [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#404-doors-doorways-and-gates\" target=\"_blank\">ADAAS:404.2.5</a>].",
        "solution_number": 2170101,
        "strategy": "Make the height change more gradual",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0217)"
    },
    "2170201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Threshold is too high",
        "solution": "Remove the door threshold",
        "solution_long": "Remove the door <a href=\"/glossary#threshold\" target=\"_blank\">threshold</a>.",
        "solution_number": 2170201,
        "strategy": "Remove the threshold",
        "task": "Unlocking and Using Doors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0217)"
    },
    "3010101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Person cannot use stairs due to mobility limitations ",
        "solution": "Replace stairs with a ramp",
        "solution_long": "If there are only a couple of steps, install a ramp that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3010101,
        "strategy": "Provide a ramp alternative to the stairs",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0301)"
    },
    "3010102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to choose and install the ramp",
        "gap": "Person cannot use stairs due to mobility limitations",
        "solution": "Use a portable ramp",
        "solution_long": "If there are only a couple of steps, use a portable ramp that can be used in place of the stairs.\r\n",
        "solution_number": 3010102,
        "strategy": "Provide a ramp alternative to the stairs",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0301)"
    },
    "3010201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Person cannot use stairs due to mobility limitations",
        "solution": "Use a stair lift ",
        "solution_long": "Install a stair lift (seat or platform variety) that the person can use to ride up the stairs.  Note that an employee's mobility aid will either need to be carried along up the stairs, or a duplicate will need to be waiting at the destination.",
        "solution_number": 3010201,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0301)"
    },
    "3010202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Person cannot use stairs due to mobility limitations",
        "solution": "Use a vertical lift",
        "solution_long": "If there are multiple steps, install a vertical lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3010202,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0301)"
    },
    "3010203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Person cannot use stairs due to mobility limitations",
        "solution": "Install Limited Use Limited Access lift",
        "solution_long": "If the stairs extend for a full floor or more, install a LULA lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3010203,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0301)"
    },
    "3010204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Person cannot use stairs due to mobility limitations",
        "solution": "Use an elevator",
        "solution_long": "If the stairs extend for a full floor or more, install an elevator that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3010204,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0301)"
    },
    "3010301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Person cannot use stairs due to mobility limitations",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.  This may involve using a different entrance.",
        "solution_number": 3010301,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0301)"
    },
    "3010302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Person cannot use stairs due to mobility limitations",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to the same level as the building entrance.  If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 3010302,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0301)"
    },
    "3010303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Person cannot use stairs due to mobility limitations",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 3010303,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0301)"
    },
    "3010304": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Person cannot use stairs due to mobility limitations",
        "solution": "Allow telework ",
        "solution_long": "If it is impossible or impractical to modify the facility, consider allowing the employee to telework as an accommodation.",
        "solution_number": 3010304,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0301)"
    },
    "3020101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair rise is too high",
        "solution": "Add additional mini-steps",
        "solution_long": "If there is sufficient step depth, add additional mini-steps in between each of the original steps. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3020101,
        "strategy": "Reduce the step height",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair rise is too high",
        "solution": "Use a walking aid",
        "solution_long": "If there is sufficient step depth, use a walking aid with built-in step.",
        "solution_number": 3020102,
        "strategy": "Reduce the step height",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3020103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair rise is too high",
        "solution": "Rebuild stairs with a lower stair rise",
        "solution_long": "Under the International Building Code, the maximum riser (step) height is 7-3/4 inches. (Other codes limit the maximum riser height to 7 inches.) There is no minimum height. All steps on a flight of stairs should have uniform riser heights and uniform tread depths. Risers should be 4 inches (100 mm) high minimum and 7 inches (180 mm) high maximum. Treads should be 11 inches (280 mm) deep minimum [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#504-stairways\" target=\"_blank\">ADAAS: 504.2</a>]. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3020103,
        "strategy": "Reduce the step height",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3020201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair rise is too high",
        "solution": "Install a ramp ",
        "solution_long": "If there are only a couple of steps, install a ramp that can be used in place of the stairs. <ul><li>The ramp will need to be about 1 foot long for every inch rise, or about 8 feet long for every step.</li> <li><wafactors RNT>If the workspace is leased, you may need to get permission to make this change.</wafactors></li></ul>",
        "solution_number": 3020201,
        "strategy": "Provide a ramp alternative to the stairs",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3020202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair rise is too high",
        "solution": "Use a portable ramp ",
        "solution_long": "If there are only a couple of steps, use a portable ramp that can be used in place of the stairs. <ul><li>The ramp will need to be about 1 foot long for every inch rise, or about 8 feet long for every step.</li></ul>",
        "solution_number": 3020202,
        "strategy": "Provide a ramp alternative to the stairs",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3020301": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Stair rise is too high",
        "solution": "Use a stair lift",
        "solution_long": "Install a stair lift (seat or platform variety) that the person can use to ride up the stairs.  Note that an employee's mobility aid will either need to be carried along up the stairs, or a duplicate will need to be waiting at the destination.",
        "solution_number": 3020301,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3020302": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Stair rise is too high",
        "solution": "Use a vertical lift",
        "solution_long": "If there are multiple steps, install a vertical lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3020302,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3020303": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Stair rise is too high",
        "solution": "Install Limited Use Limited Access lift",
        "solution_long": "If the stairs extend for a full floor or more, install a LULA lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3020303,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3020304": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the elevator installed by an experienced professional",
        "gap": "Stair rise is too high",
        "solution": "Use an elevator",
        "solution_long": "If the stairs extend for a full floor or more, install an elevator that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3020304,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3020401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair rise is too high",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.  This may involve using a different entrance.",
        "solution_number": 3020401,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3020402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair rise is too high",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to the same level as the building entrance.  If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 3020402,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3020403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair rise is too high",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 3020403,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0302)"
    },
    "3030101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stairs are not deep enough",
        "solution": "Change projecting edge of stair",
        "solution_long": "Remove or replace the stair nosings.  The radius of curvature at the leading edge of the tread should be 1/2 inch (13 mm) maximum. Nosings that project beyond risers should have the underside of the leading edge curved or beveled. Risers should be permitted to slope under the tread at an angle of 30 degrees maximum from vertical. The permitted projection of the nosing should extend 1 1/2 inches (38 mm) maximum over the tread below [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#504-stairways\" target=\"_blank\">ADAAS: 504.5</a>]. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3030101,
        "strategy": "Increase the step depth",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0303)"
    },
    "3030102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stairs are not deep enough",
        "solution": "Rebuild stairs with greater depth ",
        "solution_long": "The ADA requires treads of 11 inches (280 mm) deep minimum [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#504-stairways\" target=\"_blank\">ADAAS: 504.2</a>]. Otherwise, a minimum depth of 10 inches is generally recommended by building codes.  There is no maximum tread depth. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors> </ul>",
        "solution_number": 3030102,
        "strategy": "Increase the step depth",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0303)"
    },
    "3030201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stairs are not deep enough",
        "solution": "Install a ramp ",
        "solution_long": "If there are only a couple of steps, install a ramp that can be used in place of the stairs. <ul><li>The ramp will need to be about 1 foot long for every inch rise, or about 8 feet long for every step.</li> <li><wafactors RNT>If the workspace is leased, you may need to get permission to make this change.</wafactors></li></ul>",
        "solution_number": 3030201,
        "strategy": "Provide a ramp alternative to the stairs",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0303)"
    },
    "3030202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs are not deep enough",
        "solution": "Use a portable ramp ",
        "solution_long": "If there are only a couple of steps, use a portable ramp that can be used in place of the stairs. <ul><li>The ramp will need to be about 1 foot long for every inch rise, or about 8 feet long for every step.</li></ul>",
        "solution_number": 3030202,
        "strategy": "Provide a ramp alternative to the stairs",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0303)"
    },
    "3030301": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Stairs are not deep enough",
        "solution": "Use a stair lift",
        "solution_long": "Install a stair lift (seat or platform variety) that the person can use to ride up the stairs.  Note that an employee's mobility aid will either need to be carried along up the stairs, or a duplicate will need to be waiting at the destination.",
        "solution_number": 3030301,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0303)"
    },
    "3030302": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Stairs are not deep enough",
        "solution": "Use a vertical lift",
        "solution_long": "If there are multiple steps, install a vertical lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3030302,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0303)"
    },
    "3030303": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Stairs are not deep enough",
        "solution": "Install Limited Use Limited Access lift",
        "solution_long": "If the stairs extend for a full floor or more, install a LULA lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3030303,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0303)"
    },
    "3030304": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the elevator installed by an experienced professional",
        "gap": "Stairs are not deep enough",
        "solution": "Use an elevator",
        "solution_long": "If the stairs extend for a full floor or more, install an elevator that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3030304,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0303)"
    },
    "3030401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs are not deep enough",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.  This may involve using a different entrance.",
        "solution_number": 3030401,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0303)"
    },
    "3030402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs are not deep enough",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to the same level as the building entrance.  If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 3030402,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0303)"
    },
    "3030403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs are not deep enough",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 3030403,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b) && (a.LOW) && (a.T0303)"
    },
    "3040101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Existing ramp is too steep ",
        "solution": "Rebuild the ramp with less slope",
        "solution_long": "Rebuild the ramp with a maximum rise of 1 inch for each foot of horizontal travel (1:12).  The employee may require an even shallower slope.  Under the ADA, if an existing ramp is steeper than 1:10 but not steeper than 1:8, the maximum rise should be 3 inches. If the ramp is steeper than 1:12 but not steeper than 1:10, the maximum rise should be 6 inches. A slope steeper than 1:8 is prohibited.  The rise for any ramp run must be under 30 inches (760 mm) [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#405-ramps\" target=\"_blank\">ADAAS: 405.2 & 405.6</a>]. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3040101,
        "strategy": "Reduce the ramp incline",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0304)"
    },
    "3040201": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Existing ramp is too steep ",
        "solution": "Use a vertical lift",
        "solution_long": "If there are multiple steps, install a vertical lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3040201,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0304)"
    },
    "3040202": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Existing ramp is too steep ",
        "solution": "Install Limited Use Limited Access lift",
        "solution_long": "If the stairs extend for a full floor or more, install a LULA lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3040202,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0303)"
    },
    "3040203": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the elevator installed by an experienced professional",
        "gap": "Existing ramp is too steep ",
        "solution": "Use an elevator",
        "solution_long": "If the stairs extend for a full floor or more, install an elevator that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3040203,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0303)"
    },
    "3040301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Existing ramp is too steep ",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.  This may involve using a different entrance.",
        "solution_number": 3040301,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0304)"
    },
    "3040302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Existing ramp is too steep ",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to the same level as the building entrance.  If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 3040302,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0304)"
    },
    "3040303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Existing ramp is too steep ",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 3040303,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0304)"
    },
    "3050101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Existing ramp is too long, without adequate level landings ",
        "solution": "Add resting platforms to existing ramp",
        "solution_long": "Add resting platforms to the side of the existing ramp. Landings should be at least 60 inches (1525 mm) long and at least as wide as the widest ramp run leading to the landing. Landings where a ramp changes directions should measure at least 60 inches (1525 mm) by 60 inches (1525 mm) [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#405-ramps\" target=\"_blank\">ADAAS: 405.7</a>]. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3050101,
        "strategy": "Provide rest points along the ramp",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0305)"
    },
    "3050102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Existing ramp is too long, without adequate level landings ",
        "solution": "Rebuild the ramp with landings",
        "solution_long": "Rebuild the ramp with a level landing after each 30 inches (760 mm) of rise [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#405-ramps\" target=\"_blank\">ADAAS: 405.6</a>]. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3050102,
        "strategy": "Provide rest points along the ramp",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0305)"
    },
    "3050201": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Existing ramp is too long, without adequate level landings ",
        "solution": "Use a vertical lift",
        "solution_long": "If there are multiple steps, install a vertical lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3050201,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0305)"
    },
    "3050202": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Existing ramp is too long, without adequate level landings ",
        "solution": "Install Limited Use Limited Access lift",
        "solution_long": "If the stairs extend for a full floor or more, install a LULA lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3050202,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0303)"
    },
    "3050203": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the elevator installed by an experienced professional",
        "gap": "Existing ramp is too long, without adequate level landings ",
        "solution": "Use an elevator",
        "solution_long": "If the stairs extend for a full floor or more, install an elevator that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3050203,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0303)"
    },
    "3050301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Existing ramp is too long, without adequate level landings ",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.  This may involve using a different entrance.",
        "solution_number": 3050301,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0305)"
    },
    "3050302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Existing ramp is too long, without adequate level landings ",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to the same level as the building entrance.  If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 3050302,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0305)"
    },
    "3050303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Existing ramp is too long, without adequate level landings ",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 3050303,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0305)"
    },
    "3060101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Existing ramp is too narrow ",
        "solution": "Reposition handrails",
        "solution_long": "If the handrails are causing the ramp to be too narrow, remount them or replace them with a different style of handrail. <ul><li>Handrails should be provided on both sides of ramps that have a rise greater than 6 inches.</li> <li>Provide a minimum clear width (between handrails) of 36 inches [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#405-ramps\" target=\"_blank\">ADAAS: 405.5 & 405.8]</a>].</li></ul>",
        "solution_number": 3060101,
        "strategy": "Change the handrails",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0306)"
    },
    "3060201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Existing ramp is too narrow ",
        "solution": "Rebuild the ramp with greater width ",
        "solution_long": "Rebuild the ramp with a minimum clear width (between handrails) of 36 inches [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#405-ramps\" target=\"_blank\">ADAAS: 405.5</a>]. <ul> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3060201,
        "strategy": "Increase the width of the ramp",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0306)"
    },
    "3060301": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Existing ramp is too narrow ",
        "solution": "Use a vertical lift",
        "solution_long": "If there are multiple steps, install a vertical lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3060301,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0305)"
    },
    "3060302": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Existing ramp is too narrow ",
        "solution": "Install Limited Use Limited Access lift",
        "solution_long": "If the stairs extend for a full floor or more, install a LULA lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3060302,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0303)"
    },
    "3060303": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the elevator installed by an experienced professional",
        "gap": "Existing ramp is too narrow ",
        "solution": "Use an elevator",
        "solution_long": "If the stairs extend for a full floor or more, install an elevator that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3060303,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0303)"
    },
    "3060401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Existing ramp is too narrow ",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.  This may involve using a different entrance.",
        "solution_number": 3060401,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0306)"
    },
    "3060402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Existing ramp is too narrow ",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to the same level as the building entrance.  If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 3060402,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0306)"
    },
    "3060403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Existing ramp is too narrow ",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 3060403,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0306)"
    },
    "3070101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Add slip resistant sealer / finish",
        "solution_long": "Add a slip resistant <a href=\"/glossary#sealer\" target=\"_blank\">sealer</a> or finish to the stairs or ramp surface.  If outdoors, repaint the stairs or ramp, adding sand to the paint for greater traction [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#504-stairways\" target=\"_blank\">ADAAS: 504.7</a>].",
        "solution_number": 3070101,
        "strategy": "Change the surface",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Add non-skid treads",
        "solution_long": "Add non-skid treads or nosing to stairs.  Add horizontal non-skid treads to ramps. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3070102,
        "strategy": "Change the surface",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Add carpet to path",
        "solution_long": "Carpet stairs or ramp with a tightly woven, low-pile carpet with no or minimal padding. <ul> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3070103,
        "strategy": "Change the surface",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Clean off any slippery mold / debris",
        "solution_long": "Outside, clean off any slippery mold / debris with bleach. ",
        "solution_number": 3070201,
        "strategy": "Remove slippery coating",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Keep debris / snow / ice off path",
        "solution_long": "Do not let debris / snow / ice accumulate.",
        "solution_number": 3070202,
        "strategy": "Remove slippery coating",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Install a ramp ",
        "solution_long": "If there are only a couple of steps, install a ramp that can be used in place of the stairs. <ul><li>The ramp will need to be about 1 foot long for every inch rise, or about 8 feet long for every step.</li> <li><wafactors RNT>If the workspace is leased, you may need to get permission to make this change.</wafactors></li></ul>",
        "solution_number": 3070301,
        "strategy": "Provide a ramp alternative to the stairs",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Use a portable ramp ",
        "solution_long": "If there are only a couple of steps, use a portable ramp that can be used in place of the stairs. <ul><li>The ramp will need to be about 1 foot long for every inch rise, or about 8 feet long for every step.</li></ul>",
        "solution_number": 3070302,
        "strategy": "Provide a ramp alternative to the stairs",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070401": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Use a stair lift",
        "solution_long": "Install a stair lift (seat or platform variety) that the person can use to ride up the stairs.  Note that an employee's mobility aid will either need to be carried along up the stairs, or a duplicate will need to be waiting at the destination.",
        "solution_number": 3070401,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070402": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Use a vertical lift",
        "solution_long": "If there are multiple steps, install a vertical lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3070402,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070403": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the lift installed by an experienced electrician or engineer",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Install Limited Use Limited Access lift",
        "solution_long": "If the stairs extend for a full floor or more, install a LULA lift that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3070403,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070404": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the elevator installed by an experienced professional",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Use an elevator",
        "solution_long": "If the stairs extend for a full floor or more, install an elevator that can be used in place of the stairs. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3070404,
        "strategy": "Lift the person to the desired floor level",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.  This may involve using a different entrance.",
        "solution_number": 3070501,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070502": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to the same level as the building entrance.  If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 3070502,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3070503": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stairs or ramp are too slippery",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 3070503,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0307)"
    },
    "3080101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Staircase / individual steps are difficult to see",
        "solution": "Use high-contrast strips",
        "solution_long": "Place high-contrast strips on the edge of each step so that the steps are more visible for people with low vision [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#504-stairways\" target=\"_blank\">ADAAS: 504.4</a>]",
        "solution_number": 3080101,
        "strategy": "Increase the visibility of the steps ",
        "task": "Moving Between Floors",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0308)"
    },
    "3080102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Staircase / individual steps are difficult to see",
        "solution": "Replace with a non-glare surface",
        "solution_long": "Reduce the glare from the stair or ramp surface by using a non-glare hard surface or carpeting, or by adding texture to the surface. ",
        "solution_number": 3080102,
        "strategy": "Increase the visibility of the steps ",
        "task": "Moving Between Floors",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0308)"
    },
    "3080201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Staircase / individual steps are difficult to see",
        "solution": "Add or move lighting",
        "solution_long": "Add or move lighting to increase the amount of light on the path. <ul><li>Add filtered light instead of direct light to reduce glare.</li> <li>Add directional light or spotlight to focus on specific areas, including the stairwell.</li></ul> ",
        "solution_number": 3080201,
        "strategy": "Change the lighting",
        "task": "Moving Between Floors",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0308)"
    },
    "3080202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Staircase / individual steps are difficult to see",
        "solution": "Remove or move lighting",
        "solution_long": "If glare is a problem, remove some lights or move the lighting away from the path. ",
        "solution_number": 3080202,
        "strategy": "Change the lighting",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0308)"
    },
    "3080203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Staircase / individual steps are difficult to see",
        "solution": "Change the light bulb",
        "solution_long": "Change the light bulb type / wattage / color so that the path is well lit with minimal glare. ",
        "solution_number": 3080203,
        "strategy": "Change the lighting",
        "task": "Moving Between Floors",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0308)"
    },
    "3080204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Staircase / individual steps are difficult to see",
        "solution": "Dim or filter existing lights",
        "solution_long": "Dim existing lights by installing dimmer switches on the lighting or by placing a shade or filtering cover on the lighting.",
        "solution_number": 3080204,
        "strategy": "Change the lighting",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0308)"
    },
    "3080301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Staircase / individual steps are difficult to see",
        "solution": "Wear tinted glasses or a visor",
        "solution_long": "Allow the employee to wear tinted glasses (e.g., sunglasses) or a hat with a visor. ",
        "solution_number": 3080301,
        "strategy": "Shade the employee's eyes from bright lights",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0308)"
    },
    "3080401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Staircase / individual steps are difficult to see",
        "solution": "Use a handrail that indicates length",
        "solution_long": "Use a handrail that extends past the top and bottom steps or ramp to provide a cue to the position and length of the stairs or ramp.  ",
        "solution_number": 3080401,
        "strategy": "Provide tactile cues",
        "task": "Moving Between Floors",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0308)"
    },
    "3080402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Staircase / individual steps are difficult to see",
        "solution": "Provide tactile bumps for warning",
        "solution_long": "Provide tactile bumps or a strip at the top of the stairs or ramp as a warning. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul> ",
        "solution_number": 3080402,
        "strategy": "Provide tactile cues",
        "task": "Moving Between Floors",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0308)"
    },
    "3090101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair or ramp handrail is not available",
        "solution": "Add handrails to both sides",
        "solution_long": "Add handrails to both sides of the stairs or ramp.  Handrails should be provided for ramps that have a rise greater than 6 inches. <ul><li>Handrails should be at a consistent height, 34 to 38 inches above walking surfaces, stair nosings, and ramp surfaces.</li> <li>Provide a minimum clear width (between handrails) of 36 inches [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#405-ramps\" target=\"_blank\">ADAAS: 405.5 & 405.8]</a>.</li> <li>There should be a minimum clearance of 1.5 inches (38 mm) between handrail gripping surfaces and adjacent surfaces [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#405-ramps\" target=\"_blank\">ADAAS: 405.5]</a>. Handrail gripping surfaces should be continuous along their length and should not be obstructed along their tops or sides.</li> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li> </wafactors></ul>",
        "solution_number": 3090101,
        "strategy": "Add handrails",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b) && (a.LOW) && (a.T0309)"
    },
    "3100101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Raise / lower the handrail",
        "solution_long": "Raise / lower the handrail to a level that can be easily reached. <ul><li>Handrails should be at a consistent height above walking surfaces, stair nosings, and ramp surfaces. </li> <li>A height between 34 and 38 inches above the nosing (front edge of the stair) or ramp surface is generally recommended (ADAAS), but the person may prefer a different height [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#504-stairways\" target=\"_blank\">ADAAS: 504.5</a>].</li> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3100101,
        "strategy": "Change the height of the handrail",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T0310)"
    },
    "3100102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Add handrail at a different height",
        "solution_long": "Add another handrail at a different height. <ul><li>Handrails should be at a consistent height above walking surfaces, stair nosings, and ramp surfaces.</li> <li>A height between 34 and 38 inches above the nosing (front edge of the stair) or ramp surface is generally recommended (ADAAS), but the person may prefer a different height. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#504-stairways\" target=\"_blank\">ADAAS: 504.5</a>].</li> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3100102,
        "strategy": "Change the height of the handrail",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T0310)"
    },
    "3100201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Use a longer handrail",
        "solution_long": "Install a longer handrail that extends beyond the top and bottom of stair flights and ramp runs. <ul><li>At the top of a stair flight, handrails should extend horizontally above the landing for 12 inches (305 mm) minimum beginning directly above the first riser nosing. At the bottom of a stair flight, handrails should extend at the slope of the stair flight for a horizontal distance at least equal to one tread depth beyond the last riser nosing.</li> <li>At the top and bottom of ramp runs, handrails should extend another 12 inches (305 mm) so the person can reach it even when at the top or bottom of the slope.</li> <li>Both extensions should return to a wall, guard, or the landing surface, or should be continuous to the handrail of an adjacent stair flight or ramp run [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#505-handrails\" target=\"_blank\">ADAAS: §505.10.1 - §505.10.3</a>]. </li> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3100201,
        "strategy": "Change the length of the handrail",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T0310)"
    },
    "3100301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Add handrail brackets for support",
        "solution_long": "Add additional handrail brackets or supports to the handrail so it can support the weight of the person.",
        "solution_number": 3100301,
        "strategy": "Increase the handrail's stability",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T0310)"
    },
    "3100302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Replace with a more stable handrail",
        "solution_long": "Replace the handrail with another type that has greater stability and can support the weight of the person.",
        "solution_number": 3100302,
        "strategy": "Increase the handrail's stability",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T0310)"
    },
    "3100401": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the stairs installed by an experienced professional",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Rebuild stairs with a lower stair rise",
        "solution_long": "Rebuild the stairs with a lower stair rise (riser height). <ul><li>Under the International Building Code, the maximum riser height is 7-3/4 inches. (Other codes limit the maximum riser height to 7 inches.) There is no minimum height.</li> <li>All steps on a flight of stairs should have uniform riser heights and uniform tread depths. Risers should be between 4 inches (100 mm) high minimum and 7 inches (180 mm) high maximum. Treads should be 11 inches (280 mm) deep minimum [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#504-stairways\" target=\"_blank\">ADAAS: 504.2</a>].</li> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3100401,
        "strategy": "Reduce the need to use the handrail for stability",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T0310)"
    },
    "3100402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Change surface for more stability",
        "solution_long": "Make travel more secure by adding slip resistant sealer / finish, non-skid treads, or low pile carpeting to the stairs or ramp.  Keep it clean and free of debris. <ul><wafactors RNT> <li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3100402,
        "strategy": "Reduce the need to use the handrail for stability",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T0310)"
    },
    "3100501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.  This may involve using a different entrance.",
        "solution_number": 3100501,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T0310)"
    },
    "3100502": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to the same level as the building entrance.  If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 3100502,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T0310)"
    },
    "3100503": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 3100503,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T0310)"
    },
    "3110101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair or ramp handrail is difficult to grip",
        "solution": "Replace the handrail for easier grip",
        "solution_long": "Replace the handrail with another type that provides an easier grip. <ul><li>Use a round or oval-shaped rail that has a diameter that not so large that the person's hand slips off.</li> <li><wafactors RNT>If the workspace is leased, you may need to get permission to make this change.</wafactors></li></ul>",
        "solution_number": 3110101,
        "strategy": "Change the grip on the handrail",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U10 || a.U13 || a.U14) && (a.LOW) && (a.T0311)"
    },
    "3110102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair or ramp handrail is difficult to grip",
        "solution": "Remount handrail for more grip space",
        "solution_long": "Reposition the handrail so there is more room to grip it.  There should be a minimum clearance of 1.5 inches (38 mm) between handrail gripping surfaces and adjacent surfaces [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#505-handrails\" target=\"_blank\">ADAAS: 505.5</a>]. <ul> <li>Handrail gripping surfaces should be continuous along their length and should not be obstructed along their tops or sides.</li> <li>The bottoms of handrail gripping surfaces should not be obstructed for more than 20 percent of their length.</li> <li>Where provided, horizontal projections should occur 1.5 inches (38 mm) minimum below the bottom of the handrail gripping surface.</li></ul>",
        "solution_number": 3110102,
        "strategy": "Change the grip on the handrail",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U10 || a.U13 || a.U14) && (a.LOW) && (a.T0311)"
    },
    "3110201": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Have the stairs installed by an experienced professional",
        "gap": "Stair or ramp handrail is difficult to grip",
        "solution": "Rebuild stairs with a lower stair rise",
        "solution_long": "Rebuild the stairs with a lower stair rise (riser height). <ul><li>Under the International Building Code, the maximum riser height is 7-3/4 inches. (Other codes limit the maximum riser height to 7 inches.) There is no minimum height.</li> <li>All steps on a flight of stairs should have uniform riser heights and uniform tread depths. Risers should be between 4 inches (100 mm) high minimum and 7 inches (180 mm) high maximum. Treads should be 11 inches (280 mm) deep minimum [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#504-stairways\" target=\"_blank\">ADAAS: 504.2</a>].</li> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3110201,
        "strategy": "Reduce the need to use the handrail for stability",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U10 || a.U13 || a.U14) && (a.LOW) && (a.T0311)"
    },
    "3110202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to grip",
        "solution": "Change surface for more stability",
        "solution_long": "Make travel more secure by adding slip resistant sealer / finish, non-skid treads, or low pile carpeting to the stairs or ramp.  Keep it clean and free of debris. <ul><wafactors RNT> <li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3110202,
        "strategy": "Reduce the need to use the handrail for stability",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U10 || a.U13 || a.U14) && (a.LOW) && (a.T0311)"
    },
    "3110301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.  This may involve using a different entrance.",
        "solution_number": 3110301,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U10 || a.U13 || a.U14) && (a.LOW) && (a.T0311)"
    },
    "3110302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to the same level as the building entrance.  If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 3110302,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U10 || a.U13 || a.U14) && (a.LOW) && (a.T0311)"
    },
    "3110303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to reach and pull / push on",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 3110303,
        "strategy": "Avoid the barrier",
        "task": "Moving Between Floors",
        "trigger": "(a.L4 || a.L7 || a.L8a || a.L8b || a.U10 || a.U13 || a.U14) && (a.LOW) && (a.T0311)"
    },
    "3120101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to see",
        "solution": "Use contrasting handrail/wall colors",
        "solution_long": "Use materials or paint that provides a high contrast between the handrail and the wall or other surroundings.",
        "solution_number": 3120101,
        "strategy": "Increase visibility of the handrail",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0312)"
    },
    "3120102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to see",
        "solution": "Reduce the glare from walls",
        "solution_long": "Reduce the glare from walls by adding a non-glare wall covering (wall paper, curtain, or hanging) or painting the walls with a flat paint that does not produce glare [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#703-signs\" target=\"_blank\">ADAAS: 703.5.1</a>].",
        "solution_number": 3120102,
        "strategy": "Increase visibility of the handrail",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0312)"
    },
    "3120201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair or ramp handrail is difficult to see",
        "solution": "Add or move lighting",
        "solution_long": "Add or move lighting to highlight the handrail.  Add filtered light instead of direct light to reduce glare.",
        "solution_number": 3120201,
        "strategy": "Change the lighting",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0312)"
    },
    "3120202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Stair or ramp handrail is difficult to see",
        "solution": "Remove or move lighting",
        "solution_long": "Remove some lights or move the lighting away from the handrail to reduce glare.",
        "solution_number": 3120202,
        "strategy": "Change the lighting",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0312)"
    },
    "3120203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to see",
        "solution": "Change the light bulb",
        "solution_long": "Change the light bulb type / wattage / color so that the handrail is well lit with minimal glare.",
        "solution_number": 3120203,
        "strategy": "Change the lighting",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0312)"
    },
    "3120204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to see",
        "solution": "Dim existing lights by installing dimmer switches on the lighting or by placing a shade or filtering cover on the lighting.",
        "solution_long": "Dim existing lights by installing dimmer switches on the lighting or by placing a shade or filtering cover on the lighting.",
        "solution_number": 3120204,
        "strategy": "Change the lighting",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0312)"
    },
    "3120301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Stair or ramp handrail is difficult to see",
        "solution": "Allow the employee to wear tinted glasses (e.g., sunglasses) or a hat with a visor.",
        "solution_long": "Allow the employee to wear tinted glasses (e.g., sunglasses) or a hat with a visor.",
        "solution_number": 3120301,
        "strategy": "Shade the employee's eyes from bright lights",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0312)"
    },
    "3130101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator / arrival of elevator is difficult to locate",
        "solution": "Use contrasting colors on elevator/wall",
        "solution_long": "Paint the elevator doors and the surrounding walls in contrasting colors.",
        "solution_number": 3130101,
        "strategy": "Increase the contrast between the elevator door and wall",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0313)"
    },
    "3130201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator / arrival of elevator is difficult to locate",
        "solution": "Add Braille signs",
        "solution_long": "Add Braille signs to indicate the elevator location.  However, first make sure that the employee knows Braille.",
        "solution_number": 3130201,
        "strategy": "Provide accessible signage for elevator location",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0313)"
    },
    "3130301": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "Elevator / arrival of elevator is difficult to locate",
        "solution": "Provide visual signal for arrival",
        "solution_long": "Provide a visible signal at each elevator entrance to indicate which car is answering a call and the car's direction of travel. Position visual signal fixtures so they are easily visible. <ul><li>Visible signal fixtures should be centered at 72 inches (1830 mm) minimum above the finish floor or ground.</li> <li>The visible signal elements should be 2-1/2 inches (64 mm) minimum measured along the vertical centerline of the element.</li> <li>Signals should be visible from the floor area adjacent to the hall call button. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#407-elevators\" target=\"_blank\">ADAAS: 407.2.2.2</a>]</li> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3130301,
        "strategy": "Provide visual cues for elevator arrival",
        "task": "Moving Between Floors",
        "trigger": "(a.H1 || a.H2 || a.H3) && (a.LOW) && (a.T0313)"
    },
    "3130302": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "Elevator / arrival of elevator is difficult to locate",
        "solution": "Increase brightness of displays ",
        "solution_long": "Increase the brightness of the floor arrival light or display.",
        "solution_number": 3130302,
        "strategy": "Provide visual cues for elevator arrival",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3) && (a.LOW) && (a.T0313)"
    },
    "3130401": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "Elevator / arrival of elevator is difficult to locate",
        "solution": "Add beeps or auditory announcements ",
        "solution_long": "If possible for the type of elevator, add/activate an audible signal at each elevator entrance to indicate which car is answering a call and the car's direction of travel. Where in-car signals are provided, they should be visible from the floor area adjacent to the hall call buttons [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#407-elevators\" target=\"_blank\">ADAAS: 407.2.2.1</a>].",
        "solution_number": 3130401,
        "strategy": "Provide auditory cues for elevator arrival",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0313)"
    },
    "3130402": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "Elevator / arrival of elevator is difficult to locate",
        "solution": "Increase volume of announcements",
        "solution_long": "Increase the volume of auditory elevator arrival announcements. ",
        "solution_number": 3130402,
        "strategy": "Provide auditory cues for elevator arrival",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0313)"
    },
    "3140101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "In the elevator, arrival at a particular floor is difficult to recognize",
        "solution": "Use large print, high contrast signs",
        "solution_long": "Provide large print, high contrast signage opposite the elevator that indicates the current floor.",
        "solution_number": 3140101,
        "strategy": "Provide accessible signage",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0314)"
    },
    "3140102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "In the elevator, arrival at a particular floor is difficult to recognize",
        "solution": "Provide tactile floor labels",
        "solution_long": "Provide floor designation labels with both raised letters and Braille for each floor.  They should be positioned on the side of the elevator opening. Tactile characters should be 2 inches (51 mm) high minimum. A tactile star should be provided on both jambs at the main entry level [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#407-elevators\" target=\"_blank\">ADAAS: 407.2.3.1</a>]. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3140102,
        "strategy": "Provide accessible signage",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0314)"
    },
    "3140201": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "In the elevator, arrival at a particular floor is difficult to recognize",
        "solution": "Increase brightness of floor sign",
        "solution_long": "Increase the brightness of the floor indicator.",
        "solution_number": 3140201,
        "strategy": "Provide visual cues",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3) && (a.LOW) && (a.T0314)"
    },
    "3140301": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "In the elevator, arrival at a particular floor is difficult to recognize",
        "solution": "Add beeps or auditory announcements",
        "solution_long": "If possible for the type of elevator, add/activate beeps or auditory announcements to indicate the current floor. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3140301,
        "strategy": "Provide auditory cues",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0314)"
    },
    "3140302": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "In the elevator, arrival at a particular floor is difficult to recognize",
        "solution": "Increase volume of announcements",
        "solution_long": "Increase the volume of the auditory floor announcements. ",
        "solution_number": 3140302,
        "strategy": "Provide auditory cues",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0314)"
    },
    "3150101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Elevator controls are difficult to see",
        "solution": "Add/remove or move lighting",
        "solution_long": "Redirect lighting or add/remove lights so that the control panel is well lit with minimal glare.",
        "solution_number": 3150101,
        "strategy": "Change the lighting",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0315)"
    },
    "3150102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator controls are difficult to see",
        "solution": "Change the light bulb",
        "solution_long": "Change the light bulb type / wattage / color so that the control panel is well lit with minimal glare. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3150102,
        "strategy": "Change the lighting",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0315)"
    },
    "3150201": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "Elevator controls are difficult to see",
        "solution": "Use large, high-contrast labels",
        "solution_long": "Ensure that button labels haven't been worn down and are a large, high-contrast font. ",
        "solution_number": 3150201,
        "strategy": "Provide accessible signage",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0315)"
    },
    "3150202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator controls are difficult to see",
        "solution": "Label controls with a raised font",
        "solution_long": "Label elevator controls in a raised font.",
        "solution_number": 3150202,
        "strategy": "Provide accessible signage",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0315)"
    },
    "3150203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator controls are difficult to see",
        "solution": "Label elevator controls with Braille",
        "solution_long": "Label elevator controls with Braille.  However, first make sure that the employee knows Braille. ",
        "solution_number": 3150203,
        "strategy": "Provide accessible signage",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0315)"
    },
    "3150301": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "Elevator controls are difficult to see",
        "solution": "Use controls with larger buttons",
        "solution_long": "Replace the controls with a panel with larger buttons. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3150301,
        "strategy": "Change the size of the controls",
        "task": "Moving Between Floors",
        "trigger": "(a.V1 || a.V3 || a.V4) && (a.LOW) && (a.T0315)"
    },
    "3160101": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "Elevator controls are difficult to operate due to location, size, or force",
        "solution": "Add controls at lower height",
        "solution_long": "Add elevator controls at an accessible height.  The controls should be located between 15 and 48 inches from the floor. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3160101,
        "strategy": "Change the height of the controls",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U4 || a.U5) && (a.LOW) && (a.T0316)"
    },
    "3160102": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "Elevator controls are difficult to operate due to location, size, or force",
        "solution": "Change height of controls",
        "solution_long": "Raise or lower the height of existing elevator controls. The controls should be located between 15 and 48 inches from the floor. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3160102,
        "strategy": "Change the height of the controls",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U4 || a.U5) && (a.LOW) && (a.T0316)"
    },
    "3160201": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "Elevator controls are difficult to operate due to location, size, or force",
        "solution": "Use controls with larger buttons",
        "solution_long": "Replace the controls with a panel with larger buttons. <ul><wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3160201,
        "strategy": "Change the size of the controls",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U4 || a.U5 || a.U10 || a.U11 || a.U13 || a.U14) && (a.LOW) && (a.T0316)"
    },
    "3160301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator controls are difficult to operate due to location, size, or force",
        "solution": "Use a hand-held stick",
        "solution_long": "Use a hand-held stick to activate the buttons on the elevator.  It will extend the person's reach and provide more force to the buttons.",
        "solution_number": 3160301,
        "strategy": "Use a stick to push buttons",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U4 || a.U5 || a.U10 || a.U11 || a.U13 || a.U14) && (a.LOW) && (a.T0316)"
    },
    "3160401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator controls are difficult to operate due to location, size, or force",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.  This may involve using a different entrance.",
        "solution_number": 3160401,
        "strategy": "Seek another travel path",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U4 || a.U5 || a.U10 || a.U11 || a.U13 || a.U14) && (a.LOW) && (a.T0316)"
    },
    "3160402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator controls are difficult to operate due to location, size, or force",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to the same level as the building entrance.  If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 3160402,
        "strategy": "Seek another travel path",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U4 || a.U5 || a.U10 || a.U11 || a.U13 || a.U14) && (a.LOW) && (a.T0316)"
    },
    "3160403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator controls are difficult to operate due to location, size, or force",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 3160403,
        "strategy": "Seek another travel path",
        "task": "Moving Between Floors",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U4 || a.U5 || a.U10 || a.U11 || a.U13 || a.U14) && (a.LOW) && (a.T0316)"
    },
    "3170101": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Elevator modifications should be made by an experienced professional",
        "gap": "Elevator is difficult to enter / exit due to size",
        "solution": "Install a larger elevator",
        "solution_long": "Install a larger elevator. Inside dimensions of elevator cars and clear width of elevator doors should comply with Table 407.4.1 [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#407-elevators\" target=\"_blank\">ADAAS: 407.4.1</a>]. <ul> <wafactors RNT><li>If the workspace is leased, you may need to get permission to make this change.</li></wafactors></ul>",
        "solution_number": 3170101,
        "strategy": "Increase the size of the elevator door or car",
        "task": "Moving Between Floors",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0317)"
    },
    "3170201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator is difficult to enter / exit due to size",
        "solution": "Take a different route",
        "solution_long": "Take a different route that avoids the access problem.  This may involve using a different entrance.",
        "solution_number": 3170201,
        "strategy": "Seek another travel path",
        "task": "Moving Between Floors",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0317)"
    },
    "3170202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator is difficult to enter / exit due to size",
        "solution": "Relocate the work area",
        "solution_long": "Relocate the employee's work area to the same level as the building entrance.  If possible, keep the employee with his or her work team to promote workplace participation.",
        "solution_number": 3170202,
        "strategy": "Seek another travel path",
        "task": "Moving Between Floors",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0317)"
    },
    "3170203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Elevator is difficult to enter / exit due to size",
        "solution": "Adjust minor job duties",
        "solution_long": "Adjust minor job duties so the employee does not need to access difficult spaces.",
        "solution_number": 3170203,
        "strategy": "Seek another travel path",
        "task": "Moving Between Floors",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0317)"
    },
    "4010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The path is difficult to follow",
        "solution": "Provide tactile cues",
        "solution_long": "Provide tactile cues (e.g., mat, different textured flooring) at key orientation points (e.g., doorway, branching hallway) Where both visual and tactile characters are required, provide either one sign with both visual and tactile characters, or two separate signs, one with visual, and one with tactile characters. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#703-signs\" target=\"_blank\">ADAAS: 703.1</a>]",
        "solution_number": 4010101,
        "strategy": "Mark orientation points",
        "task": "Navigating the Workplace",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0401)"
    },
    "4010102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to choose and install the system",
        "gap": "The path is difficult to follow",
        "solution": "Use beacon technology / trip sensors",
        "solution_long": "Use beacon technology / trip sensors that detect when employees are in-range and provide information to guide them.",
        "solution_number": 4010102,
        "strategy": "Mark orientation points",
        "task": "Navigating the Workplace",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.C2) && (a.LOW) && (a.T0401)"
    },
    "4010201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The path is difficult to follow",
        "solution": "Add accessible directional signage",
        "solution_long": "Add directional signage that meets ADAAS accessibility requirements for font, contrast, etc. ",
        "solution_number": 4010201,
        "strategy": "Mark routes to key destinations",
        "task": "Navigating the Workplace",
        "trigger": "(a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H6 || a.C2 || a.C6) && (a.LOW) && (a.T0401)"
    },
    "4010202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The path is difficult to follow",
        "solution": "Provide color-coded pathways",
        "solution_long": "Provide color-coded pathways. ",
        "solution_number": 4010202,
        "strategy": "Mark routes to key destinations",
        "task": "Navigating the Workplace",
        "trigger": "(!a.V2) && (!a.V5) && (!a.V6) && (!a.H6) && (a.C2) && (a.LOW) && (a.T0401)"
    },
    "4010203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The path is difficult to follow",
        "solution": "Provide tactile pathways",
        "solution_long": "Provide tactile cues that can be followed for an entire path to key destinations. <ul><li>For example, employees who use a white cane can track a tactile strip to the cafeteria. </li></ul>",
        "solution_number": 4010203,
        "strategy": "Mark routes to key destinations",
        "task": "Navigating the Workplace",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0401)"
    },
    "4010204": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to choose and install the system",
        "gap": "The path is difficult to follow",
        "solution": "Use beacon technology / trip sensors",
        "solution_long": "Use beacon technology / trip sensors that detect when employees are in-range and provide information to guide them.",
        "solution_number": 4010204,
        "strategy": "Mark routes to key destinations",
        "task": "Navigating the Workplace",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.C2) && (a.LOW) && (a.T0401)"
    },
    "4020101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Locations are difficult to identify",
        "solution": "Add signs to identify locations",
        "solution_long": "Add signs (inc. maps) to identify locations in the workplace. <ul><li>Scannable QR codes can provide additional wayfinding information.</li></ul>",
        "solution_number": 4020101,
        "strategy": "Provide adequate signage",
        "task": "Navigating the Workplace",
        "trigger": "(a.C2) && (a.LOW) && (a.T0402)"
    },
    "4020102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to choose and install the system",
        "gap": "Locations are difficult to identify",
        "solution": "Use beacon technology / trip sensors",
        "solution_long": "Use beacon technology / trip sensors that detect when employees are in-range and provide information to guide them.  (Vision: low vision, blind; Cognitive: memory)",
        "solution_number": 4020102,
        "strategy": "Provide adequate signage",
        "task": "Navigating the Workplace",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.C2) && (a.LOW) && (a.T0402)"
    },
    "4020103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Locations are difficult to identify",
        "solution": "Add signs for space contents/purpose",
        "solution_long": "Add signs (symbols with simple text) to indicate room task/contents. Put the sign alongside the door at the latch side.  Where a tactile sign is provided at double doors with one active leaf, put the sign on the inactive leaf. Raised characters should be 1/32 inch (0.8 mm) minimum above their background. Characters should be uppercase. Characters should be sans serif.  Characters should not be italic, oblique, script, highly decorative, or of other unusual forms. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#703-signs\" target=\"_blank\">ADAAS: 703.2 and 703.4.2 </a>]",
        "solution_number": 4020103,
        "strategy": "Provide adequate signage",
        "task": "Navigating the Workplace",
        "trigger": "(a.C2) && (a.LOW) && (a.T0402)"
    },
    "4020104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Locations are difficult to identify",
        "solution": "Use direction trees at intersections",
        "solution_long": "At hallway intersections, use direction trees with arrows, using symbols that also appear on the doors.",
        "solution_number": 4020104,
        "strategy": "Provide adequate signage",
        "task": "Navigating the Workplace",
        "trigger": "(a.C2) && (a.LOW) && (a.T0402)"
    },
    "4020201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Locations are difficult to identify",
        "solution": "Use signs with larger print. ",
        "solution_long": "Use signs with larger print. ",
        "solution_number": 4020201,
        "strategy": "Provide accessible signage",
        "task": "Navigating the Workplace",
        "trigger": "(a.V3) && (a.LOW) && (a.T0402)"
    },
    "4020202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Locations are difficult to identify",
        "solution": "Use signs with greater color contrast",
        "solution_long": "Use signs with greater color contrast. The entire sign should have a non-glare finish. Characters should contrast with their background with either light characters on a dark background or dark characters on a light background. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#703-signs\" target=\"_blank\">ADAAS: 703.5.1</a>]",
        "solution_number": 4020202,
        "strategy": "Provide accessible signage",
        "task": "Navigating the Workplace",
        "trigger": "(a.V3) && (a.LOW) && (a.T0402)"
    },
    "4020203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Locations are difficult to identify",
        "solution": "Use signs that use color-coding",
        "solution_long": "Use signs that use color-coding along with text or graphics. ",
        "solution_number": 4020203,
        "strategy": "Provide accessible signage",
        "task": "Navigating the Workplace",
        "trigger": "(!a.V2) && (!a.V5) && (!a.V6) && (!a.H6) && (a.C2 || a.C6) && (a.LOW) && (a.T0402)"
    },
    "4020204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Locations are difficult to identify",
        "solution": "Use signs with graphics ",
        "solution_long": "Use graphics on signs to provide information.<ul> <li>Examples include a directional sign with a restroom icon or a sign outside a workspace with pictures of the people inside.</li> <li>Graphics should have a field height of 6 inches (150 mm) minimum.  Do not include characters and braille in the graphic field. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#703-signs\" target=\"_blank\">ADAAS: 703.6.1</a>] </li> </ul>",
        "solution_number": 4020204,
        "strategy": "Provide accessible signage",
        "task": "Navigating the Workplace",
        "trigger": "(!a.V5) && (!a.V6) && (!a.H6) && (a.C2 || a.C6) && (a.LOW) && (a.T0402)"
    },
    "4020205": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to choose and install the signs",
        "gap": "Locations are difficult to identify",
        "solution": "Use signs with raised text",
        "solution_long": "Use signs with raised text.",
        "solution_number": 4020205,
        "strategy": "Provide accessible signage",
        "task": "Navigating the Workplace",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0402)"
    },
    "4020206": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to choose and install the signs",
        "gap": "Locations are difficult to identify",
        "solution": "Use Braille signs ",
        "solution_long": "Use Braille signs. <ul><li>Make sure that the employee knows Braille. </li> <li>Tactile characters on signs should be located 48 inches (1220 mm) minimum above the floor, measured from the baseline of the lowest tactile character. </li> <li>The characters should be located 60 inches (1525 mm) maximum above the  floor, measured from the baseline of the highest tactile character. [<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#703-signs\" target=\"_blank\">ADAAS: 703.4.1</a>]</li></ul>",
        "solution_number": 4020206,
        "strategy": "Provide accessible signage",
        "task": "Navigating the Workplace",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0402)"
    },
    "5020101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is too slippery",
        "solution": "Install drains in wet areas",
        "solution_long": "Install drains in wet areas.",
        "solution_number": 5020101,
        "strategy": "Keep floors dry",
        "task": "Using the Restroom",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0502)"
    },
    "5020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Path is too slippery",
        "solution": "Dry hands near sink",
        "solution_long": "To avoid having people drip water on the floor when drying hands, provide hand towels or dryer closer to the sink.",
        "solution_number": 5020102,
        "strategy": "Keep floors dry",
        "task": "Using the Restroom",
        "trigger": "(a.L4 || a.L8a || a.L8b || a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0502)"
    },
    "5020201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Path is too slippery",
        "solution": "Use slip-resistant flooring",
        "solution_long": "Replace the flooring with slip resistant flooring.",
        "solution_number": 5020201,
        "strategy": "Change the floor surface",
        "task": "Using the Restroom",
        "trigger": "(a.L4 || a.L8a || a.L8b) && (a.LOW) && (a.T0502)"
    },
    "5050101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Toilet is difficult to approach",
        "solution": "Provide space for mobility devices ",
        "solution_long": "Provide sufficient space for mobility devices near the toilet. <ul><li>Provide a minimum clear space around the toilet that is 56 inches deep, 60 inches wide, with the toilet located within this space (ADAAS).  The wall should be 16 to 18 inches from the centerline of the toilet (<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#604-water-closets-and-toilet-compartments\" target=\"blank\">ADAAS: 604</a>).</li> <li>These measurements come from the ADA, but people who use larger mobility aids (e.g., scooters) might need more space.  Work with the employee to determine his or her specific needs.</li> <li>There should be no other obstacles or barriers within the required clear floor space.</li></ul>",
        "solution_number": 5050101,
        "strategy": "Change the access space around the toilet",
        "task": "Using the Restroom",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0505)"
    },
    "5050102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Toilet is difficult to approach",
        "solution": "Change the location of the toilet ",
        "solution_long": "Change the location of the toilet within the room or stall. <ul><li>Provide a minimum clear space around the toilet that is 56 inches deep, 60 inches wide, with the toilet located within this space (ADAAS). The wall should be 16 to 18 inches from the centerline of the toilet (<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#604-water-closets-and-toilet-compartments\" target=\"blank\">ADAAS: 604</a>).</li> <li>These measurements come from the ADA, but people who use larger mobility aids (e.g., scooters) might need more space.  Work with the employee to determine his or her specific needs.</li> <li>There should be no other obstacles or barriers within the required clear floor space.</li></ul>",
        "solution_number": 5050102,
        "strategy": "Change the access space around the toilet",
        "task": "Using the Restroom",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0505)"
    },
    "5050103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Toilet is difficult to approach",
        "solution": "Increase the size of the stall",
        "solution_long": "Increase the size of the stall. <ul><li>Provide a minimum clear space around the toilet that is 56 inches deep, 60 inches wide, with the toilet located within this space (ADAAS). The wall should be 16 to 18 inches from the centerline of the toilet (<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#604-water-closets-and-toilet-compartments\" target=\"blank\">ADAAS: 604</a>).</li> <li>These measurements come from the ADA, but people who use larger mobility aids (e.g., scooters) might need more space.  Work with the employee to determine his or her specific needs.</li> <li>There should be no other obstacles or barriers within the required clear floor space.</li> <li>If the door opens into the stall, add an equivalent amount of space equal to the width of the door to the stall's depth or width (depending upon the location of the door).</li></ul>",
        "solution_number": 5050103,
        "strategy": "Change the access space around the toilet",
        "task": "Using the Restroom",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0505)"
    },
    "5050104": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Toilet is difficult to approach",
        "solution": "Increase the size of the room",
        "solution_long": "Increase the size of the room (if there is a single toilet in the room, without stalls). <ul><li>Provide a minimum clear space around the toilet that is 56 inches deep, 60 inches wide, with the toilet located within this space (ADAAS). The wall should be 16 to 18 inches from the centerline of the toilet (<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#604-water-closets-and-toilet-compartments\" target=\"blank\">ADAAS: 604</a>).</li> <li>These measurements come from the ADA, but people who use larger mobility aids (e.g., scooters) might need more space.  Work with the employee to determine his or her specific needs.</li> <li>There should be no other obstacles or barriers within the required clear floor space.</li> <li>The clear turning radius space can overlap clear floor space.  This generally results in a toilet room that measures a minimum size of 90 (depth) x 80 (wide) inches.</li> <li>If the door opens into the room, add an equivalent amount of space equal to the width of the door to the room's width or depth (depending upon the location of the door).</li></ul>",
        "solution_number": 5050104,
        "strategy": "Change the access space around the toilet",
        "task": "Using the Restroom",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0505)"
    },
    "5060101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Toilet or urinal is difficult to locate",
        "solution": "Change the toilet/urinal or wall colors",
        "solution_long": "Change the color of the toilet/urinal or walls to increase the contrast between them.",
        "solution_number": 5060101,
        "strategy": "Increase the color contrast",
        "task": "Using the Restroom",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0506)"
    },
    "5060201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Toilet or urinal is difficult to locate",
        "solution": "Change floor texture by toilet/urinal",
        "solution_long": "The use of different flooring textures around the toilet or urinal will make it easier to find with a white cane.",
        "solution_number": 5060201,
        "strategy": "Provide tactile cues",
        "task": "Using the Restroom",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0506)"
    },
    "5070101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Toilet is difficult to transfer on / off",
        "solution": "Add grab bars ",
        "solution_long": "Add grab bars to provide a place to hold on for support.  <ul><li>The top of the grab bar should be positioned 33 to 36 inches from the floor (<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#609-grab-bars\" target=\"blank\">ADAAS: 609</a>).</li> <li>This may be a situation where a restroom used only by employees (not the general public) might deviate from the ADA standards to instead fit the specific preferences for the employee.</li></ul>",
        "solution_number": 5070101,
        "strategy": "Add places to hold on for support",
        "task": "Using the Restroom",
        "trigger": "(a.L4 || a.L5 || a.L6 || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0507)"
    },
    "5070102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Toilet is difficult to transfer on / off",
        "solution": "Add a transfer pole",
        "solution_long": "Add a transfer pole to provide a place to hold on for support.  Make sure there is still sufficient clearance around the toilet. <ul><li>This may be a situation where a restroom used only by employees (not the general public) might deviate from the ADA standards to instead fi the specific preferences for the employee.</li></ul>",
        "solution_number": 5070102,
        "strategy": "Add places to hold on for support",
        "task": "Using the Restroom",
        "trigger": "(a.L4 || a.L5 || a.L6 || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0507)"
    },
    "5070201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Toilet is difficult to transfer on / off",
        "solution": "Add an elevated toilet seat",
        "solution_long": "Add an elevated toilet seat.",
        "solution_number": 5070201,
        "strategy": "Change the height of the toilet",
        "task": "Using the Restroom",
        "trigger": "(a.L4 || a.L5 || a.L6 || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0507)"
    },
    "5070202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed plumber might be helpful",
        "gap": "Toilet is difficult to transfer on / off",
        "solution": "Change the toilet's height",
        "solution_long": "Replace the toilet or toilet base with one that is a different height to raise/lower the height of the seat. <ul><li>This may be a situation where a restroom used only by employees (not the general public) might deviate from the ADA standards to instead fit the specific preferences for the employee.</li></ul>",
        "solution_number": 5070202,
        "strategy": "Change the height of the toilet",
        "task": "Using the Restroom",
        "trigger": "(a.L4 || a.L5 || a.L6 || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0507)"
    },
    "5070301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Toilet is difficult to transfer on / off",
        "solution": "Tighten the toilet seat",
        "solution_long": "Tighten the toilet seat to avoid wobble when getting on or off.",
        "solution_number": 5070301,
        "strategy": "Make the toilet seat more secure",
        "task": "Using the Restroom",
        "trigger": "(a.L4 || a.L5 || a.L6 || a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0507)"
    },
    "5080101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Flush mechanism is difficult to operate / reach",
        "solution": "Provide clear space near toilet ",
        "solution_long": "Provide sufficient space for mobility devices near the toilet. <ul><li>Provide a minimum clear space around the toilet that is 56 inches deep, 60 inches wide, with the toilet located within this space. The wall should be 16 to 18 inches from the centerline of the toilet (<a href=\"https://www.ada.gov/law-and-regs/design-standards/2010-stds/#305-clear-floor-or-ground-space\" target=\"blank\">ADAAS: 305</a>).</li> <li>There should be no other obstacles or barriers within the required clear floor space.</li></ul>",
        "solution_number": 5080101,
        "strategy": "Change the access space around the toilet",
        "task": "Using the Restroom",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0508)"
    },
    "5080201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed plumber might be helpful",
        "gap": "Flush mechanism is difficult to operate / reach",
        "solution": "Use toilet with closer flush mechanism",
        "solution_long": "Change the toilet to one where the flush mechanism is in a different location that is easier to reach.  Under the ADA standards, the flush mechanism should be on the side of the toilet with more space (easier wheelchair access).  If it is on the opposite side, wheelchair users will need to reach across the toilet to operate it.",
        "solution_number": 5080201,
        "strategy": "Change the flush mechanism location",
        "task": "Using the Restroom",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U10 || a.U11) && (a.LOW) && (a.T0508)"
    },
    "5080701": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed plumber might be helpful",
        "gap": "Flush mechanism is difficult to operate / reach",
        "solution": "Use an automated flush mechanism",
        "solution_long": "Use a flush mechanism that is automatically activated by motion.",
        "solution_number": 5080701,
        "strategy": "Use a hands-free solution",
        "task": "Using the Restroom",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U10 || a.U11) && (a.LOW) && (a.T0508)"
    },
    "5090101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Toilet tissue is difficult to acquire (locate, reach, grip)",
        "solution": "Move dispenser for easier reach",
        "solution_long": "Move the dispenser to a location that is closer to the toilet for easier reach. <ul><li>If an employee is able to reach better on one side, consider moving the dispenser to that side or providing a loose roll of toilet paper.</li></ul>",
        "solution_number": 5090101,
        "strategy": "Change the location of the dispenser",
        "task": "Using the Restroom",
        "trigger": "(a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0509)"
    },
    "5090201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Toilet tissue is difficult to acquire (locate, reach, grip)",
        "solution": "Lower or raise the dispenser",
        "solution_long": "Lower or raise the dispenser to a height that is easier to reach. <ul><li>The ADAAS specifies that toilet paper dispensers be placed at a height 15 inches min to 48 inches max from the floor.</li> <li>For dispensers mounted over a grab bar, there should be a minimum of 12 inches between the lowest operable part of the dispenser and the top of the grab bar.</li></ul>",
        "solution_number": 5090201,
        "strategy": "Change the height of the dispenser",
        "task": "Using the Restroom",
        "trigger": "(a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0509)"
    },
    "5090301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Toilet tissue is difficult to acquire (locate, reach, grip)",
        "solution": "Change dispenser or wall color",
        "solution_long": "Change the color of the toilet paper dispenser and/or walls to increase the contrast between them.",
        "solution_number": 5090301,
        "strategy": "Increase the color contrast",
        "task": "Using the Restroom",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0509)"
    },
    "5090401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Toilet tissue is difficult to acquire (locate, reach, grip)",
        "solution": "Use dispenser with less resistance",
        "solution_long": "Change the type of dispenser to one that provides minimal resistance to pulling on the paper.",
        "solution_number": 5090401,
        "strategy": "Reduce the resistance on the paper",
        "task": "Using the Restroom",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0509)"
    },
    "5090501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Toilet tissue is difficult to acquire (locate, reach, grip)",
        "solution": "Use an unenclosed dispenser",
        "solution_long": "Enclosed dispensers, particularly double roll dispensers, can be difficult to reach into.  It may also be difficult to see the toilet paper roll to find the end.  Change the type of dispenser to one that is not enclosed and allows for easy access for gripping the paper.",
        "solution_number": 5090501,
        "strategy": "Remove obstacles surrounding the paper",
        "task": "Using the Restroom",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0509)"
    },
    "5090601": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed plumber might be helpful",
        "gap": "Toilet tissue is difficult to acquire (locate, reach, grip)",
        "solution": "Use a bidet",
        "solution_long": "A bidet uses a stream of water to cleanse a person's posterior after using the toilet.  Toilet paper is needed only to dry the area, although some more expensive bidets provide an air dryer feature.",
        "solution_number": 5090601,
        "strategy": "Reduce the need for toilet paper",
        "task": "Using the Restroom",
        "trigger": "(a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0509)"
    },
    "6010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Restroom sink is difficult to locate",
        "solution": "Change sink/wall/counter colors",
        "solution_long": "Change the color of the sink, wall, and/or counter to increase the contrast between them.",
        "solution_number": 6010101,
        "strategy": "Increase the color contrast",
        "task": "Washing Hands",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0601)"
    },
    "6010102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Restroom sink is difficult to locate",
        "solution": "Change flooring texture around sink",
        "solution_long": "The use of different flooring textures around the sink will make it easier to find with a white cane.",
        "solution_number": 6010102,
        "strategy": "Provide tactile cues",
        "task": "Washing Hands",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0601)"
    },
    "6020101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Sink is difficult to reach",
        "solution": "Change the sink location",
        "solution_long": "Move the location of the sink so there is a clear 30-inch-wide by 48-inch-deep space centered in front of the sink.",
        "solution_number": 6020101,
        "strategy": "Change the sink location",
        "task": "Washing Hands",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0602)"
    },
    "6020201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Sink is difficult to reach",
        "solution": "Change sink height",
        "solution_long": "Raise or lower the sink so it is at a height that is easier to reach.  <ul><li>The countertop should be no higher than 34 inches for wheelchair access.</li> <li>If there is knee clearance beneath the sink for a wheelchair user, the height of the space should be at least 27 inches (ADAAS).</li></ul>.",
        "solution_number": 6020201,
        "strategy": "Change the sink height",
        "task": "Washing Hands",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0602)"
    },
    "6020301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Sink is difficult to reach",
        "solution": "Use an easy-to-reach sink style",
        "solution_long": "Change the style of the sink to one that is easier to reach.",
        "solution_number": 6020301,
        "strategy": "Change the type / style of the sink",
        "task": "Washing Hands",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0602)"
    },
    "6020401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Sink is difficult to reach",
        "solution": "Cover pipes beneath sink",
        "solution_long": "Cover any hot water pipes under the sink that may be bumped by the legs of people sitting in front of the sink.",
        "solution_number": 6020401,
        "strategy": "Provide leg room under the sink",
        "task": "Washing Hands",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0602)"
    },
    "6020402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A licensed contractor might be helpful",
        "gap": "Sink is difficult to reach",
        "solution": "Remove or change enclosure under sink",
        "solution_long": "Remove the enclosure under the sink or change the style of the enclosure to provide leg room for a person to sit in front of the sink.",
        "solution_number": 6020402,
        "strategy": "Provide leg room under the sink",
        "task": "Washing Hands",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T0602)"
    },
    "6030101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A plumber might be helpful",
        "gap": "Sink controls are difficult to operate",
        "solution": "Use larger sink controls",
        "solution_long": "Build up or replace the sink controls so they are larger and easier to manipulate.",
        "solution_number": 6030101,
        "strategy": "Increase the size of the sink controls",
        "task": "Washing Hands",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0603)"
    },
    "6030201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: A plumber might be helpful",
        "gap": "Sink controls are difficult to operate",
        "solution": "Change type of sink controls",
        "solution_long": "Replace the control / faucet with a different mechanism that is easier to activate. For example, a single lever or motion activated faucet might be easier to use than one with two knobs.",
        "solution_number": 6030201,
        "strategy": "Change the type of sink controls",
        "task": "Washing Hands",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0603)"
    },
    "6030301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Sink controls are difficult to operate",
        "solution": "Label the hot and cold taps",
        "solution_long": "Label the hot and cold taps with both colors and large print or raised letters.",
        "solution_number": 6030301,
        "strategy": "Label the sink controls",
        "task": "Washing Hands",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6 || a.H6 || a.C2 || a.C6) && (a.LOW) && (a.T0603)"
    },
    "6040101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Soap dispenser is difficult to locate",
        "solution": "Change dispenser/wall/counter colors",
        "solution_long": "Change the color of the soap dispenser, wall, and/or counter to increase the contrast between them.",
        "solution_number": 6040101,
        "strategy": "Increase the color contrast",
        "task": "Washing Hands",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0604)"
    },
    "6040201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Soap dispenser is difficult to locate",
        "solution": "Move soap closer to sink",
        "solution_long": "Move the soap dispenser to a location near the sink being used.",
        "solution_number": 6040201,
        "strategy": "Change the location of the dispenser",
        "task": "Washing Hands",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T0604)"
    },
    "6050101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Soap dispenser is difficult to reach",
        "solution": "Move soap away from obstacles",
        "solution_long": "Move the soap dispenser to a location that does not require a reach over obstacles such as the sink.",
        "solution_number": 6050101,
        "strategy": "Change the location of the dispenser",
        "task": "Washing Hands",
        "trigger": "(a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0605)"
    },
    "6050102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Soap dispenser is difficult to reach",
        "solution": "Move soap within reach of sink",
        "solution_long": "Move the soap dispenser to a location within reach of the sink being used to avoid having to use the mobility aid with wet hands.",
        "solution_number": 6050102,
        "strategy": "Change the location of the dispenser",
        "task": "Washing Hands",
        "trigger": "(a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0605)"
    },
    "6050201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Soap dispenser is difficult to reach",
        "solution": "Change height of soap dispenser",
        "solution_long": "Lower or raise the height of soap dispenser. <ul><li>The operable parts of the dispenser should be located between 15 and 48 inches from the ground. (ADAAS).</li></ul>",
        "solution_number": 6050201,
        "strategy": "Change the height of the dispenser",
        "task": "Washing Hands",
        "trigger": "(a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0605)"
    },
    "6050301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Soap dispenser is difficult to reach",
        "solution": "Use portable soap dispenser",
        "solution_long": "Use a portable soap dispenser instead of one that is installed.",
        "solution_number": 6050301,
        "strategy": "Change the type of dispenser",
        "task": "Washing Hands",
        "trigger": "(a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0605)"
    },
    "6060101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Soap dispenser is difficult to operate",
        "solution": "Change the type of soap dispenser",
        "solution_long": "Change the soap dispenser to one that is operated a different way. For example it might be operated by pushing downward on a pump, pushing sideways on a lever, or it might be motion activated.",
        "solution_number": 6060101,
        "strategy": "Change the type of dispenser",
        "task": "Washing Hands",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0606)"
    },
    "6060102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Soap dispenser is difficult to operate",
        "solution": "Use a portable soap dispenser",
        "solution_long": "Use a portable soap dispenser instead of one that is installed.",
        "solution_number": 6060102,
        "strategy": "Change the type of dispenser",
        "task": "Washing Hands",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0606)"
    },
    "6070101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Paper towel dispenser or hand dryer is difficult to locate",
        "solution": "Increase color contrast of towels/dryer",
        "solution_long": "Change the color of the towel dispenser, dryer, wall, and/or counter to increase the contrast between them.",
        "solution_number": 6070101,
        "strategy": "Increase the color contrast",
        "task": "Washing Hands",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0607)"
    },
    "6070201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Paper towel dispenser or hand dryer is difficult to locate",
        "solution": "Move towels closer to sink",
        "solution_long": "Change the location of the towel dispenser or dryer to an area near the sink.  Avoid nearby obstacles on the floor (e.g., trash can) and avoid a reach over obstacles (e.g., sink).",
        "solution_number": 6070201,
        "strategy": "Change the location of the dispenser",
        "task": "Washing Hands",
        "trigger": "(a.V3 || a.V4) && (a.LOW) && (a.T0607)"
    },
    "6080101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Paper towel dispenser or hand dryer is difficult to reach",
        "solution": "Remove obstacles near towels/dryer",
        "solution_long": "Remove obstacles that prevent the employee from getting close to the paper towel dispenser or hand dryer.",
        "solution_number": 6080101,
        "strategy": "Let employee get closer to the system",
        "task": "Washing Hands",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0608)"
    },
    "6080201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: An electrician might be helpful",
        "gap": "Paper towel dispenser or hand dryer is difficult to reach",
        "solution": "Move towels/dryer away from obstacles",
        "solution_long": "Move the towel dispenser or hand dryer to a location that does not require reaching over obstacles such as the sink.",
        "solution_number": 6080201,
        "strategy": "Change the location of the dispenser",
        "task": "Washing Hands",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0608)"
    },
    "6080202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: An electrician might be helpful",
        "gap": "Paper towel dispenser or hand dryer is difficult to reach",
        "solution": "Move towels/dryer within reach of sink",
        "solution_long": "Move the towel dispenser or dryer to a location within reach of the sink to avoid having to use the mobility aid with wet hands.",
        "solution_number": 6080202,
        "strategy": "Change the location of the dispenser",
        "task": "Washing Hands",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0608)"
    },
    "6080301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: An electrician might be helpful",
        "gap": "Paper towel dispenser or hand dryer is difficult to reach",
        "solution": "Change height of towels/dryer",
        "solution_long": "Lower or raise the paper towel dispenser or hand dryer to a height that is easier to reach. <ul><li>The operable parts of the dispenser should be located between 15 and 48 inches from the ground. (ADAAS).</li></ul>",
        "solution_number": 6080301,
        "strategy": "Change the height of the dispenser",
        "task": "Washing Hands",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0608)"
    },
    "6080401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Paper towel dispenser or hand dryer is difficult to reach",
        "solution": "Provide loose paper towels",
        "solution_long": "Provide loose paper towels on the counter.",
        "solution_number": 6080401,
        "strategy": "Change the type of dispenser",
        "task": "Washing Hands",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T0608)"
    },
    "6090101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: An electrician might be helpful",
        "gap": "Hand dryer control is difficult to operate",
        "solution": "Change the type of hand dryer",
        "solution_long": "Change the hand dryer to one that is operated a different way (e.g., push button vs. motion activated).",
        "solution_number": 6090101,
        "strategy": "Change the type of dryer",
        "task": "Washing Hands",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0609)"
    },
    "6090201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Hand dryer control is difficult to operate",
        "solution": "Provide loose paper towels",
        "solution_long": "Provide loose paper towels on the counter.",
        "solution_number": 6090201,
        "strategy": "Change the method for drying hands",
        "task": "Washing Hands",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0609)"
    },
    "6090202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Hand dryer control is difficult to operate",
        "solution": "Use a towel dispenser",
        "solution_long": "Switch to using paper towels held in a dispenser.",
        "solution_number": 6090202,
        "strategy": "Change the method for drying hands",
        "task": "Washing Hands",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0609)"
    },
    "6100101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Paper towel dispenser is difficult to operate",
        "solution": "Change the type of towel dispenser",
        "solution_long": "Change the towel dispenser to one that is operated a different way (e.g., crank vs. lever vs. pull).",
        "solution_number": 6100101,
        "strategy": "Change the type of dispenser",
        "task": "Washing Hands",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0610)"
    },
    "6100102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Paper towel dispenser is difficult to operate",
        "solution": "Provide loose paper towels",
        "solution_long": "Provide loose paper towels on the counter.",
        "solution_number": 6100102,
        "strategy": "Change the type of dispenser",
        "task": "Washing Hands",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T0610)"
    },
    "6100201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: An electrician might be helpful",
        "gap": "Paper towel dispenser is difficult to operate",
        "solution": "Use a hand dryer",
        "solution_long": "Switch to using a hand dryer.",
        "solution_number": 6100201,
        "strategy": "Change the method for drying hands",
        "task": "Washing Hands",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T0610)"
    },
    "21010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "It is difficult to get in/out of the work chair because it moves too easily",
        "solution": "Remove chair's casters wheels",
        "solution_long": "Remove the casters wheels from the chair. <ul><wafactors L8c, L8d, L8e><li>Wheelchair users who have difficulty transferring into and out of an office chair are strongly encouraged to consult an assistive technology expert.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21010101,
        "strategy": "Reduce or limit the motion of the chair",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L4 || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T2101)"
    },
    "21010102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "It is difficult to get in/out of the work chair because it moves too easily",
        "solution": "Use a chair without casters",
        "solution_long": "Replace the chair with one without casters. <ul><wafactors L8c, L8d, L8e><li>Wheelchair users who have difficulty transferring into and out of an office chair are strongly encouraged to consult an assistive technology expert.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21010102,
        "strategy": "Reduce or limit the motion of the chair",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L4 || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T2101)"
    },
    "21010103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "It is difficult to get in/out of the work chair because it moves too easily",
        "solution": "Use locking safety casters",
        "solution_long": "Replace the casters with locking safety casters. <ul><wafactors L8c, L8d, L8e><li>Wheelchair users who have difficulty transferring into and out of an office chair are strongly encouraged to consult an assistive technology expert.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21010103,
        "strategy": "Reduce or limit the motion of the chair",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L4 || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T2101)"
    },
    "21010104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "It is difficult to get in/out of the work chair because it moves too easily",
        "solution": "Use a chair without a swivel",
        "solution_long": "Replace the chair with one without a swivel. <ul><wafactors L8c, L8d, L8e><li>Wheelchair users who have difficulty transferring into and out of an office chair are strongly encouraged to consult an assistive technology expert.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21010104,
        "strategy": "Reduce or limit the motion of the chair",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L4 || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T2101)"
    },
    "21010105": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "It is difficult to get in/out of the work chair because it moves too easily",
        "solution": "Lock the chair's swivel mechanism",
        "solution_long": "Lock the swivel mechanism on the chair. <ul><wafactors L8c, L8d, L8e><li>Wheelchair users who have difficulty transferring into and out of an office chair are strongly encouraged to consult an assistive technology expert.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21010105,
        "strategy": "Reduce or limit the motion of the chair",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L4 || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T2101)"
    },
    "21010106": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "It is difficult to get in/out of the work chair because it moves too easily",
        "solution": "Use an area rug to reduce chair motion  ",
        "solution_long": "Add an area rug to a smooth floor surface to increase resistance and reduce or limit the motion of the chair.  <ul><wafactors L8c, L8d, L8e><li>Wheelchair users who have difficulty transferring into and out of an office chair are strongly encouraged to consult an assistive technology expert.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21010106,
        "strategy": "Reduce or limit the motion of the chair",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L4 || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T2101)"
    },
    "21010107": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "It is difficult to get in/out of the work chair because it moves too easily",
        "solution": "Work while standing",
        "solution_long": "Provide a standing workstation.  <ul> <wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21010107,
        "strategy": "Reduce or limit the motion of the chair",
        "task": "Getting in/out of Work Chair",
        "trigger": "(!a.L2) && (!a.L4) && (!a.L8a) && (!a.L8b) && (!a.L8c) && (!a.L8d) && (!a.L8e) && (a.U1 || a.U2) && (a.LOW) && (a.T2101)"
    },
    "21010108": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "It is difficult to get in/out of the work chair because it moves too easily",
        "solution": "Work from the wheelchair or scooter",
        "solution_long": "Consider having the employee use their own wheelchair or scooter as a work seat.  <ul><li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21010108,
        "strategy": "Reduce or limit the motion of the chair",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2101)"
    },
    "21020101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The office chair is difficult to pull out or slide in",
        "solution": "Clean the casters to allow free movement",
        "solution_long": "Ensure existing casters are clean and allow free movement. <ul><wafactors L8c, L8d, L8e><li>Wheelchair users who have difficulty transferring into and out of an office chair are strongly encouraged to consult an assistive technology expert.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21020101,
        "strategy": "Increase the chair motion",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L4 || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T2102)"
    },
    "21020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The office chair is difficult to pull out or slide in",
        "solution": "Use a chair with casters",
        "solution_long": "Replace the chair with one that has casters. <ul><wafactors L8c, L8d, L8e><li>Wheelchair users who have difficulty transferring into and out of an office chair are strongly encouraged to consult an assistive technology expert.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21020102,
        "strategy": "Increase the chair motion",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L4 || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T2102)"
    },
    "21020103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The office chair is difficult to pull out or slide in",
        "solution": "Use casters that move more easily",
        "solution_long": "Replace the casters with ones that move more easily. <ul><wafactors L8c, L8d, L8e><li>Wheelchair users who have difficulty transferring into and out of an office chair are strongly encouraged to consult an assistive technology expert.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21020103,
        "strategy": "Increase the chair motion",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L4 || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T2102)"
    },
    "21020104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The office chair is difficult to pull out or slide in",
        "solution": "Use a chair that swivels",
        "solution_long": "Replace the chair with one that swivels. <ul><wafactors L8c, L8d, L8e><li>Wheelchair users who have difficulty transferring into and out of an office chair are strongly encouraged to consult an assistive technology expert.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21020104,
        "strategy": "Increase the chair motion",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L4 || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T2102)"
    },
    "21020105": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The office chair is difficult to pull out or slide in",
        "solution": "Use a smooth mat over a floor surface",
        "solution_long": "Add a smooth chair mat over a carpeted floor surface. <ul><wafactors L8c, L8d, L8e><li>Wheelchair users who have difficulty transferring into and out of an office chair are strongly encouraged to consult an assistive technology expert.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21020105,
        "strategy": "Increase the chair motion",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L4 || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2) && (a.LOW) && (a.T2102)"
    },
    "21020106": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The office chair is difficult to pull out or slide in",
        "solution": "Work from the wheelchair or scooter",
        "solution_long": "Consider having the employee use their own wheelchair or scooter as a work seat. <ul> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21020106,
        "strategy": "Increase the chair motion",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2102)"
    },
    "21030101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "The sitting / standing process is difficult",
        "solution": "Use a chair with armrests",
        "solution_long": "Replace the chair with one that has armrests that can provide support while sitting/standing. <ul><li>For longer term sitting, position the armrests so that they support the lower arm, allow the shoulders to remain relaxed during use, and allow the upper arms to remain close to the torso.</li> <li>Armrests may need to be at a different height to assist with sitting / standing than for general work tasks.  If considering this option, make sure the armrests are height adjustable without tools.</li> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030101,
        "strategy": "Use armrests for support",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The sitting / standing process is difficult",
        "solution": "Adjust armrest height",
        "solution_long": "Raise or lower adjustable armrests to an appropriate height for the person to be able to use as a support during sitting and standing. <ul><li>For longer term sitting, position the armrests so that they support the lower arm, allow the shoulders to remain relaxed during use, and allow the upper arms to remain close to the torso.</li> <li>Armrests may need to be at a different height to assist with sitting / standing than for general work tasks.  If considering this option, make sure the armrests are height adjustable without tools.</li> <wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030201,
        "strategy": "Change the armrest height",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "The sitting / standing process is difficult",
        "solution": "Change armrests for a different height",
        "solution_long": "Change the armrests to ones that are at a height to offer more support during sitting and standing.  Replace the armrests or replace the entire chair. <ul><li>For longer term sitting, position the armrests so that they support the lower arm, allow the shoulders to remain relaxed during use, and allow the upper arms to remain close to the torso.</li> <li>Armrests may need to be at a different height to assist with sitting / standing than for general work tasks.  If considering this option, make sure the armrests are height adjustable without tools.</li> <wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030202,
        "strategy": "Change the armrest height",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "The sitting / standing process is difficult",
        "solution": "Adjust armrest spacing",
        "solution_long": "Adjust the spacing between the armrests for better support during sitting or standing. <ul><li>Position the armrests so that they are at an appropriate width for the person to be able to fit comfortably in the seat, while allowing the upper arms to remain close to the torso.  The person should be able to use them for support while sitting and standing.</li> <li>The armrests many need to be a different distance apart to assist with sitting / standing than for general tasks.  If considering this option, make sure the armrests are easily adjustable.</li> <wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030301,
        "strategy": "Change the distance between the armrests",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "The sitting / standing process is difficult",
        "solution": "Change armrests for a different spacing",
        "solution_long": "Change the chair seat to one that is an appropriate width for the armrests to support sitting and standing.  Replace the seat on the chair or replace the entire chair. <ul><li>Position the armrests so that they are at an appropriate width for the person to be able to fit comfortably in the seat, while allowing the upper arms to remain close to the torso.  The person should be able to use them for support while sitting and standing.</li> <li>The armrests many need to be a different distance apart to assist with sitting / standing than for general tasks.  If considering this option, make sure the armrests are easily adjustable.</li> <wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030302,
        "strategy": "Change the distance between the armrests",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "The sitting / standing process is difficult",
        "solution": "Move armrests toward the chair's front",
        "solution_long": "Move the existing armrests toward the front of the chair. <ul><li>The armrests should be at least as long as the seat to assist with sitting and standing.</li> <li>The armrest may need to be longer to assist with sitting and standing then what allows access to the workstation surface.</li> <wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030401,
        "strategy": "Increase the armrest length",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "The sitting / standing process is difficult",
        "solution": "Use longer armrests",
        "solution_long": "Replace the armrests with ones that are longer by either replacing the armrests or replacing the entire chair. <ul><li>The armrests should be at least as long as the seat to assist with sitting and standing.</li> <li>The armrest may need to be longer to assist with sitting and standing then what allows access to the workstation surface.</li> <wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030402,
        "strategy": "Increase the armrest length",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The sitting / standing process is difficult",
        "solution": "Use a transfer pole or grab bar",
        "solution_long": "Add a transfer pole or grab bar near the work surface. <ul><wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030501,
        "strategy": "Provide another support element",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030502": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The sitting / standing process is difficult",
        "solution": "Use other furnishings for support",
        "solution_long": "Arrange the work space so that the employee can use other furnishings like the desk surface or a file cabinet to provide support while standing. <ul><wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030502,
        "strategy": "Provide another support element",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030601": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "The sitting / standing process is difficult",
        "solution": "Use a chair with built-in seat lift",
        "solution_long": "Replace the chair with one that has a built-in spring-loaded or powered seat lift. <ul><wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030601,
        "strategy": "Use a seat lift device",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030602": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "The sitting / standing process is difficult",
        "solution": "Add a spring-loaded seat lift insert",
        "solution_long": "Add a spring-loaded lift insert to the existing chair. <ul><wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030602,
        "strategy": "Use a seat lift device",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030701": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "The sitting / standing process is difficult",
        "solution": "Adjust the seat height",
        "solution_long": "Change the height of the seat to make it easier for the individual to stand.  Usually, this means using a higher seat. <ul><li>The seat height can facilitate both getting in and getting out of the chair by raising the employee's seated center of gravity higher than their knees.</li> <li>The seat height may need to be higher to assist with sitting and standing then what allows access to the workstation surface.  This may cause low back pain or impact the person's ability to access their work environment. </li> <li>A footrest may be needed so that the feet don't dangle.</li> <wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030701,
        "strategy": "Change the seat height",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030702": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "The sitting / standing process is difficult",
        "solution": "Change chair for a different height",
        "solution_long": "Replace the chair with one at an appropriate height to make it easier for the employee to stand.  Usually, this means using a higher seat. <ul><li>The seat height can facilitate both getting in and getting out of the chair by raising the individual's seated center of gravity higher than their knees.</li> <li>The seat height may need to be higher to assist with sitting and standing then what allows access to the workstation surface.  This may cause low back pain or impact the person's ability to access their work environment.</li> <wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030702,
        "strategy": "Change the seat height",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21030703": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "The sitting / standing process is difficult",
        "solution": "Add a firm seat cushion",
        "solution_long": "Increase the height of the seat by adding a firm cushion to raise the employee's center of gravity above their knees. <ul><li>The seat height can facilitate both getting in and getting out of the chair by raising the employee's seated center of gravity higher than their knees.</li> <li>The seat height may need to be higher to assist with sitting and standing then what allows access to the workstation surface.  This may cause low back pain or impact the person's ability to access their work environment.</li> <li>A footrest may be needed so that the feet don't dangle.</li> <wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21030703,
        "strategy": "Change the seat height",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L3 || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2103)"
    },
    "21040101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "It is difficult to transfer to a work chair because the chair's armrests get in the way",
        "solution": "Remove one/both armrests",
        "solution_long": "Remove one or both armrests from the chair. <ul><wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>This solution may impact the person's ability to maintain a seated posture.</li><li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21040101,
        "strategy": "Remove the armrests from the office chair",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2104)"
    },
    "21040102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "It is difficult to transfer to a work chair because the chair's armrests get in the way",
        "solution": "Use a chair without armrests",
        "solution_long": "Replace the chair with one that does not have armrests. <ul><wafactors SHD><li>This accommodation will impact anyone else using the workstation.</li></wafactors> <li>This solution may impact the person's ability to maintain a seated posture.</li><li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul>",
        "solution_number": 21040102,
        "strategy": "Remove the armrests from the office chair",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2104)"
    },
    "21040201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "It is difficult to transfer to a work chair because the chair's armrests get in the way",
        "solution": "Work from the wheelchair or scooter",
        "solution_long": "Consider having the employee use their own wheelchair or scooter as a work seat. <ul><li>If the employee has difficulty with maintaining a seated posture or accessing the work surface, please consider those solutions first.</li></ul> ",
        "solution_number": 21040201,
        "strategy": "Do not use the office chair",
        "task": "Getting in/out of Work Chair",
        "trigger": "(a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T2104)"
    },
    "31010101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty remembering appointments or meetings",
        "solution": "Use an electronic calendar ",
        "solution_long": "Use an electronic calendar (e.g., Google calendar, AbleLink Endeavor) to accept electronic invitations and record appointments or meetings as they are scheduled.  Customize reminders with audible and/or visual prompts that tell the employee what he or she needs to remember.  Program in regularly scheduled, repeating meetings or activities. <ul> <li>When scheduling reminders, include time for the employee to get ready for the next task.  Most online calendars allow the user to set a few staggered reminders (e.g., 1 hour, 5 minutes).</li> <li>Look for a system that provides reminders in a format that the employee understands and might be able to set independently (e.g., text, images, color coding, customized alarm tone). </li> <wafactors match=\"!C7\"><li>If the employee is not a strong written text user, consider using a system that supports voice recorded messages (e.g., Endeavor app).</li></wafactors> <li>Electronic calendars can synch (update entries) and work across all electronic devices (computers, mobile phones, tablets, smart watches).</li> <li>Mobile devices such as phones can create distractions and may not be allowed at work, except as an accommodation.</li> </ul><br /><a href= \"https://assistivesoftware.gatech.edu/time-management-tools\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 31010101,
        "strategy": "Record appointments as they are scheduled",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3101)"
    },
    "31010102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remembering appointments or meetings",
        "solution": "Use a portable paper calendar",
        "solution_long": "Use a paper-based appointment book, calendar, or planner that the employee can carry around. <ul> <wafactors match=\"!V3 || !V4 || !V5 || !V6\"><li>Ask the employee what information formats are preferred (e.g., text, images, color coding, texture coding).</li> </wafactors> <wafactors V3 V4 V5 V6><li>Ask the employee what information formats are preferred (e.g., large print, Braille, texture coding).</li></wafactors> </ul>",
        "solution_number": 31010102,
        "strategy": "Record appointments as they are scheduled",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3101)"
    },
    "31010103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remembering appointments or meetings",
        "solution": "Use an appointment board/wall calendar \r\n",
        "solution_long": "Use paper or dry erase board calendars that are placed in line of sight or key areas for easy reference (e.g., near entrance, near work areas, or the work surface). <ul> <li>Ask the employee what information formats are preferred (e.g., text, images, color coding, texture coding).</li> </ul>",
        "solution_number": 31010103,
        "strategy": "Record appointments as they are scheduled",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3101)"
    },
    "31010201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty remembering appointments or meetings",
        "solution": "Use an electronic calendar \r\n",
        "solution_long": "Use an electronic calendar (e.g., Google calendar, AbleLink Endeavor) to record appointments or meetings as they are scheduled.  Customize reminders with audible and/or visual prompts that tell the employee what he or she needs to remember.  Program in regularly scheduled, repeating meetings or activities. <ul> <li>When scheduling reminders, include time for the employee to get ready for the next task.  Most online calendars allow the user to set a few staggered reminders (e.g., 1 hour, 5 minutes).</li> <li>Electronic calendars can synch (update entries) and work across all electronic devices (computers, mobile phones, tablets, smart watches).</li> <li>Mobile devices such as phones can create distractions and may not be allowed at work, except as an accommodation.</li> <li>Look for a system that provides reminders in a format that the employee understands and might be able to set independently (e.g., text, images, color coding, customized alarm tone). </li> <wafactors match=\"!C7\"><li>If the employee is not a strong written text user, consider using a system that supports voice recorded messages (e.g., Endeavor app).</li> </wafactors> <li>Vibration alerts can provide private reminders</li> </ul><br /><a href= \"https://assistivesoftware.gatech.edu/time-management-tools\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 31010201,
        "strategy": "Provide an alert before the appointment\r\n",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3101)"
    },
    "31010202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remembering appointments or meetings",
        "solution": "Set clock/timer alarm for appointment\r\n",
        "solution_long": "Set a watch alarm or the alarm clock on a mobile device to go off before the appointment.  Look for a system that will repeat the alarm until it is recognized.  When scheduling reminders, include time for the employee to get ready for the next task. <ul> <li>In addition to providing the alarm, the system should tell the employee what needs to be remembered (e.g., location, who to contact if questions).</li><li>Wearables such as an Apple Watch, Fitbit, or Watchminder can also be used for reminders.</li> <li>Mobile devices such as phones can create distractions and may not be allowed at work, except as an accommodation.</li> <li>Look for a system that provides reminders in a format that the employee understands and might be able to set independently (e.g., text, images, customized alarm tone or voice).</li> <wafactors H2 H3 H4 H5 H6 NSY OPN> <li>Use a visual or vibration alert if there are concerns about the employee hearing it over the workplace noise and/or to avoid disturbing other workers.</li></wafactors></ul>",
        "solution_number": 31010202,
        "strategy": "Provide an alert before the appointment\r\n",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.MOB) && (a.T3101)"
    },
    "31010203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remembering appointments or meetings",
        "solution": "Review future appointments\r\n",
        "solution_long": "Plan ahead by reviewing appointments scheduled for the next day or for the week.",
        "solution_number": 31010203,
        "strategy": "Provide an alert before the appointment \r\n",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3101)"
    },
    "31020101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Use a to-do list/homework planner app \r\n",
        "solution_long": "Use electronic to-do lists or planner on a workstation computer, tablet, or cell phone to record work tasks as they are assigned.  Then, set alarms and prompts that tell the employee what needs to be remembered. Many apps allow the user to set a few staggered reminders (e.g., 1 hour, 5 minutes). <ul> <li>Look for a system that provides a reminder when tasks are due.</li> <li>Seeing the whole work task list at once can be overwhelming.  Consider showing only one task at a time as they are checked off.</li> <li>Carry over unfinished tasks to the next day. </li> <li>Electronic calendars can synch (update entries) and work across all electronic devices (computers, mobile phones, tablets, smart watches).</li> <li>Mobile devices such as phones can create distractions and may not be allowed at work, except as an accommodation.</li> <li>Look for a system that uses formats that the employee understands and might be able to set independently (e.g., text, images, color coding, sound). </li> <wafactors match=\"!C7\"> <li>If the employee is not a strong written text user, consider using a system that supports voice recorded messages (e.g., Endeavor app).</li> </wafactors> <wafactors H2 H3 H4 H5 H6 NSY OPN><li> Use a visual or vibration alert if there are concerns about the employee hearing it over the workplace noise and/or to avoid disturbing other workers.</li></wafactors></ul> <br /><a href= \"https://assistivesoftware.gatech.edu/time-management-tools\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 31020101,
        "strategy": "Record work tasks and when they are due as they are assigned",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3102)"
    },
    "31020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Upload to-do list items ",
        "solution_long": "If the employee has difficulty creating the list of work tasks, try using an app that allows a supervisor to create an electronic to-do list (e.g. Trello, ZenKit, and ToodleDo), then assign it to the employee. It will be added to the employee's list of tasks.  Both the employee and supervisor can track progress as the employee checks off completed tasks.",
        "solution_number": 31020102,
        "strategy": "Record work tasks and when they are due as they are assigned ",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3102)"
    },
    "31020103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Use a printed task list",
        "solution_long": "Use a written to-do list or a homework planner that the employee can carry around.  This could include using a notebook, clipboard or small white board that is easily accessible.  Record tasks and check them off as they are completed.  Carry over unfinished tasks to the next day.  <ul> <li>Ask the employee what information formats are preferred (e.g., text, images, color coding, texture coding).</li> </ul>",
        "solution_number": 31020103,
        "strategy": "Record work tasks and when they are due as they are assigned ",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3102)"
    },
    "31020104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Use portable, reusable to-do lists ",
        "solution_long": "If the employee completes the same tasks each day or week, provide a daily \"personal schedule\".  Laminated (plastic-coated) schedules provide a way for tasks to be easily checked off, then erased and reused the next day. <ul> <li>Some employees may prefer to end their day by erasing the schedule, while others may not feel that their work is done if the list is erased before they leave. </li> <li>Ask the employee what information formats are preferred (e.g., text, images, color coding, texture coding).</li> </ul>",
        "solution_number": 31020104,
        "strategy": "Record work tasks and when they are due as they are assigned ",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3102)"
    },
    "31020105": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Use stationary task boards or lists",
        "solution_long": "Use paper or dry erase board task lists that are positioned in key areas for easy reference (e.g., near entrance, near work areas).  Record tasks and check them off as they are completed.  Carry over unfinished tasks to the next day. <ul> <li>Some employees may prefer to end their day by erasing the schedule, while others may not feel that their work is done if the list is erased before they leave. </li> <li>Ask the employee what information formats are preferred (e.g., text, images, color coding, texture coding).</li> </ul>",
        "solution_number": 31020105,
        "strategy": "Record work tasks and when they are due as they are assigned ",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3102)"
    },
    "31020201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Use a to-do list/homework planner app \r\n",
        "solution_long": "Use electronic to-do lists or planner on a workstation computer, tablet, or cell phone to record work tasks as they are assigned.  Then, set alarms and prompts that tell the employee what needs to be remembered. Many apps allow the user to set a few staggered reminders (e.g., 1 hour, 5 minutes). <ul> <li>Look for a system that provides a reminder when tasks are due.</li> <li>Seeing the whole work task list at once can be overwhelming.  Consider showing only one task at a time as they are checked off.</li> <li>Carry over unfinished tasks to the next day. </li> <li>Electronic calendars can synch (update entries) and work across all electronic devices (computers, mobile phones, tablets, smart watches).</li> <li>Mobile devices such as phones can create distractions and may not be allowed at work, except as an accommodation. </li> <li>Look for a system that uses formats that the employee understands and might be able to set independently (e.g., text, images, color coding, sound). </li> <wafactors match=\"!C7\"> <li>If the employee is not a strong written text user, consider using a system that supports voice recorded messages (e.g., Endeavor app).</li> </wafactors>  <wafactors H2 H3 H4 H5 H6 NSY OPN> <li>Use a visual or vibration alert if there are concerns about the employee hearing it over the workplace noise and/or to avoid disturbing other workers.</li></wafactors></ul> <br /> <a href= \"https://assistivesoftware.gatech.edu/time-management-tools\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 31020201,
        "strategy": "Provide an alert before assignments are due",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3102)"
    },
    "31020202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Set work schedule alerts/alarms ",
        "solution_long": "Set a watch alarm or the alarm clock on a mobile device to go off when it is time to change work tasks.  Look for a system that will repeat the alarm until it is recognized.  When scheduling reminders, include time for the employee to get ready for the next task.<ul> <li>In addition to providing the alarm, the system should tell the employee what needs to be remembered (e.g., what task is next).</li> <li>Wearables such as an Apple Watch, Fitbit, or Watchminder can also be used for reminders.</li> <li>Mobile devices such as phones can create distractions and may not be allowed at work, except as an accommodation.</li> <li>Look for a system that provides reminders in a format that the employee understands and might be able to set independently (e.g., text, images, customized alarm tone or voice).</li> <wafactors H2 H3 H4 H5 H6 NSY OPN> <li> Use a visual or vibration alert if there are concerns about the employee hearing it over the workplace noise and/or to avoid disturbing other workers.</li> </wafactors></ul>",
        "solution_number": 31020202,
        "strategy": "Provide an alert before assignments are due",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.MOB) && (a.T3102)"
    },
    "31020301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Divide large tasks into smaller tasks",
        "solution_long": "Break down complex work tasks into smaller, easier to manage tasks.  Set deadlines for each sub-task and schedule them. <ul> <li>Decide whether this process is best carried out by the supervisor or the employee.</li> </ul>",
        "solution_number": 31020301,
        "strategy": "Break down complex work tasks into smaller tasks ",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3102)"
    },
    "31020401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Block out sound distractions",
        "solution_long": "Use noise-canceling headphones, music, or white noise to block out distracting noises. <ul> <li>Music with a beat may encourage the worker to keep moving.</li> <li>Make sure that these solutions are compatible with the tasks the worker is doing.  For example, do not create safety issues by blocking out warning noises.</li> </ul>",
        "solution_number": 31020401,
        "strategy": "Avoid sensory distractions",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3102)"
    },
    "31020402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Move to a quiet work area",
        "solution_long": "Try positioning the workstation in quieter area (e.g., area with lower employee \"traffic\", private office instead of open office). <ul><li>Be careful not to isolate the employee if the work area is moved.</li></ul>",
        "solution_number": 31020402,
        "strategy": "Avoid sensory distractions",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3102)"
    },
    "31020403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Block out visual distractions ",
        "solution_long": "Position the employee's workstation or set up dividers to reduce visual distractions from other parts of the workplace.  Try positioning the workstation in a lower employee \"traffic\" area.  Reduce unnecessary visual distractions or clutter, including location by windows.<ul> <li>Be careful not to isolate the employee if the work area is moved.</li> <li>Make sure that these solutions are compatible with the tasks the worker is doing.  For example, do not create safety issues by blocking out visual warnings.</li> </ul>",
        "solution_number": 31020403,
        "strategy": "Avoid sensory distractions",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3102)"
    },
    "31020501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Estimate the time needed for each task ",
        "solution_long": "Estimate how long each task will take and block out the full amount of time in a daily schedule. <ul> <li>For tasks that are done often, time how long it takes so the correct amount of time can be blocked out the next time it is scheduled.</li> </ul>",
        "solution_number": 31020501,
        "strategy": "Block out time for completing the task, not just start times ",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3102)"
    },
    "31020502": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Use a calendar with moveable time blocks ",
        "solution_long": "Use an electronic calendar/app, paper calendar, or task board that shows each task as a moveable block of time (e.g., on a post-it) for easier planning.",
        "solution_number": 31020502,
        "strategy": "Block out time for completing the task, not just start times ",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3102)"
    },
    "31020503": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Provide reminders for due dates",
        "solution_long": "Provide reminders when a due date is coming up.",
        "solution_number": 31020503,
        "strategy": "Block out time for completing the task, not just start times ",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3102)"
    },
    "31020504": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Provide reminders to switch tasks ",
        "solution_long": "Provide reminders when it is time to switch to a different task. <ul> <li>When scheduling reminders, include time for the employee to get ready for the next task.  Most online calendars allow the user to set a few staggered reminders (e.g., 1 hour, 5 minutes).</li> <wafactors H2 H3 H4 H5 H6 NSY OPN> <li> Use a visual or vibration alert if there are concerns about the employee hearing it over the workplace noise and/or to avoid disturbing other workers.</li> </wafactors></ul>",
        "solution_number": 31020504,
        "strategy": "Block out time for completing the task, not just start times ",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3102)"
    },
    "31020505": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product.",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Use visual timers ",
        "solution_long": "Use a visual timer or app  to show the total amount of time to complete the task and how much time has passed. <ul> <li>The time might be subdivided.  For example, if an employee has 90 minutes to clean 3 rooms, he or she might see the 90 minutes but also 30 minute segments.</li> </ul>",
        "solution_number": 31020505,
        "strategy": "Block out time for completing the task, not just start times",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW || a.MOB) && (a.T3102)"
    },
    "31020601": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Mark the most important work tasks ",
        "solution_long": "Add marks next to the most important tasks in a task list.  For example, this can be done by putting an asterisk next to high priority tasks. <ul> <li>Ask the employee what information formats are preferred (e.g., text, images, color coding, texture coding, sound).</li> </ul>",
        "solution_number": 31020601,
        "strategy": "Assign priority levels to tasks",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3102)"
    },
    "31020602": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "List tasks in the order to be done ",
        "solution_long": "Make a list of work tasks in the order that they should be done.  Provide a checklist that the employee can use to mark items off as they are completed. <ul> <li>Ask the employee what information formats are preferred (e.g., text, images, color coding, texture coding, sound).</li> </ul>",
        "solution_number": 31020602,
        "strategy": "Assign priority levels to tasks",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3102)"
    },
    "31020603": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Identify when task priority might change ",
        "solution_long": "Tell employees about situations where the rules might change about task priority (e.g., could do it now but would be interrupting others).",
        "solution_number": 31020603,
        "strategy": "Assign priority levels to tasks",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3102)"
    },
    "31020604": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product.",
        "gap": "Difficulty with time management (e.g., getting things done on time)",
        "solution": "Use a schedule app that prioritizes tasks ",
        "solution_long": "Use an electronic system/mobile app that sets a schedule based on scheduled events and task priority. <ul><li>Mobile devices such as phones can create distractions and may not be allowed at work, except as an accommodation.</li> <li>Ask the employee what information formats are preferred (e.g., text, images, color coding, sound).</li> </ul>",
        "solution_number": 31020604,
        "strategy": "Assign priority levels to tasks",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3102)"
    },
    "31030801": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty using standard time management systems",
        "solution": "Use voice-controlled calendar apps ",
        "solution_long": "Look for apps that can be operated through voice commands and dictation. <ul><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li> This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors S2 S3 S4 S5> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors> </ul>",
        "solution_number": 31030801,
        "strategy": "Use a voice-controlled time management system",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.S5) && (a.CMP || a.MOB) && (a.T3103)"
    },
    "31030802": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty using standard time management systems",
        "solution": "Use voice assistants to edit calendar ",
        "solution_long": "Use smart technology (e.g., Alexa, Siri) to add items to an electronic calendar. <ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors S2 S3 S4 S5> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors> </ul>",
        "solution_number": 31030802,
        "strategy": "Use a voice-controlled time management system",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.S5) && (a.CMP || a.MOB) && (a.T3103)"
    },
    "31030803": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty using standard time management systems",
        "solution": "Use voice messages to set reminders ",
        "solution_long": "Use a reminder system based on voice recorded messages (e.g., Endeavor app). <ul> <wafactors NSY OPN><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors H2 H3 H4> <li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li> </wafactors> </ul>",
        "solution_number": 31030803,
        "strategy": "Use a voice-controlled time management system",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.Ha || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (!a.C7) && (a.MOB) && (a.T3103)"
    },
    "31030901": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty using standard time management systems",
        "solution": "Use an app that provides spoken alerts ",
        "solution_long": "Look for apps that provide spoken reminders (e.g., Endeavor app). <ul> <wafactors NSY OPN><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors H2 H3 H4> <li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li> </wafactors> </ul>",
        "solution_number": 31030901,
        "strategy": "Use a time management system with spoken alerts",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.Ha || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.C7) && (a.MOB) && (a.T3103)"
    },
    "31030902": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty using standard time management systems",
        "solution": "Record voice messages as reminders ",
        "solution_long": "Use a reminder system based on voice recorded messages (e.g., Endeavor app). <wafactors H2 H3 H4> <ul> <li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li> </ul> </wafactors>",
        "solution_number": 31030902,
        "strategy": "Use a time management system with spoken alerts",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.Ha || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (!a.C7) && (a.MOB) && (a.T3103)"
    },
    "31031001": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty using standard time management systems",
        "solution": "Use color-coding ",
        "solution_long": "For either app or paper-based time management systems, use color-coding in addition to text to group similar entries, mark priority, etc. ",
        "solution_number": 31031001,
        "strategy": "Use a time management system with visual, non-text reminders",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V2) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW || a.CMP || a.MOB) && (a.T3103)"
    },
    "31031002": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty using standard time management systems",
        "solution": "Add images",
        "solution_long": "Use a visual calendar app  or mark calendar and schedule entries with pictures.  ",
        "solution_number": 31031002,
        "strategy": "Use a time management system with visual, non-text reminders",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW || a.CMP || a.MOB) && (a.T3103)"
    },
    "31031101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty using standard time management systems",
        "solution": "Add Braille or textures ",
        "solution_long": "Mark calendar and schedule entries with Braille and/or different textures that a person can review by touch. <ul> <li>Not everyone who is blind knows Braille. Make sure that the employee is comfortable using Braille.</li> </ul>",
        "solution_number": 31031101,
        "strategy": "Use a time management system with touch-related reminders",
        "task": "Planning the Work Day",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T3103)"
    },
    "31031201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty using standard time management systems",
        "solution": "Learn to use time management tools ",
        "solution_long": "If needed, help employees learn how to use their time management tools, including adding tasks, setting alarms, and carrying unfinished tasks to the next day.",
        "solution_number": 31031201,
        "strategy": "Provide training on using time management tools",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3103)"
    },
    "31040101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Clarify work expectations",
        "solution_long": "Ask the supervisor what is expected in terms of off-hour communications, etc.",
        "solution_number": 31040101,
        "strategy": "Set work/home boundary expectations",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3104)"
    },
    "31040102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Follow a set work schedule",
        "solution_long": "Follow a set schedule / routine with separate work and non-work hours.",
        "solution_number": 31040102,
        "strategy": "Set work/home boundary expectations",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3104)"
    },
    "31040103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Use a time management app ",
        "solution_long": "Use a time management app to track home and work tasks.<br /><a href= \"https://assistivesoftware.gatech.edu/time-management-tools\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 31040103,
        "strategy": "Set work/home boundary expectations",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3104)"
    },
    "31040104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Set limits for checking messages",
        "solution_long": "Set aside specific times for responding to emails or other messages.  Avoid checking messages outside of those set times.  Sign out of emails if done for the day.",
        "solution_number": 31040104,
        "strategy": "Set work/home boundary expectations",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3104)"
    },
    "31040105": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Schedule non-work activities",
        "solution_long": "Include time for breaks for relaxation, exercise, and home activities in the daily schedule.",
        "solution_number": 31040105,
        "strategy": "Set work/home boundary expectations",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3104)"
    },
    "31040106": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Use Pomodoro work/break technique",
        "solution_long": "Try the Pomodoro technique, which breaks work into set intervals separated by short breaks.  For example, every half hour a person might work for 25 minutes, then break for 5 minutes.  For more information: <br /><a href=\"https://en.wikipedia.org/wiki/Pomodoro_Technique\" target=\"_blank\">Wikipedia Pomodoro Technique page.</a>",
        "solution_number": 31040106,
        "strategy": "Set work/home boundary expectations",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3104)"
    },
    "31040107": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Use a relaxation app",
        "solution_long": "Use an app to help the employee relax (e.g., Zen Garden, Breathe2Relax, Bubble Wrap Popping).",
        "solution_number": 31040107,
        "strategy": "Set work/home boundary expectations",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3104)"
    },
    "31040201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Share coworker work schedules",
        "solution_long": "Help coworkers respect each other's work/life balance by sharing the work schedules of employees who are working non-standard days or times.",
        "solution_number": 31040201,
        "strategy": "Respect employee/coworker work schedules",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3104)"
    },
    "31040202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Set up \"office hours\"",
        "solution_long": "Set up employee \"office hours\" with preferred times that the employee is available to meet.",
        "solution_number": 31040202,
        "strategy": "Respect employee/coworker work schedules",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3104)"
    },
    "31040203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Delay sending emails",
        "solution_long": "Use email features that delay the sending of messages until standard work hours.",
        "solution_number": 31040203,
        "strategy": "Respect employee/coworker work schedules",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3104)"
    },
    "31040301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Use a dedicated work area",
        "solution_long": "Use a dedicated, isolated area for work.",
        "solution_number": 31040301,
        "strategy": "Avoid family interruptions / distractions",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3104)"
    },
    "31040302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Signal when available for family issues",
        "solution_long": "Provide a signal to indicate when an employee is available to deal with family issues and for when the employee shouldn't be interrupted.  For younger family members, try color coded signals (e.g., piece of red or green paper on door).",
        "solution_number": 31040302,
        "strategy": "Avoid family interruptions / distractions",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3104)"
    },
    "31040303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty separating or balancing work and home activities when teleworking",
        "solution": "Use separate devices for work and play",
        "solution_long": "Use separate devices for work and play.  For example, a laptop might be used for work, but an iPhone might be used for social media and games.",
        "solution_number": 31040303,
        "strategy": "Avoid family interruptions / distractions",
        "task": "Planning the Work Day",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3104)"
    },
    "32010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty identifying / remembering steps in an assigned task",
        "solution": "List each step in performing a task",
        "solution_long": "Do a task analysis, listing out each step needed to complete the task.  If possible, help the employee make his or her own list of task steps so that it uses words and a format (e.g., with graphics, large print, Braille) that he or she understands.",
        "solution_number": 32010101,
        "strategy": "Provide reminders of the task steps",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3201)"
    },
    "32010102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty identifying / remembering steps in an assigned task",
        "solution": "Use a video to show how to do the task",
        "solution_long": "Provide a video demonstration of the task being completed, that shows clearly defined steps and important details.  The video might be viewed on a computer at the employee's workstation, or on a mobile device.",
        "solution_number": 32010102,
        "strategy": "Provide reminders of the task steps",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.CMP || a.MOB) && (a.T3201)"
    },
    "32010103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Difficulty identifying / remembering steps in an assigned task",
        "solution": "Use an app that shows task steps",
        "solution_long": "Use an app or desktop-based software to show video, or pictures with recorded instructions, that demonstrate the task.  Show clearly defined steps and include important details. <ul> <li>Mobile devices such as phones can create distractions and may not be allowed at work, except as an accommodation.</li> </ul><br /><a href= \"https://assistivesoftware.gatech.edu/prompting-aid\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 32010103,
        "strategy": "Provide reminders of the task steps",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.CMP || a.MOB) && (a.T3201)"
    },
    "32010104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty identifying / remembering steps in an assigned task",
        "solution": "Use a book with pictures of task steps",
        "solution_long": "Make a book or folder with a page for each step of a task, in order.  Show images of each step along with printed instructions. ",
        "solution_number": 32010104,
        "strategy": "Provide reminders of the task steps",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW) && (a.T3201)"
    },
    "32010105": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty identifying / remembering steps in an assigned task",
        "solution": "Post sign with pictures of task steps",
        "solution_long": "Make and hang up signs or laminated (plastic-coated) pages for each step of a task, in order.  Show images of each step along with printed instructions.",
        "solution_number": 32010105,
        "strategy": "Provide reminders of the task steps",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW) && (a.T3201)"
    },
    "32010106": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty identifying / remembering steps in an assigned task",
        "solution": "Use photo album with voice recording",
        "solution_long": "Use a photo album with voice recording (\"talking photo album\") to combine pictures of each step of a task, in order, with sound recordings of instructions. <ul> <wafactors NSY OPN><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors H2 H3 H4 H5 H6> <li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li> </wafactors> </ul>",
        "solution_number": 32010106,
        "strategy": "Provide reminders of the task steps",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.C7) && (a.LOW) && (a.T3201)"
    },
    "32010107": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty identifying / remembering steps in an assigned task",
        "solution": "Use VOCA to give verbal instructions",
        "solution_long": "Use a set of voice output communication aids (VOCA) to play recorded instructions for each step of a task.  For example, a single VOCA might take the form of a button switch that plays a recording when pressed.  Images that show the step can be placed on each VOCA device. <ul> <wafactors NSY OPN><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors H2 H3 H4 H5 H6> <li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li> </wafactors> </ul>",
        "solution_number": 32010107,
        "strategy": "Provide reminders of the task steps",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.C7) && (a.LOW) && (a.T3201)"
    },
    "32010108": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty identifying / remembering steps in an assigned task",
        "solution": "Use music to help learn steps",
        "solution_long": "During training, use music, jingles or rhythmic patterns to help learn and remember task steps.<ul> <wafactors NSY OPN><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors H2 H3 H4 H5 H6> <li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li> </wafactors> </ul>",
        "solution_number": 32010108,
        "strategy": "Provide reminders of the task steps",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW) && (a.T3201)"
    },
    "32010201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty identifying / remembering steps in an assigned task",
        "solution": "Have the employee develop the prompts",
        "solution_long": "Help the employee make his or her own task list so that it uses words and a format that he or she understands.  <ul> <li>This is a good option for workers who are very literal.</li> </ul>",
        "solution_number": 32010201,
        "strategy": "Help the employee understand instructions",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3201)"
    },
    "32010202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty identifying / remembering steps in an assigned task",
        "solution": "Provide examples for descriptive words",
        "solution_long": "Be specific with word choice and provide visual examples of descriptive words.  For example, instead of \"empty when full\", a line might be drawn with the instruction \"empty when it reaches the line\".   A picture guide might show examples of a \"clean\" room for a maid or a \"good\" banana for a grocery worker.",
        "solution_number": 32010202,
        "strategy": "Help the employee understand instructions",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3201)"
    },
    "32010203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty identifying / remembering steps in an assigned task",
        "solution": "Provide pictures of the desired result",
        "solution_long": "Provide pictures that the employee can use to compare their work to the desired result (e.g.,  picture that shows proper placement of an address label). ",
        "solution_number": 32010203,
        "strategy": "Help the employee understand instructions",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (a.LOW || a.CMP || a.MOB) && (a.T3201)"
    },
    "32020101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remembering specific information needed to complete a task",
        "solution": "Use posted reminders",
        "solution_long": "Use easily-visible reminders in the form of post-its, computer post-its, or notes on a bulletin board.",
        "solution_number": 32020101,
        "strategy": "Provide reminders of needed information (e.g., account numbers)",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3202)"
    },
    "32020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remembering specific information needed to complete a task",
        "solution": "Store private info in electronic files",
        "solution_long": "For information that needs to be kept private, create electronic files that contain the information. <ul> <li>Security concerns might be addressed by putting a password on the file or by using phone biometrics to limit who can open the file.</li> <wafactors PRT><li>Use a phone, laptop or tablet if a portable solution is needed.</li></wafactors> </ul>",
        "solution_number": 32020102,
        "strategy": "Provide reminders of needed information (e.g., account numbers)",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3202)"
    },
    "32020103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remembering specific information needed to complete a task",
        "solution": "Save information as sound recordings",
        "solution_long": "Make sound recordings of the information that can be played back when needed.<ul> <li>This is a good option for employees who are not strong text users.</li> <wafactors H2 H3 H4 H5 H6> <li>Make sure that the employee is able to hear and understand the information.  A solution that uses another communication format (e.g., text, touch) might work better.</li> </wafactors> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors S2 S3 S4 S5> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors> </ul>",
        "solution_number": 32020103,
        "strategy": "Provide reminders of needed information (e.g., account numbers)",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (!a.C7) && (a.LOW || a.CMP || a.MOB) && (a.T3202)"
    },
    "32020104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remembering specific information needed to complete a task",
        "solution": "Store passwords in a password manager",
        "solution_long": "Store passwords securely in a reminder system (e.g., 1Password, Dashlane).",
        "solution_number": 32020104,
        "strategy": "Provide reminders of needed information (e.g., account numbers)",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3202)"
    },
    "32030101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty accessing on-the-job training",
        "solution": "Include captions for videos",
        "solution_long": "Provide captions, or at least a written version (transcript) of the spoken information, for video materials.",
        "solution_number": 32030101,
        "strategy": "Make sure that video training materials are accessible",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.V6 || a.H4 || a.H5 || a.H6 || a.C1 || a.C7) && (a.LOW || a.CMP || a.MOB) && (a.T3203)"
    },
    "32030102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty accessing on-the-job training",
        "solution": "Describe visual material",
        "solution_long": "Describe important visual material such as graphs, images, etc.",
        "solution_number": 32030102,
        "strategy": "Make sure that video training materials are accessible",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.LOW || a.CMP || a.MOB) && (a.T3203)"
    },
    "32030201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty accessing on-the-job training",
        "solution": "Provide one-on-one training ",
        "solution_long": "Provide one-on-one training  if packaged training materials are not easy to use or understand.  One-on-one training may also be helpful if the employee will be doing the job a different way due to his or her accommodations.",
        "solution_number": 32030201,
        "strategy": "Provide personalized training",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3203)"
    },
    "32030202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty accessing on-the-job training",
        "solution": "Provide hands-on-training",
        "solution_long": "Hands-on training  can help workers learn and carry out job tasks.",
        "solution_number": 32030202,
        "strategy": "Provide personalized training",
        "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3203)"
    },
    "33010201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remaining focused on task",
        "solution": "Follow a set work schedule",
        "solution_long": "Follow a set work schedule / routine.",
        "solution_number": 33010201,
        "strategy": "Follow a schedule",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3301)"
    },
    "33010301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty remaining focused on task",
        "solution": "Mark finished tasks on to-do list app",
        "solution_long": "Use an app to list out the steps needed to complete the work for the day and have the employee check items off as they are completed. <ul> <li>Use a format that the employee understands (e.g., text, images, color coding).</li> <li>Seeing the whole work task list at once can be overwhelming.  Consider showing only one task at a time as they are checked off.</li> <li>Mobile devices such as phones can create distractions and may not be typically allowed at work, except as an accommodation.</li> </ul> <br /><a href= \"https://assistivesoftware.gatech.edu/time-management-tools\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 33010301,
        "strategy": "Provide a checklist of tasks or task steps",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3301)"
    },
    "33010302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remaining focused on task",
        "solution": "Mark completed tasks on a physical list",
        "solution_long": "Make a list of the steps needed to complete the work for the day, using a notebook, clipboard, or small white board that is easily accessible.  Ask the employee to check items off as they are completed.  Laminated (plastic-coated) schedules provide a way for tasks to be easily checked off, then erased and reused the next day. <ul> <li>Use a format that the employee understands (e.g., text, images, color coding).</li> <li>Some employees may prefer to end their day by erasing the schedule, while others may not feel that their work is done if the list is erased before they leave.</li></ul>",
        "solution_number": 33010302,
        "strategy": "Provide a checklist of tasks or task steps",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3301)"
    },
    "33010303": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology. ",
        "gap": "Difficulty remaining focused on task",
        "solution": "Use a to-do list app that is monitored",
        "solution_long": "If the employee has difficulty creating the list of work tasks, try using an app that allows a supervisor to create an electronic to-do list (e.g. Trello, ZenKit, and ToodleDo), then assign it to the employee. It will be added to the employee's list of tasks.  Both the employee and supervisor can track progress as the employee checks off completed tasks.",
        "solution_number": 33010303,
        "strategy": "Provide a checklist of tasks or task steps",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T3301)"
    },
    "33010401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remaining focused on task",
        "solution": "Change tasks during the work day",
        "solution_long": "Have the employee switch tasks several times during a work day and use the time between tasks as a break to stretch and reset.",
        "solution_number": 33010401,
        "strategy": "Change tasks during the work day",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3301)"
    },
    "33010501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remaining focused on task",
        "solution": "Provide short breaks every hour",
        "solution_long": "Provide hourly, short (e.g., 2-3 minute) breaks to allow the employee to stretch and reset.  Alternatively, follow the Pomodoro technique and alternate 25-minute work periods with 5-minute breaks. <ul> <li>Use a reminder app, watch alarms, etc. to announce breaks and when the employee needs to return from break.</li> <li>Have the employee switch tasks several times during a work day and use the time needed to switch tasks as a break.  However, too much changing between tasks could be distracting.</li> </ul> <br />For more information: <br /><a href=\"https://en.wikipedia.org/wiki/Pomodoro_Technique\" title=\"Wikipedia Pomodoro Technique page\">Wikipedia Pomodoro Technique page.</a>",
        "solution_number": 33010501,
        "strategy": "Provide extra, short breaks to refocus",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3301)"
    },
    "33010601": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remaining focused on task",
        "solution": "Block out sound distractions",
        "solution_long": "Use noise-cancelling headphones, music, or white noise to block out distracting noises. <ul> <li>Music with a beat may encourage the worker to keep moving.</li> <li>Avoid music that is itself distracting (e.g., with lyrics).  Try classical music, instrumental meditation music, or soundtracks.</li> <li>Make sure that these solutions are compatible with the tasks the worker is doing (e.g., safety issues aren't created by blocking out warning noises).</li> </ul>",
        "solution_number": 33010601,
        "strategy": "Avoid sensory distractions",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3301)"
    },
    "33010602": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remaining focused on task",
        "solution": "Move to a quiet work area",
        "solution_long": "Try positioning the workstation in quieter area (e.g., area with lower employee \"traffic\", private office instead of open office). <ul><li>Be careful not to isolate the employee if the work area is moved.</li></ul>",
        "solution_number": 33010602,
        "strategy": "Avoid sensory distractions",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3301)"
    },
    "33010603": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remaining focused on task",
        "solution": "Block out visual distractions",
        "solution_long": "Position the employee's workstation or set up dividers to reduce visual distractions from other parts of the workplace.  Try positioning the workstation in a lower employee \"traffic\" area.  Reduce unnecessary visual distractions or clutter, including location by windows. <ul> <li>Be careful not to isolate the employee if the work area is moved.</li> <li>Make sure that these solutions are compatible with the tasks the worker is doing.  For example, do not create safety issues by blocking out visual warnings.</li> </ul>",
        "solution_number": 33010603,
        "strategy": "Avoid sensory distractions",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3301)"
    },
    "33010604": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remaining focused on task",
        "solution": "Use a dedicated work area",
        "solution_long": "Set up an area in the home that is used only for work tasks.  Position the workstation or put up dividers to reduce visual or auditory distractions from other parts of the home.",
        "solution_number": 33010604,
        "strategy": "Avoid sensory distractions",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3301)"
    },
    "33010701": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remaining focused on task",
        "solution": "Record and review completed work",
        "solution_long": "Have employees monitor their own work by using an activity log to record activity at 15- or 30-minute intervals.  Increase the time or reduce logging periods as productivity grows.",
        "solution_number": 33010701,
        "strategy": "Self-monitor work",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T3301)"
    },
    "33010801": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty remaining focused on task",
        "solution": "Use hand-held fidgets",
        "solution_long": "Have the employee use hand-held fidgets (e.g., spinner, stress ball, worry beads).",
        "solution_number": 33010801,
        "strategy": "Channel excess energy without distracting others",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U10 || a.U13 || a.U14 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.U11) && (!a.U12) && (!a.U15) && (!a.U16) && (!a.U17) && (!a.U18) && (a.LOW) && (a.T3301)"
    },
    "33010802": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Difficulty remaining focused on task",
        "solution": "Use a sit-stand workstation",
        "solution_long": "Use a <a href=\"/glossary#sitstand\" target=\"_blank\">sit stand workstation</a> sit-stand workstation to allow the employee to change positions occasionally while working. ",
        "solution_number": 33010802,
        "strategy": "Channel excess energy without distracting others",
        "task": "Staying on Task",
        "trigger": "(a.L1 || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.L3) && (!a.L4) && (!a.L8a) && (!a.L8b) && (!a.L8c) && (!a.L8d) && (!a.L8e) && (a.LOW) && (a.T3301)"
    },
    "33010803": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Difficulty remaining focused on task",
        "solution": "Sit on a ball chair",
        "solution_long": "Use a <a href=\"/glossary#ballchair\" target=\"_blank\">ball chair</a> for temporary alternative seating.  <ul> <wafactors SHD><li>This solution might work best when the employee has a private workstation.  Coworkers may be tempted to move bulky equipment and not reset it.</li></wafactors> </ul>",
        "solution_number": 33010803,
        "strategy": "Channel excess energy without distracting others",
        "task": "Staying on Task",
        "trigger": "(!a.L3) && (!a.L4) && (!a.L8a) && (!a.L8b) && (!a.L8c) && (!a.L8d) && (!a.L8e) && (a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3301)"
    },
    "33010804": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Difficulty remaining focused on task",
        "solution": "Sit on an exercycle while working",
        "solution_long": "Use an <a href=\"/glossary#exercycle\" target=\"_blank\">exercycle</a> under the workstation to pedal while working. <ul> <li>[display if shared workstation] This solution might work best when the employee has a private workstation.  Coworkers may be tempted to move bulky equipment and not reset it.</li> </ul>",
        "solution_number": 33010804,
        "strategy": "Channel excess energy without distracting others",
        "task": "Staying on Task",
        "trigger": "(!a.L7) && (!a.L8a) && (!a.L8b) && (!a.L8c) && (!a.L8d) && (!a.L8e) && (a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T3301)"
    },
    "41010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use gestures or sign language ",
        "solution_long": "Use gestures and/or sign language (e.g., ASL) for one-on-one communication. ",
        "solution_number": 41010101,
        "strategy": "Use gestures or sign language ",
        "task": "Hearing Speech",
        "trigger": "(a.V6 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.Hb || a.Hc || a.Hd) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4101)"
    },
    "41010102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use text to sign language conversion app ",
        "solution_long": "Use an app that converts text to sign language (e.g., Hand Talk, other Google options). <ul><li>Caution: Communication partners will not be able to verify the intended message is represented in sign language correctly, unless they know sign language.</li></ul> ",
        "solution_number": 41010102,
        "strategy": "Use gestures or sign language ",
        "task": "Hearing Speech",
        "trigger": "(a.H2 || a.H3 || a.H4 || a.H5 || a.Hb) && (a.MOB) && (a.SML) && (a.ONS) && (a.T4101)"
    },
    "41010103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Provide a sign language interpreter ",
        "solution_long": "Provide a sign language interpreter, particularly for meetings/ presentations. <ul><li>An interpreter may be brought to the site.</li> <li>Remote interpreting can be provided through a contract with a provider of Video Remote Interpreting (VRI). The meeting location must be equipped with a computer and monitor large enough to view from a distance with a webcam and high-quality microphone.  An off-site interpreter will sign what the speaker/employer is saying and voice the employee's signs into speech.</li></ul>",
        "solution_number": 41010103,
        "strategy": "Use gestures or sign language ",
        "task": "Hearing Speech",
        "trigger": "(a.V6 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.Hb || a.Hc || a.Hd) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4101)"
    },
    "41010201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Communicate with writing",
        "solution_long": "Use writing (e.g., notepad, text messages, email, bulletin/message boards, chat rooms) instead of speech or to supplement speech. <ul><li>Ensure both parties are comfortable communicating through writing and that messages are understood.</li></ul>",
        "solution_number": 41010201,
        "strategy": "Use text to communicate ",
        "task": "Hearing Speech",
        "trigger": "(a.V6 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.Ha || a.Hb || a.Hc || a.Hd) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4101)"
    },
    "41010202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use wireless chat system",
        "solution_long": "Type information that can be read on a display screen.  Examples include texting/instant messaging on a cell phone or using the Ubi-Duo system, which is designed specifically for people with disabilities. <ul><li>Ensure both parties are comfortable communicating through writing and that messages are understood.</li></ul>",
        "solution_number": 41010202,
        "strategy": "Use text to communicate ",
        "task": "Hearing Speech",
        "trigger": "(a.H2 || a.H3 || a.H4 || a.H5 || a.Ha || a.Hb || a.Hc) && (a.LOW) && (a.SML) && (a.ONS) && (a.T4101)"
    },
    "41010203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use app that converts speech to text ",
        "solution_long": "Use an app that converts speech to text (e.g., OtterAI, Ava). <ul><li> Ensure that the employee is comfortable communicating through text and that the information is understood. </li></ul>",
        "solution_number": 41010203,
        "strategy": "Use text to communicate ",
        "task": "Hearing Speech",
        "trigger": "(a.H2 || a.H3 || a.H4 || a.H5 || a.Ha || a.Hb || a.Hc) && (a.MOB) && (a.SML) && (a.ONS) && (a.T4101)"
    },
    "41010204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use real-time, live captioning",
        "solution_long": "Use real-time captioning with a human captioner.  This type of option is used primarily for understanding presenters in meetings and conferences.  It is not designed for two-way communication. <ul><li> Ensure that the employee is comfortable communicating through text and that the information is understood. </li></ul>",
        "solution_number": 41010204,
        "strategy": "Use text to communicate ",
        "task": "Hearing Speech",
        "trigger": "(a.H2 || a.H3 || a.H4 || a.H5 || a.Ha || a.Hb || a.Hc) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4101)"
    },
    "41010205": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use meeting software with auto-captions",
        "solution_long": "Use automated captioning features built into most conferencing software. <ul><li>Auto-generated captions will include some errors.  Thus, this option may not be appropriate for some conversations where complete accuracy is required (e.g., legal discussions, performance reviews).</li> <li>Ensure that the employee is comfortable communicating through text and that the information is understood.</li></ul>",
        "solution_number": 41010205,
        "strategy": "Use text to communicate ",
        "task": "Hearing Speech",
        "trigger": "(a.H2 || a.H3 || a.H4 || a.H5 || a.Ha || a.Hb || a.Hc) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.REM) && (a.T4101)"
    },
    "41010206": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use software chat features",
        "solution_long": "Use chat features within the software instead of communicating by voice. <ul><li>This option works well for brief comments or to clarify something, but is not a good fit for lengthy communication.</li> <li>Ensure all parties are comfortable communicating through writing and that messages are understood.</li></ul>",
        "solution_number": 41010206,
        "strategy": "Use text to communicate ",
        "task": "Hearing Speech",
        "trigger": "(a.H2 || a.H3 || a.H4 || a.H5 || a.Ha || a.Hb || a.Hc) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.REM) && (a.T4101)"
    },
    "41010207": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use visual aids when presenting",
        "solution_long": "Use visual aids such as Powerpoint, flip charts, etc. to help the employee follow along with a presentation or discussion.",
        "solution_number": 41010207,
        "strategy": "Use text to communicate ",
        "task": "Hearing Speech",
        "trigger": "(a.H2 || a.H3 || a.H4 || a.H5 || a.Ha || a.Hb || a.Hc) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4101)"
    },
    "41010208": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the messages need to be set up. ",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use a text-based communication board",
        "solution_long": "Use a text-based communication board or book, prepared in advance, to communicate several commonly-used messages. <ul><li>If a larger number of messages are needed, warranting a whole communication system, consult with an AAC Specialist.<li></ul>",
        "solution_number": 41010208,
        "strategy": "Use text to communicate ",
        "task": "Hearing Speech",
        "trigger": "(a.H2 || a.H3 || a.H4 || a.H5 || a.Ha || a.Hb || a.Hc) && (a.LOW || a.CMP || a.MOB) && (a.SML) && (a.ONS) && (a.T4101)"
    },
    "41010301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the messages need to be set up. ",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use an image-based communication board",
        "solution_long": "Use an image- or symbol-based communication board or book to communicate commonly-used messages. <ul><li>A symbol-based word processor may help to make these materials (e.g., Clicker 8, Pixwriter, SymWriter 2, Boardmaker).</li> <li>Powerpoint can also be used to create image pages.</li> <li>Image-based cues, alone or supported with text, may be preferred for employees with lower literacy skills or for employees who are still learning job-specific vocabulary.</li> <li>Some employees may feel that using images is not professional and be reluctant to use them.  Avoid images that are too childlike.</li> <li>If a larger number of messages are needed, requiring a whole communication system, consult with an AAC Specialist.</li></ul>",
        "solution_number": 41010301,
        "strategy": "Use pictures to communicate",
        "task": "Hearing Speech",
        "trigger": "(a.H2 || a.H3 || a.H4 || a.H5 || a.Ha || a.Hb || a.Hc) && (a.LOW || a.CMP || a.MOB) && (a.SML) && (a.ONS) && (a.T4101)"
    },
    "41010401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Support speech reading",
        "solution_long": "Use speech reading to communicate, especially if the person can use it as a supplement to residual hearing. <ul><li>Provide appropriate lighting. Speakers must face each other.</li> <li>Communication partners should remove or generously trim facial hair, so that mouth movements are not impeded.</li> <li>Face masks are available that have transparent panels to keep the mouth visible.</li> <li>Note: Even proficient speech readers can only decode a small percentage of what is said.  Another communication method should be used for critical information.</li></ul>",
        "solution_number": 41010401,
        "strategy": "Support speech reading",
        "task": "Hearing Speech",
        "trigger": "(a.H2 || a.H3 || a.H4 || a.H5 || a.Ha) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4101)"
    },
    "41010402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Have speaker show that they are speaking",
        "solution_long": "In groups, have the speaker indicate that he or she is talking.",
        "solution_number": 41010402,
        "strategy": "Support speech reading",
        "task": "Hearing Speech",
        "trigger": "(a.H2 || a.H3 || a.H4 || a.H5 || a.Ha) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4101)"
    },
    "41010501": {
        "expertise_level": 3,
        "expertise_text": "Consult an audiologist if the employee needs to be fitted for a hearing aid, or have it adjusted.",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use a hearing aid",
        "solution_long": "Use a hearing aid if the employee has useable residual hearing and if it is medically approved. <ul><li>If used in a noisy environment, some hearing aids may amplify both the background noise and speech, causing discomfort.</li> <li>Note: the ADA does not require employers to pay for this type of accommodation, since it is considered personal equipment, rather than work equipment.</li></ul>",
        "solution_number": 41010501,
        "strategy": "Maximize the loudness/quality of the audio",
        "task": "Hearing Speech",
        "trigger": "(a.H3 || a.H4 || a.Ha) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4101)"
    },
    "41010502": {
        "expertise_level": 2,
        "expertise_text": "Consult an audiologist to ensure the system is compatible with the employee's hearing aids.",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use an assistive listening system",
        "solution_long": "Use an assistive listening system if the employee has useable residual hearing.  These sound amplifiers are often used to listen to distant sounds in larger spaces such as a conference room. <ul><li>They may be used in combination with a hearing aid or cochlear implant, and in those cases, may be considered personal technology.</li> <li>If using a cochlear implant, consult with an Audiologist.</li></ul>",
        "solution_number": 41010502,
        "strategy": "Maximize the loudness/quality of the audio",
        "task": "Hearing Speech",
        "trigger": "(a.H3 || a.H4 || a.Ha) && (a.LOW) && (a.SML || a.LRG) && (a.ONS) && (a.T4101)"
    },
    "41010503": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Adjust online audio settings ",
        "solution_long": "Adjust speaker and microphone settings for online meetings to improve the sound level.",
        "solution_number": 41010503,
        "strategy": "Maximize the loudness/quality of the audio",
        "task": "Hearing Speech",
        "trigger": "(a.H3 || a.H4 || a.Ha) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.REM) && (a.T4101)"
    },
    "41010504": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use microphones ",
        "solution_long": "Experiment with different types of microphones to improve the audio quality during online meetings. <ul><li>Use headset microphones instead of the computer microphone to focus on the voice of the speaker and reduce echo.</li> <li>If the employee does not want to use a headset, try a good omni-directional cardioid microphone that also focuses on the voice of the speaker.  Remember to face it.</li></ul>",
        "solution_number": 41010504,
        "strategy": "Maximize the loudness/quality of the audio",
        "task": "Hearing Speech",
        "trigger": "(a.H3 || a.H4 || a.Ha) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.REM) && (a.T4101)"
    },
    "41010505": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Use a headset to listen ",
        "solution_long": "Use a headset or earbuds to listen instead of computer speakers.",
        "solution_number": 41010505,
        "strategy": "Maximize the loudness/quality of the audio",
        "task": "Hearing Speech",
        "trigger": "(a.H3 || a.H4 || a.Ha) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.REM) && (a.T4101)"
    },
    "41010601": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Pick a quiet place to talk",
        "solution_long": "Meet in a quiet, enclosed room, away from machinery.",
        "solution_number": 41010601,
        "strategy": "Avoid background noise",
        "task": "Hearing Speech",
        "trigger": "(a.H3 || a.H4 || a.Ha) && (a.LOW) && (a.SML || a.LRG) && (a.REM) && (a.T4101)"
    },
    "41010602": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Dampen environmental noise",
        "solution_long": "Use acoustic panels / carpeting to dampen other noises.  Another way to reduce noise is to add felt pads to chair legs on hard floor surfaces.",
        "solution_number": 41010602,
        "strategy": "Avoid background noise",
        "task": "Hearing Speech",
        "trigger": "(a.H3 || a.H4 || a.Ha) && (a.LOW) && (a.SML || a.LRG) && (a.REM) && (a.T4101)"
    },
    "41010603": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Mute microphones when not speaking ",
        "solution_long": "Ask participants to turn off microphones when they are not speaking.  The host may also turn off microphones, but have a method for participants to indicate when they want to speak (e.g., \"raise hand\" indicator in the software).",
        "solution_number": 41010603,
        "strategy": "Avoid background noise",
        "task": "Hearing Speech",
        "trigger": "(a.H3 || a.H4 || a.Ha) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.REM) && (a.T4101)"
    },
    "41010604": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee is not able to understand the speech of communication partners in face-to-face communication",
        "solution": "Establish a process to take turns",
        "solution_long": "Establish a process to keep participants from talking over each other (e.g., raising hand, getting attention in chat).",
        "solution_number": 41010604,
        "strategy": "Avoid background noise",
        "task": "Hearing Speech",
        "trigger": "(a.H3 || a.H4 || a.Ha) && (a.LOW || a.CMP || a.MOB) && (a.LRG) && (a.ONS || a.REM) && (a.T4101)"
    },
    "42010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's speech is slurred or distorted",
        "solution": "Pick a quiet place to talk",
        "solution_long": "Meet in a quiet, enclosed room, away from machinery.",
        "solution_number": 42010101,
        "strategy": "Reduce competing noise",
        "task": "Producing Speech",
        "trigger": "(a.S2 || a.S4) && (a.LOW) && (a.SML || a.LRG) && (a.ONS) && (a.T4201)"
    },
    "42010102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's speech is slurred or distorted",
        "solution": "Dampen workplace noise",
        "solution_long": "Use acoustic panels / carpeting to dampen other noises.  Another way to reduce noise is to add felt pads to chair legs on hard floor surfaces.",
        "solution_number": 42010102,
        "strategy": "Reduce competing noise",
        "task": "Producing Speech",
        "trigger": "(a.S2 || a.S4) && (a.LOW) && (a.SML || a.LRG) && (a.ONS) && (a.T4201)"
    },
    "42010201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's speech is slurred or distorted",
        "solution": "Give more time to speak",
        "solution_long": "Provide extra time for employee to speak.",
        "solution_number": 42010201,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S2 || a.S4) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4201)"
    },
    "42010202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's speech is slurred or distorted",
        "solution": "Speak in short phrases",
        "solution_long": "Employee can speak in short phrases.",
        "solution_number": 42010202,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S2 || a.S4) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4201)"
    },
    "42010203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's speech is slurred or distorted",
        "solution": "Let them know when not understood",
        "solution_long": "Work with the employee to choose an appropriate way to let them know when they were not understood.  Ask the employee to repeat what they said or communicate it a different way.",
        "solution_number": 42010203,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S2 || a.S4) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4201)"
    },
    "42010204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's speech is slurred or distorted",
        "solution": "Set up a smaller group",
        "solution_long": "Reduce the number of people speaking within a group.",
        "solution_number": 42010204,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S2 || a.S4) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4201)"
    },
    "42010301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's speech is slurred or distorted",
        "solution": "Use writing for some communication",
        "solution_long": "Use writing to clarify communication when needed.",
        "solution_number": 42010301,
        "strategy": "Use text or pictures to communicate",
        "task": "Producing Speech",
        "trigger": "(a.S2 || a.S4) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4201)"
    },
    "42010401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's speech is slurred or distorted",
        "solution": "Use an interpreter",
        "solution_long": "If the employee uses sign language as a means of communication, an interpreter may be needed for meetings (remote or in person).",
        "solution_number": 42010401,
        "strategy": "Use sign language to communicate",
        "task": "Producing Speech",
        "trigger": "(a.S2 || a.S4) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4201)"
    },
    "42010501": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Consult with an AAC specialist if the employee does not already use the AAC system.",
        "gap": "Employee's speech is slurred or distorted",
        "solution": "Use a communication device",
        "solution_long": "Use a communication device (AAC system) to supplement speech if the severity of speech affects the employee's ability to be understood.  An AAC system might be used in combination with speech, for example, to set context to clarify what the employee is saying.",
        "solution_number": 42010501,
        "strategy": "Use a communication device",
        "task": "Producing Speech",
        "trigger": "(a.S2 || a.S4) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4201)"
    },
    "42010502": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the communication supports.",
        "gap": "Employee's speech is slurred or distorted",
        "solution": "Use a low-tech communication system",
        "solution_long": "Use a low-tech communication system such as a communication wallet to list out common words and phrases (in text or image format).",
        "solution_number": 42010502,
        "strategy": "Use a communication device",
        "task": "Producing Speech",
        "trigger": "(a.S2 || a.S4) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4201)"
    },
    "42020101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's voice is too soft or weak",
        "solution": "Pick a quiet place to talk",
        "solution_long": "Meet in a quiet, enclosed room, away from machinery.",
        "solution_number": 42020101,
        "strategy": "Reduce competing noise",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.LOW) && (a.SML || a.LRG) && (a.ONS) && (a.T4202)"
    },
    "42020201": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Consult with an SLP or medical doctor regarding diagnosis and whether there are any speaking restrictions.",
        "gap": "Employee's voice is too soft or weak",
        "solution": "Help with posture and breathing",
        "solution_long": "Encourage/ support the incorporation of posture and breathing techniques for better sound production.  A Speech/Language Pathologist or counselor may be able to provide techniques to make the speech clearer.",
        "solution_number": 42020201,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4202)"
    },
    "42020202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's voice is too soft or weak",
        "solution": "Provide time to rest voice",
        "solution_long": "Provide time for the speaker to rest their voice.",
        "solution_number": 42020202,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4202)"
    },
    "42020203": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Consult with an SLP or medical doctor regarding diagnosis and whether there are any speaking restrictions.",
        "gap": "Employee's voice is too soft or weak",
        "solution": "Use a personal voice amplification system ",
        "solution_long": "Use a personal voice amplification system (if cleared for use by a speech/language pathologist or doctor).",
        "solution_number": 42020203,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4202)"
    },
    "42020204": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Consult with an SLP or medical doctor regarding diagnosis and whether there are any speaking restrictions.",
        "gap": "Employee's voice is too soft or weak",
        "solution": "Use a sound system with microphone",
        "solution_long": "Provide a sound system with microphone for larger settings or groups.  Make sure that the employee does not have speaking restrictions and has been cleared by a professional (SLP or doctor).",
        "solution_number": 42020204,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4202)"
    },
    "42020205": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's voice is too soft or weak",
        "solution": "Use a microphone with computer ",
        "solution_long": "For online meetings, use a microphone attached to the computer.  Make sure that the employee does not have speaking restrictions and has been cleared by a professional (SLP or doctor).",
        "solution_number": 42020205,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.CMP) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4202)"
    },
    "42020301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the communication supports.",
        "gap": "Employee's voice is too soft or weak",
        "solution": "Use written supports",
        "solution_long": "Use word processing app or Symbol based word processor (e.g., Clicker 8, Pixwriter, SymWriter 2, Boardmaker) to provide employee with written notes/cue cards to support communication.",
        "solution_number": 42020301,
        "strategy": "Use text or pictures to communicate",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.CMP) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4202)"
    },
    "42020302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's voice is too soft or weak",
        "solution": "Display text on a screen",
        "solution_long": "Use a word processing app to prepare information ahead of time to be displayed on a projection screen or monitor.",
        "solution_number": 42020302,
        "strategy": "Use text or pictures to communicate",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4202)"
    },
    "42020401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's voice is too soft or weak",
        "solution": "Use gestures and signs",
        "solution_long": "Use gestures and/or sign language to supplement speech.",
        "solution_number": 42020401,
        "strategy": "Use gestures or sign language",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4202)"
    },
    "42020501": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Consult with an AAC specialist if the employee does not already use the AAC system.",
        "gap": "Employee's voice is too soft or weak",
        "solution": "Use a communication device",
        "solution_long": "Use an AAC system (text or symbol based) for temporary loss of communication.",
        "solution_number": 42020501,
        "strategy": "Use a communication device",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4202)"
    },
    "42020502": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Consult with an AAC specialist if the employee does not already use the AAC system.",
        "gap": "Employee's voice is too soft or weak",
        "solution": "Use speech system with stored phrases",
        "solution_long": "Use a word processing app with text to speech to read pre-programmed speech for presentations.  External speakers are available to make the computer voice louder.",
        "solution_number": 42020502,
        "strategy": "Use a communication device",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4202)"
    },
    "42030101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's voice is hoarse",
        "solution": "Pick a quiet place to talk",
        "solution_long": "Meet in a quiet, enclosed room, away from machinery.",
        "solution_number": 42030101,
        "strategy": "Reduce competing noise",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.LOW) && (a.SML || a.LRG) && (a.ONS) && (a.T4203)"
    },
    "42030201": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Consult with an SLP or medical doctor regarding diagnosis and whether there are any speaking restrictions.",
        "gap": "Employee's voice is hoarse",
        "solution": "Use voice amplification",
        "solution_long": "Provide a voice amplification system for larger settings or groups, if cleared by professional (SLP or doctor).",
        "solution_number": 42030201,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.LOW) && (a.SML || a.LRG) && (a.ONS) && (a.T4203)"
    },
    "42030202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's voice is hoarse",
        "solution": "Let them rest their voice",
        "solution_long": "Provide time for the employee to rest their voice. <ul><li>Consult with an SLP or medical doctor to determine if the employee has any speaking restrictions.</li></ul>",
        "solution_number": 42030202,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4203)"
    },
    "42030301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's voice is hoarse",
        "solution": "Use written supports",
        "solution_long": "Use word processing app to prepare written notes/cue cards to support communication.",
        "solution_number": 42030301,
        "strategy": "Use text or pictures to communicate",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4203)"
    },
    "42030302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's voice is hoarse",
        "solution": "Project information on a screen",
        "solution_long": "Use word processing app to prepare information to be displayed on projection screen",
        "solution_number": 42030302,
        "strategy": "Use text or pictures to communicate",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.CMP) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4203)"
    },
    "42030401": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Consult with an AAC specialist if the employee does not already use the AAC system.",
        "gap": "Employee's voice is hoarse",
        "solution": "Use a communication device",
        "solution_long": "Use an AAC system for temporary loss of communication (text or symbol based)",
        "solution_number": 42030401,
        "strategy": "Use a communication device",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4203)"
    },
    "42030402": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Consult with an AAC specialist if this is a new accommodation for the employee.",
        "gap": "Employee's voice is hoarse",
        "solution": "Use speech system with stored phrases",
        "solution_long": "Use a word processing app with text to speech to read pre-programmed speech for presentations",
        "solution_number": 42030402,
        "strategy": "Use a communication device",
        "task": "Producing Speech",
        "trigger": "(a.S3) && (a.CMP) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4203)"
    },
    "42040101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulties with stuttering",
        "solution": "Remove pressure to speak",
        "solution_long": "Do not pressure the employee to start speaking and provide as much time as is needed.",
        "solution_number": 42040101,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S2) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4204)"
    },
    "42040102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulties with stuttering",
        "solution": "Avoid interruptions",
        "solution_long": "Do not interrupt the employee while they are speaking.",
        "solution_number": 42040102,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S2) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4204)"
    },
    "42040103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulties with stuttering",
        "solution": "Find a comfortable place to speak",
        "solution_long": "Determine speaking environments where employee will feel more comfortable speaking (i.e., different environments/settings).",
        "solution_number": 42040103,
        "strategy": "Optimize employee's speech",
        "task": "Producing Speech",
        "trigger": "(a.S2) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4204)"
    },
    "42040201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulties with stuttering",
        "solution": "Use written supports",
        "solution_long": "Use writing as means of communication versus face-to-face (i.e., texting, emails, etc.).",
        "solution_number": 42040201,
        "strategy": "Use text or pictures to communicate",
        "task": "Producing Speech",
        "trigger": "(a.S2) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4204)"
    },
    "43010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Coworkers monitor their speech patterns   ",
        "solution_long": "Coworkers can help the employee understand by being aware of their own speech patterns (complexity, speed, body language and eye contact).",
        "solution_number": 43010101,
        "strategy": "Have coworkers simplify their speech",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Use simple language and avoid jargon",
        "solution_long": "Use vocabulary with a reduced reading level and avoid using jargon when giving instructions or explaining.",
        "solution_number": 43010102,
        "strategy": "Have coworkers simplify their speech",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Use a personal dictionary",
        "solution_long": "Help the employee create a personal dictionary of words to use when answering questions or making comments.",
        "solution_number": 43010201,
        "strategy": "Provide supports for decoding words",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Use a personal notebook",
        "solution_long": "Help the employee create a personal notebook of words, key points for specific topics to use when answering questions or making comments.",
        "solution_number": 43010202,
        "strategy": "Provide supports for decoding words",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Give employee background on topic",
        "solution_long": "Employer or co-worker will set the context with employee topic/job to be discussed or completed",
        "solution_number": 43010203,
        "strategy": "Provide supports for decoding words",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Tie new tasks back to familiar tasks",
        "solution_long": "Connect the topic/tasks being discussed with specific experiences in employee's previous experience (use a picture based resume of jobs employee has done previously).",
        "solution_number": 43010204,
        "strategy": "Provide supports for decoding words",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010205": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful for choosing an app platform and setting up the prompts",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Provide visual examples ",
        "solution_long": "Use pictures (i.e., Clicker 8, PixWriter, SymWriter 2, Boardmaker, etc.)  as visual prompts about what is being discussed ",
        "solution_number": 43010205,
        "strategy": "Provide supports for decoding words",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010206": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful for choosing an app platform and setting up the prompts",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Provide video examples ",
        "solution_long": "Use video (e.g., Word Toob, Go Visual) to model process of asking for clarification (what is appropriate, and what is not).",
        "solution_number": 43010206,
        "strategy": "Provide supports for decoding words",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Provide time to review information",
        "solution_long": "Provide time for the employee to review information and determine the areas they need more information.",
        "solution_number": 43010301,
        "strategy": "Provide time to process information",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Provide questions ahead of time",
        "solution_long": "Provide questions to the employee before they need to answer to make sure they understand the question and give them time to compose their answer.",
        "solution_number": 43010302,
        "strategy": "Provide time to process information",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Make notes ahead of time",
        "solution_long": "Make notes prior to meeting for reference.",
        "solution_number": 43010303,
        "strategy": "Provide time to process information",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010304": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Send meeting notes ahead of time",
        "solution_long": "Send meeting PowerPoints/notes prior to meetings for employee to review.",
        "solution_number": 43010304,
        "strategy": "Provide time to process information",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Make cue cards to use as prompts",
        "solution_long": "Use a word processing app for computer or tablet with text to speech and graphic support capacity (i.e., Google images, etc.) to create text-based, picture-based or talking cue cards for employee to use as prompts during meetings/discussions or job assignments. (i.e., Talking Photo Album)",
        "solution_number": 43010401,
        "strategy": "Plan the communication",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful for choosing an app platform and setting up the prompts",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Take pictures to use as work prompts",
        "solution_long": "Use visual Support skill building apps (Visual Impact, First-Then) to take pictures with employee of task and work responsibilities as prompts for skill training and work discussion.",
        "solution_number": 43010402,
        "strategy": "Plan the communication",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Encourage questions and clarification",
        "solution_long": "Create an environment where questions and clarification are encouraged.  Work with employee to establish that it is OK to say, \"I don't understand.\" Or \"Please say that in a different way.\"",
        "solution_number": 43010501,
        "strategy": "Ask questions for clarification",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010502": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Help employee shape their question",
        "solution_long": "Use strategy of \"wh\" questions to assist employee in asking questions, such as \"what do you need to know?\" (Who, what, where, when and how).",
        "solution_number": 43010502,
        "strategy": "Ask questions for clarification",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010503": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Help employee list possible questions",
        "solution_long": "Employee can generate a list of questions prior to meetings for items they need more information.",
        "solution_number": 43010503,
        "strategy": "Ask questions for clarification",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43010601": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "The employee has difficulty understanding what is said",
        "solution": "Role-play about upcoming topic",
        "solution_long": "Have Job coach/champion engage in role playing and to help employee anticipate what will be said.",
        "solution_number": 43010601,
        "strategy": "Practice communication skills",
        "task": "Understanding Communication",
        "trigger": "(a.H3 || a.H4 || a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4301)"
    },
    "43030101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful for setting up the system.",
        "gap": "Employee has difficulty staying focused upon what is being said",
        "solution": "Keep focus by heading off diversions  ",
        "solution_long": "Avoid opportunities for distractions by setting limits on what apps can be used on the computer or mobile device.  Apple has this option (Assistive Access).",
        "solution_number": 43030101,
        "strategy": "Avoid distractions",
        "task": "Understanding Communication",
        "trigger": "(a.C1 || a.C2 || a.C3 || a.C4 || a.C7 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4303)"
    },
    "43030201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty staying focused upon what is being said",
        "solution": "Make notes ahead of time",
        "solution_long": "Use a word processing app for computer or tablet with text to speech and graphic capacity to create an outline of items to be discussed.",
        "solution_number": 43030201,
        "strategy": "Provide time to process information",
        "task": "Understanding Communication",
        "trigger": "(a.C1 || a.C2 || a.C3 || a.C4 || a.C7 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4303)"
    },
    "43030202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty staying focused upon what is being said",
        "solution": "Give a visual outline of the information ",
        "solution_long": "Use a mind mapping or sequencing app to create a visual display of key points under discussion.",
        "solution_number": 43030202,
        "strategy": "Provide time to process information",
        "task": "Understanding Communication",
        "trigger": "(a.C1 || a.C2 || a.C3 || a.C4 || a.C7 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4303)"
    },
    "43040101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty remembering what has been said",
        "solution": "Provide meeting notes ahead of time ",
        "solution_long": "Use a word processing app or a checklist app to create a list of items to be covered in discussion that can be checked when addressed.",
        "solution_number": 43040101,
        "strategy": "Provide time to process information",
        "task": "Understanding Communication",
        "trigger": "(a.C1 || a.C2 || a.C7) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4304)"
    },
    "43040201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty remembering what has been said",
        "solution": "Have a gesture to say, “You're off topic\"",
        "solution_long": "Develop an agreed upon signal/symbol to use when employee gets off topic.",
        "solution_number": 43040201,
        "strategy": "Use subtle cues to guide",
        "task": "Understanding Communication",
        "trigger": "(a.C1 || a.C2 || a.C7) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4304)"
    },
    "43040301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty remembering what has been said",
        "solution": "Give a visual outline of meeting notes",
        "solution_long": "Develop an outline of items to be discussed in sequence using a mind mapping or webbing app.",
        "solution_number": 43040301,
        "strategy": "Make the words used understandable",
        "task": "Understanding Communication",
        "trigger": "(a.C1 || a.C2 || a.C7) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4304)"
    },
    "43040302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty remembering what has been said",
        "solution": "Support discussion with visual examples",
        "solution_long": "Provide pictures of information to be discussed.  For example, create a \"Pros & Cons Matrix\" with pictures and descriptions of ideal and actual performance for discussion.",
        "solution_number": 43040302,
        "strategy": "Make the words used understandable",
        "task": "Understanding Communication",
        "trigger": "(a.C1 || a.C2 || a.C7) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4304)"
    },
    "44010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty spontaneously composing statements or questions",
        "solution": "Give additional time",
        "solution_long": "Provide time for the employee to ask questions.",
        "solution_number": 44010101,
        "strategy": "Provide time to process information",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4401)"
    },
    "44010102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to format the information.",
        "gap": "Employee has difficulty spontaneously composing statements or questions",
        "solution": "Provide information ahead of time",
        "solution_long": "Alert the employee ahead of time when they may need to ask their questions. <ul><li>Use a word-processing app or a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to create & make notes available prior to meeting with key points and terminology, reduce vocabulary level (Wordify, etc.).</li></ul>",
        "solution_number": 44010102,
        "strategy": "Provide time to process information",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4401)"
    },
    "44010103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty spontaneously composing statements or questions",
        "solution": "Agree to arrange to talk later",
        "solution_long": "Provide a way to say, \"I don't have a question just now. But I'll think about it and get back to you\".",
        "solution_number": 44010103,
        "strategy": "Provide time to process information",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4401)"
    },
    "44010201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the dictionary",
        "gap": "Employee has difficulty spontaneously composing statements or questions",
        "solution": "Create a personal dictionary",
        "solution_long": "Use a word-processing app or a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to create a personal dictionary/notebook of vocabulary/terminology needed for specific tasks/topics.",
        "solution_number": 44010201,
        "strategy": "Make the words used understandable",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4401)"
    },
    "44010301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee has difficulty spontaneously composing statements or questions",
        "solution": "Provide visual supports ahead of time",
        "solution_long": "Use a word-processing app or a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to provide a list (visual) of possible questions or topics prior to meeting with key vocabulary.",
        "solution_number": 44010301,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4401)"
    },
    "44010401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty spontaneously composing statements or questions",
        "solution": "Coach employee on communication",
        "solution_long": "A coworker can assist the employee with formulating questions or statements.",
        "solution_number": 44010401,
        "strategy": "Practice communication skills",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4401)"
    },
    "44010501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty spontaneously composing statements or questions",
        "solution": "Break instructions into smaller parts",
        "solution_long": "Help break general questions and /or statements into smaller, specific topics.",
        "solution_number": 44010501,
        "strategy": "Provide support for communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4401)"
    },
    "44010502": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Seek help from an Augmentative Communication (AAC) specialist.",
        "gap": "Employee has difficulty spontaneously composing statements or questions",
        "solution": "Create a communication board",
        "solution_long": "Use a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to create a topic-based communication board with pictures representing topics/terminology/questions.",
        "solution_number": 44010502,
        "strategy": "Provide support for communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4401)"
    },
    "44010503": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Seek help from an Augmentative Communication (AAC) specialist.",
        "gap": "Employee has difficulty spontaneously composing statements or questions",
        "solution": "Add messages to communication device",
        "solution_long": "If using an Augmentative Communication (AAC ) system, add job specific pre-stored phrases with the help of an AAC Specialist.",
        "solution_number": 44010503,
        "strategy": "Provide support for communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4401)"
    },
    "44020101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty spontaneously answering questions ",
        "solution": "Give additional time",
        "solution_long": "Provide the employee with time to compose their answer. Anticipate (use a word-processing app or list making app) and provide employee with list of questions they need to answer and can prepare ahead of time.",
        "solution_number": 44020101,
        "strategy": "Provide time to process information",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4402)"
    },
    "44020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty spontaneously answering questions ",
        "solution": "Agree to arrange to talk later",
        "solution_long": "Provide a way to say, \"I don't have an answer for you just now. But I'll think about it and get back to you.\"",
        "solution_number": 44020102,
        "strategy": "Provide time to process information",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4402)"
    },
    "44020201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the dictionary",
        "gap": "Employee has difficulty spontaneously answering questions ",
        "solution": "Create a personal dictionary",
        "solution_long": "Use a word-processing app or a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to create a dictionary/notebook of words, key points for specific topics to use when answering question.",
        "solution_number": 44020201,
        "strategy": "Make the words used understandable",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4402)"
    },
    "44020301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty spontaneously answering questions ",
        "solution": "Make notes ahead of time",
        "solution_long": "Encourage employee to use a word-processing app or list making app to make notes prior to meeting for reference.",
        "solution_number": 44020301,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4402)"
    },
    "44020401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty spontaneously answering questions ",
        "solution": "Coach employee on answering questions",
        "solution_long": "Coworker can assist employee with formulating answers to questions.",
        "solution_number": 44020401,
        "strategy": "Practice communication skills",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4402)"
    },
    "44020501": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Seek help from an Augmentative Communication (AAC) specialist.",
        "gap": "Employee has difficulty spontaneously answering questions ",
        "solution": "Add messages to communication device",
        "solution_long": "If using an Augmentative Communication (AAC ) system, add job specific pre-stored phrases with the help of an AAC Specialist.",
        "solution_number": 44020501,
        "strategy": "Provide support for communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4402)"
    },
    "44030101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Suggest rephrasing of questions",
        "solution_long": "Ask employee to \"tell me in a different way\", \"give me an example\", \"show me\", etc.",
        "solution_number": 44030101,
        "strategy": "Make the words used understandable",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the dictionary",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Create a dictionary/notebook",
        "solution_long": "Use a word processing app or symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to help the employee create a personal dictionary/notebook of vocabulary/terminology needed for specific tasks/topics.",
        "solution_number": 44030102,
        "strategy": "Make the words used understandable",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Provide time to review information",
        "solution_long": "Provide time for employee to ask question. Prompt them, \"Do you want to ask a question?\"",
        "solution_number": 44030201,
        "strategy": "Provide time to process information",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to format the information.",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Give employee information beforehand",
        "solution_long": "Alert the employee ahead of time when they might need to ask their questions, anticipate questions that might arise. <ul><li>Use a word processing app or symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to provide notes prior to meeting with key points and terminology.  Reduce the vocabulary level (i.e. Wordify).</li></ul>",
        "solution_number": 44030202,
        "strategy": "Provide time to process information",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Use flag to announce there is a question",
        "solution_long": "Provide employee with a \"flag\" (innocuous card or symbol) that says, \"I have a question.\" Champion or job coach will address shortly.",
        "solution_number": 44030203,
        "strategy": "Provide time to process information",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Encourage questions and clarification",
        "solution_long": "Let employee know that it is OK to ask for help.",
        "solution_number": 44030301,
        "strategy": "Encourage questions",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to make the cue cards.",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Make cue cards or visuals ",
        "solution_long": "Use a word-processing app or a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to create cue cards (text or picture-based) for employee on different types of questions (who, what, when, where, how).",
        "solution_number": 44030401,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Provide visual supports and prompts",
        "solution_long": "Job coach/champion will provide visual supports/prompts to guide employee.",
        "solution_number": 44030402,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Prep task so last item prompts next step",
        "solution_long": "Set up work task so that last item has a \"tag\" that says, \"you are done, go ask ______ what is next\". Use a word-processing app or a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to create these tags.",
        "solution_number": 44030403,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030501": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training.",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Role-play and model asking for help",
        "solution_long": "Engage in informal conversations with employer to practice asking for assistance.",
        "solution_number": 44030501,
        "strategy": "Practice communication skills",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030502": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training.",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Rehearse questions and responses",
        "solution_long": "Work with employee to determine what they do not understand – go through standard questions about job/task.",
        "solution_number": 44030502,
        "strategy": "Practice communication skills",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030503": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to develop the videos.",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Use videos to model communication",
        "solution_long": "Apps for video modeling (WordToob, GoVisual ) to create video to address how and when to ask specific question, cues in the environment that tip you off.",
        "solution_number": 44030503,
        "strategy": "Practice communication skills",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030504": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to develop the videos.",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Use videos to model expectations",
        "solution_long": "Create videos (Using WordToob, GoVisual, etc.) of topic/task to help employee understand. Use video to help employee identify area of question.",
        "solution_number": 44030504,
        "strategy": "Practice communication skills",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030601": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Seek help from an Augmentative Communication (AAC) specialist.",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Create a communication board",
        "solution_long": "Use a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to create topic-based communication board with pictures representing topics/terminology/questions.",
        "solution_number": 44030601,
        "strategy": "Provide support for communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030602": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Seek help from an Augmentative Communication (AAC) specialist.",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Add messages to communication device",
        "solution_long": "Add messages to the employee's Augmentative Communication (AAC) device to represent topics/terminology/questions.",
        "solution_number": 44030602,
        "strategy": "Provide support for communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44030603": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee does not know what question to ask when in need of help (or how to ask for help)",
        "solution": "Provide feedback and guidance ",
        "solution_long": "Help individual understand the timing of question and when it is appropriate to ask.",
        "solution_number": 44030603,
        "strategy": "Provide support for communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C2 || a.C4 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4403)"
    },
    "44040101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has trouble coming up with words they want to say",
        "solution": "Agree to arrange to talk later",
        "solution_long": "Provide employee with a \"flag\" (innocuous card or symbol) that says, \"I have a question/something to say - but I need help.\" Champion or job coach will help address shortly.",
        "solution_number": 44040101,
        "strategy": "Provide time to process information",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C3 || a.C4 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4404)"
    },
    "44040201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has trouble coming up with words they want to say",
        "solution": "Provide discussion outline",
        "solution_long": "Encourage use of a word-processing app, mind mapping app, or list making app to create an outline of items to be discussed.",
        "solution_number": 44040201,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C3 || a.C4 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4404)"
    },
    "44040202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee has trouble coming up with words they want to say",
        "solution": "Help employee organize steps/details",
        "solution_long": "Use a sequencing app on a tablet or computer to organize details in correct sequence.",
        "solution_number": 44040202,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C3 || a.C4 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4404)"
    },
    "44040203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee has trouble coming up with words they want to say",
        "solution": "Use mind map to present main points",
        "solution_long": "Use a mind mapping/web app to create a visual display of key points.",
        "solution_number": 44040203,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C3 || a.C4 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4404)"
    },
    "44040204": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee has trouble coming up with words they want to say",
        "solution": "Make cue cards or visuals ",
        "solution_long": "Use a word-processing app or a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to create cue cards with key points to discuss.",
        "solution_number": 44040204,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C3 || a.C4 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4404)"
    },
    "44040301": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Seek help from an Augmentative Communication (AAC) specialist.",
        "gap": "Employee has trouble coming up with words they want to say",
        "solution": "Create a communication board",
        "solution_long": "Use a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to provide a topic based and/or core vocabulary communication boards.",
        "solution_number": 44040301,
        "strategy": "Provide support for communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C3 || a.C4 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4404)"
    },
    "44050101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty engaging in a reciprocal discussion",
        "solution": "Rehearse questions and responses",
        "solution_long": "Work with the employee to determine what they do not understand. Provide feedback and guidance.",
        "solution_number": 44050101,
        "strategy": "Practice communication skills",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4405)"
    },
    "44050201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty engaging in a reciprocal discussion",
        "solution": "Have employee make a list of things to say",
        "solution_long": "The employee can use a mind mapping, webbing app, or list making app to create an outline of key points to be discussed prior to the meeting.",
        "solution_number": 44050201,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4405)"
    },
    "44050202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee has difficulty engaging in a reciprocal discussion",
        "solution": "Provide accessible information in advance",
        "solution_long": "Use a word-processing app or a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to provide the employee with information on formal topics in an accessible format.",
        "solution_number": 44050202,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4405)"
    },
    "44050203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee has difficulty engaging in a reciprocal discussion",
        "solution": "Coach employee on the topic at hand",
        "solution_long": "Ask a job coach or champion to review the topic/job to be discussed prior to the meeting.",
        "solution_number": 44050203,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4405)"
    },
    "44050204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty engaging in a reciprocal discussion",
        "solution": "Use a gesture to cue employee",
        "solution_long": "During group discussions, signal who needs to talk and and when.",
        "solution_number": 44050204,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4405)"
    },
    "44050205": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty engaging in a reciprocal discussion",
        "solution": "Have an informal signal for interruptions",
        "solution_long": "Create an agreed upon signal if employee interrupts.",
        "solution_number": 44050205,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4405)"
    },
    "44050206": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty engaging in a reciprocal discussion",
        "solution": "Engage employee in discussions by design",
        "solution_long": "Provide time for the employee to engage in discussion. Provide pauses or ask questions such as \"what do you think?\" to encourage participation.",
        "solution_number": 44050206,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4405)"
    },
    "44050207": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty engaging in a reciprocal discussion",
        "solution": "Prompt employee their turn is coming",
        "solution_long": "Alert the employee ahead of time when it is their turn to participate.",
        "solution_number": 44050207,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4405)"
    },
    "44050208": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty engaging in a reciprocal discussion",
        "solution": "Encourage employee to \"take their time\"",
        "solution_long": "Use a gesture or expression to encourage the employee to take their time.",
        "solution_number": 44050208,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4405)"
    },
    "44050209": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Seek help from an Augmentative Communication (AAC) specialist.",
        "gap": "Employee has difficulty engaging in a reciprocal discussion",
        "solution": "Use \"quick-hits\" section of AAC system",
        "solution_long": "If using AAC system, provide an area for pre-stored phrases, such as \"can we go back and revisit something we discussed earlier?\"",
        "solution_number": 44050209,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4405)"
    },
    "44050301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee has difficulty engaging in a reciprocal discussion",
        "solution": "Make visual cue cards ",
        "solution_long": "Use a word-processing app or a symbol-based word processor (e.g., SymWriter 2, Boardmaker) to create picture-based cue cards to use as prompts during meetings/discussions or job assignments.",
        "solution_number": 44050301,
        "strategy": "Make the words used understandable",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4405)"
    },
    "44060101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee has difficulty staying on topic",
        "solution": "Develop a list of items for discussion",
        "solution_long": "Use a word-processing app or a symbol-based word processor (i.e. SymWriter 2, Boardmaker, etc.) to create a check list of items to be covered in discussion that can be checked when addressed.",
        "solution_number": 44060101,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.C1 || a.C2 || a.C4 || a.C8) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4406)"
    },
    "44060102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee has difficulty staying on topic",
        "solution": "Use visual representations of key points",
        "solution_long": "Create a visual display (use a mind mapping or webbing app or list making app) for employees with key points to be discussed.",
        "solution_number": 44060102,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.C1 || a.C2 || a.C4 || a.C8) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4406)"
    },
    "44060201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee has difficulty staying on topic",
        "solution": "Model good communication",
        "solution_long": "Model appropriate communication through video modeling & social stories (using video modeling apps like WordToob & GoVisual and Social story creation apps like Pictello).",
        "solution_number": 44060201,
        "strategy": "Practice communication skills",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.C1 || a.C2 || a.C4 || a.C8) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4406)"
    },
    "44060202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training and supports.",
        "gap": "Employee has difficulty staying on topic",
        "solution": "Work with individual to develop skills",
        "solution_long": "Job coach/ Champion will review with employee how we have a conversation.",
        "solution_number": 44060202,
        "strategy": "Practice communication skills",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.C1 || a.C2 || a.C4 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4406)"
    },
    "44060301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty staying on topic",
        "solution": "Cue employee that they are off topic",
        "solution_long": "Develop an agreed upon signal to use when employee gets off topic.  If employee exceeds predetermined number of off topic comments, signal that they are off topic and work with this at a later time.",
        "solution_number": 44060301,
        "strategy": "Provide support for communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.C1 || a.C2 || a.C4 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4406)"
    },
    "44060302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty staying on topic",
        "solution": "Overlook 1 or 2 stray comments",
        "solution_long": "Offer a \"Small Pass\" – where champion overlooks 1 stray comment to give employee a chance to circle back to topic.",
        "solution_number": 44060302,
        "strategy": "Provide support for communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.C1 || a.C2 || a.C4 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4406)"
    },
    "44060303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty staying on topic",
        "solution": "Agree to arrange to talk later",
        "solution_long": "If comment is off topic, put comment in a \"parking lot\" to return to at a later time.",
        "solution_number": 44060303,
        "strategy": "Provide support for communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.C1 || a.C2 || a.C4 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4406)"
    },
    "44070101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty giving information in an organized manner",
        "solution": "Review topic with employee ahead of time",
        "solution_long": "A job coach or champion can review the topic/job to be discussed or completed with employee.",
        "solution_number": 44070101,
        "strategy": "Provide time to process information",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.C1 || a.C4 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4407)"
    },
    "44070201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty giving information in an organized manner",
        "solution": "Develop a list of items for discussion",
        "solution_long": "The employee can use a mind mapping, webbing app, or list making app to create an outline of key points to be discussed prior to the meeting.",
        "solution_number": 44070201,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.C1 || a.C4 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4407)"
    },
    "44070202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty giving information in an organized manner",
        "solution": "Make cue cards or visuals ",
        "solution_long": "Use a word programming app or list making app to create cue cards for the employee to use as prompts during meetings/discussions or job assignments. <ul><li>If the employee prefers to communicate with images, use a symbol based word processor (e.g., SymWriter 2, Boardmaker) to create picture based cue cards.</li></ul>",
        "solution_number": 44070202,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.C1 || a.C4 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4407)"
    },
    "44070203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty giving information in an organized manner",
        "solution": "Help sequence steps",
        "solution_long": "Use a sequencing app on a tablet or computer to organize details in correct sequence.",
        "solution_number": 44070203,
        "strategy": "Plan the communication",
        "task": "Expressing Ideas and Thoughts",
        "trigger": "(a.C1 || a.C4 || a.A1) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4407)"
    },
    "45010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee makes comments that are not related to the context of the conversation",
        "solution": "List items for discussion",
        "solution_long": "Create a check list of items to be covered in discussion that can be checked when addressed.",
        "solution_number": 45010101,
        "strategy": "Plan the communication",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4501)"
    },
    "45010201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee makes comments that are not related to the context of the conversation",
        "solution": "Cue employee that they are off topic",
        "solution_long": "Develop an agreed upon signal to use when employee gets off topic.",
        "solution_number": 45010201,
        "strategy": "Provide support for communication",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4501)"
    },
    "45010202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee makes comments that are not related to the context of the conversation",
        "solution": "Use a \"free pass\" strategy",
        "solution_long": "Provide a \"free pass\" strategy of allowing a limited number of off-topic comments before cueing that the employee is off-topic.",
        "solution_number": 45010202,
        "strategy": "Provide support for communication",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4501)"
    },
    "45010301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the displays or to teach a manager to use the software.",
        "gap": "Employee makes comments that are not related to the context of the conversation",
        "solution": "Use visual representations of key points ",
        "solution_long": "Use a Word Processing app or Symbol based word processor (i.e., Clicker 8, Pixwriter, SymWriter 2, Boardmaker, etc.) to create a visual display for employees with key points to be discussed.",
        "solution_number": 45010301,
        "strategy": "Make the words used understandable",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C1 || a.C2 || a.C3 || a.C4 || a.C8 || a.A1) && (a.CMP) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4501)"
    },
    "45020101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee often misunderstands comments made by others",
        "solution": "Use concrete, simple language",
        "solution_long": "Avoid using figurative language (i.e., idioms, etc.) and keep language level concrete.",
        "solution_number": 45020101,
        "strategy": "Make the words used understandable",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.C1 || a.C3 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4502)"
    },
    "45020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee often misunderstands comments made by others",
        "solution": "Have employee restate information",
        "solution_long": "Have employee restate information in their own words. <ul><li>In the case where the communication involves actions that the employee must personally carry out, ask the employee to recount what they have been asked to do.</li></ul>",
        "solution_number": 45020102,
        "strategy": "Make the words used understandable",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.C1 || a.C3 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4502)"
    },
    "45020103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee often misunderstands comments made by others",
        "solution": "Help employee to understand comments",
        "solution_long": "Revisit specific comments without pointing out misunderstanding, such as \"another way to look at this might be…\"",
        "solution_number": 45020103,
        "strategy": "Make the words used understandable",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.C1 || a.C3 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4502)"
    },
    "45020201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee often misunderstands comments made by others",
        "solution": "Provide information ahead of time",
        "solution_long": "Provide the employee with information on the topics of discussion in accessible format.",
        "solution_number": 45020201,
        "strategy": "Provide time to process information",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.C1 || a.C3 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4502)"
    },
    "45020202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the cue cards.",
        "gap": "Employee often misunderstands comments made by others",
        "solution": "Make cue cards or visuals ",
        "solution_long": "Word processor with graphic capacity to create cue cards for employee to use as prompts during meetings/discussions or job assignments.",
        "solution_number": 45020202,
        "strategy": "Provide time to process information",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.C1 || a.C3 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4502)"
    },
    "45020301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the social cues videos.",
        "gap": "Employee often misunderstands comments made by others",
        "solution": "Teach social cues ",
        "solution_long": "Use WordToob app for video modeling of meanings of different types of body language.",
        "solution_number": 45020301,
        "strategy": "Practice communication skills",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.C1 || a.C3 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4502)"
    },
    "45020401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee often misunderstands comments made by others",
        "solution": "Encourage questions and clarification",
        "solution_long": "Develop a regular habit to ask employee, \"Do you have a question?\" or \"Do you understand?\"",
        "solution_number": 45020401,
        "strategy": "Encourage questions",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.C1 || a.C3 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4502)"
    },
    "45030101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty offering help or demonstrating cooperation",
        "solution": "Develop an \"If-then\" strategy",
        "solution_long": "Use \"If-Then\" strategy (i.e., If this happens, then you do this…)",
        "solution_number": 45030101,
        "strategy": "Plan the communication",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4503)"
    },
    "45030201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the videos.",
        "gap": "Employee has difficulty offering help or demonstrating cooperation",
        "solution": "Use video modelling to coach teamwork ",
        "solution_long": "Use WordToob to create video modeling to present examples of cooperation that are adapted to for the specific work setting.",
        "solution_number": 45030201,
        "strategy": "Practice communication skills",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.C8) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4503)"
    },
    "45030202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the social stories.",
        "gap": "Employee has difficulty offering help or demonstrating cooperation",
        "solution": "Use social stories to develop teamwork ",
        "solution_long": "Incorporate Social Stories (using Pictello, Social Story Creator, etc.) to help teach cooperation skills.",
        "solution_number": 45030202,
        "strategy": "Practice communication skills",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.C8) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4503)"
    },
    "45030203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty offering help or demonstrating cooperation",
        "solution": "Compare present work with ideal work",
        "solution_long": "Create a set of \"pros and cons\" cards showing positive and negative examples of cooperation (can be color coded, for example red/green).",
        "solution_number": 45030203,
        "strategy": "Practice communication skills",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4503)"
    },
    "45030204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty offering help or demonstrating cooperation",
        "solution": "Use visual images of key points ",
        "solution_long": "Use word processor with graphic capacity to create a set of cue cards with examples of positive collaboration that are appropriate for the company and setting.",
        "solution_number": 45030204,
        "strategy": "Practice communication skills",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4503)"
    },
    "45030301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty offering help or demonstrating cooperation",
        "solution": "Use texting to share next steps",
        "solution_long": "Use texting to send messages seeking help or indicating they are finished with a task.",
        "solution_number": 45030301,
        "strategy": "Use text to communicate",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C2 || a.C3 || a.C4 || a.C8) && (a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4503)"
    },
    "45040101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the social stories.",
        "gap": "Employee's conversation is sometimes disrespectful to the environment or communication partner",
        "solution": "Use socials stories  ",
        "solution_long": "Use social stories -- individualized short stories that depict social situations -- to reinforce respectful behaviors versus disrespectful behaviors.  Tools to help with the creation of the stories include Pictello, and the Social Story Creator and Library.",
        "solution_number": 45040101,
        "strategy": "Practice communication skills",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C3 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4504)"
    },
    "45040102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's conversation is sometimes disrespectful to the environment or communication partner",
        "solution": "Provide individualized training",
        "solution_long": "Provide individualized training to address inappropriate behaviors that the employee has displayed in the workplace.",
        "solution_number": 45040102,
        "strategy": "Practice communication skills",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C3 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4504)"
    },
    "45040201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the cards.",
        "gap": "Employee's conversation is sometimes disrespectful to the environment or communication partner",
        "solution": "Create \"Power Cards\" ",
        "solution_long": "Use Word Processing app or symbol based word processor (i.e., Clicker 8, Pixwriter, SymWriter 2, Boardmaker, etc.) to create a set of \"Power Cards\" - cue cards that list appropriate things (on a green background) that are OK to say and identify things to not say (printed on a red background).",
        "solution_number": 45040201,
        "strategy": "Plan the communication",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C3 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4504)"
    },
    "45040202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee's conversation is sometimes disrespectful to the environment or communication partner",
        "solution": "Use understated response ",
        "solution_long": "Develop a policy to avoid reacting to the inappropriate statement, then taking the individual aside and pointing out how the comment was inappropriate – suggesting an alternative phrase or comment that would have been more appropriate.",
        "solution_number": 45040202,
        "strategy": "Plan the communication",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C3 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4504)"
    },
    "45040203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the cards.",
        "gap": "Employee's conversation is sometimes disrespectful to the environment or communication partner",
        "solution": "Develop a set of \"signal\" cue cards ",
        "solution_long": "Use Word processing app or symbol based word processor (i.e., Clicker 8, Pixwriter, SymWriter 2, Boardmaker, etc.) to develop a \"signal\" cue card that lists \"tells\" from the posture and body language of others that would indicate what kinds of comments and questions to say and what not to say.",
        "solution_number": 45040203,
        "strategy": "Plan the communication",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.S1 || a.C3 || a.C8 || a.A1) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4504)"
    },
    "45050101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training.",
        "gap": "Employee does not recognize the reactions of the conversation partner and speaks inappropriately",
        "solution": "Help identify cues in the environment",
        "solution_long": "Engage employee in training in identifying social cues (reading expressions, emotions, body language).",
        "solution_number": 45050101,
        "strategy": "Practice communication skills",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.C1 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4505)"
    },
    "45050102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training.",
        "gap": "Employee does not recognize the reactions of the conversation partner and speaks inappropriately",
        "solution": "Use scripts and role-play",
        "solution_long": "Provide support through scripting, and role play.",
        "solution_number": 45050102,
        "strategy": "Practice communication skills",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.C1 || a.C7 || a.C8) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4505)"
    },
    "45050103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to create the social stories.",
        "gap": "Employee does not recognize the reactions of the conversation partner and speaks inappropriately",
        "solution": "Use socials stories ",
        "solution_long": "Incorporate use of Social Stories (using Pictello, Social Story Creator, etc.) to reinforce appropriate behaviors versus inappropriate behaviors.",
        "solution_number": 45050103,
        "strategy": "Practice communication skills",
        "task": "Socially / Situationally Appropriate Communication",
        "trigger": "(a.C1 || a.C7 || a.C8) && (a.LOW || a.CMP || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4505)"
    },
    "46010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Pages are difficult to hold / turn",
        "solution": "Use a book stand",
        "solution_long": "Use a book stand to hold the book, hands-free, in a comfortable reading position. <ul><wafactors PRT MLT><li>Some book stands are easier to move and store than others.  Keep this in mind if a portable solution is needed and/or if the employee uses multiple work areas.</li></wafactors> <wafactors V3 V4> <li>If the employee also has low vision, he or she might have better success with a solution that uses electronic documents.</li></wafactors></ul>",
        "solution_number": 46010101,
        "strategy": "Remove need to hold the book",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW) && (a.T4601)"
    },
    "46010201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Pages are difficult to hold / turn",
        "solution": "Rebind materials into a ring binder",
        "solution_long": "Rebind materials into a ring binder that will sit open flat. <ul><wafactors V3 V4> <li>If the employee also has low vision, he or she might have better success with a solution that uses electronic documents.</li></wafactors> </ul>",
        "solution_number": 46010201,
        "strategy": "Change the page binding",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW) && (a.T4601)"
    },
    "46010301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Pages are difficult to hold / turn",
        "solution": "Laminate pages",
        "solution_long": "Laminate pages, that is, apply a thin layer of plastic to paper or card sheets.  This will make the pages stiffer and easier to grip, and also protect them from debris, liquids, etc. <ul><wafactors V3 V4> <li>If the employee also has low vision, he or she might have better success with a solution that uses electronic documents.</li></wafactors></ul>",
        "solution_number": 46010301,
        "strategy": "Make materials easier to grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW) && (a.T4601)"
    },
    "46010302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Pages are difficult to hold / turn",
        "solution": "Attach tabs to pages",
        "solution_long": "Attach tabs to pages to provide a place to grip them. <ul> <wafactors V3 V4> <li>If the employee also has low vision, he or she might have better success with a solution that uses electronic documents.</li></wafactors></ul>",
        "solution_number": 46010302,
        "strategy": "Make materials easier to grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW) && (a.T4601)"
    },
    "46010401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Pages are difficult to hold / turn",
        "solution": "Turn pages with a hand-held stylus ",
        "solution_long": "Turn pages with a hand-held stylus (e.g., stick with rubber tip, stylus worn with a Velcro grip). <ul> <li>The employee may need help identifying an appropriate stylus if this is a new accommodation.  If this is the case, or if multiple problems are being addressed, then consultation with an AT expert might be helpful.</li><li>The employee may need help putting on the device.</li><wafactors V3 V4> <li>If the employee also has low vision, he or she might have better success with a solution that uses electronic documents.</li></wafactors></ul>",
        "solution_number": 46010401,
        "strategy": "Remove need to grip the page",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW) && (a.T4601)"
    },
    "46010402": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Strongly encouraged to make sure that inappropriate forces are not placed on the neck or teeth",
        "gap": "Pages are difficult to hold / turn",
        "solution": "Turn pages with a mouth-held stylus",
        "solution_long": "Turn pages with a stylus held in the mouth (e.g., mouthstick with rubber tip). <ul><li>This option can place stress on the neck, mouth, and teeth.  Consult an occupational therapist or AT expert before using it.</li><li>Make sure that the stylus is kept clean between uses.</li><wafactors V3 V4> <li>If the employee also has low vision, he or she might have better success with a solution that uses electronic documents.</li></wafactors></ul>",
        "solution_number": 46010402,
        "strategy": "Remove need to grip the page",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW) && (a.T4601)"
    },
    "46010403": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Strongly encouraged due to cost and performance of the technology",
        "gap": "Pages are difficult to hold / turn",
        "solution": "Use a mechanical page turner",
        "solution_long": "Use a mechanical page turner. <ul> <li>The employee may need help with loading books and documents.</li><li>The employee may need help identifying a specific product if this is a new accommodation.  If this is the case, or if multiple problems are being addressed, then consultation with an AT expert might be helpful.</li><wafactors SHD> <li>This solution might work best when the employee has a private workstation. Coworkers may be tempted to move bulky equipment and not reset it.</li></wafactors><wafactors MLT><li>This solution might work best when the employee uses a single workstation. The equipment will either need to be available at each workstation used, or it will need to be available in another central location.</li></wafactors><wafactors V3 V4> <li>If the employee also has low vision, he or she might have better success with a solution that uses electronic documents.</li></wafactors></ul>",
        "solution_number": 46010403,
        "strategy": "Remove need to grip the page",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW) && (a.T4601)"
    },
    "46010501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Pages are difficult to hold / turn",
        "solution": "Use electronic documents",
        "solution_long": "Use electronic documents on a computer.  Scan print documents to convert them into a version that can be viewed on a computer screen. <ul><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors></ul>",
        "solution_number": 46010501,
        "strategy": "Provide documents in digital form",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (a.CMP || a.MOB) && (a.T4601)"
    },
    "46010601": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Pages are difficult to hold / turn",
        "solution": "Get help from an assistant",
        "solution_long": "Have an assistant or coworker position print materials for reading and/or load documents to be scanned.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance.",
        "solution_number": 46010601,
        "strategy": "Get help moving pages",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T4601)"
    },
    "46020101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Paper slides while writing",
        "solution": "Use a non-slip surface",
        "solution_long": "Use a non-slip surface to reduce paper movement while writing.",
        "solution_number": 46020101,
        "strategy": "Stabilize the paper",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U6 || a.U7 || a.U8 || a.U9) && (a.LOW) && (a.T4602)"
    },
    "46020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Paper slides while writing",
        "solution": "Use sticky paper (e.g., Post-it paper)",
        "solution_long": "Use sticky paper (e.g., Post-it paper) that will stay in place while writing.",
        "solution_number": 46020102,
        "strategy": "Stabilize the paper",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U6 || a.U7 || a.U8 || a.U9) && (a.LOW) && (a.T4602)"
    },
    "46020103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Paper slides while writing",
        "solution": "Use a clipboard",
        "solution_long": "Use a clipboard to hold the paper in place while writing.",
        "solution_number": 46020103,
        "strategy": "Stabilize the paper",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U6 || a.U7 || a.U8 || a.U9) && (a.LOW) && (a.T4602)"
    },
    "46020104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Paper slides while writing",
        "solution": "Use a paperweight",
        "solution_long": "Use a paperweight to hold the paper in place while writing.",
        "solution_number": 46020104,
        "strategy": "Stabilize the paper",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U6 || a.U7 || a.U8 || a.U9) && (a.LOW) && (a.T4602)"
    },
    "46030101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Use a wrist or forearm rest",
        "solution_long": "Use a wrist rest and/or forearm rest to position and support the hand and forearm.  This may relax the grip and/or stabilize the wrist and hand to improve coordination and legibility. <ul> <li>Some forearm rests have joints that permit supported arm movement.</li> <li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li><wafactors MLT><li>This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors><wafactors SHD><li>This solution might work best when the employee has a private workstation. This technology might interfere with how coworkers would normally work.</li></wafactors></ul>",
        "solution_number": 46030101,
        "strategy": "Position hand and arm in a neutral position",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U10) && (a.LOW) && (a.T4603)"
    },
    "46030102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Use a slant board",
        "solution_long": "Use a slant board to change the paper position, and thus, the arm and hand position and grip. <ul><li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li><wafactors MLT><li>This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors><wafactors SHD><li>This solution might work best when the employee has a private workstation. This technology might interfere with how coworkers would normally work.</li></wafactors></ul>",
        "solution_number": 46030102,
        "strategy": "Position hand and arm in a neutral position",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U10) && (a.LOW) && (a.T4603)"
    },
    "46030201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Use a pen or darker lead",
        "solution_long": "Use a dark pen (e.g., felt tip pen) or darker lead so the employee can relax his or her grip and does not need to press down as hard.",
        "solution_number": 46030201,
        "strategy": "Change the writing tool to relax grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10) && (a.LOW) && (a.T4603)"
    },
    "46030202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Add a gripping aid",
        "solution_long": "Add a gripping aid to the pen or pencil to provide a softer, larger grip (e.g., pencil placed through a ball), and/or triangular grip.<ul><li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li></ul>",
        "solution_number": 46030202,
        "strategy": "Change the writing tool to relax grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10) && (a.LOW) && (a.T4603)"
    },
    "46030203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product.",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Use an adapted pen or pencil",
        "solution_long": "Use an <a href=\"/glossary#adapted\" target=\"_blank\">adapted</a> pen or pencil that has a larger barrel and/or a softer or triangular grip.<ul><li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li></ul>",
        "solution_number": 46030203,
        "strategy": "Change the writing tool to relax grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10) && (a.LOW) && (a.T4603)"
    },
    "46030301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Use inked stamps for signatures, etc.",
        "solution_long": "Use inked stamps for signatures, etc. Place a tactile mark on the stamp to show which way to position it.",
        "solution_number": 46030301,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10) && (a.LOW) && (a.T4603)"
    },
    "46030302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Use electronic signatures",
        "solution_long": "Use an electronic signature as an alternative to handwriting. <ul> <li>Use a laptop or tablet if a portable solution is needed.</li> </ul>",
        "solution_number": 46030302,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10) && (a.CMP || a.MOB) && (a.T4603)"
    },
    "46030303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Use an electronic device to type",
        "solution_long": "Use a computer, tablet, or notetaking device to write text or fill in forms converted into an electronic format.  Typing may be physically easier than writing by hand. <ul> <li>Use a laptop or tablet if a portable solution is needed.</li> </ul>",
        "solution_number": 46030303,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U10) && (a.CMP || a.MOB) && (a.T4603)"
    },
    "46030304": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Get meeting notes in advance",
        "solution_long": "Get meeting agendas, etc. in advance to reduce the need to take notes.<ul> <li>Use notes that are in an electronic format if the employee has difficulty handling printouts, or seeing or reading text.</li> </ul>",
        "solution_number": 46030304,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U10) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4603)"
    },
    "46030305": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Share meeting notes",
        "solution_long": "Have a designated person take notes during meetings and share them with attendees.<ul> <li>Use notes that are in an electronic format if the employee has difficulty handling printouts, or seeing or reading text.</li> </ul>",
        "solution_number": 46030305,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U10) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4603)"
    },
    "46030306": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Make notes with a voice assistant",
        "solution_long": "Use a voice assistant (e.g., Siri, Alexa) to make brief notes for later review. <ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46030306,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U10) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.MOB) && (a.T4603)"
    },
    "46030307": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Use speech recognition software",
        "solution_long": "Use speech recognition / speech to text software (e.g., Dragon Naturally Speaking) to dictate to the computer. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer. If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li> <li>The employee will need to read well enough to recognize and fix the computer's recognition errors.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46030307,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U10) && (!a.S5) && (a.CMP || a.MOB) && (a.T4603)"
    },
    "46030308": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Make a sound recording of the text",
        "solution_long": "Dictate into a recorder to make a sound recording of the text.  Recordings may be played back later for quick review or can be transcribed. <ul><wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1> <li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul>",
        "solution_number": 46030308,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U10) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.MOB) && (a.T4603)"
    },
    "46030309": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Record instructions and meetings ",
        "solution_long": "Record coworkers' instructions and meetings as an alternative to taking notes.  Recordings may be played back later for quick review or can be transcribed. <ul> <li>Get permission from everyone involved before recording them.</li><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors OPN NSY><li>Use headphones when playing back recordings, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better. </li></wafactors></ul>",
        "solution_number": 46030309,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U10) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4603)"
    },
    "46030310": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting causes discomfort / pain",
        "solution": "Dictate to an assistant or coworker",
        "solution_long": "Dictate to an assistant or coworker.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance. <ul><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This solution might be difficult to use in a noisy workplace.</li></wafactors> <wafactors OPN NSY><li>Use headphones when playing back messages, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4><li>Make sure that the person providing assistance is able to understand the employee's speech. </li></wafactors> </ul>",
        "solution_number": 46030310,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U1 || a.U10) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW) && (a.T4603)"
    },
    "46040101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Use a wrist or forearm rest",
        "solution_long": "Use a wrist rest and/or forearm rest to position and support the hand and forearm.  This may relax the grip and/or stabilize the wrist and hand to improve coordination and legibility. <ul> <li>Some forearm rests have joints that permit supported arm movement.</li> <li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li><wafactors MLT><li>This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors><wafactors SHD><li>This solution might work best when the employee has a private workstation. This technology might interfere with how coworkers would normally work.</li></wafactors></ul>",
        "solution_number": 46040101,
        "strategy": "Position hand and arm in a neutral position",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4604)"
    },
    "46040201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Add a gripping aid",
        "solution_long": "Add a gripping aid to the pen or pencil to provide a softer, larger grip (e.g., pencil placed through a ball), and/or triangular grip.<ul><li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li></ul>",
        "solution_number": 46040201,
        "strategy": "Change the writing tool to relax grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4604)"
    },
    "46040202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product.",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Use an adapted pen or pencil",
        "solution_long": "Use an <a href=\"/glossary#adapted\" target=\"_blank\">adapted</a> pen or pencil that has a larger barrel and/or a softer or triangular grip.<ul><li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li></ul>",
        "solution_number": 46040202,
        "strategy": "Change the writing tool to relax grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4604)"
    },
    "46040301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product.",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Use a weighted writing tool",
        "solution_long": "Use a weighted pen or pencil to provide resistance to counter tremor.",
        "solution_number": 46040301,
        "strategy": "Provide resistance to counter tremor",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4604)"
    },
    "46040302": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Is strongly recommended to ensure that this type of solution is appropriate for the worker",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Wear a weight on the writing hand",
        "solution_long": "Wear a weight on the wrist of the writing hand to provide resistance to counter tremor.",
        "solution_number": 46040302,
        "strategy": "Provide resistance to counter tremor",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4604)"
    },
    "46040401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Use inked stamps for signatures, etc.",
        "solution_long": "Use inked stamps for signatures, etc. Place a tactile mark on the stamp to show which way to position it.",
        "solution_number": 46040401,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T4604)"
    },
    "46040402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Use electronic signatures",
        "solution_long": "Use an electronic signature as an alternative to handwriting. <ul> <li>Use a laptop or tablet if a portable solution is needed.</li> </ul>",
        "solution_number": 46040402,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.CMP || a.MOB) && (a.T4604)"
    },
    "46040403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Use an electronic device to type",
        "solution_long": "Use a computer, tablet, or notetaking device to write text or fill in forms converted into an electronic format.  Typing may be physically easier than writing by hand. <ul> <li>Use a laptop or tablet if a portable solution is needed.</li> </ul>",
        "solution_number": 46040403,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.CMP || a.MOB) && (a.T4604)"
    },
    "46040404": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Get meeting notes in advance",
        "solution_long": "Get meeting agendas, etc. in advance to reduce the need to take notes.<ul> <li>Use notes that are in an electronic format if the employee has difficulty handling printouts, or seeing or reading text.</li> </ul>",
        "solution_number": 46040404,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4604)"
    },
    "46040405": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Share meeting notes",
        "solution_long": "Have a designated person take notes during meetings and share them with attendees.<ul> <li>Use notes that are in an electronic format if the employee has difficulty handling printouts, or seeing or reading text.</li> </ul>",
        "solution_number": 46040405,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4604)"
    },
    "46040406": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Make notes with a voice assistant",
        "solution_long": "Use a voice assistant (e.g., Siri, Alexa) to make brief notes for later review. <ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46040406,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.MOB) && (a.T4604)"
    },
    "46040407": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Use speech recognition software",
        "solution_long": "Use speech recognition / speech to text software (e.g., Dragon Naturally Speaking) to dictate to the computer. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer. If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li> <li>The employee will need to read well enough to recognize and fix the computer's recognition errors.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46040407,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.S5) && (a.CMP || a.MOB) && (a.T4604)"
    },
    "46040408": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Make a sound recording of the text",
        "solution_long": "Dictate into a recorder to make a sound recording of the text.  Recordings may be played back later for quick review or can be transcribed. <ul><wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1> <li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul>",
        "solution_number": 46040408,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.MOB) && (a.T4604)"
    },
    "46040409": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Record instructions and meetings ",
        "solution_long": "Record coworkers' instructions and meetings as an alternative to taking notes.  Recordings may be played back later for quick review or can be transcribed. <ul> <li>Get permission from everyone involved before recording them.</li><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors OPN NSY><li>Use headphones when playing back recordings, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better. </li></wafactors></ul>",
        "solution_number": 46040409,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4604)"
    },
    "46040410": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting requires too much fine motor coordination",
        "solution": "Dictate to an assistant or coworker",
        "solution_long": "Dictate to an assistant or coworker.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance. <ul><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This solution might be difficult to use in a noisy workplace.</li></wafactors> <wafactors OPN NSY><li>Use headphones when playing back messages, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4><li>Make sure that the person providing assistance is able to understand the employee's speech. </li></wafactors> </ul>",
        "solution_number": 46040410,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW) && (a.T4604)"
    },
    "46050101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Writing is not legible",
        "solution": "Use a wrist or forearm rest",
        "solution_long": "Use a wrist rest and/or forearm rest to position and support the hand and forearm.  This may relax the grip and/or stabilize the wrist and hand to improve coordination and legibility. <ul> <li>Some forearm rests have joints that permit supported arm movement.</li> <li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li><wafactors MLT><li>This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors><wafactors SHD><li>This solution might work best when the employee has a private workstation. This technology might interfere with how coworkers would normally work.</li></wafactors></ul>",
        "solution_number": 46050101,
        "strategy": "Position hand and arm in a neutral position",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4605)"
    },
    "46050102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Use a slant board",
        "solution_long": "Use a slant board to change the paper position, and thus, the arm and hand position and grip. <ul><li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li><wafactors MLT><li>This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors><wafactors SHD><li>This solution might work best when the employee has a private workstation. This technology might interfere with how coworkers would normally work.</li></wafactors></ul>",
        "solution_number": 46050102,
        "strategy": "Position hand and arm in a neutral position",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4605)"
    },
    "46050201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Use a pen or darker lead",
        "solution_long": "Use a dark pen (e.g., felt tip pen) or darker lead so the employee can relax his or her grip and does not need to press down as hard.",
        "solution_number": 46050201,
        "strategy": "Change the writing tool to relax grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4605)"
    },
    "46050202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Add a gripping aid",
        "solution_long": "Add a gripping aid to the pen or pencil to provide a softer, larger grip (e.g., pencil placed through a ball), and/or triangular grip.<ul><li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li></ul>",
        "solution_number": 46050202,
        "strategy": "Change the writing tool to relax grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4605)"
    },
    "46050203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product.",
        "gap": "Writing is not legible",
        "solution": "Use an adapted pen or pencil",
        "solution_long": "Use an <a href=\"/glossary#adapted\" target=\"_blank\">adapted</a> pen or pencil that has a larger barrel and/or a softer or triangular grip.<ul><li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li></ul>",
        "solution_number": 46050203,
        "strategy": "Change the writing tool to relax grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4605)"
    },
    "46050301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Use ruled paper with dark lines",
        "solution_long": "Use ruled paper with dark lines as a guide for writing in a straight line. <ul> <li>If the employee also has low vision, he or she might have better success with a solution that uses electronic documents.</li> </ul>",
        "solution_number": 46050301,
        "strategy": "Provide cues for writing in a straight line",
        "task": "Reading & Writing Documents",
        "trigger": "(a.C6) && (a.LOW) && (a.T4605)"
    },
    "46050302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Use grid paper for alignment",
        "solution_long": "Use grid paper as a guide to keep columns lined up. <ul> <li>If the employee also has low vision, he or she might have better success with a solution that uses electronic documents.</li> </ul>",
        "solution_number": 46050302,
        "strategy": "Provide cues for writing in a straight line",
        "task": "Reading & Writing Documents",
        "trigger": "(a.C6) && (a.LOW) && (a.T4605)"
    },
    "46050401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Use inked stamps for signatures, etc.",
        "solution_long": "Use inked stamps for signatures, etc. Place a tactile mark on the stamp to show which way to position it.",
        "solution_number": 46050401,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12 || a.C6) && (a.LOW) && (a.T4605)"
    },
    "46050402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Use electronic signatures",
        "solution_long": "Use an electronic signature as an alternative to handwriting. <ul> <li>Use a laptop or tablet if a portable solution is needed.</li> </ul>",
        "solution_number": 46050402,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12 || a.C6) && (a.CMP || a.MOB) && (a.T4605)"
    },
    "46050403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Use an electronic device to type",
        "solution_long": "Use a computer, tablet, or notetaking device to write text or fill in forms converted into an electronic format.  Typing may be physically easier than writing by hand. <ul> <li>Use a laptop or tablet if a portable solution is needed.</li> </ul>",
        "solution_number": 46050403,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12 || a.C6) && (a.CMP || a.MOB) && (a.T4605)"
    },
    "46050404": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Get meeting notes in advance",
        "solution_long": "Get meeting agendas, etc. in advance to reduce the need to take notes.<ul> <li>Use notes that are in an electronic format if the employee has difficulty handling printouts, or seeing or reading text.</li> </ul>",
        "solution_number": 46050404,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12 || a.C6) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4605)"
    },
    "46050405": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Share meeting notes",
        "solution_long": "Have a designated person take notes during meetings and share them with attendees.<ul> <li>Use notes that are in an electronic format if the employee has difficulty handling printouts, or seeing or reading text.</li> </ul>",
        "solution_number": 46050405,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12 || a.C6) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4605)"
    },
    "46050406": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Writing is not legible",
        "solution": "Make notes with a voice assistant",
        "solution_long": "Use a voice assistant (e.g., Siri, Alexa) to make brief notes for later review. <ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46050406,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12 || a.C6) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.MOB) && (a.T4605)"
    },
    "46050407": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Writing is not legible",
        "solution": "Use speech recognition software",
        "solution_long": "Use speech recognition / speech to text software (e.g., Dragon Naturally Speaking) to dictate to the computer. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer. If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li> <li>The employee will need to read well enough to recognize and fix the computer's recognition errors.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46050407,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12 || a.C6) && (!a.S5) && (a.CMP || a.MOB) && (a.T4605)"
    },
    "46050408": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Make a sound recording of the text",
        "solution_long": "Dictate into a recorder to make a sound recording of the text.  Recordings may be played back later for quick review or can be transcribed. <ul><wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1> <li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul>",
        "solution_number": 46050408,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12 || a.C6) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.MOB) && (a.T4605)"
    },
    "46050409": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Record instructions and meetings ",
        "solution_long": "Record coworkers' instructions and meetings as an alternative to taking notes.  Recordings may be played back later for quick review or can be transcribed. <ul> <li>Get permission from everyone involved before recording them.</li><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors OPN NSY><li>Use headphones when playing back recordings, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better. </li></wafactors></ul>",
        "solution_number": 46050409,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12 || a.C6) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4605)"
    },
    "46050410": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not legible",
        "solution": "Dictate to an assistant or coworker",
        "solution_long": "Dictate to an assistant or coworker.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance. <ul><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This solution might be difficult to use in a noisy workplace.</li></wafactors> <wafactors OPN NSY><li>Use headphones when playing back messages, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4><li>Make sure that the person providing assistance is able to understand the employee's speech. </li></wafactors> </ul>",
        "solution_number": 46050410,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12 || a.C6) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW) && (a.T4605)"
    },
    "46060101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Add a gripping aid",
        "solution_long": "Add a gripping aid to the pen or pencil to provide a softer, larger grip (e.g., pencil placed through a ball), and/or triangular grip.<ul><li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li></ul>",
        "solution_number": 46060101,
        "strategy": "Change the writing tool to relax grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4606)"
    },
    "46060102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product.",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Use an adapted pen or pencil",
        "solution_long": "Use an <a href=\"/glossary#adapted\" target=\"_blank\">adapted</a> pen or pencil that has a larger barrel and/or a softer or triangular grip.<ul><li>If this is a new accommodation, it may be helpful to work with an occupational therapist or AT expert to identify a specific product.</li></ul>",
        "solution_number": 46060102,
        "strategy": "Change the writing tool to relax grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11) && (a.LOW) && (a.T4606)"
    },
    "46060201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product.",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Use a grip-free writing tool",
        "solution_long": "Use an <a href=\"/glossary#adapted\" target=\"_blank\">adapted</a> writing tool that fits over a finger or other part of the hand to minimize the amount of grip needed.",
        "solution_number": 46060201,
        "strategy": "Use a writing tool that does not require grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T4606)"
    },
    "46060202": {
        "expertise_level": 2,
        "expertise_text": "Expert assitance: Might be helpful",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Seek occupational or physical therapy",
        "solution_long": "Consult with an occupational therapist or physical therapist about how the employee can write with wrist or arm movements rather than finger movements.",
        "solution_number": 46060202,
        "strategy": "Use a writing tool that does not require grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T4606)"
    },
    "46060203": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Strongly encouraged to make sure that inappropriate forces are not placed on the neck or teeth",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Use a writing tool on a mouthstick",
        "solution_long": "Use a pen or pencil that can be attached to the end of a mouthstick.",
        "solution_number": 46060203,
        "strategy": "Use a writing tool that does not require grip",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T4606)"
    },
    "46060301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Use inked stamps for signatures, etc.",
        "solution_long": "Use inked stamps for signatures, etc. Place a tactile mark on the stamp to show which way to position it.",
        "solution_number": 46060301,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.T4606)"
    },
    "46060302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Use electronic signatures",
        "solution_long": "Use an electronic signature as an alternative to handwriting. <ul> <li>Use a laptop or tablet if a portable solution is needed.</li> </ul>",
        "solution_number": 46060302,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.CMP || a.MOB) && (a.T4606)"
    },
    "46060303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Use an electronic device to type",
        "solution_long": "Use a computer, tablet, or notetaking device to write text or fill in forms converted into an electronic format.  Typing may be physically easier than writing by hand. <ul> <li>Use a laptop or tablet if a portable solution is needed.</li> </ul>",
        "solution_number": 46060303,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.CMP || a.MOB) && (a.T4606)"
    },
    "46060304": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Get meeting notes in advance",
        "solution_long": "Get meeting agendas, etc. in advance to reduce the need to take notes.<ul> <li>Use notes that are in an electronic format if the employee has difficulty handling printouts, or seeing or reading text.</li> </ul>",
        "solution_number": 46060304,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4606)"
    },
    "46060305": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Share meeting notes",
        "solution_long": "Have a designated person take notes during meetings and share them with attendees.<ul> <li>Use notes that are in an electronic format if the employee has difficulty handling printouts, or seeing or reading text.</li> </ul>",
        "solution_number": 46060305,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4606)"
    },
    "46060306": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Make notes with a voice assistant",
        "solution_long": "Use a voice assistant (e.g., Siri, Alexa) to make brief notes for later review. <ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46060306,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.MOB) && (a.T4606)"
    },
    "46060307": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Use speech recognition software",
        "solution_long": "Use speech recognition / speech to text software (e.g., Dragon Naturally Speaking) to dictate to the computer. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer. If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li> <li>The employee will need to read well enough to recognize and fix the computer's recognition errors.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46060307,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.S5) && (a.CMP || a.MOB) && (a.T4606)"
    },
    "46060308": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Make a sound recording of the text",
        "solution_long": "Dictate into a recorder to make a sound recording of the text.  Recordings may be played back later for quick review or can be transcribed. <ul><wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1> <li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul>",
        "solution_number": 46060308,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.MOB) && (a.T4606)"
    },
    "46060309": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Record instructions and meetings ",
        "solution_long": "Record coworkers' instructions and meetings as an alternative to taking notes.  Recordings may be played back later for quick review or can be transcribed. <ul> <li>Get permission from everyone involved before recording them.</li><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors OPN NSY><li>Use headphones when playing back recordings, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better. </li></wafactors></ul>",
        "solution_number": 46060309,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4606)"
    },
    "46060310": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Standard pen is too difficult to hold",
        "solution": "Dictate to an assistant or coworker",
        "solution_long": "Dictate to an assistant or coworker.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance. <ul><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This solution might be difficult to use in a noisy workplace.</li></wafactors> <wafactors OPN NSY><li>Use headphones when playing back messages, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4><li>Make sure that the person providing assistance is able to understand the employee's speech. </li></wafactors> </ul>",
        "solution_number": 46060310,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW) && (a.T4606)"
    },
    "46070101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting is too slow",
        "solution": "Use an electronic device to type",
        "solution_long": "Use a computer, tablet, or notetaking device to write text or fill in forms converted into an electronic format.  Typing may be physically easier than writing by hand. <ul> <li>Use a laptop or tablet if a portable solution is needed.</li> </ul>",
        "solution_number": 46070101,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.CMP || a.MOB) && (a.T4607)"
    },
    "46070102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting is too slow",
        "solution": "Get meeting notes in advance",
        "solution_long": "Get meeting agendas, etc. in advance to reduce the need to take notes.<ul> <li>Use notes that are in an electronic format if the employee has difficulty handling printouts, or seeing or reading text.</li> </ul>",
        "solution_number": 46070102,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4607)"
    },
    "46070103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting is too slow",
        "solution": "Share meeting notes",
        "solution_long": "Have a designated person take notes during meetings and share them with attendees.<ul> <li>Use notes that are in an electronic format if the employee has difficulty handling printouts, or seeing or reading text.</li> </ul>",
        "solution_number": 46070103,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (a.LOW) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4607)"
    },
    "46070104": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Handwriting is too slow",
        "solution": "Make notes with a voice assistant",
        "solution_long": "Use a voice assistant (e.g., Siri, Alexa) to make brief notes for later review. <ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46070104,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.MOB) && (a.T4607)"
    },
    "46070105": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Handwriting is too slow",
        "solution": "Use speech recognition software",
        "solution_long": "Use speech recognition / speech to text software (e.g., Dragon Naturally Speaking) to dictate to the computer. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer. If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li> <li>The employee will need to read well enough to recognize and fix the computer's recognition errors.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46070105,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.S5) && (a.CMP || a.MOB) && (a.T4607)"
    },
    "46070106": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting is too slow",
        "solution": "Make a sound recording of the text",
        "solution_long": "Dictate into a recorder to make a sound recording of the text.  Recordings may be played back later for quick review or can be transcribed. <ul><wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1> <li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul>",
        "solution_number": 46070106,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.MOB) && (a.T4607)"
    },
    "46070107": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting is too slow",
        "solution": "Record instructions and meetings ",
        "solution_long": "Record coworkers' instructions and meetings as an alternative to taking notes.  Recordings may be played back later for quick review or can be transcribed. <ul> <li>Get permission from everyone involved before recording them.</li><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors OPN NSY><li>Use headphones when playing back recordings, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better. </li></wafactors></ul>",
        "solution_number": 46070107,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4607)"
    },
    "46070108": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Handwriting is too slow",
        "solution": "Dictate to an assistant or coworker",
        "solution_long": "Dictate to an assistant or coworker.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance. <ul><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This solution might be difficult to use in a noisy workplace.</li></wafactors> <wafactors OPN NSY><li>Use headphones when playing back messages, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4><li>Make sure that the person providing assistance is able to understand the employee's speech. </li></wafactors> </ul>",
        "solution_number": 46070108,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.U10 || a.U11 || a.U12) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW) && (a.T4607)"
    },
    "46080101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Use typed, not handwritten, documents",
        "solution_long": "Use typed documents to avoid difficulty with reading handwriting.",
        "solution_number": 46080101,
        "strategy": "Change the text style",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.LOW) && (a.T4608)"
    },
    "46080102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Use a sans serif font (e.g., Arial)",
        "solution_long": "Format documents so that they use a fixed-width, sans serif font (e.g., Arial, Helvetica, Verdana). <ul> <li>Many people with low vision or learning disabilities read slower when using fonts with serifs (e.g., Times New Roman).</li> </ul>",
        "solution_number": 46080102,
        "strategy": "Change the text style",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.LOW) && (a.T4608)"
    },
    "46080201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Make a large print version of document",
        "solution_long": "Make a large print version of documents.  Use an 18 point or larger sans serif font (e.g., Arial). ",
        "solution_number": 46080201,
        "strategy": "Increase text / graphic size",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.LOW) && (a.T4608)"
    },
    "46080202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Enlarge documents with a copier",
        "solution_long": "Use a copier to make enlarged versions of documents.  This option will also make graphics larger.",
        "solution_number": 46080202,
        "strategy": "Increase text / graphic size",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.LOW) && (a.T4608)"
    },
    "46080203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Use a marker for larger, bold text",
        "solution_long": "Use a marker for larger, bold text.",
        "solution_number": 46080203,
        "strategy": "Increase text / graphic size",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.LOW) && (a.T4608)"
    },
    "46080204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Use a handheld or desktop magnifier ",
        "solution_long": "Use a handheld or desktop magnifier. <ul><li>For employees that need higher levels of magnification, consultation with a low vision specialist may be helpful.</li></ul>",
        "solution_number": 46080204,
        "strategy": "Increase text / graphic size",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.LOW) && (a.T4608)"
    },
    "46080205": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Text is too small",
        "solution": "Use a video magnifier",
        "solution_long": "Use a video magnifier to magnify the document. <ul><li>This option also allows the employee to adjust the document colors.</li><wafactors PRT MLT><li>Portable video magnifiers are available.</li></wafactors> <wafactors MTL SHD><li>Full-sized systems have a large monitor and a base large enough to hold an open book.  While the large monitor is more efficient, these magnifiers take up a significant amount of the workstation space and are not easily moved.</li></wafactors></ul>",
        "solution_number": 46080205,
        "strategy": "Increase text / graphic size",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.LOW || a.MOB) && (a.T4608)"
    },
    "46080206": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Use e-document, enlarge with View/Zoom",
        "solution_long": "Use electronic documents, and use the View/Zoom features of the software to enlarge the document to make it easier to read. <ul><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors></ul>",
        "solution_number": 46080206,
        "strategy": "Increase text / graphic size",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.CMP) && (a.T4608)"
    },
    "46080207": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Adjust computer text settings",
        "solution_long": "Use electronic documents, and adjust the computer's operating system (OS) settings to display the document with the desired text size.<ul><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors></ul>",
        "solution_number": 46080207,
        "strategy": "Increase text / graphic size",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.CMP) && (a.T4608)"
    },
    "46080208": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Use dedicated magnification software  ",
        "solution_long": "Use electronic documents along with magnification software.  This is a preferred option for employees who need greater levels of magnification. <ul><li>This solution addresses both print access and computer access issues.</li> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/screen-magnification-desktops\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46080208,
        "strategy": "Increase text / graphic size",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.CMP) && (a.T4608)"
    },
    "46080301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Use recorded sound materials",
        "solution_long": "Use recorded sound materials.  For example, some instructional materials are available as digital recordings. <ul> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul>",
        "solution_number": 46080301,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.CMP || a.MOB) && (a.T4608)"
    },
    "46080302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Text is too small",
        "solution": "Use built-in screen reading features",
        "solution_long": "Use electronic documents and the screen reading features built into the computer (e.g., Narrator).<ul><li>This solution addresses both print access and computer access issues.</li> <li>If this is a new accommodation, the employee may need help setting up and learning the system.  Consultation with an AT expert might be helpful.</li><li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/screen-reading-desktops\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46080302,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP || a.MOB) && (a.T4608)"
    },
    "46080303": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Text is too small",
        "solution": "Use dedicated screen reading software",
        "solution_long": "Use electronic documents and dedicated screen reading software on the computer.<ul><li>This solution addresses both print access and computer access issues.</li> <li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul>",
        "solution_number": 46080303,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T4608)"
    },
    "46080304": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Text is too small",
        "solution": "Use mobile OCR to listen to document",
        "solution_long": "For small amounts of text (e.g. reading a single page) or when a portable solution is needed, use a mobile app that takes a picture of the page, uses optical character recognition (OCR) to convert it into an electronic file, and then reads it out loud.<ul> <li>The OCR accuracy may not be as good as that of desktop scanners, but may be more convenient for quick reading needs.</li><li>If this is a new accommodation, the employee may need help selecting the app, and setting up and learning the system.  Consultation with an AT expert might be helpful.</li><wafactors V3 V4 V5><li>Employees who are blind may have difficulty aiming the mobile device's camera at the text.  Try using a camera stand combined with a page guide to help with positioning.</li></wafactors> <wafactors OPN NSY><li>When listening to documents, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/ocr-reading-mobile-app\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46080304,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.MOB) && (a.T4608)"
    },
    "46080305": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Text is too small",
        "solution": "Use computer OCR to listen to document",
        "solution_long": "For larger amounts of text (e.g., multiple pages at a time), use a computer-based system that scans a paper document, uses optical character recognition (OCR) to convert it into an electronic file, and then uses screen reading to read it out loud. <ul> <li>These systems often offer additional tools for reviewing documents that screen reading doesn't provide (e.g., notetaking features), and they require much less training.</li> <li>This type of solution addresses print access, but not computer access.</li> <li>If this is a new accommodation, the employee may need help selecting the app, and setting up and learning the system.  Consultation with an AT expert might be helpful.</li><wafactors OPN NSY> <li>When listening to documents, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul>",
        "solution_number": 46080305,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T4608)"
    },
    "46080306": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Listen to text descriptions of graphics",
        "solution_long": "Provide a text description of graphical information (\"alternative text\" or \"Alt text\") that can be reviewed with screen reading.<ul> <wafactors OPN NSY><li>When listening to descriptions, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers. </li></wafactors> <wafactors PRT><li>Use a laptop if a portable solution is needed.</li> </wafactors></ul>",
        "solution_number": 46080306,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP || a.MOB) && (a.T4608)"
    },
    "46080307": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Have an assistant read materials",
        "solution_long": "Have an assistant or coworker read print materials out loud.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance.<ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li> </wafactors></ul>",
        "solution_number": 46080307,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW) && (a.T4608)"
    },
    "46080401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Use inked stamps for signatures, etc.",
        "solution_long": "Use inked stamps for signatures, etc. Place a tactile mark on the stamp to show which way to position it.",
        "solution_number": 46080401,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.LOW) && (a.T4608)"
    },
    "46080402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Use electronic signatures",
        "solution_long": "Use an electronic signature as an alternative to handwriting. <ul> <li>Use a laptop or tablet if a portable solution is needed.</li> </ul>",
        "solution_number": 46080402,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (a.CMP || a.MOB) && (a.T4608)"
    },
    "46080501": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Text is too small",
        "solution": "Make notes with a voice assistant",
        "solution_long": "Use a voice assistant (e.g., Siri, Alexa) to make brief notes for later review. <ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors></ul>",
        "solution_number": 46080501,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.MOB) && (a.T4608)"
    },
    "46080502": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Make a sound recording of the text",
        "solution_long": "Dictate into a recorder to make a sound recording of the text.  Recordings may be played back later for quick review or can be transcribed. <ul><wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1> <li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul>",
        "solution_number": 46080502,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.MOB) && (a.T4608)"
    },
    "46080503": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Record instructions and meetings ",
        "solution_long": "Record coworkers' instructions and meetings as an alternative to taking notes.  Recordings may be played back later for quick review or can be transcribed. <ul> <li>Get permission from everyone involved before recording them.</li><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors OPN NSY><li>Use headphones when playing back recordings, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better. </li></wafactors></ul>",
        "solution_number": 46080503,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4608)"
    },
    "46080504": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Text is too small",
        "solution": "Dictate to an assistant or coworker",
        "solution_long": "Dictate to an assistant or coworker.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance. <ul><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This solution might be difficult to use in a noisy workplace.</li></wafactors> <wafactors OPN NSY><li>Use headphones when playing back messages, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4><li>Make sure that the person providing assistance is able to understand the employee's speech. </li></wafactors> </ul>",
        "solution_number": 46080504,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW) && (a.T4608)"
    },
    "46090101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the page is visible",
        "solution": "Use e-document, shrink with View/Zoom",
        "solution_long": "Use electronic documents, and use the View/Zoom features of the software to shrink the document to make it fit within the field of vision. <ul><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors></ul>",
        "solution_number": 46090101,
        "strategy": "Decrease text / graphic size",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (a.CMP) && (a.T4609)"
    },
    "46090102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the page is visible",
        "solution": "Adjust computer text settings",
        "solution_long": "Use electronic documents, and adjust the computer's operating system (OS) settings to display the document with the desired text size.<ul><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors></ul>",
        "solution_number": 46090102,
        "strategy": "Decrease text / graphic size",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (a.CMP) && (a.T4609)"
    },
    "46090201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the page is visible",
        "solution": "Reposition the information",
        "solution_long": "Reposition the document, computer screen, or information on the screen so it falls within the field of vision.  For example, for a person who can only see things to his or her right, position an electronic document toward the right side of the display.",
        "solution_number": 46090201,
        "strategy": "Reposition the information",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (a.LOW || a.CMP) && (a.T4609)"
    },
    "46090301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the page is visible",
        "solution": "Use recorded sound materials",
        "solution_long": "Use recorded sound materials.  For example, some instructional materials are available as digital recordings. <ul> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul>",
        "solution_number": 46090301,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.CMP || a.MOB) && (a.T4609)"
    },
    "46090302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Only part of the page is visible",
        "solution": "Use built-in screen reading features",
        "solution_long": "Use electronic documents and the screen reading features built into the computer (e.g., Narrator).<ul><li>This solution addresses both print access and computer access issues.</li> <li>If this is a new accommodation, the employee may need help setting up and learning the system.  Consultation with an AT expert might be helpful.</li><li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/screen-reading-desktops\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46090302,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP || a.MOB) && (a.T4609)"
    },
    "46090303": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Only part of the page is visible",
        "solution": "Use dedicated screen reading software",
        "solution_long": "Use electronic documents and dedicated screen reading software on the computer.<ul><li>This solution addresses both print access and computer access issues.</li> <li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul>",
        "solution_number": 46090303,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T4609)"
    },
    "46090304": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Only part of the page is visible",
        "solution": "Use mobile OCR to listen to document",
        "solution_long": "For small amounts of text (e.g. reading a single page) or when a portable solution is needed, use a mobile app that takes a picture of the page, uses optical character recognition (OCR) to convert it into an electronic file, and then reads it out loud.<ul> <li>The OCR accuracy may not be as good as that of desktop scanners, but may be more convenient for quick reading needs.</li><li>If this is a new accommodation, the employee may need help selecting the app, and setting up and learning the system.  Consultation with an AT expert might be helpful.</li><wafactors V3 V4 V5><li>Employees who are blind may have difficulty aiming the mobile device's camera at the text.  Try using a camera stand combined with a page guide to help with positioning.</li></wafactors> <wafactors OPN NSY><li>When listening to documents, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/ocr-reading-mobile-app\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46090304,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (a.MOB) && (a.T4609)"
    },
    "46090305": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Only part of the page is visible",
        "solution": "Use computer OCR to listen to document",
        "solution_long": "For larger amounts of text (e.g., multiple pages at a time), use a computer-based system that scans a paper document, uses optical character recognition (OCR) to convert it into an electronic file, and then uses screen reading to read it out loud. <ul> <li>These systems often offer additional tools for reviewing documents that screen reading doesn't provide (e.g., notetaking features), and they require much less training.</li> <li>This type of solution addresses print access, but not computer access.</li> <li>If this is a new accommodation, the employee may need help selecting the app, and setting up and learning the system.  Consultation with an AT expert might be helpful.</li><wafactors OPN NSY> <li>When listening to documents, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul>",
        "solution_number": 46090305,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T4609)"
    },
    "46090306": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the page is visible",
        "solution": "Listen to text descriptions of graphics",
        "solution_long": "Provide a text description of graphical information (\"alternative text\" or \"Alt text\") that can be reviewed with screen reading.<ul> <wafactors OPN NSY><li>When listening to descriptions, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers. </li></wafactors> <wafactors PRT><li>Use a laptop if a portable solution is needed.</li> </wafactors></ul>",
        "solution_number": 46090306,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP || a.MOB) && (a.T4609)"
    },
    "46090307": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the page is visible",
        "solution": "Have an assistant read materials",
        "solution_long": "Have an assistant or coworker read print materials out loud.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance.<ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li> </wafactors></ul>",
        "solution_number": 46090307,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW) && (a.T4609)"
    },
    "46090308": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Only part of the page is visible",
        "solution": "Make notes with a voice assistant",
        "solution_long": "Use a voice assistant (e.g., Siri, Alexa) to make brief notes for later review. <ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors></ul>",
        "solution_number": 46090308,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.MOB) && (a.T4609)"
    },
    "46090309": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the page is visible",
        "solution": "Make a sound recording of the text",
        "solution_long": "Dictate into a recorder to make a sound recording of the text.  Recordings may be played back later for quick review or can be transcribed. <ul><wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1> <li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul>",
        "solution_number": 46090309,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.CMP || a.MOB) && (a.T4609)"
    },
    "46090310": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the page is visible",
        "solution": "Record instructions and meetings ",
        "solution_long": "Record coworkers' instructions and meetings as an alternative to taking notes.  Recordings may be played back later for quick review or can be transcribed. <ul> <li>Get permission from everyone involved before recording them.</li><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors OPN NSY><li>Use headphones when playing back recordings, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better. </li></wafactors></ul>",
        "solution_number": 46090310,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4609)"
    },
    "46090311": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the page is visible",
        "solution": "Dictate to an assistant or coworker",
        "solution_long": "Dictate to an assistant or coworker.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance. <ul><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This solution might be difficult to use in a noisy workplace.</li></wafactors> <wafactors OPN NSY><li>Use headphones when playing back messages, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4><li>Make sure that the person providing assistance is able to understand the employee's speech. </li></wafactors> </ul>",
        "solution_number": 46090311,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW) && (a.T4609)"
    },
    "46100101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Documents cannot be seen",
        "solution": "Use recorded sound materials",
        "solution_long": "Use recorded sound materials.  For example, some instructional materials are available as digital recordings. <ul> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul>",
        "solution_number": 46100101,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (!a.H5) && (a.LOW || a.CMP || a.MOB) && (a.T4610)"
    },
    "46100102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Documents cannot be seen",
        "solution": "Use built-in screen reading features",
        "solution_long": "Use electronic documents and the screen reading features built into the computer (e.g., Narrator).<ul><li>This solution addresses both print access and computer access issues.</li> <li>If this is a new accommodation, the employee may need help setting up and learning the system.  Consultation with an AT expert might be helpful.</li><li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/screen-reading-desktops\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46100102,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (!a.H5) && (a.CMP || a.MOB) && (a.T4610)"
    },
    "46100103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Documents cannot be seen",
        "solution": "Use dedicated screen reading software",
        "solution_long": "Use electronic documents and dedicated screen reading software on the computer.<ul><li>This solution addresses both print access and computer access issues.</li> <li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/screen-reading-desktops\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46100103,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (!a.H5) && (a.CMP || a.MOB) && (a.T4610)"
    },
    "46100104": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Documents cannot be seen",
        "solution": "Use mobile OCR to listen to document",
        "solution_long": "For small amounts of text (e.g. reading a single page) or when a portable solution is needed, use a mobile app that takes a picture of the page, uses optical character recognition (OCR) to convert it into an electronic file, and then reads it out loud.<ul> <li>The OCR accuracy may not be as good as that of desktop scanners, but may be more convenient for quick reading needs.</li><li>If this is a new accommodation, the employee may need help selecting the app, and setting up and learning the system.  Consultation with an AT expert might be helpful.</li><wafactors V3 V4 V5><li>Employees who are blind may have difficulty aiming the mobile device's camera at the text.  Try using a camera stand combined with a page guide to help with positioning.</li></wafactors> <wafactors OPN NSY><li>When listening to documents, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/ocr-reading-mobile-app\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46100104,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (!a.H5) && (a.MOB) && (a.T4610)"
    },
    "46100105": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Documents cannot be seen",
        "solution": "Use computer OCR to listen to document",
        "solution_long": "For larger amounts of text (e.g., multiple pages at a time), use a computer-based system that scans a paper document, uses optical character recognition (OCR) to convert it into an electronic file, and then uses screen reading to read it out loud. <ul> <li>These systems often offer additional tools for reviewing documents that screen reading doesn't provide (e.g., notetaking features), and they require much less training.</li> <li>This type of solution addresses print access, but not computer access.</li> <li>If this is a new accommodation, the employee may need help selecting the app, and setting up and learning the system.  Consultation with an AT expert might be helpful.</li><wafactors OPN NSY> <li>When listening to documents, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul>",
        "solution_number": 46100105,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (!a.H5) && (a.CMP) && (a.T4610)"
    },
    "46100106": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Documents cannot be seen",
        "solution": "Listen to text descriptions of graphics",
        "solution_long": "Provide a text description of graphical information (\"alternative text\" or \"Alt text\") that can be reviewed with screen reading.<ul> <wafactors OPN NSY><li>When listening to descriptions, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers. </li></wafactors> <wafactors PRT><li>Use a laptop if a portable solution is needed.</li> </wafactors></ul>",
        "solution_number": 46100106,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (!a.H5) && (a.CMP || a.MOB) && (a.T4610)"
    },
    "46100107": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Documents cannot be seen",
        "solution": "Have an assistant read materials",
        "solution_long": "Have an assistant or coworker read print materials out loud.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance.<ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li> </wafactors></ul>",
        "solution_number": 46100107,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (!a.H5) && (a.LOW) && (a.T4610)"
    },
    "46100201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Documents cannot be seen",
        "solution": "Use inked stamps for signatures, etc.",
        "solution_long": "Use inked stamps for signatures, etc. Place a tactile mark on the stamp to show which way to position it.",
        "solution_number": 46100201,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5) && (a.LOW) && (a.T4610)"
    },
    "46100202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Documents cannot be seen",
        "solution": "Use electronic signatures",
        "solution_long": "Use an electronic signature as an alternative to handwriting. <ul> <li>Use a laptop or tablet if a portable solution is needed.</li> </ul>",
        "solution_number": 46100202,
        "strategy": "Communicate without writing by hand",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5) && (a.CMP || a.MOB) && (a.T4610)"
    },
    "46100301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Documents cannot be seen",
        "solution": "Make notes with a voice assistant",
        "solution_long": "Use a voice assistant (e.g., Siri, Alexa) to make brief notes for later review. <ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors></ul>",
        "solution_number": 46100301,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.MOB) && (a.T4610)"
    },
    "46100302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Documents cannot be seen",
        "solution": "Make a sound recording of the text",
        "solution_long": "Dictate into a recorder to make a sound recording of the text.  Recordings may be played back later for quick review or can be transcribed. <ul><wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1> <li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul>",
        "solution_number": 46100302,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.CMP || a.MOB) && (a.T4610)"
    },
    "46100303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Documents cannot be seen",
        "solution": "Record instructions and meetings ",
        "solution_long": "Record coworkers' instructions and meetings as an alternative to taking notes.  Recordings may be played back later for quick review or can be transcribed. <ul> <li>Get permission from everyone involved before recording them.</li><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors OPN NSY><li>Use headphones when playing back recordings, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better. </li></wafactors></ul>",
        "solution_number": 46100303,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4610)"
    },
    "46100304": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Documents cannot be seen",
        "solution": "Dictate to an assistant or coworker",
        "solution_long": "Dictate to an assistant or coworker.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance. <ul><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This solution might be difficult to use in a noisy workplace.</li></wafactors> <wafactors OPN NSY><li>Use headphones when playing back messages, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4><li>Make sure that the person providing assistance is able to understand the employee's speech. </li></wafactors> </ul>",
        "solution_number": 46100304,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW) && (a.T4610)"
    },
    "46100401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Documents cannot be seen",
        "solution": "Use printed Braille materials",
        "solution_long": "Use Braille materials that are produced in-house or that are ordered from an external source.  If produced in-house, use electronic documents, a Braille conversion program, and a Braille embosser. <ul><li>Not everyone who is blind knows Braille.  Make sure that the employee is comfortable using Braille.</li></ul>",
        "solution_number": 46100401,
        "strategy": "Present the information through touch",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T4610)"
    },
    "46100402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Documents cannot be seen",
        "solution": "Write with a Braille slate and stylus",
        "solution_long": "Use a Braille slate and stylus to write braille on a notecard.<ul><li>Not everyone who is blind knows Braille.  Make sure that the employee is comfortable using Braille.</li></ul>",
        "solution_number": 46100402,
        "strategy": "Present the information through touch",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T4610)"
    },
    "46100403": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Documents cannot be seen",
        "solution": "Use a refreshable Braille display",
        "solution_long": "Use electronic documents, accessed with a refreshable Braille display.<ul><li>Not everyone who is blind knows Braille.  Make sure that the employee is comfortable using Braille.</li> <li>Refreshable Braille displays tend to be expensive, so if the person has not previously used this software, consultation with an assistive technology specialist may be helpful.</li></ul>",
        "solution_number": 46100403,
        "strategy": "Present the information through touch",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.CMP) && (a.T4610)"
    },
    "46100404": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Documents cannot be seen",
        "solution": "Use a Braille embosser",
        "solution_long": "Create tactile graphics by using a Braille embosser capable of producing raised lines and textures that the employee can feel.<ul><li>Not everyone who is blind knows Braille.  Make sure that the employee is comfortable using Braille.</li> <wafactors OPN><li>This technology can be noisy and might disturb other workers if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors>",
        "solution_number": 46100404,
        "strategy": "Present the information through touch",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T4610)"
    },
    "46113101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing areas (form blanks) are not visible",
        "solution": "Use a template with cutouts for writing",
        "solution_long": "Use a template with cutouts for writing areas that can be felt.",
        "solution_number": 46113101,
        "strategy": "Provide a tactile guide for writing",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T4611)"
    },
    "46113102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing areas (form blanks) are not visible",
        "solution": "Mark writing boundaries with tape",
        "solution_long": "Use clear or removable tape to mark writing boundaries that can be felt.",
        "solution_number": 46113102,
        "strategy": "Provide a tactile guide for writing",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW) && (a.T4611)"
    },
    "46113201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing areas (form blanks) are not visible",
        "solution": "Use an electronic version of the form",
        "solution_long": "Use an electronic version of the form.  Review and fill it out with the help of a screen reader or other access method.<ul> <wafactors OPN NSY><li>If using a screen reader, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors></ul>",
        "solution_number": 46113201,
        "strategy": "Present the information with a different format  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.CMP) && (a.T4611)"
    },
    "46113301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing areas (form blanks) are not visible",
        "solution": "Dictate to an assistant or coworker",
        "solution_long": "Dictate to an assistant or coworker.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance. <ul><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This solution might be difficult to use in a noisy workplace.</li></wafactors> <wafactors OPN NSY><li>Use headphones when playing back messages, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4><li>Make sure that the person providing assistance is able to understand the employee's speech. </li></wafactors> </ul>",
        "solution_number": 46113301,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.V3 || a.V4 || a.V5) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW) && (a.T4611)"
    },
    "46120101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use typed, not handwritten, documents",
        "solution_long": "Use typed documents to avoid difficulty with reading handwriting.",
        "solution_number": 46120101,
        "strategy": "Change the text style",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T4612)"
    },
    "46120102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use a sans serif font (e.g., Arial)",
        "solution_long": "Format documents so that they use a fixed-width, sans serif font (e.g., Arial, Helvetica, Verdana). <ul> <li>Many people with low vision or learning disabilities read slower when using fonts with serifs (e.g., Times New Roman).</li> </ul>",
        "solution_number": 46120102,
        "strategy": "Change the text style",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T4612)"
    },
    "46120201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use a handheld scanner with dictionary",
        "solution_long": "Use a handheld scanner with dictionary (e.g., Reading Pen) to look up specific words while reading. <ul> <li>The employee must aim and slide the device over the desired word, which might be difficult for some people with vision or motor issues. </li> </ul>",
        "solution_number": 46120201,
        "strategy": "Look up unfamiliar words",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T4612)"
    },
    "46120202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use speech-to-text to look up words",
        "solution_long": "Use a speech-to-text app to look up words on a mobile device. <ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace. </li></wafactors> <wafactors S2 S3 S4><li>This technology might have difficulty recognizing non-typical speech.</li></wafactors>",
        "solution_number": 46120202,
        "strategy": "Look up unfamiliar words",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.S5) && (a.MOB) && (a.T4612)"
    },
    "46120203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use a dictionary app or website",
        "solution_long": "Look up unfamiliar or hard-to-spell words with a dictionary app or website.<ul><wafactors PRT><li> Use a mobile phone, laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 46120203,
        "strategy": "Look up unfamiliar words",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T4612)"
    },
    "46120204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use a talking electronic dictionary",
        "solution_long": "Look up unfamiliar or hard-to-spell words with a talking electronic dictionary.<ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace. </li></wafactors> </ul>",
        "solution_number": 46120204,
        "strategy": "Look up unfamiliar words",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW) && (a.T4612)"
    },
    "46120205": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use a job-specific word/phrase bank",
        "solution_long": "Look up unfamiliar or hard-to-spell words with a job-specific word/phrase bank. <ul> <li>The employee may need assistance with setting up the word list. </li></ul>",
        "solution_number": 46120205,
        "strategy": "Look up unfamiliar words",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T4612)"
    },
    "46120206": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use a visual dictionary or app ",
        "solution_long": "Look up words with a general visual dictionary app (e.g., Oxford Picture Dictionary, Kids Picture Dictionary) or a job-specific visual dictionary.",
        "solution_number": 46120206,
        "strategy": "Look up unfamiliar words",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.CMP || a.MOB) && (a.T4612)"
    },
    "46120301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use a thesaurus",
        "solution_long": "Use a thesaurus.  An app or website might be used (e.g., WordHippo), or many word processing programs have a built-in thesaurus feature.",
        "solution_number": 46120301,
        "strategy": "Provide word suggestions",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T4612)"
    },
    "46120302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use partially-filled writing templates",
        "solution_long": "Use templates with some text already provided (e.g., email templates). <ul><li>The employee may need assistance with setting up the templates.</li></ul>",
        "solution_number": 46120302,
        "strategy": "Provide word suggestions",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP) && (a.T4612)"
    },
    "46120303": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use word prediction/completion software",
        "solution_long": "Use a computer with word prediction/ completion software, which offers choices of words based on the first few letters that are typed. <ul> <li> This feature may also be built into text-to-speech and talking word processor programs.</li> <li>The employee will need to read well enough to recognize and select the desired word.</li><li>The employee may need help identifying appropriate software if this is a new accommodation.  If this is the case, or if multiple problems are being addressed, then consultation with an AT expert might be helpful.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> </ul>",
        "solution_number": 46120303,
        "strategy": "Provide word suggestions",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T4612)"
    },
    "46120304": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use built-in auto-correction settings",
        "solution_long": "Use built-in auto-correction settings (e.g., Microsoft Autocorrect, Apple Proofing) to correct spelling.  Phrases or blocks of text can also be programmed in and can be inserted with a few keystrokes or an abbreviation. <ul> <li>If this is a new accommodation, an AT expert may be able to help adjust the computer settings and program phrases that fit the job.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors> </ul>",
        "solution_number": 46120304,
        "strategy": "Provide word suggestions",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T4612)"
    },
    "46120305": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use a virtual keyboard with prompts",
        "solution_long": "Use a virtual keyboard that uses word completion technology to provide next-letter suggestions.  Only those next letters that make words are active. <ul> <li>The employee may need help identifying appropriate software if this is a new accommodation.  If this is the case, or if multiple problems are being addressed, then consultation with an AT expert might be helpful.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 46120305,
        "strategy": "Provide word suggestions",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.CMP || a.MOB) && (a.T4612)"
    },
    "46120401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use recorded sound materials",
        "solution_long": "Use recorded sound materials.  For example, some instructional materials are available as digital recordings. <ul> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul>",
        "solution_number": 46120401,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.CMP || a.MOB) && (a.T4612)"
    },
    "46120402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use built-in screen reading features",
        "solution_long": "Use electronic documents and the screen reading features built into the computer (e.g., Narrator).<ul><li>This solution addresses both print access and computer access issues.</li> <li>If this is a new accommodation, the employee may need help setting up and learning the system.  Consultation with an AT expert might be helpful.</li><li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul>",
        "solution_number": 46120402,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP || a.MOB) && (a.T4612)"
    },
    "46120403": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use text-to-speech software",
        "solution_long": "Use a text-to-speech or talking word processor program on the computer.  The Employee can listen to the text as it is written to see if it sounds right.  Look for software with word-by-word highlighting as the content/words are read aloud. <ul> <li>Other features to look for include adjustable reading rate, font size adjustment, and choice of voices.</li><li>If this is a new accommodation, the employee may need help selecting software, and setting up and learning the system.  Consultation with an AT expert might be helpful.</li> <li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/reading-writing-for-learning-disabilities\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46120403,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP || a.MOB) && (a.T4612)"
    },
    "46120404": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use mobile OCR to listen to document",
        "solution_long": "For small amounts of text (e.g. reading a single page) or when a portable solution is needed, use a mobile app that takes a picture of the page, uses optical character recognition (OCR) to convert it into an electronic file, and then reads it out loud.<ul> <li>The OCR accuracy may not be as good as that of desktop scanners, but may be more convenient for quick reading needs.</li><li>If this is a new accommodation, the employee may need help selecting the app, and setting up and learning the system.  Consultation with an AT expert might be helpful.</li><wafactors V3 V4 V5><li>Employees who are blind may have difficulty aiming the mobile device's camera at the text.  Try using a camera stand combined with a page guide to help with positioning.</li></wafactors> <wafactors OPN NSY><li>When listening to documents, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/ocr-reading-mobile-app\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 46120404,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (a.MOB) && (a.T4612)"
    },
    "46120405": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use computer OCR to listen to document",
        "solution_long": "For larger amounts of text (e.g., multiple pages at a time), use a computer-based system that scans a paper document, uses optical character recognition (OCR) to convert it into an electronic file, and then uses screen reading to read it out loud. <ul> <li>These systems often offer additional tools for reviewing documents that screen reading doesn't provide (e.g., notetaking features), and they require much less training.</li> <li>This type of solution addresses print access, but not computer access.</li> <li>If this is a new accommodation, the employee may need help selecting the app, and setting up and learning the system.  Consultation with an AT expert might be helpful.</li><wafactors OPN NSY> <li>When listening to documents, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul>",
        "solution_number": 46120405,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T4612)"
    },
    "46120406": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Listen to text descriptions of graphics",
        "solution_long": "Provide a text description of graphical information (\"alternative text\" or \"Alt text\") that can be reviewed with screen reading.<ul> <wafactors OPN NSY><li>When listening to descriptions, use headphones to block competing noise, maintain privacy, and avoid disturbing other workers. </li></wafactors> <wafactors PRT><li>Use a laptop if a portable solution is needed.</li> </wafactors></ul>",
        "solution_number": 46120406,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP || a.MOB) && (a.T4612)"
    },
    "46120407": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Have an assistant read materials",
        "solution_long": "Have an assistant or coworker read print materials out loud.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance.<ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li> </wafactors></ul>",
        "solution_number": 46120407,
        "strategy": "Present the information with sound/speech",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW) && (a.T4612)"
    },
    "46120408": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Make notes with a voice assistant",
        "solution_long": "Use a voice assistant (e.g., Siri, Alexa) to make brief notes for later review. <ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech.</li> </wafactors></ul>",
        "solution_number": 46120408,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.MOB) && (a.T4612)"
    },
    "46120409": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use speech recognition software",
        "solution_long": "Use speech recognition / speech to text software (e.g., Dragon Naturally Speaking) to dictate to the computer. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer. If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li> <li>The employee will need to read well enough to recognize and fix the computer's recognition errors.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul>",
        "solution_number": 46120409,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.S5) && (a.CMP || a.MOB) && (a.T4612)"
    },
    "46120410": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Make a sound recording of the text",
        "solution_long": "Dictate into a recorder to make a sound recording of the text.  Recordings may be played back later for quick review or can be transcribed. <ul><wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better.</li></wafactors><wafactors S1> <li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul>",
        "solution_number": 46120410,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW || a.CMP || a.MOB) && (a.T4612)"
    },
    "46120411": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty with literacy",
        "solution": "Record instructions and meetings ",
        "solution_long": "Record coworkers' instructions and meetings as an alternative to taking notes.  Recordings may be played back later for quick review or can be transcribed. <ul> <li>Get permission from everyone involved before recording them.</li><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors OPN NSY><li>Use headphones when playing back recordings, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors H2 H3 H4><li>Make sure that the employee is able to hear and understand the information. A solution that uses another communication format (e.g., text, touch) might work better. </li></wafactors></ul>",
        "solution_number": 46120411,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW || a.MOB) && (a.SML || a.LRG) && (a.ONS || a.REM) && (a.T4612)"
    },
    "46120412": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Dictate to an assistant or coworker",
        "solution_long": "Dictate to an assistant or coworker.  Make sure that supervisors approve, and that the coworker is willing to provide this assistance. <ul><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li></wafactors> <wafactors NSY><li>This solution might be difficult to use in a noisy workplace.</li></wafactors> <wafactors OPN NSY><li>Use headphones when playing back messages, to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4><li>Make sure that the person providing assistance is able to understand the employee's speech. </li></wafactors> </ul>",
        "solution_number": 46120412,
        "strategy": "Present the information with sound/speech  ",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (!a.S5) && (a.LOW) && (a.T4612)"
    },
    "46120501": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Replace text with pictures",
        "solution_long": "Use pictures (e.g., photos, graphics) to communicate information.  For example, instructions might be presented as a series of photos showing each step. <ul> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> </ul>",
        "solution_number": 46120501,
        "strategy": "Present the information with pictures",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW || a.CMP || a.MOB) && (a.T4612)"
    },
    "46120502": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty with literacy",
        "solution": "Use software to write with pictures",
        "solution_long": "Use a symbol-based word processing program that allows selection of images to create a sentence (e.g., Clicker 8, PixWriter, SymWriter). <ul><li> If this is a new accommodation, consultation with an AT expert or Speech-Language Pathologist (SLP) is strongly recommended.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 46120502,
        "strategy": "Present the information with pictures",
        "task": "Reading & Writing Documents",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.CMP) && (a.T4612)"
    },
    "47010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing contains misspellings or grammar mistakes",
        "solution": "Use a dictionary app or website",
        "solution_long": "Look up unfamiliar or hard-to-spell words with a dictionary app or website.<ul><wafactors PRT><li> Use a mobile phone, laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 47010101,
        "strategy": "Look up unfamiliar words",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T4701)"
    },
    "47010102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing contains misspellings or grammar mistakes",
        "solution": "Use a talking electronic dictionary",
        "solution_long": "Look up unfamiliar or hard-to-spell words with a talking electronic dictionary.<ul> <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.</li> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace. </li></wafactors> </ul>",
        "solution_number": 47010102,
        "strategy": "Look up unfamiliar words",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.H1 || a.H2 || a.H3 || a.H4 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V6) && (!a.H5) && (!a.H6) && (a.LOW) && (a.T4701)"
    },
    "47010103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing contains misspellings or grammar mistakes",
        "solution": "Use a job-specific word/phrase bank",
        "solution_long": "Look up unfamiliar or hard-to-spell words with a job-specific word/phrase bank. <ul> <li>The employee may need assistance with setting up the word list. </li></ul>",
        "solution_number": 47010103,
        "strategy": "Look up unfamiliar words",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T4701)"
    },
    "47010104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing contains misspellings or grammar mistakes",
        "solution": "Use a visual dictionary or app ",
        "solution_long": "Look up words with a general visual dictionary app (e.g., Oxford Picture Dictionary, Kids Picture Dictionary) or a job-specific visual dictionary.",
        "solution_number": 47010104,
        "strategy": "Look up unfamiliar words",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW || a.CMP || a.MOB) && (a.T4701)"
    },
    "47010201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing contains misspellings or grammar mistakes",
        "solution": "Use a thesaurus",
        "solution_long": "Use a thesaurus.  An app or website might be used (e.g., WordHippo), or many word processing programs have a built-in thesaurus feature.",
        "solution_number": 47010201,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T4701)"
    },
    "47010202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing contains misspellings or grammar mistakes",
        "solution": "Use partially-filled writing templates",
        "solution_long": "Use templates with some text already provided (e.g., email templates). <ul><li>The employee may need assistance with setting up the templates.</li></ul>",
        "solution_number": 47010202,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP) && (a.T4701)"
    },
    "47010203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Writing contains misspellings or grammar mistakes",
        "solution": "Use word prediction/completion software",
        "solution_long": "Use a computer with word prediction/ completion software, which offers choices of words based on the first few letters that are typed. <ul> <li> This feature may also be built into text-to-speech and talking word processor programs.</li> <li>The employee will need to read well enough to recognize and select the desired word.</li><li>The employee may need help identifying appropriate software if this is a new accommodation.  If this is the case, or if multiple problems are being addressed, then consultation with an AT expert might be helpful.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> </ul>",
        "solution_number": 47010203,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T4701)"
    },
    "47010204": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Writing contains misspellings or grammar mistakes",
        "solution": "Use built-in auto-correction settings",
        "solution_long": "Use built-in auto-correction settings (e.g., Microsoft Autocorrect, Apple Proofing) to correct spelling.  Phrases or blocks of text can also be programmed in and can be inserted with a few keystrokes or an abbreviation. <ul> <li>If this is a new accommodation, an AT expert may be able to help adjust the computer settings and program phrases that fit the job.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors> </ul>",
        "solution_number": 47010204,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T4701)"
    },
    "47010205": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Writing contains misspellings or grammar mistakes",
        "solution": "Use a virtual keyboard with prompts",
        "solution_long": "Use a virtual keyboard that uses word completion technology to provide next-letter suggestions.  Only those next letters that make words are active. <ul> <li>The employee may need help identifying appropriate software if this is a new accommodation.  If this is the case, or if multiple problems are being addressed, then consultation with an AT expert might be helpful.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 47010205,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.CMP || a.MOB) && (a.T4701)"
    },
    "47010206": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Writing contains misspellings or grammar mistakes",
        "solution": "Use speech recognition software",
        "solution_long": "Use speech recognition / speech to text software (e.g., Dragon Naturally Speaking) to dictate to the computer. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer. If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li> <li>The employee will need to read well enough to recognize and fix the computer's recognition errors.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors><wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li></wafactors><wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors><wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors><wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors><wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul>",
        "solution_number": 47010206,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.S5) && (a.CMP || a.MOB) && (a.T4701)"
    },
    "47010301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Writing contains misspellings or grammar mistakes",
        "solution": "Use text-to-speech software",
        "solution_long": "Use a text-to-speech or talking word processor program on the computer.  The Employee can listen to the text as it is written to see if it sounds right.  Look for software with word-by-word highlighting as the content/words are read aloud. <ul> <li>Other features to look for include adjustable reading rate, font size adjustment, and choice of voices.</li><li>If this is a new accommodation, the employee may need help selecting software, and setting up and learning the system.  Consultation with an AT expert might be helpful.</li> <li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors></ul>",
        "solution_number": 47010301,
        "strategy": "Present the information with sound/speech",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T4701)"
    },
    "47020101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty thinking of the right words to use",
        "solution": "Use a job-specific word/phrase bank",
        "solution_long": "Look up unfamiliar or hard-to-spell words with a job-specific word/phrase bank. <ul> <li>The employee may need assistance with setting up the word list. </li></ul>",
        "solution_number": 47020101,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(a.S1 || a.C2) && (a.LOW || a.CMP || a.MOB) && (a.T4702)"
    },
    "47020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty thinking of the right words to use",
        "solution": "Use a visual dictionary or app ",
        "solution_long": "Look up words with a general visual dictionary app (e.g., Oxford Picture Dictionary, Kids Picture Dictionary) or a job-specific visual dictionary.",
        "solution_number": 47020102,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(!a.V5) && (!a.V6) && (!a.H6) && (a.S1 || a.C2) && (a.LOW || a.CMP || a.MOB) && (a.T4702)"
    },
    "47020103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty thinking of the right words to use",
        "solution": "Use a thesaurus",
        "solution_long": "Use a thesaurus.  An app or website might be used (e.g., WordHippo), or many word processing programs have a built-in thesaurus feature.",
        "solution_number": 47020103,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(a.S1 || a.C2) && (a.LOW || a.CMP || a.MOB) && (a.T4702)"
    },
    "47020104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Employee has difficulty thinking of the right words to use",
        "solution": "Use partially-filled writing templates",
        "solution_long": "Use templates with some text already provided (e.g., email templates). <ul><li>The employee may need assistance with setting up the templates.</li></ul>",
        "solution_number": 47020104,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(a.S1 || a.C2) && (a.LOW || a.CMP) && (a.T4702)"
    },
    "47020105": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty thinking of the right words to use",
        "solution": "Use word prediction/completion software",
        "solution_long": "Use a computer with word prediction/ completion software, which offers choices of words based on the first few letters that are typed. <ul> <li> This feature may also be built into text-to-speech and talking word processor programs.</li> <li>The employee will need to read well enough to recognize and select the desired word.</li><li>The employee may need help identifying appropriate software if this is a new accommodation.  If this is the case, or if multiple problems are being addressed, then consultation with an AT expert might be helpful.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> </ul>",
        "solution_number": 47020105,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(a.S1 || a.C2) && (a.CMP || a.MOB) && (a.T4702)"
    },
    "47020106": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty thinking of the right words to use",
        "solution": "Use built-in auto-correction settings",
        "solution_long": "Use built-in auto-correction settings (e.g., Microsoft Autocorrect, Apple Proofing) to correct spelling.  Phrases or blocks of text can also be programmed in and can be inserted with a few keystrokes or an abbreviation. <ul> <li>If this is a new accommodation, an AT expert may be able to help adjust the computer settings and program phrases that fit the job.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors> </ul>",
        "solution_number": 47020106,
        "strategy": "Provide word suggestions",
        "task": "Composing Text",
        "trigger": "(a.S1 || a.C2) && (a.CMP || a.MOB) && (a.T4702)"
    },
    "47020201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty thinking of the right words to use",
        "solution": "Use software to write with pictures",
        "solution_long": "Use a symbol-based word processing program that allows selection of images to create a sentence (e.g., Clicker 8, PixWriter, SymWriter). <ul><li> If this is a new accommodation, consultation with an AT expert or Speech-Language Pathologist (SLP) is strongly recommended.</li><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 47020201,
        "strategy": "Present the information with pictures",
        "task": "Composing Text",
        "trigger": "(!a.V5) && (!a.V6) && (!a.H6) && (a.S1 || a.C2) && (a.CMP) && (a.T4702)"
    },
    "47020202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Employee has difficulty thinking of the right words to use",
        "solution": "Use an AAC device to send text to computer",
        "solution_long": "Use a graphic-based AAC device to create sentences; use the AAC device to send text to computer. <ul><li>If this is a new accommodation, consultation with an AT expert or Speech-Language Pathologist (SLP) is strongly recommended.</li></ul>",
        "solution_number": 47020202,
        "strategy": "Present the information with pictures",
        "task": "Composing Text",
        "trigger": "(a.S1 || a.C2) && (a.LOW || a.CMP || a.MOB) && (a.T4702)"
    },
    "47030101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not organized",
        "solution": "Type instead of writing by hand",
        "solution_long": "Use a computer in place of writing by hand.  This allows for easier edits and the rearrangement of ideas. <ul> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors></ul>",
        "solution_number": 47030101,
        "strategy": "Provide a structure for writing",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP) && (a.T4703)"
    },
    "47030102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not organized",
        "solution": "Use writing templates or checklists",
        "solution_long": "Use templates with some text already provided (e.g., email templates) or checklists for standard documents to make sure all of the needed information is included and in the desired order.<ul> <li>The employee may need assistance with setting up the templates.</li> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> </ul>",
        "solution_number": 47030102,
        "strategy": "Provide a structure for writing",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW || a.CMP || a.MOB) && (a.T4703)"
    },
    "47030103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Writing is not organized",
        "solution": "Create an outline with paper notes",
        "solution_long": "Use sortable notecards or post-its with points to include to create an outline for the document.",
        "solution_number": 47030103,
        "strategy": "Provide a structure for writing",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T4703)"
    },
    "47030104": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Writing is not organized",
        "solution": "Use software to create an outline",
        "solution_long": "Use software that helps a person outline his or her ideas (e.g., mind mapping software, Inspiration) before writing. <ul><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors></ul>",
        "solution_number": 47030104,
        "strategy": "Provide a structure for writing",
        "task": "Composing Text",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP) && (a.T4703)"
    },
    "61010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Keyboard is too far away ",
        "solution": "Move the keyboard closer",
        "solution_long": "Move the keyboard closer on the work surface to the person.  This might be made easier by using a keyboard extension cable or wireless keyboard. <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors>",
        "solution_number": 61010101,
        "strategy": "Move the keyboard closer",
        "task": "Using a Computer",
        "trigger": "(a.U5 || a.U6 || a.U7) && (a.CMP) && (a.T6101)"
    },
    "61010102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Keyboard is too far away ",
        "solution": "Use a keyboard tray",
        "solution_long": "Use a keyboard tray to position the keyboard closer to the person.",
        "solution_number": 61010102,
        "strategy": "Move the keyboard closer",
        "task": "Using a Computer",
        "trigger": "(a.U5 || a.U6 || a.U7) && (a.CMP) && (a.T6101)"
    },
    "61010103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Keyboard is too far away ",
        "solution": "Use a height adjustable work surface",
        "solution_long": "If the person is standing, use a height adjustable work surface or a keyboard tray with vertical movement to position the keyboard closer to the person. <wafactors MLT><ul><li>This solution might work best when the employee uses a single workstation. The equipment will need to be available at each work area used.</li></ul></wafactors>",
        "solution_number": 61010103,
        "strategy": "Move the keyboard closer",
        "task": "Using a Computer",
        "trigger": "(a.U5 || a.U6 || a.U7) && (a.CMP) && (a.T6101)"
    },
    "61010201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Keyboard is too far away ",
        "solution": "Use a speech recognition system ",
        "solution_long": "Use a speech recognition system to dictate to the computer and control it with voice commands.  Keep in mind that speech recognition is good, but not perfect.  The employee will need to be able to recognize and fix recognition errors, and not be frustrated when they occur. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer.  If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li>  <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li> </wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61010201,
        "strategy": "Enter text without a keyboard",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9) && (a.CMP) && (a.T6101)"
    },
    "61020101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Cannot hit keys at same time (e.g., Shift-Letter)",
        "solution": "Use CAPS lock instead of Shift",
        "solution_long": "Use CAPS Lock to reduce the need to hold down the Shift key while capitalizing letters. <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors>",
        "solution_number": 61020101,
        "strategy": "Latch or lock down the keys",
        "task": "Using a Computer",
        "trigger": "(a.U12 || a.U15 || a.U16 || a.U17) && (a.CMP) && (a.T6102)"
    },
    "61020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Cannot hit keys at same time (e.g., Shift-Letter)",
        "solution": "Use StickyKeys setting in Windows/MacOS",
        "solution_long": "For Windows users, turn on the built-in StickyKeys feature which temporarily latches the Shift, Ctrl and Alt keys while another key is hit.  This allows keys to be hit one after the other instead of at the same time. <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed.</li></ul></wafactors></br>(Add Link to directions)",
        "solution_number": 61020102,
        "strategy": "Latch or lock down the keys",
        "task": "Using a Computer",
        "trigger": "(a.U12 || a.U15 || a.U16 || a.U17) && (a.CMP) && (a.T6102)"
    },
    "61030101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Cannot reach all of the keys",
        "solution": "Learn one-handed touch typing",
        "solution_long": "Learn one-handed touch typing on a standard keyboard.  This involves using a home row that is shifted toward the center of the keyboard. <ul><li>The employee will need to have one hand with very good dexterity.</li> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li></wafactors></ul>",
        "solution_number": 61030101,
        "strategy": "Reposition hand or keyboard",
        "task": "Using a Computer",
        "trigger": "(a.U12 || a.U13 || a.U15 || a.U17) && (!a.C2) && (a.CMP) && (a.T6103)"
    },
    "61030102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Cannot reach all of the keys",
        "solution": "Use a separate number pad",
        "solution_long": "Use a separate number pad which can be repositioned for easy reach.  For example, a person who is typing with only his or her left hand might move the number pad to the left of the keyboard. <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors>",
        "solution_number": 61030102,
        "strategy": "Reposition hand or keyboard",
        "task": "Using a Computer",
        "trigger": "(a.U10 || a.U11 || a.U12 || a.U13 || a.U15 || a.U17) && (a.CMP) && (a.T6103)"
    },
    "61030201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Cannot reach all of the keys",
        "solution": "Use a compact keyboard",
        "solution_long": "Use a mini or compact keyboard in which the keys, key spacing, and/or overall size of the keyboard are smaller. <ul><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61030201,
        "strategy": "Decrease key size / spacing",
        "task": "Using a Computer",
        "trigger": "(a.U11 || a.U12 || a.U13 || a.U15 || a.U17) && (a.CMP) && (a.T6103)"
    },
    "61030202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Cannot reach all of the keys",
        "solution": "Use a bowl-shaped keyboard",
        "solution_long": "Use a bowl-shaped keyboard that positions the keys closer to the reach of the fingers.  The Maltron keyboard is an example. <ul><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard.</li></wafactors> <wafactors MLT><li> This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61030202,
        "strategy": "Decrease key size / spacing",
        "task": "Using a Computer",
        "trigger": "(a.U12 || a.U13 || a.U15 || a.U17) && (!a.C2) && (a.CMP) && (a.T6103)"
    },
    "61030301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Cannot reach all of the keys",
        "solution": "Use keyboard based on most-used letters",
        "solution_long": "Use a keyboard where the most-used letters are placed closer together, around the keyboard center.  Some mini-keyboards have this feature. <ul><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard.</li></wafactors> <wafactors MLT><li> This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61030301,
        "strategy": "Change the keyboard layout",
        "task": "Using a Computer",
        "trigger": "(a.U11 || a.U12 || a.U13 || a.U15 || a.U17) && (a.CMP) && (a.T6103)"
    },
    "61030302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Cannot reach all of the keys",
        "solution": "Use a one-handed DVORAK keyboard",
        "solution_long": "Use a DVORAK keyboard layout, where the most-used letters are placed closer on either the right or left side of the keyboard.  Select this layout within the operating system settings.  Relabel a keyboard to show this letter arrangement. <ul><wafactors SHD><li> Coworkers may need to reboot / login to toggle between the standard or new system settings.</li></wafactors> <wafactors MLT><li> This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61030302,
        "strategy": "Change the keyboard layout",
        "task": "Using a Computer",
        "trigger": "(a.U12 || a.U13 || a.U15 || a.U17) && (!a.C2) && (a.CMP) && (a.T6103)"
    },
    "61030303": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Cannot reach all of the keys",
        "solution": "Use a keyboard with fewer keys",
        "solution_long": "Use a keyboard that uses fewer keys.  For example, Half-QWERTY assigns more than one letter to a key depending on whether or not the space bar is hit at the same time.  Or chordic keyboards provide a small number of keys, and combinations of keys are used to type each letter. <ul><li>The employee will need to have one hand with very good dexterity.</li> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard.</li></wafactors> <wafactors MLT><li> This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61030303,
        "strategy": "Change the keyboard layout",
        "task": "Using a Computer",
        "trigger": "(a.U12 || a.U13 || a.U15 || a.U17) && (!a.C2) && (a.CMP) && (a.T6103)"
    },
    "61030401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Cannot reach all of the keys",
        "solution": "Use an on-screen keyboard",
        "solution_long": "Use an on-screen keyboard with an appropriate cursor control method (also see Using the Mouse).  An image of a keyboard is shown on the screen, and letters and characters are chosen by pointing and/or clicking on them. <ul> <li>Basic on-screen keyboards are included in both the Windows and Mac operating systems.</li> <li>Commercial versions may have additional features that are helpful, such as word completion and the ability to change the look and position of the keyboard.</li> <wafactors MLT><li> Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud). </li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61030401,
        "strategy": "Change the typing format",
        "task": "Using a Computer",
        "trigger": "(a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.V5) && (!a.V6) && (!a.H6) && (a.CMP) && (a.T6103)"
    },
    "61030501": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Cannot reach all of the keys",
        "solution": "Use a speech recognition system ",
        "solution_long": "Use a speech recognition system to dictate to the computer and control it with voice commands.  Keep in mind that speech recognition is good, but not perfect.  The employee will need to be able to recognize and fix recognition errors, and not be frustrated when they occur. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer.  If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li>  <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li> </wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61030501,
        "strategy": "Use a hands-free text entry method",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.S5) && (a.CMP) && (a.T6103)"
    },
    "61040101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key press requires too much force ",
        "solution": "Use a membrane keyboard",
        "solution_long": "Use a membrane keyboard or similar that requires minimal force to select the letters. <ul><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard.</li></wafactors> <wafactors MLT><li> This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61040101,
        "strategy": "Reduce the force needed to press keys",
        "task": "Using a Computer",
        "trigger": "(a.U10 || a.U11 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17) && (a.CMP) && (a.T6104)"
    },
    "61040102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product and setup the technology",
        "gap": "Key press requires too much force ",
        "solution": "Use a projection keyboard",
        "solution_long": "Use a projection keyboard, where the image of a virtual keyboard is projected onto a surface, and when a user touches the surface covered by an image of a key, the device records the corresponding keystroke. <ul><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard. However, this solution might work best when the employee has a private workstation. Coworkers may be tempted to move equipment and not reset it.</li></wafactors> <wafactors MLT><li> This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61040102,
        "strategy": "Reduce the force needed to press keys",
        "task": "Using a Computer",
        "trigger": "(a.U10 || a.U11 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17) && (a.CMP) && (a.T6104)"
    },
    "61040103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key press requires too much force ",
        "solution": "Use an on-screen keyboard",
        "solution_long": "Use an on-screen keyboard with an appropriate cursor control method (also see Using the Mouse).  An image of a keyboard is shown on the screen, and letters and characters are chosen by pointing and/or clicking on them. <ul> <li>Basic on-screen keyboards are included in both the Windows and Mac operating systems.</li> <li>Commercial versions may have additional features that are helpful, such as word completion and the ability to change the look and position of the keyboard.</li> <wafactors MLT><li> Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud). </li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61040103,
        "strategy": "Reduce the force needed to press keys",
        "task": "Using a Computer",
        "trigger": "(a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.V5) && (!a.V6) && (!a.H6) && (a.CMP) && (a.T6104)"
    },
    "61040201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Key press requires too much force ",
        "solution": "Use a speech recognition system ",
        "solution_long": "Use a speech recognition system to dictate to the computer and control it with voice commands.  Keep in mind that speech recognition is good, but not perfect.  The employee will need to be able to recognize and fix recognition errors, and not be frustrated when they occur. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer.  If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li>  <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li> </wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61040201,
        "strategy": "Use a hands-free text entry method",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.S5) && (a.CMP) && (a.T6104)"
    },
    "61050101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Unwanted keys selected (e.g., from tremor)",
        "solution": "Use an expanded keyboard",
        "solution_long": "Use an expanded keyboard that provides larger keys and larger spacing between the keys.  This provides a larger target for the user. <ul> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard. However, this solution might work best when the employee has a private workstation. Coworkers may be tempted to move equipment and not reset it.</li></wafactors>  <wafactors MLT><li> This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61050101,
        "strategy": "Provide spaces or barriers between keys",
        "task": "Using a Computer",
        "trigger": "(!a.U3) && (!a.U9) && (a.U10 || a.U11 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17) && (a.CMP) && (a.T6105)"
    },
    "61050102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product and setup the technology",
        "gap": "Unwanted keys selected (e.g., from tremor)",
        "solution": "Use a projection keyboard",
        "solution_long": "Use a projection keyboard, where the image of a virtual keyboard is projected onto a surface, and when a user touches the surface covered by an image of a key, the device records the corresponding keystroke. <ul><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard. However, this solution might work best when the employee has a private workstation. Coworkers may be tempted to move equipment and not reset it.</li></wafactors> <wafactors MLT><li> This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61050102,
        "strategy": "Provide spaces or barriers between keys",
        "task": "Using a Computer",
        "trigger": "(!a.U3) && (!a.U9) && (a.U10 || a.U11 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17) && (a.CMP) && (a.T6105)"
    },
    "61050103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product",
        "gap": "Unwanted keys selected (e.g., from tremor)",
        "solution": "Use a keyguard",
        "solution_long": "Use a keyguard to provide a barrier between keys.  A keyguard is a plastic or metal surface that fits over the keyboard and has holes over each key.  Having to push the finger into the hole to press the key reduces errors but can also slow typing.  The person can also stabilize his or her hand by resting it on the keyguard surface. <ul><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard. However, this solution might work best when the employee has a private workstation. Coworkers may be tempted to move equipment and not reset it.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 61050103,
        "strategy": "Provide spaces or barriers between keys",
        "task": "Using a Computer",
        "trigger": "(a.U10 || a.U11 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17) && (a.CMP) && (a.T6105)"
    },
    "61050201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to adjust the settings",
        "gap": "Unwanted keys selected (e.g., from tremor)",
        "solution": "Adjust key sensitivity in Windows",
        "solution_long": "Change the system settings to register only the desired keystrokes.  In Windows, features such as FilterKeys or RepeatKeys can be adjusted so that a key must be held down a certain amount of time before the letter is entered, or so that repeated letters won't occur. <ul><wafactors SHD><li> Coworkers may need to reboot / login to toggle between the standard or new system settings.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul> </br>(Add link to directions)",
        "solution_number": 61050201,
        "strategy": "Reduce sensitivity of keys",
        "task": "Using a Computer",
        "trigger": "(a.U10 || a.U11 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17) && (a.CMP) && (a.T6105)"
    },
    "61050202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to adjust the settings",
        "gap": "Unwanted keys selected (e.g., from tremor)",
        "solution": "Adjust key sensitivity in MacOS",
        "solution_long": "Change the system settings to register only the desired keystrokes.  For Macs, use Slow Keys to adjust how long a key must be held down before the letter is entered.  In addition, the key repeat rate and delay can be adjusted. <ul><wafactors SHD><li> Coworkers may need to reboot / login to toggle between the standard or new system settings.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul> </br> (Add link to directions)",
        "solution_number": 61050202,
        "strategy": "Reduce sensitivity of keys",
        "task": "Using a Computer",
        "trigger": "(a.U10 || a.U11 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17) && (a.CMP) && (a.T6105)"
    },
    "61050301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Unwanted keys selected (e.g., from tremor)",
        "solution": "Press the keys with a hand-held stylus",
        "solution_long": "Use a hand-held stylus as a pointer to press the keys. <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors>",
        "solution_number": 61050301,
        "strategy": "Use a stick to press keys",
        "task": "Using a Computer",
        "trigger": "(a.U11 || a.U16) && (a.CMP) && (a.T6105)"
    },
    "61050302": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Strongly encouraged to make sure that inappropriate forces are not placed on the neck or teeth",
        "gap": "Unwanted keys selected (e.g., from tremor)",
        "solution": "Press the keys with a mouthstick",
        "solution_long": "Use a mouthstick to press the keys. <ul><li>Make sure that inappropriate forces are not placed on the neck or teeth.</li> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard. However, this solution might work best when the employee has a private workstation. Coworkers may be tempted to move equipment and not reset it.</li></wafactors> <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors>",
        "solution_number": 61050302,
        "strategy": "Use a stick to press keys",
        "task": "Using a Computer",
        "trigger": "(a.U18) && (a.CMP) && (a.T6105)"
    },
    "61050401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Unwanted keys selected (e.g., from tremor)",
        "solution": "Use an on-screen keyboard",
        "solution_long": "Use an on-screen keyboard with an appropriate cursor control method (also see Using the Mouse).  An image of a keyboard is shown on the screen, and letters and characters are chosen by pointing and/or clicking on them. <ul> <li>Basic on-screen keyboards are included in both the Windows and Mac operating systems.</li> <li>Commercial versions may have additional features that are helpful, such as word completion and the ability to change the look and position of the keyboard.</li> <wafactors MLT><li> Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud). </li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61050401,
        "strategy": "Use a hands-free text entry method",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.V5) && (!a.V6) && (!a.H6) && (a.CMP) && (a.T6105)"
    },
    "61050402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Unwanted keys selected (e.g., from tremor)",
        "solution": "Use a speech recognition system ",
        "solution_long": "Use a speech recognition system to dictate to the computer and control it with voice commands.  Keep in mind that speech recognition is good, but not perfect.  The employee will need to be able to recognize and fix recognition errors, and not be frustrated when they occur. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer.  If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li>  <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li> </wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61050402,
        "strategy": "Use a hands-free text entry method",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.S5) && (a.CMP) && (a.T6105)"
    },
    "61060101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Typing causes hand / wrist / arm pain or fatigue",
        "solution": "Move the keyboard",
        "solution_long": "Move the keyboard to a position where it can be used in a more neutral posture.  This may involve changing the height of the work surface, moving the keyboard to a keyboard tray, or changing the height of the chair.  See the section on Accessing the Work Surface for more ideas. <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors>",
        "solution_number": 61060101,
        "strategy": "Change position of hand/arm",
        "task": "Using a Computer",
        "trigger": "(a.U5 || a.U6 || a.U7) && (a.CMP) && (a.T6106)"
    },
    "61060102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Typing causes hand / wrist / arm pain or fatigue",
        "solution": "Use a wrist rest",
        "solution_long": "Use a wrist rest.  This helps hold the wrist in a more neutral position. <ul> <wafactors MLT><li> The equipment will need to be available at each workstation used.</li></wafactors> <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors></ul>",
        "solution_number": 61060102,
        "strategy": "Change position of hand/arm",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U4 || a.U5 || a.U10 || a.U13 || a.U14) && (a.CMP) && (a.T6106)"
    },
    "61060103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Typing causes hand / wrist / arm pain or fatigue",
        "solution": "Use an arm support",
        "solution_long": "Use a rotating forearm support to promote a more ergonomic arm position (e.g., promote a neutral wrist position). <ul> <wafactors MLT><li> The equipment will need to be available at each workstation used.</li></wafactors> <wafactors SHD><li>This solution might work best when the employee has a private workstation. Coworkers may be tempted to move equipment and not reset it.</li></wafactors> </ul>",
        "solution_number": 61060103,
        "strategy": "Change position of hand/arm",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U4 || a.U5 || a.U10 || a.U13 || a.U14) && (a.CMP) && (a.T6106)"
    },
    "61060201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Typing causes hand / wrist / arm pain or fatigue",
        "solution": "Use an ergonomic keyboard",
        "solution_long": "Use an ergonomic keyboard that keeps the hand and wrist in a neutral position.  A slight inward rotation of the palms of the hand is preferred. <ul> <wafactors MLT><li> The equipment will need to be available at each workstation used.</li></wafactors> <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors></ul>",
        "solution_number": 61060201,
        "strategy": "Change position of hand/arm",
        "task": "Using a Computer",
        "trigger": "(a.U10 || a.U13 || a.U14) && (a.CMP) && (a.T6106)"
    },
    "61060301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Typing causes hand / wrist / arm pain or fatigue",
        "solution": "Use keyboard that uses minimal movement",
        "solution_long": "Use a keyboard requiring only minimal movement to press the keys.  For example, the DataHand keyboard surrounds the fingers with key switches that are selected with only slight movements. <ul><wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard.</li></wafactors> <wafactors MLT><li> This solution might work best when the employee uses a single workstation. The equipment will need to be available at each workstation used.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61060301,
        "strategy": "Reduce number of keys presses",
        "task": "Using a Computer",
        "trigger": "(a.U12 || a.U13 || a.U15 || a.U17) && (!a.C2) && (a.CMP) && (a.T6106)"
    },
    "61060302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the employee needs to learn how to use the technology and customize it for the job.",
        "gap": "Typing causes hand / wrist / arm pain or fatigue",
        "solution": "Use abbreviation expansion",
        "solution_long": "Use abbreviation expansion software or features.  This allows a person to type an abbreviation, but have the full text be entered. <ul><li>For example, the Auto Correct feature in Microsoft Word will not only fix typos, but can be set so that an abbreviation such as gt- will spell out \"Georgia Tech\".  This example reduces the typing load by 9 characters.</li> <li>Adding a character such as a dash to abbreviations can help the software figure out if you are typing an abbreviation or a word.</li> <li>Expert help might be needed to program the abbreviations to fit the job tasks.</li> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors PRT> <li> Use a laptop or tablet if a portable solution is needed. </li></wafactors></ul>",
        "solution_number": 61060302,
        "strategy": "Reduce number of keys presses",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (a.CMP) && (a.T6106)"
    },
    "61060303": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the employee needs to learn how to use the technology and customize it for the job.",
        "gap": "Typing causes hand / wrist / arm pain or fatigue",
        "solution": "Use macros for commands",
        "solution_long": "Use macros for commands.  These are single, user-created commands that in turn execute a series of commands.  For example, the press of a single Function key might be set to open a program, enter login information, and open a particular file. <ul><li>Expert help might be needed to program the macros to fit the job tasks.</li> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed.</li></wafactors> </ul>",
        "solution_number": 61060303,
        "strategy": "Reduce number of keys presses",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (a.CMP) && (a.T6106)"
    },
    "61060304": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Typing causes hand / wrist / arm pain or fatigue",
        "solution": "Use a speech recognition system ",
        "solution_long": "Use a speech recognition system to dictate to the computer and control it with voice commands.  Keep in mind that speech recognition is good, but not perfect.  The employee will need to be able to recognize and fix recognition errors, and not be frustrated when they occur. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer.  If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li>  <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li> </wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61060304,
        "strategy": "Reduce number of keys presses",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.S5) && (a.CMP) && (a.T6106)"
    },
    "61070101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the employee needs to learn how to use the technology and customize it for the job.",
        "gap": "Typing is too slow",
        "solution": "Use abbreviation expansion",
        "solution_long": "Use abbreviation expansion software or features.  This allows a person to type an abbreviation, but have the full text be entered. <ul><li>For example, the Auto Correct feature in Microsoft Word will not only fix typos, but can be set so that an abbreviation such as gt- will spell out \"Georgia Tech\".  This example reduces the typing load by 9 characters.</li> <li>Adding a character such as a dash to abbreviations can help the software figure out if you are typing an abbreviation or a word.</li> <li>Expert help might be needed to program the abbreviations to fit the job tasks.</li> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors PRT> <li> Use a laptop or tablet if a portable solution is needed. </li></wafactors></ul>",
        "solution_number": 61070101,
        "strategy": "Reduce number of keys presses",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (a.CMP) && (a.T6107)"
    },
    "61070102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the employee needs to learn how to use the technology and customize it for the job.",
        "gap": "Typing is too slow",
        "solution": "Use macros for commands",
        "solution_long": "Use macros for commands.  These are single, user-created commands that in turn execute a series of commands.  For example, the press of a single Function key might be set to open a program, enter login information, and open a particular file. <ul><li>Expert help might be needed to program the macros to fit the job tasks.</li> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed.</li></wafactors> </ul>",
        "solution_number": 61070102,
        "strategy": "Reduce number of keys presses",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (a.CMP) && (a.T6107)"
    },
    "61070103": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Typing is too slow",
        "solution": "Use a speech recognition system ",
        "solution_long": "Use a speech recognition system to dictate to the computer and control it with voice commands.  Keep in mind that speech recognition is good, but not perfect.  The employee will need to be able to recognize and fix recognition errors, and not be frustrated when they occur. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer.  If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li>  <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li> </wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61070103,
        "strategy": "Reduce number of keys presses",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.S5) && (a.CMP) && (a.T6107)"
    },
    "61070104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Typing is too slow",
        "solution": "Use word completion/prediction software",
        "solution_long": "Use word completion or prediction software.  With word completion, a person begins a word, and the computer displays words that it thinks the person may be trying to type.  The desired word can be chosen from the list rather than being completely keyed in.  With word prediction, the computer also tries to guess the next word based on context.  Either way, there is a savings in keystrokes, but it is offset by the time spent reading through the list.  Thus, this option works best for people who type about 10 wpm or slower or who also benefit from the spelling assistance. <ul><wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors PRT> <li> Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 61070104,
        "strategy": "Reduce number of keys presses",
        "task": "Using a Computer",
        "trigger": "(a.U16 || a.U17 || a.U18) && (a.CMP) && (a.T6107)"
    },
    "61080101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful depending on the complexity of the cursor control method",
        "gap": "Hands-free typing method is needed ",
        "solution": "Use an on-screen keyboard",
        "solution_long": "Use an on-screen keyboard with an appropriate cursor control method (also see Using the Mouse).  An image of a keyboard is shown on the screen, and letters and characters are chosen by pointing and/or clicking on them. <ul> <li>Basic on-screen keyboards are included in both the Windows and Mac operating systems.</li> <li>Commercial versions may have additional features that are helpful, such as word completion and the ability to change the look and position of the keyboard.</li> <wafactors MLT><li> Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud). </li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61080101,
        "strategy": "Type using cursor movements",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.V5) && (!a.V6) && (!a.H6) && (a.CMP) && (a.T6108)"
    },
    "61080201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Hands-free typing method is needed ",
        "solution": "Use a speech recognition system ",
        "solution_long": "Use a speech recognition system to dictate to the computer and control it with voice commands.  Keep in mind that speech recognition is good, but not perfect.  The employee will need to be able to recognize and fix recognition errors, and not be frustrated when they occur. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer.  If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Job-specific terms and phrases can be added.</li>  <wafactors OPN><li>This solution may not provide enough privacy if used in an open-plan workspace, where multiple people work in the same room.  Use of a stenographer's mask may help.</li> </wafactors> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61080201,
        "strategy": "Type by voice",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.S5) && (a.CMP) && (a.T6108)"
    },
    "61080301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Hands-free typing method is needed ",
        "solution": "Use switch-controlled scanning",
        "solution_long": "Use an on-screen keyboard with switch-controlled scanning.  Each letter is highlighted in turn, and when the desired letter is displayed, the person uses a switch to select it.  A variety of switches can be used, including those operated with the tap of the head, by blowing into a straw, or by blinking an eye.  This approach is used by people with significant motor impairments.  It provides a very slow method of text entry, so it is typically an option of last resort.  ",
        "solution_number": 61080301,
        "strategy": "Type by activating a switch",
        "task": "Using a Computer",
        "trigger": "(a.U9 || a.U18) && (a.CMP) && (a.T6108)"
    },
    "61080302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Hands-free typing method is needed ",
        "solution": "Use switches and Morse code",
        "solution_long": "Use 1-2 switches to enter Morse code. With two switches, one serves as a dot and the other a dash.  Individuals who are able to learn the system often find that this approach is faster than scanning. It also doesn't require as much visual attention. <ul><li>This approach is used by people with significant motor impairments.</li> <li>A variety of switches can be used, including those operated with the tap of the head, by blowing into a straw, or by blinking an eye.</li> <li>Consultation with an assistive technology specialist may be helpful if the employee has not used this type of entry method before.</li></ul>",
        "solution_number": 61080302,
        "strategy": "Type by activating a switch",
        "task": "Using a Computer",
        "trigger": "(a.U9 || a.U18) && (!a.C2) && (a.CMP) && (a.T6108)"
    },
    "61090101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty changing focus between keyboard and screen ",
        "solution": "Learn to touch type",
        "solution_long": "Learn to touch type so that hunt-and-peck typing isn't needed.",
        "solution_number": 61090101,
        "strategy": "Learn to touch type",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V5 || a.V6 || a.H6) && (a.CMP) && (a.T6109)"
    },
    "61090201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Difficulty changing focus between keyboard and screen ",
        "solution": "Reposition screen / keyboard",
        "solution_long": "Reposition the screen and/or keyboard so that both fall within the preferred field of vision.  Use a screen stand or keyboard stand to reposition the equipment.  Alternatively, use a workstation where the screen is recessed so that it is positioned closer to the keyboard.",
        "solution_number": 61090201,
        "strategy": "Position the keyboard and screen in the same field of vision",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.LOW || a.CMP) && (a.T6109)"
    },
    "61090202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty changing focus between keyboard and screen ",
        "solution": "Use an on-screen keyboard",
        "solution_long": "Use an on-screen keyboard where the keyboard is displayed on the screen.  Basic on-screen keyboards are included in both the Windows and Mac operating systems.",
        "solution_number": 61090202,
        "strategy": "Position the keyboard and screen in the same field of vision",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.CMP) && (a.T6109)"
    },
    "61100101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key labels are difficult to see",
        "solution": "Learn to touch type",
        "solution_long": "Learn to touch type so that hunt-and-peck typing isn't needed.",
        "solution_number": 61100101,
        "strategy": "Learn to touch type",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V5 || a.V6 || a.H6) && (a.CMP) && (a.T6110)"
    },
    "61100201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key labels are difficult to see",
        "solution": "Apply high contrast labels",
        "solution_long": "Apply high contrast labels to the keyboard.",
        "solution_number": 61100201,
        "strategy": "Change label color/contrast",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.LOW || a.CMP) && (a.T6110)"
    },
    "61100202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key labels are difficult to see",
        "solution": "Use a high contrast keyboard",
        "solution_long": "Use a high contrast keyboard",
        "solution_number": 61100202,
        "strategy": "Change label color/contrast",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.CMP) && (a.T6110)"
    },
    "61100203": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key labels are difficult to see",
        "solution": "Use a backlit keyboard",
        "solution_long": "Use a backlit keyboard, where the keys light up for viewing in low light environments.",
        "solution_number": 61100203,
        "strategy": "Change label color/contrast",
        "task": "Using a Computer",
        "trigger": "(!a.V1) && (a.V3) && (a.CMP) && (a.T6110)"
    },
    "61100204": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key labels are difficult to see",
        "solution": "Label keys for orientation",
        "solution_long": "Apply 3D paint for tactile nubs (F/J), and T painted on tab key for orientation.",
        "solution_number": 61100204,
        "strategy": "Change label color/contrast",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.LOW || a.CMP) && (a.T6110)"
    },
    "61100301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key labels are difficult to see",
        "solution": "Apply large print labels",
        "solution_long": "Apply large print labels to the keyboard.",
        "solution_number": 61100301,
        "strategy": "Change label size",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.LOW || a.CMP) && (a.T6110)"
    },
    "61100302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key labels are difficult to see",
        "solution": "Use a large print keyboard ",
        "solution_long": "Use a large print keyboard.  Typically, these keyboards also have high-contrast labeling (e.g., Zoomtext Large-Print Keyboard). ",
        "solution_number": 61100302,
        "strategy": "Change label size",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.CMP) && (a.T6110)"
    },
    "61100401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key labels are difficult to see",
        "solution": "Change label format ",
        "solution_long": "Apply Braille and/or raised print labels to the keyboard.  Note that exploring the keys this way will produce a delay and may slow typing.  Use this option only if the person is a hunt-and-peck typist who knows Braille.",
        "solution_number": 61100401,
        "strategy": "Change label format ",
        "task": "Using a Computer",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.LOW || a.CMP) && (a.T6110)"
    },
    "61100501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key labels are difficult to see",
        "solution": "Adjust screen reader settings ",
        "solution_long": "Screen readers not only translate text on the screen to speech, but can speak each key as it is pressed.  Activate the learning or key orientation mode to turn on this feature. ",
        "solution_number": 61100501,
        "strategy": "Adjust screen reader settings",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4) && (a.CMP) && (a.T6110)"
    },
    "61100601": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key labels are difficult to see",
        "solution": "Change task lighting ",
        "solution_long": "Add, reduce, or position task lighting to shine on the keyboard, in a manner that doesn't produce glare. ",
        "solution_number": 61100601,
        "strategy": "Change the way light hits the keyboard",
        "task": "Using a Computer",
        "trigger": "(a.V1 || a.V3) && (a.LOW || a.CMP) && (a.T6110)"
    },
    "61100701": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key labels are difficult to see",
        "solution": "Wear tinted glasses or a visor ",
        "solution_long": "Allow the employee to wear tinted glasses (e.g., sunglasses) or a hat with a visor. ",
        "solution_number": 61100701,
        "strategy": "Shade the employee’s eyes from bright lights",
        "task": "Using a Computer",
        "trigger": "(a.V1 || a.V3) && (a.LOW || a.CMP) && (a.T6110)"
    },
    "61110101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key layout is confusing",
        "solution": "Learn to touch type",
        "solution_long": "Learn to touch type so that hunt-and-peck typing isn't necessary. ",
        "solution_number": 61110101,
        "strategy": "Learn to touch type ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V5 || a.V6 || a.H6 || a.C1 || a.C6) && (a.CMP) && (a.T6111)"
    },
    "61110201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Key layout is confusing",
        "solution": "Use an alphabetical keyboard layout",
        "solution_long": "Use a keyboard with an alphabetical layout. <ul><li>If the person has not previously used this type of technology, consultation with an assistive technology specialist may be helpful. </li></ul>",
        "solution_number": 61110201,
        "strategy": "Change type of keyboard layout ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V5 || a.V6 || a.H6 || a.C1 || a.C2 || a.C6) && (a.CMP) && (a.T6111)"
    },
    "61110202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Key layout is confusing",
        "solution": "Use a Braille notetaker",
        "solution_long": "Use a Braille notetaker with a Braille keyboard.  Either use the notetaker by itself, or interface it to a computer. <ul><li>If the person has not previously used this type of technology, consultation with an assistive technology specialist may be helpful. </li></ul>",
        "solution_number": 61110202,
        "strategy": "Change type of keyboard layout ",
        "task": "Using a Computer",
        "trigger": "(a.V5 || a.V6 || a.H6) && (a.CMP) && (a.T6111)"
    },
    "61120101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse is positioned too far away",
        "solution": "Use the other hand",
        "solution_long": "Use the mouse with the other hand.  In the system settings, reverse the mouse buttons so that the primary click is activated with the index finger. <ul><wafactors SHD><li> Coworkers may need to reboot / login to toggle between right and left hand settings.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 61120101,
        "strategy": "Operate with the other hand",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U4) && (a.CMP) && (a.T6112)"
    },
    "61120201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse is positioned too far away",
        "solution": "Move the mouse closer",
        "solution_long": "Move the mouse closer on the work surface to the person.  This might be made easier by using an extension cable or wireless mouse. <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors>",
        "solution_number": 61120201,
        "strategy": "Move the mouse closer",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6) && (a.CMP) && (a.T6112)"
    },
    "61120202": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Mouse is positioned too far away",
        "solution": "Use a keyboard / mouse tray",
        "solution_long": "Use a keyboard / mouse tray to position the mouse closer to the person.",
        "solution_number": 61120202,
        "strategy": "Move the mouse closer",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6) && (a.CMP) && (a.T6112)"
    },
    "61120203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Mouse is positioned too far away",
        "solution": "Use a height adjustable work surface",
        "solution_long": "If the person is standing, use a height adjustable work surface or a keyboard tray with vertical movement to position the mouse closer to the person. <wafactors MLT><ul><li>This solution might work best when the employee uses a single workstation. The equipment will need to be available at each work area used.</li></ul></wafactors>",
        "solution_number": 61120203,
        "strategy": "Move the mouse closer",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6) && (a.CMP) && (a.T6112)"
    },
    "61130101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse is difficult to grasp",
        "solution": "Use the other hand",
        "solution_long": "Use the mouse with the other hand.  In the system settings, reverse the mouse buttons so that the primary click is activated with the index finger. <ul><wafactors SHD><li> Coworkers may need to reboot / login to toggle between right and left hand settings.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 61130101,
        "strategy": "Operate with the other hand",
        "task": "Using a Computer",
        "trigger": "(a.U10 || a.U13) && (a.CMP) && (a.T6113)"
    },
    "61130201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse is difficult to grasp",
        "solution": "Use an ergonomic mouse",
        "solution_long": "Use an ergonomic mouse that fits the size and shape of the user's hand. <ul> <wafactors SHD><li> If the workstation is shared, avoid mice that are for only right-handed or only left-handed use.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 61130201,
        "strategy": "Change the size / shape of mouse",
        "task": "Using a Computer",
        "trigger": "(a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6113)"
    },
    "61130301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse is difficult to grasp",
        "solution": "Use a fixed-position device",
        "solution_long": "Use a device that remains in a fixed position and does not need to be grasped (e.g., trackball, touch pad or track pad, joystick). <ul> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer mouse.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61130301,
        "strategy": "Use a different cursor control method",
        "task": "Using a Computer",
        "trigger": "(a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6113)"
    },
    "61140101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse use causes hand/ wrist/ arm pain",
        "solution": "Use the other hand.",
        "solution_long": "Use the mouse with the other hand.  In the system settings, reverse the mouse buttons so that the primary click is activated with the index finger. <ul><wafactors SHD><li> Coworkers may need to reboot / login to toggle between right and left hand settings.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 61140101,
        "strategy": "Operate with the other hand",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U4 || a.U10 || a.U13) && (a.CMP) && (a.T6114)"
    },
    "61140201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse use causes hand/ wrist/ arm pain",
        "solution": "Use an ergonomic mouse",
        "solution_long": "Use an ergonomic mouse that fits the size and shape of the user's hand. <ul> <wafactors SHD><li> If the workstation is shared, avoid mice that are for only right-handed or only left-handed use.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 61140201,
        "strategy": "Change the size / shape of mouse",
        "task": "Using a Computer",
        "trigger": "(a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6114)"
    },
    "61140301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse use causes hand/ wrist/ arm pain",
        "solution": "Change the mouse position",
        "solution_long": "Change the position of where the mouse is used.  For example, a mouse tray and/or extension cable might be used to bring the mouse closer to the user and promote a more ergonomic arm position. <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors>",
        "solution_number": 61140301,
        "strategy": "Change position of the hand/arm",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6) && (a.CMP) && (a.T6114)"
    },
    "61140302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Mouse use causes hand/ wrist/ arm pain",
        "solution": "Use a wrist rest / arm support",
        "solution_long": "Use a wrist rest / rotating forearm support to promote a more ergonomic arm position (e.g., promote a neutral wrist position). <wafactors MLT><ul><li> The equipment will need to be available at each workstation used.</li></ul></wafactors>",
        "solution_number": 61140302,
        "strategy": "Change position of the hand/arm",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6) && (a.CMP) && (a.T6114)"
    },
    "61140401": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Strongly encouraged to make sure that inappropriate forces are not placed on the neck, teeth, or other body part used",
        "gap": "Mouse use causes hand/ wrist/ arm pain",
        "solution": "Use a different body part (e.g., foot)",
        "solution_long": "Operate the cursor control device with a chin, elbow, foot, mouthstick, etc. rather than the hand.  This approach requires a cursor control device that does not need to be grasped. <ul> <li>Cursor buttons can be replaced with a separate switch, again, possibly operated with a different body part.</li> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard. However, this solution might work best when the employee has a private workstation. Coworkers may be tempted to move equipment and not reset it.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61140401,
        "strategy": "Use a different body movement",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U9 || a.U11 || a.U12 || a.U18) && (a.CMP) && (a.T6114)"
    },
    "61140501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse use causes hand/ wrist/ arm pain",
        "solution": "Use a fixed-position device",
        "solution_long": "Use a device that remains in a fixed position and does not need to be grasped (e.g., trackball, touch pad or track pad, joystick). <ul> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer mouse.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61140501,
        "strategy": "Use a different cursor control method",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6 || a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6114)"
    },
    "61140502": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Mouse use causes hand/ wrist/ arm pain",
        "solution": "Use voice commands",
        "solution_long": "Choose menu commands and move the cursor with voice commands.  Keep in mind that speech recognition is good, but not perfect.  The employee will need to be able to recognize and fix recognition errors and not be frustrated when they occur. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer.  If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Some people find that voice commands are good for menu commands and general placement of the cursor, but another method might be better at fine control of the cursor.</li> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61140502,
        "strategy": "Use a different cursor control method",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.S5) && (a.CMP) && (a.T6114)"
    },
    "61150101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse requires too much motion / strength",
        "solution": "Use the other hand",
        "solution_long": "Use the mouse with the other hand.  In the system settings, reverse the mouse buttons so that the primary click is activated with the index finger. <ul><wafactors SHD><li> Coworkers may need to reboot / login to toggle between right and left hand settings.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 61150101,
        "strategy": "Operate with the other hand",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U4 || a.U10 || a.U13) && (a.CMP) && (a.T6115)"
    },
    "61150201": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Strongly encouraged to make sure that inappropriate forces are not placed on the neck, teeth, or other body part used",
        "gap": "Mouse requires too much motion / strength",
        "solution": "Use a different body part (e.g., foot)",
        "solution_long": "Operate the cursor control device with a chin, elbow, foot, mouthstick, etc. rather than the hand.  This approach requires a cursor control device that does not need to be grasped. <ul> <li>Cursor buttons can be replaced with a separate switch, again, possibly operated with a different body part.</li> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard. However, this solution might work best when the employee has a private workstation. Coworkers may be tempted to move equipment and not reset it.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61150201,
        "strategy": "Use a different body movement",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U9 || a.U11 || a.U12 || a.U18) && (a.CMP) && (a.T6115)"
    },
    "61150301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to adjust the settings",
        "gap": "Mouse requires too much motion / strength",
        "solution": "Increase the mouse sensitivity",
        "solution_long": "Adjust the operating system settings to increase the sensitivity of the mouse, so that it takes smaller device movements to move the cursor. <ul> <wafactors SHD><li> Coworkers may need to reboot / login to toggle between the standard or new system settings.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 61150301,
        "strategy": "Change the mouse sensitivity",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6) && (a.CMP) && (a.T6115)"
    },
    "61150401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse requires too much motion / strength",
        "solution": "Use a fixed-position device",
        "solution_long": "Use a device that remains in a fixed position and does not need to be grasped (e.g., trackball, touch pad or track pad, joystick). <ul> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer mouse.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li> </wafactors></ul>",
        "solution_number": 61150401,
        "strategy": "Use a different cursor control method",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6 || a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6115)"
    },
    "61150402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Mouse requires too much motion / strength",
        "solution": "Use voice commands",
        "solution_long": "Choose menu commands and move the cursor with voice commands.  Keep in mind that speech recognition is good, but not perfect.  The employee will need to be able to recognize and fix recognition errors and not be frustrated when they occur. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer.  If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Some people find that voice commands are good for menu commands and general placement of the cursor, but another method might be better at fine control of the cursor.</li> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61150402,
        "strategy": "Use a different cursor control method",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (!a.S5) && (a.CMP) && (a.T6115)"
    },
    "61150403": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Mouse requires too much motion / strength",
        "solution": "Use head movements or gestures",
        "solution_long": "Use a cursor control device operated by head movements or gestures. <ul><li>These options can be among the more expensive options for computer access.  Consulting a computer access specialist is recommended.</li> <wafactors SHD MLT><li>This solution might work best when the employee has a single, private workstation. The equipment will need to be available at each workstation used, and coworkers may be tempted to move equipment and not reset it.</li></wafactors> </ul>",
        "solution_number": 61150403,
        "strategy": "Use a different cursor control method",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U9 || a.U11 || a.U12 || a.U18) && (a.CMP) && (a.T6115)"
    },
    "61150404": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Strongly encouraged due to the complexity and expense of the technology",
        "gap": "Mouse requires too much motion / strength",
        "solution": "Use eye movements",
        "solution_long": "Use a cursor control device operated by eye movements. <ul><li>These options can be among the more expensive options for computer access.  Consulting a computer access specialist is recommended.</li> <wafactors SHD MLT><li>This solution might work best when the employee has a single, private workstation. The equipment will need to be available at each workstation used, and coworkers may be tempted to move equipment and not reset it.</li></wafactors> </ul>",
        "solution_number": 61150404,
        "strategy": "Use a different cursor control method",
        "task": "Using a Computer",
        "trigger": "(a.U3 || a.U9 || a.U12 || a.U18) && (a.CMP) && (a.T6115)"
    },
    "61150405": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Mouse requires too much motion / strength",
        "solution": "Use switch-controlled scanning",
        "solution_long": "Use an on-screen keyboard with switch-controlled scanning.  For cursor movements, each mouse direction is highlighted in turn, and when the desired direction is displayed, the person activates a switch to select it.  <ul><li>A variety of switches can be used, including those operated with the tap of the head, by blowing into a straw, or by blinking an eye.</li> <li>This approach provides a very slow method of text entry or mouse control, so it is typically an option of last resort.</li> <wafactors SHD MLT><li>This solution might work best when the employee has a single, private workstation. The equipment will need to be available at each workstation used, and coworkers may be tempted to move equipment and not reset it.</li></wafactors> </ul>",
        "solution_number": 61150405,
        "strategy": "Use a different cursor control method",
        "task": "Using a Computer",
        "trigger": "(a.U3 || a.U9 || a.U12 || a.U18) && (a.CMP) && (a.T6115)"
    },
    "61160101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Cursor cannot be positioned with sufficient precision",
        "solution": "Use the other hand",
        "solution_long": "Use the mouse with the other hand.  In the system settings, reverse the mouse buttons so that the primary click is activated with the index finger. <ul><wafactors SHD><li> Coworkers may need to reboot / login to toggle between right and left hand settings.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 61160101,
        "strategy": "Operate with the other hand",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U9 || a.U11 || a.U12 || a.U18) && (a.CMP) && (a.T6116)"
    },
    "61160201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Cursor cannot be positioned with sufficient precision",
        "solution": "Change the default text and icon size",
        "solution_long": "Change the system settings (the Accessibility Options) to make the default text size and icons larger.",
        "solution_number": 61160201,
        "strategy": "Increase the size of text and/or icons",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6 || a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6116)"
    },
    "61160301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to adjust the settings",
        "gap": "Cursor cannot be positioned with sufficient precision",
        "solution": "Decrease mouse sensitivity",
        "solution_long": "Adjust the system settings to decrease the sensitivity of the mouse, so that it takes larger device movements to move the cursor.",
        "solution_number": 61160301,
        "strategy": "Reduce the mouse sensitivity",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6 || a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6116)"
    },
    "61160302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Cursor cannot be positioned with sufficient precision",
        "solution": "Use a device with greater precision",
        "solution_long": "Use a device with greater precision.  For example, MouseKeys controls the cursor through the number pad and allows the cursor to be moved just a few pixels at a time.",
        "solution_number": 61160302,
        "strategy": "Reduce the mouse sensitivity",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6 || a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6116)"
    },
    "61160401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Cursor cannot be positioned with sufficient precision",
        "solution": "Use \"dwell-click\" software",
        "solution_long": "Use \"dwell-click\" software that initiates a click when the cursor dwells in a location.",
        "solution_number": 61160401,
        "strategy": "Separate the button operation from positioning",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6 || a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6116)"
    },
    "61160402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Cursor cannot be positioned with sufficient precision",
        "solution": "Use a device with a \"drag\" button",
        "solution_long": "Use a device with a \"drag\" button that locks the button click until the drag is completed.  This feature is frequently found on trackballs and computer joysticks.",
        "solution_number": 61160402,
        "strategy": "Separate the button operation from positioning",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6 || a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6116)"
    },
    "61170101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Device buttons are too hard to push or hold down",
        "solution": "Use the other hand",
        "solution_long": "Use the mouse with the other hand.  In the system settings, reverse the mouse buttons so that the primary click is activated with the index finger. <ul><wafactors SHD><li> Coworkers may need to reboot / login to toggle between right and left hand settings.</li></wafactors> <wafactors PRT><li> Use a laptop or tablet if a portable solution is needed. </li></wafactors> </ul>",
        "solution_number": 61170101,
        "strategy": "Operate with the other hand",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U9 || a.U11 || a.U12 || a.U18) && (a.CMP) && (a.T6117)"
    },
    "61170201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to identify a specific product and setup the technology",
        "gap": "Device buttons are too hard to push or hold down",
        "solution": "Use a device with a \"lighter touch\"",
        "solution_long": "Use a cursor control device with a \"lighter touch.\"",
        "solution_number": 61170201,
        "strategy": "Reduce the button activation force",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6 || a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6117)"
    },
    "61170202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Device buttons are too hard to push or hold down",
        "solution": "Use \"dwell-click\" software",
        "solution_long": "Use \"dwell-click\" software that initiates a click when the cursor dwells in a location.",
        "solution_number": 61170202,
        "strategy": "Reduce the button activation force",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6 || a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6117)"
    },
    "61170301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Device buttons are too hard to push or hold down",
        "solution": "Use a device with a \"drag\" button",
        "solution_long": "Use a device with a \"drag\" button that locks the button click until the drag is completed.  This feature is frequently found on trackballs and computer joysticks.",
        "solution_number": 61170301,
        "strategy": "Eliminate the need to keep keys held down",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U4 || a.U5 || a.U6 || a.U10 || a.U11 || a.U13 || a.U14 || a.U15) && (a.CMP) && (a.T6117)"
    },
    "61180101": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Strongly encouraged to make sure that inappropriate forces are not placed on the neck or teeth",
        "gap": "Hands-free cursor control method is needed",
        "solution": "Use mouthstick and fixed-position device",
        "solution_long": "Use a mouthstick with a device that remains in a fixed position and does not need to be grasped (e.g., trackball, touch pad). <ul> <li>Look for a device with a \"drag\" button that locks the button click until the drag is completed.</li> <li>Consider using \"dwell-click\" software that initiates a click when the cursor dwells in a location.</li> <li>Make sure that inappropriate forces are not placed on the neck or teeth.</li> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard. However, this solution might work best when the employee has a private workstation. Coworkers may be tempted to move equipment and not reset it.</li></wafactors> <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors>",
        "solution_number": 61180101,
        "strategy": "Change the input method",
        "task": "Using a Computer",
        "trigger": "(a.U3 || a.U9 || a.U12 || a.U18) && (a.CMP) && (a.T6118)"
    },
    "61180102": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Strongly encouraged to make sure that inappropriate forces are not placed on the neck or teeth",
        "gap": "Hands-free cursor control method is needed",
        "solution": "Use mouthstick with keyboard commands",
        "solution_long": "Use a mouthstick with keyboard commands for software control.  Use StickyKeys (built into the operating system) to temporarily latch the Shift, Ctrl and Alt keys while another key is hit. <ul><li>In Windows, turn this feature on via the Control Panel, then either Accessibility or Ease of Access (depending on the specific operating system).</li> <li>For Macs, turn this feature on via the System Preferences, then Universal Access, then Keyboard.</li> <li>Make sure that inappropriate forces are not placed on the neck or teeth.</li> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard. However, this solution might work best when the employee has a private workstation. Coworkers may be tempted to move equipment and not reset it.</li></wafactors> <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors>",
        "solution_number": 61180102,
        "strategy": "Change the input method",
        "task": "Using a Computer",
        "trigger": "(a.U3 || a.U9 || a.U12 || a.U18) && (a.CMP) && (a.T6118)"
    },
    "61180103": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Strongly encouraged to make sure that inappropriate forces are not placed on the neck or teeth",
        "gap": "Hands-free cursor control method is needed",
        "solution": "Use a mouthstick with MouseKeys utility",
        "solution_long": "Use a mouthstick with the MouseKeys utility.  This feature (built into the operating system) allows the user to control the cursor through the number pad. <ul><li>Make sure that inappropriate forces are not placed on the neck or teeth.</li> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard. However, this solution might work best when the employee has a private workstation. Coworkers may be tempted to move equipment and not reset it.</li></wafactors> <wafactors PRT> <ul><li> Use a laptop or tablet if a portable solution is needed. </li></ul></wafactors></br>(Add link for directions)",
        "solution_number": 61180103,
        "strategy": "Change the input method",
        "task": "Using a Computer",
        "trigger": "(a.U3 || a.U9 || a.U12 || a.U18) && (a.CMP) && (a.T6118)"
    },
    "61180104": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Hands-free cursor control method is needed",
        "solution": "Use voice commands",
        "solution_long": "Choose menu commands and move the cursor with voice commands.  Keep in mind that speech recognition is good, but not perfect.  The employee will need to be able to recognize and fix recognition errors and not be frustrated when they occur. <ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer.  If this is a new accommodation, consultation with an AT expert is recommended.</li> <li>Some people find that voice commands are good for menu commands and general placement of the cursor, but another method might be better at fine control of the cursor.</li> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61180104,
        "strategy": "Change the input method",
        "task": "Using a Computer",
        "trigger": "(a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18) && (a.CMP) && (a.T6118)"
    },
    "61180105": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Hands-free cursor control method is needed",
        "solution": "Use head movements or gestures",
        "solution_long": "Use a cursor control device operated by head movements or gestures. <ul><li>These options can be among the more expensive options for computer access.  Consulting a computer access specialist is recommended.</li> <wafactors SHD MLT><li>This solution might work best when the employee has a single, private workstation. The equipment will need to be available at each workstation used, and coworkers may be tempted to move equipment and not reset it.</li></wafactors> </ul>",
        "solution_number": 61180105,
        "strategy": "Change the input method",
        "task": "Using a Computer",
        "trigger": "(a.U2 || a.U3 || a.U7 || a.U9 || a.U11 || a.U12 || a.U18) && (a.CMP) && (a.T6118)"
    },
    "61180106": {
        "expertise_level": 3,
        "expertise_text": "Expert assistance: Strongly encouraged due to the complexity and expense of the technology",
        "gap": "Hands-free cursor control method is needed",
        "solution": "Use eye movements",
        "solution_long": "Use a cursor control device operated by eye movements. <ul><li>These options can be among the more expensive options for computer access.  Consulting a computer access specialist is recommended.</li> <wafactors SHD MLT><li>This solution might work best when the employee has a single, private workstation. The equipment will need to be available at each workstation used, and coworkers may be tempted to move equipment and not reset it.</li></wafactors> </ul>",
        "solution_number": 61180106,
        "strategy": "Change the input method",
        "task": "Using a Computer",
        "trigger": "(a.U3 || a.U9 || a.U12 || a.U18) && (a.CMP) && (a.T6118)"
    },
    "61180107": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Hands-free cursor control method is needed",
        "solution": "Use switch-controlled scanning",
        "solution_long": "Use an on-screen keyboard with switch-controlled scanning.  For cursor movements, each mouse direction is highlighted in turn, and when the desired direction is displayed, the person activates a switch to select it.  <ul><li>A variety of switches can be used, including those operated with the tap of the head, by blowing into a straw, or by blinking an eye.</li> <li>This approach provides a very slow method of text entry or mouse control, so it is typically an option of last resort.</li> <wafactors SHD MLT><li>This solution might work best when the employee has a single, private workstation. The equipment will need to be available at each workstation used, and coworkers may be tempted to move equipment and not reset it.</li></wafactors> </ul>",
        "solution_number": 61180107,
        "strategy": "Change the input method",
        "task": "Using a Computer",
        "trigger": "(a.U3 || a.U9 || a.U12 || a.U18 || a.V1) && (a.CMP) && (a.T6118)"
    },
    "61190101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse cursor is difficult to track ",
        "solution": "Adjust the cursor size/shape settings ",
        "solution_long": "Adjust the cursor settings to change the size and/or shape of the cursor. ",
        "solution_number": 61190101,
        "strategy": "Change the cursor size/ shape",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.C6) && (a.CMP) && (a.T6119)"
    },
    "61190102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Mouse cursor is difficult to track ",
        "solution": "Use magnification software ",
        "solution_long": "Use magnification software to enlarge the cursor.  This is a preferred option for employees who also need to magnify other information on the computer screen. <ul><li>If the person has not previously used this type of technology, consultation with an assistive technology specialist may be helpful.</li></ul>",
        "solution_number": 61190102,
        "strategy": "Change the cursor size/ shape",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.C6) && (a.CMP) && (a.T6119)"
    },
    "61190201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse cursor is difficult to track ",
        "solution": "Adjust the cursor color settings ",
        "solution_long": "Adjust the cursor settings to change the color of the cursor. ",
        "solution_number": 61190201,
        "strategy": "Change the cursor color ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.C6) && (a.CMP) && (a.T6119)"
    },
    "61190301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse cursor is difficult to track ",
        "solution": "Use Magnification Settings in Windows/Mac",
        "solution_long": "Utilize built-in magnification settings in Windows or Mac operating systems to locate the cursor, such as the bullseye or focus indicator.",
        "solution_number": 61190301,
        "strategy": "Activate cursor tracking features",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.C6) && (a.CMP) && (a.T6119)"
    },
    "61190302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse cursor is difficult to track ",
        "solution": "Activate Cursor Trails",
        "solution_long": "Enable cursor trails to leave a visible tracking path as the cursor moves, making it easier to follow.",
        "solution_number": 61190302,
        "strategy": "Activate cursor tracking features",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.C6) && (a.CMP) && (a.T6119)"
    },
    "61190401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse cursor is difficult to track ",
        "solution": "Use a touch screen/window  ",
        "solution_long": "Use a touch screen / touch window so that the cursor will follow the position of the user's finger. ",
        "solution_number": 61190401,
        "strategy": "Use direct selection",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.C6) && (a.CMP) && (a.T6119)"
    },
    "61190501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Mouse cursor is difficult to track ",
        "solution": "Use hotkeys or keyboard commands ",
        "solution_long": "Use hotkeys / keyboard commands in place of clicking on menu commands. ",
        "solution_number": 61190501,
        "strategy": "Use the keyboard to perform tasks usually done with the mouse ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.C6) && (a.CMP) && (a.T6119)"
    },
    "61190601": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Mouse cursor is difficult to track ",
        "solution": "Use voice commands ",
        "solution_long": "Use a speech recognition system to control the computer with voice commands.  The employee will need to be able to recognize and fix recognition errors. <ul><li>If the person has not previously used this type of technology, consultation with an assistive technology specialist may be helpful. </li> <li>While some results may be seen out-of-the-box, it is essential that the employee receive proper training on how to use this technology. </li><ul>",
        "solution_number": 61190601,
        "strategy": "Use voice commands ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.C6) && (a.CMP) && (a.T6119)"
    },
    "61200101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Difficulty using a mouse to make menu selections",
        "solution": "Use hotkeys or keyboard commands",
        "solution_long": "Use hotkeys / keyboard commands in place of clicking on menu commands.",
        "solution_number": 61200101,
        "strategy": "Use keyboard commands ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.CMP) && (a.T6120)"
    },
    "61200201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Difficulty using a mouse to make menu selections",
        "solution": "Use voice commands",
        "solution_long": "Choose menu commands and move the cursor with voice commands.  Keep in mind that speech recognition is good, but not perfect.  The employee will need to be able to recognize and fix recognition errors and not be frustrated when they occur.<ul> <li>While some results may be seen out-of-the-box, it is important that the employee receive proper training on how to use this technology and already be comfortable using a computer.  If this is a new accommodation, consultation with an AT expert is recommended.</li> <wafactors NSY><li>This technology might be difficult to use in a noisy workplace.</li></wafactors> <wafactors MLT><li>Make sure that this software is available on each workstation computer that the employee uses (e.g., installed on it, available from a network or the Cloud).</li></wafactors> <wafactors SHD><li>Coworkers who also use the computer can continue using the standard computer keyboard or mouse.</li></wafactors> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors S1><li>If the employee also has difficulty thinking of the right words to say, he or she might have better success with a solution that uses some form of typing.</li></wafactors> <wafactors S2 S3 S4> <li>This technology might have difficulty recognizing non-typical speech. </li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/voice-input\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61200201,
        "strategy": "Use voice commands ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.V5 || a.V6 || a.H6) && (a.CMP) && (a.T6120)"
    },
    "61210101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Tracking / visual processing issues make it difficult  to read the screen ",
        "solution": "Use a fixed-width, sans serif font (e.g., Arial) ",
        "solution_long": "Change the default text style to a fixed-width, sans serif font (e.g., Arial, Helvetica, Verdana).  Many people with low vision or learning disabilities read slower when using fonts with serifs (e.g., Times New Roman). ",
        "solution_number": 61210101,
        "strategy": "Change the font",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.C6) && (a.CMP) && (a.T6121)"
    },
    "61210201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Tracking / visual processing issues make it difficult  to read the screen ",
        "solution": "Increase text spacing ",
        "solution_long": "Switch to 1.5 or double spacing when working on documents. ",
        "solution_number": 61210201,
        "strategy": "Magnify / increase text and icon size and spacing ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.C1 || a.C6) && (!a.V4) && (a.CMP) && (a.T6121)"
    },
    "61210202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Tracking / visual processing issues make it difficult  to read the screen ",
        "solution": "Change default text / icon size ",
        "solution_long": "Change the system settings (the Accessibility Options) to make the default text size and icons larger.",
        "solution_number": 61210202,
        "strategy": "Magnify / increase text and icon size and spacing ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.C1 || a.C6) && (!a.V4) && (a.CMP) && (a.T6121)"
    },
    "61210203": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Tracking / visual processing issues make it difficult  to read the screen ",
        "solution": "Use software Zoom features ",
        "solution_long": "Use the Zoom features within applications to display smaller text or images.  Note that this option will not change the size of menu bars or icons. ",
        "solution_number": 61210203,
        "strategy": "Magnify / increase text and icon size and spacing ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.C1 || a.C6) && (!a.V4) && (a.CMP) && (a.T6121)"
    },
    "61210301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Tracking / visual processing issues make it difficult  to read the screen ",
        "solution": "Use screen reading features built into the computer ",
        "solution_long": "Use the screen reading features built into the computer (e.g., Narrator).<ul><li>This solution addresses both print access and computer access issues.</li> <li>If this is a new accommodation, the employee may need help setting up and learning the system.  Consultation with an AT expert might be helpful.</li><li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/screen-reading-desktops\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61210301,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.C6) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6121)"
    },
    "61210302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Tracking / visual processing issues make it difficult  to read the screen ",
        "solution": "Use a text-to-speech / screen reading program ",
        "solution_long": "Use a text-to-speech or screen reading program on the computer which features word-by-word highlighting as the content/words are read aloud.  Other features to look for include adjustable reading rate, font size adjustment, and choice of voices.<ul><li>This solution addresses both print access and computer access issues.</li> <li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul>",
        "solution_number": 61210302,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(a.V3 || a.V4 || a.C1 || a.C6) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6121)"
    },
    "61220101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the screen is visible due to visual field deficit",
        "solution": "Move the computer ",
        "solution_long": "Reposition the computer screen, for example, by using a monitor stand, so that it falls within the person's field of vision.",
        "solution_number": 61220101,
        "strategy": "Change information position to compensate for visual field deficit",
        "task": "Using a Computer",
        "trigger": "(a.V4) && (a.LOW || a.CMP) && (a.T6122)"
    },
    "61220102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the screen is visible due to visual field deficit",
        "solution": "Use a smaller screen ",
        "solution_long": "Use a smaller screen that can be positioned in the person's field of vision.",
        "solution_number": 61220102,
        "strategy": "Change information position to compensate for visual field deficit",
        "task": "Using a Computer",
        "trigger": "(a.V4) && (a.LOW || a.CMP) && (a.T6122)"
    },
    "61220103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the screen is visible due to visual field deficit",
        "solution": "Reposition the information ",
        "solution_long": "Reposition information on the screen so it falls within the field of vision.  For example, for a person who can only see things to his or her right, position application windows toward the right side of the display.",
        "solution_number": 61220103,
        "strategy": "Change information position to compensate for visual field deficit",
        "task": "Using a Computer",
        "trigger": "(a.V4) && (a.CMP) && (a.T6122)"
    },
    "61220201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the screen is visible due to visual field deficit",
        "solution": "Change default text / icon size ",
        "solution_long": "Change the system settings (e.g., Accessibility Options) to make the default text size and icons smaller.",
        "solution_number": 61220201,
        "strategy": "Decrease text size to compensate for visual field deficit ",
        "task": "Using a Computer",
        "trigger": "(a.V4) && (a.CMP) && (a.T6122)"
    },
    "61220202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Only part of the screen is visible due to visual field deficit",
        "solution": "Use software Zoom features ",
        "solution_long": "Use the Zoom features within applications to display smaller text or images.  Note that this option will not change the size of menu bars or icons. ",
        "solution_number": 61220202,
        "strategy": "Decrease text size to compensate for visual field deficit ",
        "task": "Using a Computer",
        "trigger": "(a.V4) && (a.CMP) && (a.T6122)"
    },
    "61220301": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Only part of the screen is visible due to visual field deficit",
        "solution": "Use screen reading features built into the computer ",
        "solution_long": "Use the screen reading features built into the computer (e.g., Narrator).<ul><li>This solution addresses both print access and computer access issues.</li> <li>If this is a new accommodation, the employee may need help setting up and learning the system.  Consultation with an AT expert might be helpful.</li><li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/screen-reading-desktops\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61220301,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6122)"
    },
    "61220302": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Only part of the screen is visible due to visual field deficit",
        "solution": "Use a dedicated screen reading program ",
        "solution_long": "Use dedicated screen reading software on the computer.<ul><li>This solution addresses both print access and computer access issues.</li> <li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers. </li></wafactors></ul>",
        "solution_number": 61220302,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(a.V4) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6122)"
    },
    "61230101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Move the computer",
        "solution_long": "Reposition the computer screen so that it does not face windows or bright lights. ",
        "solution_number": 61230101,
        "strategy": "Change the way light hits the screen, to reduce glare",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.C6) && (a.LOW || a.CMP) && (a.T6123)"
    },
    "61230102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Redirect artificial lighting ",
        "solution_long": "Redirect lighting away from the screen, possibly by using adjustable task lighting instead of overhead lights.  ",
        "solution_number": 61230102,
        "strategy": "Change the way light hits the screen, to reduce glare",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.C6) && (a.LOW || a.CMP) && (a.T6123)"
    },
    "61230103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Reduce artificial lighting ",
        "solution_long": "Reduce the amount of light on the screen by turning off or dimming lights.  Install dimmer switches, or place a shade or filtering cover on the lighting.  Change the light bulb type / wattage / color to provide less light. ",
        "solution_number": 61230103,
        "strategy": "Change the way light hits the screen, to reduce glare",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.C6) && (a.LOW || a.CMP) && (a.T6123)"
    },
    "61230104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Reduce light from windows ",
        "solution_long": "Reduce the light from windows by closing curtains or blinds that are producing glare on the screen, or by applying tinted film on windows. ",
        "solution_number": 61230104,
        "strategy": "Change the way light hits the screen, to reduce glare",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.C6) && (a.LOW || a.CMP) && (a.T6123)"
    },
    "61230105": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Use an anti-glare shield over the screen ",
        "solution_long": "Use an anti-glare shield mounted on the screen. ",
        "solution_number": 61230105,
        "strategy": "Change the way light hits the screen, to reduce glare",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.C6) && (a.LOW || a.CMP) && (a.T6123)"
    },
    "61230106": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Use an anti-glare shield around the screen",
        "solution_long": "Provide a shield on the top and sides of the screen to block light.  This could be as simple as some cardboard placed around the screen. ",
        "solution_number": 61230106,
        "strategy": "Change the way light hits the screen, to reduce glare",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.C6) && (a.LOW || a.CMP) && (a.T6123)"
    },
    "61230201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Wear tinted glasses or a visor ",
        "solution_long": "Allow the employee to wear tinted glasses (e.g., sunglasses) or a hat with a visor. ",
        "solution_number": 61230201,
        "strategy": "Shade the employee’s eyes from bright lights",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.C6) && (a.LOW || a.CMP) && (a.T6123)"
    },
    "61230301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Adjust the screen brightness ",
        "solution_long": "Use the Screen controls to adjust the brightness of the computer screen. ",
        "solution_number": 61230301,
        "strategy": "Change the brightness of the screen",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.C6) && (a.CMP) && (a.T6123)"
    },
    "61230302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Change the type of screen ",
        "solution_long": "Try a different LED, LCD, or plasma screen.  Due to differences in brightness, colors, flicker, and resolution, one type of screen might be easier for a person to read than another. ",
        "solution_number": 61230302,
        "strategy": "Change the brightness of the screen",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.C6) && (a.CMP) && (a.T6123)"
    },
    "61230401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Change the font color ",
        "solution_long": "Change the color of the font within software applications. ",
        "solution_number": 61230401,
        "strategy": "Change the color / contrast of text & icons",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V3 || a.C6) && (a.CMP) && (a.T6123)"
    },
    "61230402": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Change the color scheme ",
        "solution_long": "Use another color scheme (e.g., inverted text with white or yellow text on black or dark blue; grey scale), by adjusting the operating system settings.",
        "solution_number": 61230402,
        "strategy": "Change the color / contrast of text & icons",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V3 || a.C6) && (a.CMP) && (a.T6123)"
    },
    "61230403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Simplify backgrounds ",
        "solution_long": "Avoid documents and webpages with busy backgrounds (e.g., watermarks). ",
        "solution_number": 61230403,
        "strategy": "Change the color / contrast of text & icons",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V3 || a.C6) && (a.CMP) && (a.T6123)"
    },
    "61230404": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Use personal color preferences ",
        "solution_long": "Use software that allows personal color preference settings to be maintained.  Look for this design feature when purchasing software.  Include this design criteria when creating websites for internal use. ",
        "solution_number": 61230404,
        "strategy": "Change the color / contrast of text & icons",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V3 || a.C6) && (a.CMP) && (a.T6123)"
    },
    "61230501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Use a fixed-width, sans serif font (e.g., Arial) ",
        "solution_long": "Change the default text style to a fixed-width, sans serif font (e.g., Arial, Helvetica, Verdana).  Many people with low vision or learning disabilities read slower when using fonts with serifs (e.g., Times New Roman). ",
        "solution_number": 61230501,
        "strategy": "Change the font",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V3 || a.C6) && (a.CMP) && (a.T6123)"
    },
    "61230601": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Use a larger screen ",
        "solution_long": "Use a larger screen.  Note, however, that this will produce only a minor improvement in size.  This solution is best used in conjunction with other strategies for enlarging the information.",
        "solution_number": 61230601,
        "strategy": "Magnify / increase text and icon size",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V3) && (a.CMP) && (a.T6123)"
    },
    "61230602": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Use software Zoom feature",
        "solution_long": "Use the Zoom features within applications to display larger text or images.  Note that this option will not change the size of menu bars or icons. ",
        "solution_number": 61230602,
        "strategy": "Magnify / increase text and icon size",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V3 || a.C6) && (a.CMP) && (a.T6123)"
    },
    "61230603": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Change default text / icon size ",
        "solution_long": "Change the system settings (the Accessibility Options) to make the default text size and icons larger.",
        "solution_number": 61230603,
        "strategy": "Magnify / increase text and icon size",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V3 || a.C6) && (a.CMP) && (a.T6123)"
    },
    "61230604": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Use magnification software ",
        "solution_long": "Use magnification software to enlarge the information on the computer screen.  This is a preferred option for employees who need greater levels of magnification.",
        "solution_number": 61230604,
        "strategy": "Magnify / increase text and icon size",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V3) && (a.CMP) && (a.T6123)"
    },
    "61230605": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Use a magnifier attached to the screen ",
        "solution_long": "Use a magnifier mounted on the screen.  This approach will not provide a large amount of magnification, however, and we generally recommend trying one of the other options. ",
        "solution_number": 61230605,
        "strategy": "Magnify / increase text and icon size",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V3) && (a.CMP) && (a.T6123)"
    },
    "61230701": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Use screen reading features built into the computer",
        "solution_long": "Use the screen reading features built into the computer (e.g., Narrator).<ul><li>This solution addresses both print access and computer access issues.</li> <li>If this is a new accommodation, the employee may need help setting up and learning the system.  Consultation with an AT expert might be helpful.</li><li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/screen-reading-desktops\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61230701,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.C6) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6123)"
    },
    "61230702": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Use a dedicated screen reading program",
        "solution_long": "Use dedicated screen reading software (e.g., JAWS) on the computer.<ul><li>This solution addresses both print access and computer access issues.</li> <li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers. </li></wafactors></ul>",
        "solution_number": 61230702,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6123)"
    },
    "61230703": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Color, contrast, and/or glare makes screen difficult to see or causes eye strain  ",
        "solution": "Use a text-to-speech program",
        "solution_long": "Use a text-to-speech or screen reading program on the computer which features word-by-word highlighting as the content/words are read aloud.  Other features to look for include adjustable reading rate, font size adjustment, and choice of voices.<ul><li>This solution addresses both print access and computer access issues.</li> <li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors></ul>",
        "solution_number": 61230703,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V3 || a.C6) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6123)"
    },
    "61240101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Small size makes screen difficult to see or causes eye strain",
        "solution": "Use a screen stand ",
        "solution_long": "Use a screen stand to move the screen closer to the person. ",
        "solution_number": 61240101,
        "strategy": "Move the screen closer  ",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.LOW || a.CMP) && (a.T6124)"
    },
    "61240201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Small size makes screen difficult to see or causes eye strain",
        "solution": "Use a fixed-width, sans serif font (e.g., Arial) ",
        "solution_long": "Change the default text style to a fixed-width, sans serif font (e.g., Arial, Helvetica, Verdana).  Many people with low vision or learning disabilities read slower when using fonts with serifs (e.g., Times New Roman). ",
        "solution_number": 61240201,
        "strategy": "Change the font",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.CMP) && (a.T6124)"
    },
    "61240301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Small size makes screen difficult to see or causes eye strain",
        "solution": "Use a larger screen ",
        "solution_long": "Use a larger screen.  Note, however, that this will produce only a minor improvement in size.  This solution is best used in conjunction with other strategies for enlarging the information. ",
        "solution_number": 61240301,
        "strategy": "Magnify / increase text and icon size ",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.LOW || a.CMP) && (a.T6124)"
    },
    "61240302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Small size makes screen difficult to see or causes eye strain",
        "solution": "Use software Zoom features ",
        "solution_long": "Use the Zoom features within applications to display larger text or images.  Note that this option will not change the size of menu bars or icons. ",
        "solution_number": 61240302,
        "strategy": "Magnify / increase text and icon size ",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.CMP) && (a.T6124)"
    },
    "61240303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Small size makes screen difficult to see or causes eye strain",
        "solution": "Change default text / icon size ",
        "solution_long": "Change the system settings (the Accessibility Options) to make the default text size and icons larger. ",
        "solution_number": 61240303,
        "strategy": "Magnify / increase text and icon size ",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.CMP) && (a.T6124)"
    },
    "61240304": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Small size makes screen difficult to see or causes eye strain",
        "solution": "Use magnification software ",
        "solution_long": "Use magnification software to enlarge the information on the computer screen.  This is a preferred option for employees who need greater levels of magnification.",
        "solution_number": 61240304,
        "strategy": "Magnify / increase text and icon size ",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.CMP) && (a.T6124)"
    },
    "61240305": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Small size makes screen difficult to see or causes eye strain",
        "solution": "Use a magnifier attached to the screen ",
        "solution_long": "Use a magnifier mounted on the screen.  This approach will not provide a large amount of magnification, however, and we generally recommend trying one of the other options.",
        "solution_number": 61240305,
        "strategy": "Magnify / increase text and icon size ",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (a.CMP) && (a.T6124)"
    },
    "61240401": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Small size makes screen difficult to see or causes eye strain",
        "solution": "Use screen reading features built into the computer ",
        "solution_long": "Use the screen reading features built into the computer (e.g., Narrator).<ul><li>This solution addresses both print access and computer access issues.</li> <li>If this is a new accommodation, the employee may need help setting up and learning the system.  Consultation with an AT expert might be helpful.</li><li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/screen-reading-desktops\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61240401,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6124)"
    },
    "61240402": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Small size makes screen difficult to see or causes eye strain",
        "solution": "Use a dedicated screen reading program ",
        "solution_long": "Use dedicated screen reading software (e.g., JAWS) on the computer.<ul><li>This solution addresses both print access and computer access issues.</li> <li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers. </li></wafactors></ul>",
        "solution_number": 61240402,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6124)"
    },
    "61240403": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Small size makes screen difficult to see or causes eye strain",
        "solution": "Use software with described images ",
        "solution_long": "Use software that tags graphical information with text that can be spoken by software.  Images should have text descriptions.  Buttons should have descriptive labels.  Look for this design feature when purchasing software.  Include this design criteria (e.g., use of alt tags) when creating websites for internal use. ",
        "solution_number": 61240403,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(a.V3) && (!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6124)"
    },
    "61250101": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Information cannot be seen at all",
        "solution": "Use screen reading features built into the computer ",
        "solution_long": "Use the screen reading features built into the computer (e.g., Narrator).<ul><li>This solution addresses both print access and computer access issues.</li> <li>If this is a new accommodation, the employee may need help setting up and learning the system.  Consultation with an AT expert might be helpful.</li><li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers.</li></wafactors><wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li> </wafactors></ul><br /><a href= \"https://assistivesoftware.gatech.edu/screen-reading-desktops\" target= \"_blank\">Find more information about this type of product.</a>",
        "solution_number": 61250101,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6125)"
    },
    "61250102": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Information cannot be seen at all",
        "solution": "Use a dedicated screen reading program ",
        "solution_long": "Use dedicated screen reading software (e.g., JAWS) on the computer.<ul><li>This solution addresses both print access and computer access issues.</li> <li>It is important that the employee be trained on how to use the software or have prior experience using it.</li> <wafactors PRT><li>Use a laptop or tablet if a portable solution is needed.</li></wafactors> <wafactors OPN NSY><li>Use headphones to block competing noise, maintain privacy, and avoid disturbing other workers. </li></wafactors></ul>",
        "solution_number": 61250102,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6125)"
    },
    "61250103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Information cannot be seen at all",
        "solution": "Use software with described images ",
        "solution_long": "Use software that tags graphical information with text that can be spoken by software.  Images should have text descriptions.  Buttons should have descriptive labels.  Look for this design feature when purchasing software.  Include this design criteria (e.g., use of alt tags) when creating websites for internal use. ",
        "solution_number": 61250103,
        "strategy": "Present information with sound ",
        "task": "Using a Computer",
        "trigger": "(!a.V6) && (!a.H5) && (!a.H6) && (a.CMP) && (a.T6125)"
    },
    "61250201": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful if the accommodation is new and the employee needs to learn how to use the technology",
        "gap": "Information cannot be seen at all",
        "solution": "Present information with touch",
        "solution_long": "Use a refreshable Braille computer display.  However, first make sure that the employee knows Braille.  Note that refreshable Braille displays can be expensive, so if this is the employee's first time using this type of accommodation, an assistive technology specialist should be consulted. ",
        "solution_number": 61250201,
        "strategy": "Present information with touch",
        "task": "Using a Computer",
        "trigger": "(a.V6 || a.H6) && (a.CMP) && (a.T6125)"
    },
    "61250202": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Information cannot be seen at all",
        "solution": "Use software with described images ",
        "solution_long": "Use software that tags graphical information with text that can be spoken by software.  Images should have text descriptions.  Buttons should have descriptive labels.  Look for this design feature when purchasing software.  Include this design criteria (e.g., use of alt tags) when creating websites for internal use. ",
        "solution_number": 61250202,
        "strategy": "Present information with touch",
        "task": "Using a Computer",
        "trigger": "(a.V6 || a.H6) && (a.CMP) && (a.T6125)"
    },
    "73010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Items are too heavy / bulky to carry",
        "solution": "Use a handtruck or dolly  ",
        "solution_long": "Use a handtruck or dolly to provide a wheeled means to move items, particularly boxes. ",
        "solution_number": 73010101,
        "strategy": "Use an aid that supports the item's weight",
        "task": "Carrying Materials",
        "trigger": "(a.L4 || a.L5 || a.L8a || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T7301)"
    },
    "73010102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Items are too heavy / bulky to carry",
        "solution": "Use a cart / table on wheels  ",
        "solution_long": "Use a cart or wheeled table to support the weight of the item while it is being moved across the workplace. ",
        "solution_number": 73010102,
        "strategy": "Use an aid that supports the item's weight",
        "task": "Carrying Materials",
        "trigger": "(a.L4 || a.L5 || a.L8a || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T7301)"
    },
    "73010103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Items are too heavy / bulky to carry",
        "solution": "Use lift assist system ",
        "solution_long": "Use a lift assist system that uses machinery to lift the item or supports the weight of the item while the employer moves it into the desired position. ",
        "solution_number": 73010103,
        "strategy": "Use an aid that supports the item's weight",
        "task": "Carrying Materials",
        "trigger": "(a.L4 || a.L5 || a.L8a || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T7301)"
    },
    "73010104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Items are too heavy / bulky to carry",
        "solution": "Use a conveyor belt ",
        "solution_long": "Use a conveyor belt to move the item across the workplace. ",
        "solution_number": 73010104,
        "strategy": "Use an aid that supports the item's weight",
        "task": "Carrying Materials",
        "trigger": "(a.L4 || a.L5 || a.L8a || a.U1 || a.U4 || a.U5) && (a.LOW) && (a.T7301)"
    },
    "73020101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Person has difficulty with balance when carrying",
        "solution": "Use a cart ",
        "solution_long": "Use a cart to carry items.  The cart will also provide some stability while standing or walking. ",
        "solution_number": 73020101,
        "strategy": "Use a carrying aid that also helps with balance",
        "task": "Carrying Materials",
        "trigger": "(a.L4 || a.L8a) && (a.LOW) && (a.T7302)"
    },
    "73020102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Person has difficulty with balance when carrying",
        "solution": "Use a carrier for mobility aids ",
        "solution_long": "Attach a carrier (e.g., bag, tray, basket) to the employee's rollator (a type of wheeled walker), wheelchair, or scooter. ",
        "solution_number": 73020102,
        "strategy": "Use a carrying aid that also helps with balance",
        "task": "Carrying Materials",
        "trigger": "(a.L4 || a.L8a) && (a.LOW) && (a.T7302)"
    },
    "73030101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Person does not have free hands (e.g., using mobility aid)",
        "solution": "Use a cart ",
        "solution_long": "Use a cart that can be pushed with the body or wheelchair. ",
        "solution_number": 73030101,
        "strategy": "Use a hands-free carrying aid",
        "task": "Carrying Materials",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T7303)"
    },
    "73030102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Person does not have free hands (e.g., using mobility aid)",
        "solution": "Use a carrier for mobility aids ",
        "solution_long": "Attach a carrier (e.g., bag, tray, basket) to the employee's rollator (a type of wheeled walker), wheelchair, or scooter",
        "solution_number": 73030102,
        "strategy": "Use a hands-free carrying aid",
        "task": "Carrying Materials",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T7303)"
    },
    "73030103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Person does not have free hands (e.g., using mobility aid)",
        "solution": "Use a conveyor belt ",
        "solution_long": "Use a conveyor belt to move the item across the workplace. ",
        "solution_number": 73030103,
        "strategy": "Use a hands-free carrying aid",
        "task": "Carrying Materials",
        "trigger": "(a.L8b || a.L8c || a.L8d || a.L8e) && (a.LOW) && (a.T7303)"
    },
    "81010101": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Seek counseling / therapy",
        "solution_long": "The employee may benefit from talking to a mental health counselor. <ul><li>Flexible working hours can make it easier for the employee to attend counseling.</li> <li>Virtual / online therapy might be more convenient.  The employee can meet with a counselor over a computer rather than travel to a doctor's office.  Examples of online therapy programs include BetterHelp and Talkspace.</li></ul>",
        "solution_number": 81010101,
        "strategy": "Use support services",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010102": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use an app for mental health support",
        "solution_long": "Use an app to provide mental health support.  Apps such as MindShift, Calm, Balance, etc. can be used to track emotions, and for journaling.",
        "solution_number": 81010102,
        "strategy": "Use support services",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.CMP || a.MOB) && (a.T8101)"
    },
    "81010103": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use an Employee Assistance Program",
        "solution_long": "Employee Assistance Program (EAP) services can help workers with personal issues that can affect their performance at work.  The issues can include substance abuse, caregiving, relationships, money management, legal problems, wellness, or workplace violence. <ul><li>EAP programs are often included as part of employee benefits and are free for workers.</li> <li>The services may include phone, video, online, or face-to-face counseling.</li> <li>Spouses/partners and children of employees may also be eligible for EAP services.</li> <li>EAPs that provide direct counseling rather than just referrals for counseling are regulated under ERISA and are subject to COBRA.</li></ul>",
        "solution_number": 81010103,
        "strategy": "Use support services",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010104": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Work with a job coach",
        "solution_long": "Job coaches provide personalized training to help people with disabilities adjust to their job and travel to and from work.  They may suggest accommodations and offer ongoing support to overcome barriers and make the workplace more inclusive.",
        "solution_number": 81010104,
        "strategy": "Use support services",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010105": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Work with a support person",
        "solution_long": "A \"buddy\" such as a co-worker, job coach or close contact may provide support and a safe space for the employee.  The support person may help the employee stay focused and handle social situations.  During important meetings (e.g., job evaluations) a support person might help with questions, remembering discussion points, and clarifying outcomes. <ul><li>Make sure that all parties are careful with confidentiality issues and disclosure of the employee's disability.</li></ul>",
        "solution_number": 81010105,
        "strategy": "Use support services",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010106": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Find a mentor",
        "solution_long": "A mentor is a trusted and experienced individual who provides guidance and support to someone with less or different experience. Mentors help the mentee develop their skills, reach their goals, and deal with challenges. They offer encouragement and helpful criticism.  They may serve as role models.  The key is that there is a personal connection of support. <ul><li>An employee may find their own mentor or be matched through a formal program.  Mentors might be found within the place of employment, professional organizations, or other networking groups.</li> <li>A mentor may meet with a single employee or a group, and may meet face-to-face or at a distance via phone or video conferencing.</li></ul>",
        "solution_number": 81010106,
        "strategy": "Use support services",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010201": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use a support animal",
        "solution_long": "Emotional support animals, usually cats or dogs, may be prescribed by a therapist.  These pets provide comfort, companionship, security, and a sense of emotional stability to their owners. <ul><li>Emotional support animals do not include trained service animals (e.g., those trained to recognize PTSD, etc.).  The ADA does not require employers to accept emotional support animals at work.</li> <li>Be aware that some coworkers may be afraid of the animal.  <wafactors SHD>It could also be a distraction to coworkers.  A private workstation is recommended.</wafactors></li> <li>If the employer is willing to consider this as an option, set expectations for how the animal will behave and will be handled at work.  A trial period might help demonstrate whether the animal can meet these expectations.</li></ul>",
        "solution_number": 81010201,
        "strategy": "Use a support animal",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010301": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use grounding techniques",
        "solution_long": "Grounding techniques can help a person manage stressful memories / flashbacks, negative thoughts, or strong emotions.  They use mental distractions to shift focus away from upsetting memories and feelings and bring attention back to the present moment. <ul><li>Examples: Play a memory game. Think in categories. Use math and numbers. Recite something. Make yourself laugh. Use an anchoring phrase. Engage in positive self-talk or express gratitude.  Visualize a daily task you enjoy or don't mind doing.</li></ul>",
        "solution_number": 81010301,
        "strategy": "Use relaxation strategies",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010302": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use meditation",
        "solution_long": "Meditation is a practice that involves training the mind to focus and redirect thoughts to find an inner calmness.  It is often used to help a person relax, reduce stress, and feel better overall.  During meditation, individuals use techniques like deep breathing, being mindful or aware of the present moment, or imagining peaceful scenes.  Some people may combine it with prayer.  With regular practice, meditation can improve concentration, reduce fear and stress, improve self-awareness, and support a sense of inner peace and balance. <ul><li>Meditation can be done anywhere.  Although it might be mostly done outside of work, it can be useful in the workplace to help a person calm down.</li> <li>Some wearable fitness trackers have features that can assist with meditation.  There are also apps that help guide meditation.</li></ul>",
        "solution_number": 81010302,
        "strategy": "Use relaxation strategies",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010303": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Try yoga or tai chi",
        "solution_long": "Yoga uses breathing, meditation, and specific postures to relax and improve health.  Tai chi is a form of martial art that is a form of moving meditation.  It combines slow, continuous movements with mental focus and deep breathing.  Both can be used to increase energy, reduce stress, and support relaxation and sleep.  They can also improve overall health including strength, balance, flexibility, and heart health. <ul><li>Although yoga or tai chi might be mostly done outside of work, it can be useful in the workplace to help a person remain calm or regulate their emotions and energy.</li> <li>Seated or chair yoga is an option for people who have difficulties with mobility or balance.  It also might be easier to do during work breaks.</li></ul>",
        "solution_number": 81010303,
        "strategy": "Use relaxation strategies",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.U1) && (!a.U2) && (!a.U3) && (!a.U6) && (!a.U7) && (!a.U8) && (!a.U9) && (a.LOW) && (a.T8101)"
    },
    "81010304": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use a modified break schedule",
        "solution_long": "A modified break schedule is a personalized plan for taking breaks at work that better suit a person's preferences, health requirements, or productivity levels.  This could include taking breaks that are more often, longer, or at different times than the usual schedule.",
        "solution_number": 81010304,
        "strategy": "Use relaxation strategies",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010305": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use a weighted blanket",
        "solution_long": "Pressure from a weighted blanket or vest can copy a therapeutic technique called deep pressure stimulation.  This technique has been shown to relieve pain, lessen anxiety, and improve mood.  Both the blanket and vest can be used as much as is needed.  Weighted blankets usually weigh 5 to 30 pounds.  Weighted vests should have a snug fit.",
        "solution_number": 81010305,
        "strategy": "Use relaxation strategies",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010401": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Adjust lighting",
        "solution_long": "Light settings can affect a person's mood.  Experiment with adding or dimming lights or changing their tint. <ul><li>For example, lamps can simulate the natural light of the sun at varying brightness levels.</li> <li>Florescent lights, which tend to strobe and have harsh light, can be replaced with lamps, or might be covered with paper for a softer effect.</li> <wafactors SHD><li>Make sure that lighting changes also meet the needs of coworkers who will be using the space.</li> </wafactors> </ul>",
        "solution_number": 81010401,
        "strategy": "Improve mood with light",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V5) && (!a.V6) && (!a.H6) && (a.LOW) && (a.T8101)"
    },
    "81010501": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Block out sound distractions ",
        "solution_long": "Use noise-cancelling headphones, music, or white noise to block out distracting noises. <ul><li>Try calming music, such as classical or instrumental mediation music, that will not break the employee's concentration. </li> <li>Make sure that these solutions are compatible with the worker's tasks and still allow warning sounds and alarms to be heard.</li></ul>",
        "solution_number": 81010501,
        "strategy": "Reduce sound distractions",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.H1 || a.H2 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.V3) && (!a.V4) && (!a.V5) && (!a.V6) && (!a.H3) && (!a.H4) && (!a.H5) && (!a.H6) && (a.LOW) && (a.T8101)"
    },
    "81010502": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Move to a quiet work area ",
        "solution_long": "Find a quieter area for the employee's workstation to minimize distractions.  This might include using a private office or working in a less busy area. <ul><li>It is important that the new location does not isolate the employee and still allows them to interact with their colleagues.</li></ul>",
        "solution_number": 81010502,
        "strategy": "Reduce sound distractions",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010601": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Be aware of environmental scents",
        "solution_long": "Be aware of environmental scents.  Strong smells can be distracting or overwhelming in certain individuals, leading to increased stress levels.  Certain smells may also trigger upsetting (or calming) memories.  However, what may be stress-reducing for one person may not be the same for another.  A scent-free environment might provide a good compromise. <wafactors SHD><ul><li>Since this change impacts coworkers, it might be easiest to implement when an employee has a private work area.</li> </ul></wafactors>",
        "solution_number": 81010601,
        "strategy": "Control environmental scents",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010701": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Exercise",
        "solution_long": "Exercise helps to channel and release pent-up energy in a healthy and productive way, reducing restlessness.  Exercise also releases brain chemicals that act as natural mood elevators and reduces the levels of stress hormones in the body.  This can reduce feelings of fear or stress.  Regular exercise can also lead to better sleep patterns and can improve overall health.",
        "solution_number": 81010701,
        "strategy": "Burn off excess energy",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010702": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use hand-held fidgets",
        "solution_long": "Use a hand-held fidget device (e.g., stress ball).  Fidget toys are designed to take on the stress of repeated movement, and are also a great way to soothe nerves, calm the mind, and improve focus. <ul><li>Other examples include fidget spinners, Playdoh, and worry beads.</li> <li>These gadgets have also been proven to be a good way to replace unwanted habits like shaking a leg, biting nails or cracking knuckles.</li></ul>",
        "solution_number": 81010702,
        "strategy": "Burn off excess energy",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U10 || a.U13 || a.U14 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.U11) && (!a.U12) && (!a.U15) && (!a.U16) && (!a.U17) && (!a.U18) && (a.LOW) && (a.T8101)"
    },
    "81010703": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use a sit-stand workstation  ",
        "solution_long": "A sit-stand workstation usually consists of a desk or work surface that can be adjusted in height to fit both sitting and standing workers.  These workstations make it easier for individuals to change positions while working.",
        "solution_number": 81010703,
        "strategy": "Burn off excess energy",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (!a.L3) && (!a.L4) && (!a.L8a) && (!a.L8b) && (!a.L8c) && (!a.L8d) && (!a.L8e) && (a.LOW) && (a.T8101)"
    },
    "81010704": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Sit on a ball chair ",
        "solution_long": "A ball chair features a large exercise ball as its seating surface instead of a traditional chair seat. The constant micro-adjustments needed to stay balanced on the ball can improve posture and provide workers with an opportunity to move around, reducing restlessness.  Many ball chairs come with a stable base that holds the exercise ball in place. Features may include adjustable height settings, backrests, or armrests. <ul><li>It can be tiring to sit on this type of chair for long periods.  The worker may want to alternate between the ball chair and a standard chair.</li> <li>A ball chair may not be appropriate for a person with poor balance or back pain.  Check with a healthcare professional or ergonomics specialist.</li> <wafactors SHD><li>This solution might work best when the employee has a private workstation.  There may be a risk of coworkers moving or not properly resetting the equipment at shared workstations.</li></wafactors></ul>",
        "solution_number": 81010704,
        "strategy": "Burn off excess energy",
        "task": "Maintaining Well-Being",
        "trigger": "(!a.L3) && (!a.L4) && (!a.L8a) && (!a.L8b) && (!a.L8c) && (!a.L8d) && (!a.L8e) && (a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010705": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to address the ergonomics of this workstation change",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use an exercycle workstation ",
        "solution_long": "An exercycle workstation, also known as a desk cycle or under-desk cycle, is a combination of an exercycle and workstation.  The user sits in a chair and pedals the exercycle while reading or doing other desk-based work.  It provides workers with an opportunity to move around, reducing restlessness.  Also, exercise has been found to improve a person's mood and reduce feelings of fear or stress. <wafactors SHD><ul> <li>This solution might work best when the employee has a private workstation.  In shared workstations, there may be a risk of coworkers moving or not properly resetting the equipment.</li> </ul></wafactors>",
        "solution_number": 81010705,
        "strategy": "Burn off excess energy",
        "task": "Maintaining Well-Being",
        "trigger": "(!a.L7) && (!a.L8a) && (!a.L8b) && (!a.L8c) && (!a.L8d) && (!a.L8e) && (a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010801": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Improve communication",
        "solution_long": "Poor communication between the workers and with a supervisor can increase stress and behavioral issues.  Make sure that everyone is communicating in a way that can be understood.  Visit our section on Communication tasks for more ideas.",
        "solution_number": 81010801,
        "strategy": "Adjust supervisory practices",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010802": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Provide supervisory feedback",
        "solution_long": "Provide feedback to help employees improve in areas where they are having difficulty. <ul><li>A supervisor may need to change the method by which feedback is provided (e.g., face-to-face, writing, email).</li> <li>Reinforce good behaviors by having supervisors provide positive feedback, either verbally or in writing.</li></ul>",
        "solution_number": 81010802,
        "strategy": "Adjust supervisory practices",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010803": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Help the employee follow behavior rules",
        "solution_long": "Make sure employees behave appropriately at work by doing the following: <ul><li>Teach employees how to act appropriately in social settings through training sessions.</li> <li>Provide visual reminders of rules in key work areas.</li> <li>Give feedback quickly and clearly whenever there is a problem.</li> <li>Show specific examples of what is right and wrong when addressing a problem.</li></ul>",
        "solution_number": 81010803,
        "strategy": "Adjust supervisory practices",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010804": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Avoid surprises",
        "solution_long": "Uncertainty and new situations can be stressful.  Let the employee know what to expect in advance. <ul><li>For example, provide an agenda before meetings.</li></ul>",
        "solution_number": 81010804,
        "strategy": "Adjust supervisory practices",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010805": {
        "expertise_level": 2,
        "expertise_text": "Expert assistance: Might be helpful to provide the training",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Provide disability awareness training",
        "solution_long": "Disability awareness and etiquette trainings help workers learn about the challenges that people with disabilities face.  They also encourage workers to treat everyone fairly and equally.  Workers learn about common misunderstandings, effective communication, using the right words and manners, and making the workplace accessible to everyone. These trainings can help workers learn to be more respectful and feel more at ease when interacting with their coworkers who have disabilities.",
        "solution_number": 81010805,
        "strategy": "Adjust supervisory practices",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010901": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use a flexible schedule",
        "solution_long": "Employees can use a flexible schedule to work when they are most productive and to accommodate personal needs.  This might include changing start and end times of the workday, combining regular breaks into an extended break, or dividing large breaks into smaller ones.  Flexible schedules can provide workers with opportunities for mental rest and a chance to refocus on work.",
        "solution_number": 81010901,
        "strategy": "Change the job/setting",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010902": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use job restructuring",
        "solution_long": "Job restructuring is the process of changing how work is organized, assigned, or done to fit an employee's skills, abilities, or limitations.  The goal is to create a better fit between the employee and their job.  For example, if an employee has significant fear or stress when making presentations to large groups, a coworker might do that task while the employee takes charge of a written report instead.",
        "solution_number": 81010902,
        "strategy": "Change the job/setting",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010903": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Use a private rest area",
        "solution_long": "A private rest area can provide an employee with a safe, quiet space to relax and reduce anxiety.  The worker can use the space for calming activities such as deep breathing exercises, meditation, or simply taking a break from social interactions.  The employee may also use the space to rest or take medicine.",
        "solution_number": 81010903,
        "strategy": "Change the job/setting",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    },
    "81010904": {
        "expertise_level": 1,
        "expertise_text": "Expert assistance: Not needed",
        "gap": "Managing Anxiety, Stress, or Emotions",
        "solution": "Work from home/remotely",
        "solution_long": "Try working from home or remotely.  Remote workers typically have more time and fewer distractions, which leads to increased productivity and performance.  Another benefit is not having to commute. <ul><li>Make sure that remote work is not being suggested to avoid interacting with the employee.  Do not isolate the employee.</li></ul>",
        "solution_number": 81010904,
        "strategy": "Change the job/setting",
        "task": "Maintaining Well-Being",
        "trigger": "(a.L1 || a.L2 || a.L3 || a.L4 || a.L5 || a.L6 || a.L7 || a.L8a || a.L8b || a.L8c || a.L8d || a.L8e || a.U1 || a.U2 || a.U3 || a.U4 || a.U5 || a.U6 || a.U7 || a.U8 || a.U9 || a.U10 || a.U11 || a.U12 || a.U13 || a.U14 || a.U15 || a.U16 || a.U17 || a.U18 || a.V1 || a.V2 || a.V3 || a.V4 || a.V5 || a.V6 || a.H1 || a.H2 || a.H3 || a.H4 || a.H5 || a.H6 || a.S1 || a.S2 || a.S3 || a.S4 || a.S5 || a.C1 || a.C2 || a.C3 || a.C4 || a.C5 || a.C6 || a.C7 || a.C8 || a.A1) && (a.LOW) && (a.T8101)"
    }
}
export default rules;