import React, {Component} from 'react';
import logo from './logo.svg';

//import RouteHook from 'react-route-hook';

//import 'bootstrap/dist/css/bootstrap_lux.min.css';
//import './css/sb-admin-2.min.css';
//import './css/bootstrap_cosmo.min.css';

//import './App.css';

import './scss/App.scss';



import {Button, ButtonToolbar, Navbar, Nav, NavDropdown, Card, Row, Col} from 'react-bootstrap';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
} from "react-router-dom";

import {
    LinkContainer
}
from "react-router-bootstrap";

import {MdMenu} from 'react-icons/md';

import _ from 'lodash';

import LogInForm from './components/LogInForm';
import NavPanel from './components/NavPanel';
import Home from "./components/Home";
import NeedDifficultiesProfile from "./components/NeedDifficultiesProfile";
import NeedJobReqsProfile from "./components/NeedJobReqsProfile";
import Difficulties from "./components/Difficulties";
import Difficulties2 from "./components/Difficulties2";
import DifficultiesIndividual from "./components/DifficultiesIndividual";
import DifficultiesMore from "./components/DifficultiesMore";
import JobRequirements from "./components/JobRequirements2";
import JobRequirementsIndividual from "./components/JobRequirementsIndividual";
import JobRequirementsMore from "./components/JobRequirementsMore";
import ExampleAssessment from "./components/ExampleAssessment";
import Solution from "./components/ExampleSolution";
import SolutionDetailed from "./components/ExampleSolutionDetailed";
import OnBoard from "./components/OnBoard";
import LogIn from "./components/LogIn";
import Profile from "./components/Profile";

import NoolsDemo from "./components/NoolsDemo5";

import WorkACCESSRulesEngine from "./components/WorkACCESSRulesEngine";

import WorkACCESSTasksRulesEngine from "./components/WorkACCESSTasksRulesEngine";

import PreviousSessions from "./components/PreviousSessions";

import Favorites from "./components/Favorites";

import AdditionalFactorsTechnology from "./components/AdditionalFactorsTechnology";

import AdditionalFactorsWorkArea from "./components/AdditionalFactorsWorkArea";

import Glossary from "./components/Glossary";

// Supertokens

// COMMENT OUT TO DISABLE Supertokens
import SuperTokens, { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import axios from "axios";


import withRulesEngine from "./components/DataSourceHOC";
import StorageAdmin from "./components/StorageAdmin";
import BestPractices from "./components/BestPractices";
import FindExperts from "./components/FindExperts";
import AdditionalFactorsInteraction from "./components/AdditionalFactorsInteraction";
import OtherResources from "./components/OtherResources";
import Funding from "./components/Funding";
import Experts from "./components/Experts";
import LearnAboutProject from "./components/LearnAboutProject";
import ContactUs from "./components/ContactUs";
// import { signOut } from "supertokens-auth-react/recipe/emailpassword";


// SET TO FALSE TO DISABLE Supertokens
const USE_SUPERTOKENS = true;
const SAVE_WITH_SUPERTOKENS = false;
const LOCAL_SUPERTOKENS = false

// UNCOMMENT TO DISABLE Supertokens
//     let SuperTokens = null;
//     let EmailPassword = null;
//     let Session = null;
//     let axios = null;
//     let getSuperTokensRoutesForReactRouterDom = null;


// Add Supertokens session management to axios
if(USE_SUPERTOKENS)
    Session.addAxiosInterceptors(axios);

// const localDev = false;

// export function getApiDomain() {
//     const apiPort = localDev ? 3001 : 80;
//     const apiUrl = localDev ? `http://localhost:${apiPort}` : "https://workaccess-re.imtc.gatech.edu";
//     return apiUrl;
// }

// export function getWebsiteDomain() {
//     const websitePort = localDev ? 3000 : 80;
//     const websiteUrl = localDev ? `http://localhost:${websitePort}` : "https://workaccess-re.imtc.gatech.edu";
//     return websiteUrl;
// }

if(USE_SUPERTOKENS) {
    SuperTokens.init({
        appInfo: {
            appName: "WorkACCESS",
            apiDomain: LOCAL_SUPERTOKENS ?  "http://localhost:3001" : "https://workaccess.ipat.gatech.edu",
            websiteDomain: LOCAL_SUPERTOKENS ? "http://localhost:3000" : "https://workaccess.ipat.gatech.edu" ,
            apiBasePath: "/supertokens",
            websiteBasePath: "/auth",
        },
        recipeList: [
            EmailPassword.init({
                signInAndUpFeature: {
                    signUpForm: {
                        formFields: [{
                            id: "name",
                            label: "Full name",
                            placeholder: "First name and last name"
                        }, {
                            id: "age",
                            label: "Your age",
                            placeholder: "How old are you?",
                            optional: true
                        }, {
                            id: "country",
                            label: "Your country",
                            placeholder: "Where do you live?",
                            optional: true
                        }]
                    }
                },
                emailVerificationFeature: {
                    mode: "REQUIRED",
                },
                onHandleEvent: (context) => {
                    if (context.action === "EMAIL_VERIFIED_SUCCESSFUL") {
                        console.log('EMAIL_VERIFIED_SUCCESSFUL!');
                    } else if (context.action === "PASSWORD_RESET_SUCCESSFUL") {
                        console.log('PASSWORD_RESET_SUCCESSFUL');
                    } else if (context.action === "RESET_PASSWORD_EMAIL_SENT") {
                        console.log('RESET_PASSWORD_EMAIL_SENT');
                    } else if (context.action === "SESSION_ALREADY_EXISTS") {
                        // called when a user visits the login / sign up page with a valid session
                        // in this case, they are usually redirected to the main app
                        console.log('SESSION_ALREADY_EXISTS');
                    } else if (context.action === "SUCCESS") {
                        let user = context.user;
                        if (context.isNewUser) {
                            // sign up success
                            //alert('New Signup Success!');
                        } else {
                            // sign in success
                            //alert(`Signed in now: ${JSON.stringify(user, null, 2)}`);
                        }
                    } else if (context.action === "VERIFY_EMAIL_SENT") {

                    }
                }
            }),
            Session.init(),
        ]
    });

}

class App extends Component {

    storeActiveSessionRemote;


/* -- Jeff's constructor
    constructor(props) {
        super(props);

        let activeSession = this.getActiveSession();

        console.log("activeSession");
        console.log(JSON.stringify(activeSession));


        let sessions = this.getSessions();


        // Special case to make sure active session is in the list
        let ind = sessions.findIndex(s => s.id === activeSession.id);
        if(ind > -1)
        {
            console.log("active session update at pos: " + ind);
            sessions[ind] = activeSession;
        }
        else
        {
            console.log("active session add");
            sessions.push(activeSession);
        }

        console.log("SESSIONS ARE NOW:");
        console.log(JSON.stringify(sessions));

        this.state = {
            showLogIn: false,

            activeSession: activeSession,
            sessions: sessions,

            rulesEngine:new WorkACCESSRulesEngine(),

            // this handles showing tasks based on abilities selected
            tasksRuleEngine: new WorkACCESSTasksRulesEngine(),


            favorites: {}
        }


        this.JobReqFaq = withRulesEngine(JobRequirementsIndividual, this.state.tasksRuleEngine); //, "facility_access"
        this.JobReqComm = withRulesEngine(JobRequirementsIndividual, this.state.tasksRuleEngine); //, "communication"
        this.JobReqOrg = withRulesEngine(JobRequirementsIndividual, this.state.tasksRuleEngine); //, "organization"
        this.JobReqEquip = withRulesEngine(JobRequirementsIndividual, this.state.tasksRuleEngine); //, "using_equipment"


        // we use storeXXX instead of setAndStoreXXX b/c we don't want to use setState() in constructor
        this.storeActiveSession(activeSession);
        // keep the active session up to date in the list of sessions too

        this.storeSessions(sessions);
        this.storeSession(activeSession);
    }
 */
    constructor(props) {
        super(props);

        this.apiBaseUrl = LOCAL_SUPERTOKENS ? 'http://localhost:3001' : 'https://workaccess.ipat.gatech.edu';
        // this.apiBaseUrl = 'http://localhost:3001';

        // Initialize component state
        this.state = {
            showLogIn: false,
            isLoggedIn: false,

            activeSession: {},
            activeSessionId: null,
            sessions: [],
            sessionsId: null,

            rulesEngine: new WorkACCESSRulesEngine(),

            // this handles showing tasks based on abilities selected
            tasksRuleEngine: new WorkACCESSTasksRulesEngine(),

            favorites: {}
        }


        /*
        let activeSession = this.getActiveSession();

        console.log("activeSession");
        console.log(JSON.stringify(activeSession));


        let sessions = this.getSessions();


        // Special case to make sure active session is in the list
        let ind = sessions.findIndex(s => s.id === activeSession.id);
        if(ind > -1)
        {
            console.log("active session update at pos: " + ind);
            sessions[ind] = activeSession;
        }
        else
        {
            console.log("active session add");
            sessions.push(activeSession);
        }

        console.log("SESSIONS ARE NOW:");
        console.log(JSON.stringify(sessions));

        this.state = {
            showLogIn: false,
            isLoggedIn: false,

            activeSession: activeSession,
            sessions: sessions,

            rulesEngine: new WorkACCESSRulesEngine(),

            // this handles showing tasks based on abilities selected
            tasksRuleEngine: new WorkACCESSTasksRulesEngine(),


            favorites: {}
        }


        // we use storeXXX instead of setAndStoreXXX b/c we don't want to use setState() in constructor
        this.storeActiveSession(activeSession);
        // keep the active session up to date in the list of sessions too

        this.storeSessions(sessions);
        this.storeSession(activeSession);
         */
    }

    // Check for active SuperTokens session when app is loaded
    componentDidMount = async () => {
        if(USE_SUPERTOKENS)
            await this.checkForAuthSession();

        let activeSession = this.getActiveSession();

        console.log("activeSession");
        console.log(JSON.stringify(activeSession));

        let sessions = this.getSessions();

        // Special case to make sure active session is in the list
        let ind = sessions.findIndex(s => s.id === activeSession.id);
        if(ind > -1)
        {
            console.log("active session update at pos: " + ind);
            sessions[ind] = activeSession;
        }
        else
        {
            console.log("active session add");
            sessions.push(activeSession);
        }

        console.log("SESSIONS ARE NOW:");
        console.log(JSON.stringify(sessions));

        let favorites = this.getFavorites();

        // Update state with session data
        this.setState({
            activeSession: activeSession,
            sessions: sessions,
            favorites: favorites,
        });

        // this.state = {
        //     showLogIn: false,
        //     isLoggedIn: false,
        //
        //     activeSession: activeSession,
        //     sessions: sessions,
        //
        //     rulesEngine: new WorkACCESSRulesEngine(),
        //
        //     favorites: {}
        // }


        this.JobReqFaq = withRulesEngine(JobRequirementsIndividual, this.state.tasksRuleEngine); //, "facility_access"
        this.JobReqWorkstation = withRulesEngine(JobRequirementsIndividual, this.state.tasksRuleEngine); //, "workstation"
        this.JobReqOrg = withRulesEngine(JobRequirementsIndividual, this.state.tasksRuleEngine); //, "organization"
        this.JobReqComm = withRulesEngine(JobRequirementsIndividual, this.state.tasksRuleEngine); //, "communication"
        this.JobReqEquip = withRulesEngine(JobRequirementsIndividual, this.state.tasksRuleEngine); //, now using_tools was using_equipment
        this.JobReqWellbeing = withRulesEngine(JobRequirementsIndividual, this.state.tasksRuleEngine);
        //this.JobReqWorkplace = withRulesEngine(JobRequirementsIndividual, this.state.tasksRuleEngine); //, "workplace"


        this.storeActiveSession(activeSession);
        // keep the active session up to date in the list of sessions too
        this.storeSessions(sessions);
        this.storeSession(activeSession);
    }

    // SuperTokens session signout
    signOut = async () => {
        console.log("******  SIGNING OUT !!!!! *********");
        await Session.signOut();
        this.setState({ isLoggedIn: false });
        // setIsLoggedIn(false);
    }

    // Check if SuperTokens session exists
    checkForAuthSession = async () => {
        if (await Session.doesSessionExist()) {
            this.setState({ isLoggedIn: true });
            //setIsLoggedIn(true);
            console.log("IS logged in!")
        }
        else
        {
            console.log("NOT logged in!");
        }
    }

    storeActiveSession(val)
    {
        console.log("StoreActiveSession");

        try {
            let jsonItem = JSON.stringify(val);
            localStorage.setItem('activeSession', jsonItem);

            // Store activeSession via API
            // this.storeActiveSessionRemote(val).then(result => {
            //     console.log(`Done storing activeSession via API: ${result}`);
            // });
        }catch(e) {
            console.log("FAILED to store JSON to localStorage.activeSession");
            console.log(val);
        }
    }

    /**
     * storeActiveSessionRemote(activeSession)
     * @param activeSession
     * Store session data via Web API
     */
    storeActiveSessionRemote = async (activeSession) => {
        console.log('storeSessionsRemote');

        try {
            const data = {
                data: { activeSession: activeSession },
                title: 'activeSession',
            };
            console.log('Attempting to save session data via web API to ' + this.apiBaseUrl + '/data');
            const response = await axios.post(`${this.apiBaseUrl}/data`, data);
            console.log(`Successfully stored activeSession web API: ${response}`);
        } catch (e) {
            console.log('Failed to store activeSession via web API');
        }
    }

    setAndStoreActiveSession(val)
    {
        //let activeSession = this.state.activeSession;

        console.log("setAndStoreActiveSession");
        console.log(JSON.stringify(val));

        let sessions = this.state.sessions;

        let ind = sessions.findIndex(s => s.id === val.id);

        if(ind >= -1)
        {
            sessions[ind] = val;
        }
        else
        {
            sessions.push(val);
        }

        this.setState(
            {
                activeSession: val,
                sessions: sessions
            }, () => {

                this.storeActiveSession(val);
                this.storeSessions(sessions);

                if(USE_SUPERTOKENS && SAVE_WITH_SUPERTOKENS) {
                    this.storeActiveSessionRemote(val)
                        .then(result => {
                            this.storeSessionsRemote(sessions)
                                .then(result => {
                                    console.log('Done storing remote sessions!!!');
                                });
                        });
                }
            }
        )
    }

    storeSessions(val)
    {
        console.log("storeSessions");

        try {
            let jsonItem = JSON.stringify(val);
            console.log("writing to local storage!");
            localStorage.setItem('sessions', jsonItem);

            // Store activeSession via API
            if(USE_SUPERTOKENS && SAVE_WITH_SUPERTOKENS) {
                this.storeSessionsRemote(val).then(result => {
                    console.log(`Done storing sessions via API: ${result}`);
                });
            }
        }catch(e) {
            console.log("FAILED to store JSON to localStorage.sessions");
            console.log(val);
        }
    }

    /**
     * storeSessionsRemote(sessions)
     * @param sessions
     * Store session data via Web API
     */
    // storeSessionsRemote = async (sessions) => {
    async storeSessionsRemote(sessions)
        {
        console.log('storeSessionsRemote');

        try {
            const data = {
                data: { sessions: sessions },
                title: 'sessions',
            };
            console.log('Attempting to save session data via web API to ' + this.apiBaseUrl + '/data');
            const response = await axios.post(`${this.apiBaseUrl}/data`, data);
            console.log(`Successfully stored JSON data via web API: ${response}`);
        } catch (e) {
            console.log('Failed to store JSON data via web API');
        }
    }

    storeSession(val)
    {
        console.log("storeSession");

        let sessions = this.state.sessions;

        let ind = sessions.findIndex(s => s.id === val.id);

        if(ind >= -1)
        {
            sessions[ind] = val;
        }
        else
        {
            sessions.push(val);
        }

        this.storeSessions(sessions);
    }

    setAndStoreSession(val)
    {
        console.log("setAndStoreSession");

        let sessions = this.state.sessions;

        let ind = sessions.findIndex(s => s.id === val.id);

        if(ind >= -1)
        {
            sessions[ind] = val;
        }
        else
        {
            sessions.push(val);
        }


        this.setState(
            {
                sessions: sessions
            }, () => {

                this.storeSessions(sessions);

                //TODO: shouldn't this be promise chain?

                if(USE_SUPERTOKENS && SAVE_WITH_SUPERTOKENS) {
                    this.storeSessionsRemote(sessions)
                        .then(result => {
                            console.log('Done storing remote sessions!!!');
                        });
                }
            }
        )
    }


    getActiveSession()
    {
        console.log("in active session");

        let activeSession = this.localState("activeSession");

        if (!activeSession)
        {
            console.log("NULL");
            activeSession = {};
        }

        if(!activeSession.id)
        {
            activeSession.id = this.makeDateStr();
        }

        if (!activeSession.dbId) {
            activeSession.dbId = null;
        }

        if(!activeSession.difficulties)
        {
            activeSession.difficulties = [];
        }

        if(!activeSession.difficultiesGroups)
        {
            activeSession.difficultiesGroups = [];
        }

        if(!activeSession.techPrefs)
        {
            activeSession.techPrefs = [];
        }

        if(!activeSession.interactionPrefs)
        {
            activeSession.interactionPrefs = [];
        }

        if(!activeSession.workAreaPrefs)
        {
            activeSession.workAreaPrefs = [];
        }

        if(!activeSession.tasks)
        {
            activeSession.tasks = [];
        }

        if(!activeSession.midTasks)
        {
            activeSession.midTasks = {};
        }

        if(!activeSession.taskGroups)
        {
            activeSession.taskGroups = [];
        }

        return activeSession;
    }

    getSessions()
    {
        let sessions = this.localState("sessions");

        if (!sessions)
        {
            sessions = [];
        }

        return sessions;
    }

    getRemoteSessions = async () => {
        try {
            const response = await axios.get(`${this.apiBaseUrl}/data`);
            const { data } = response.data;

            console.log("Remote session data is: ");
            console.log(data);

            return data;
        } catch (e) {
            console.log(`Error in getRemoteSessions: ${e.message}`);
        }

        return [];
    };

    createSession()
    {
        let activeSession = {
            id: this.makeDateStr(),
            // database record ID obtained from SuperTokens API
            dbId: null,
            difficulties:[],
            tasks:[],
            midTasks:{},
            taskGroups:[],
            difficultiesGroups:[],
            // These must get updated if AdditionalFactors[Technology|WorkArea] changes
            techPrefs:[ 'LOW', 'CMP', 'MOB', 'nLOW', 'nCMP', 'nMOB'],
            interactionPrefs:['SML', 'LRG', 'ONS', 'REM', 'nSML', 'nLRG', 'nONS', 'nREM'],
            workAreaPrefs:['nADA', 'nMLT', 'nNSY', 'nOPN', 'nSHD', 'nSTR']
        };

        return activeSession;
    }


    storeFavorites(val)
    {
        console.log("storeFavorites");

        try {
            let jsonItem = JSON.stringify(val);
            console.log("writing favorites to local storage!");
            localStorage.setItem('favorites', jsonItem);
        }catch(e) {
            console.log("FAILED to store JSON to localStorage.favorites");
            console.log(val);
        }
    }

    getFavorites()
    {
        let favs = this.localState("favorites");

        if (!favs)
        {
            favs = {};
        }

        return favs;
    }


    setAndStoreFavorite(val, doDelete=false)
    {
        console.log("setAndStoreFavorite");

        let faves = _.clone(this.state.favorites);

        let solnNum = val.rule.solution_number;

        if(doDelete) {
            if (_.has(faves, solnNum))
                delete faves[solnNum];
            else
                console.log("Couldn't delete fave. Not present.");
        }
        else {
            faves[solnNum] = val;
        }

        this.setState(
            {
                favorites: faves
            }, () => {

                this.storeFavorites(faves);

            }
        )
    }

    clearFavorites()
    {
        console.log("clearFavorites");


        this.setState(
            {
                favorites: {}
            }, () => {

                localStorage.setItem('favorites', '{}');

            }
        )
    }

    clearSession(id)
    {
        console.log("clearSession(id)");

        // this.setState(
        //     {
        //         sessions: []
        //     }, () => {
        //
        //         localStorage.setItem('sessions', '[]');
        //
        //         if(USE_SUPERTOKENS && SAVE_WITH_SUPERTOKENS) {
        //             this.clearSessionsRemote()
        //                 .then(result => {
        //                     console.log('Done clearing remote sessions!!!');
        //                 });
        //         }
        //
        //     }
        // )
    }



    clearSessions()
    {
        console.log("clearSessions");


        this.setState(
            {
                sessions: []
            }, () => {

                localStorage.setItem('sessions', '[]');

                if(USE_SUPERTOKENS && SAVE_WITH_SUPERTOKENS) {
                    this.clearSessionsRemote()
                        .then(result => {
                            console.log('Done clearing remote sessions!!!');
                        });
                }

            }
        )
    }

    /**
     * clearSessionsRemote()
     * Clear session data via Web API
     */
    // storeSessionsRemote = async (sessions) => {
    async clearSessionsRemote()
    {
        console.log('clearSessionsRemote');

        try {
            const data = {
                data: { sessions: [] },
                title: 'sessions',
            };
            console.log('Attempting to clear session data via web API to ' + this.apiBaseUrl + '/data');
            const response = await axios.post(`${this.apiBaseUrl}/data`, data);
            console.log(`Successfully stored JSON data via web API: ${response}`);
        } catch (e) {
            console.log('Failed to store JSON data via web API');
        }
    }



    makeDateStr()
    {
        let d = new Date();

        //return d.format("dd/mm/yyy hh:MM TT");
        return d.toLocaleString();
    }

    // startNewActiveSession()
    startNewActiveSession = async () => {
        console.log("startNewActiveSession");
        let session = this.createSession();

        this.setAndStoreActiveSession(session);


        // TODO this is not atomic with setAndStoreActiveSession() above. Is that an issue?
        //this.setAndStoreSession(session);
    }

    // clearCurrentCase()
    // {
    //     localStorage.clear();
    //
    //     console.log("after clear new session");
    //     let s = this.createSession();
    //     console.log(JSON.stringify(s));
    //
    //     this.setState({"activeSession":s});//,  "selectedDifficulties":[], "selectedTasks": []});
    // }

    localState(key)
    {
        let item = localStorage.getItem(key);
        let jsonItem = null;

        if(item)
        {
            try {
                jsonItem = JSON.parse(item);
            } catch(e) {
                console.log("could not parse json from local state");
                console.log(item);
                console.log(typeof item);
                jsonItem = null;
            }
        }
        else
        {
            console.log("localState: Item: " +key+" is null");
        }

        //return jsonItem || [];
        return jsonItem;
    }


    makeSessionActive(val, nextAction)
    {
        console.log("makeSessionActive");

        let sessions = this.state.sessions;

        let ind = sessions.findIndex(s => s.id === val);

        let newS = this.state.activeSession;

        if(ind >= -1)
        {
            newS = sessions[ind];
        }
        else
        {
            console.error("COULD NOT SWITCH TO NEW SESSION! " + val);
        }

        this.setState(
            {
                activeSession: newS
            }, () => {

                this.storeActiveSession(newS);

                nextAction();
            }
        )

    }


    updateFavorites(val)
    {
        let faves = _.clone(this.state.favorites);

        if(_.has(faves, val.rule.solution_number))
            delete faves[val.rule.solution_number];
        else
            faves[val.rule.solution_number] = val;

        this.storeFavorites(faves);
    }

    updateSelectedTechPrefs(val)
    {
        let sess = this.state.activeSession;
        console.log('updateSelectedTechPrefs');
        console.log(val)
        sess.techPrefs = val;

        this.setAndStoreActiveSession(sess);
    }

    updateSelectedInteractionPrefs(val)
    {
        let sess = this.state.activeSession;
        console.log('updateSelectedInteractionPrefs');
        console.log(val)
        sess.interactionPrefs = val;

        this.setAndStoreActiveSession(sess);
    }

    updateSelectedWorkAreaPrefs(val)
    {
        let sess = this.state.activeSession;

        //TODO
        sess.workAreaPrefs = val;

        this.setAndStoreActiveSession(sess);
    }


    updateSelectedDifficultiesGroups(val)
    {
        let sess = this.state.activeSession;

        sess.difficultiesGroups = val;

        this.setAndStoreActiveSession(sess);
    }


    updateSelectedDifficulties(val)
    {
        console.log("APP level updateSelectedDifficulties! " + val);
        console.log(typeof val);
        console.log(val);

        let sess = this.state.activeSession;

        sess.difficulties = val;

        this.setAndStoreActiveSession(sess);

        // TODO not atomic with above, does it need to be?
        //this.setAndStoreSession(sess);

        // this.setState(
        //     {
        //         //selectedDifficulties: val
        //         activeSession: sess
        //     }, () => {
        //
        //         try {
        //             // let jsonItem = JSON.stringify(val);
        //             // console.log("Storing: ^^" + jsonItem + "^^");
        //             // localStorage.setItem('selectedDifficulties', jsonItem);
        //             let jsonItem = JSON.stringify(sess);
        //             localStorage.setItem('activeSession', jsonItem);
        //         }catch(e) {
        //             console.log("FAILED to store JSON to activeSession.difficulties");
        //             console.log(sess);
        //         }
        //     }
        // )
    }


    updateSelectedTaskGroups(val)
    {
        let sess = this.state.activeSession;

        console.log("updateSelectedTaskGroups");
        console.log(JSON.stringify(sess));
        console.log("passed val: " + val);

        sess.taskGroups = val;

        this.setAndStoreActiveSession(sess);


    }


    updateSelectedMidTasks(val)
    {
        console.log("APP level updateSelectedMidTasks! " + val);

        let sess = this.state.activeSession;

        sess.midTasks = val;

        this.setAndStoreActiveSession(sess);

    }



    updateSelectedTasks(val)
    {
        console.log("APP level updateSelectedTasks! " + val);

        let sess = this.state.activeSession;

        sess.tasks = val;

        this.setAndStoreActiveSession(sess);

        // TODO not atomic with above, does it need to be?
        //this.setAndStoreSession(sess);

        // this.setState(
        //     {
        //         //selectedTasks: val
        //         activeSession: sess
        //     }, () => {
        //         try {
        //             //let jsonItem = JSON.stringify(val);
        //             //localStorage.setItem('selectedTasks', val);
        //             let jsonItem = JSON.stringify(sess);
        //             localStorage.setItem('activeSession', jsonItem);
        //         }catch(e) {
        //             console.log("FAILED to store JSON to activeSession.tasks");
        //             console.log(sess);
        //         }
        //     }
        // )
    }



    //noolsObj = null;

    render() {


        // if(this.noolsObj === null)
        // {
        //     this.noolsObj = <NoolsDemo selectedTasks={this.state.selectedTasks} selectedDifficulties={this.state.selectedDifficulties}/>;
        // }


        console.log("DIFFICULTIES: " + this.state.activeSession.difficulties);
        console.log("TASKS: " + this.state.activeSession.tasks);
        console.log("TASK_GROUPS: " + this.state.activeSession.taskGroups);

        //let test_tasks = ['M4'];
        //let test_difficulties = ['T0100', 'T1000', 'T1001'];


        let progDenom = 16 * (1/100.0);


        let indvDiffEndPath = "NOT_USED"; //"/need_tech_pref";
        let indvJobReqsEndPath = "/need_tech_pref";

        let indvDiffStartPath = '/need_difficulties';


        let JobReqFaq = this.JobReqFaq;
        let JobReqWorkstation = this.JobReqWorkstation;
        let JobReqOrg = this.JobReqOrg;
        let JobReqComm = this.JobReqComm;
        let JobReqEquip = this.JobReqEquip;
        let JobReqWellbeing = this.JobReqWellbeing;
        //let JobReqWorkplace = this.JobReqWorkplace;



        let supertokensRoutes = null;

        let homeDom = <Home newAssessmentPath={"/need_job_reqs"} completeFollowupPath={"/saved_difficulties"} revisePath={"/revise_difficulties"} favoritesPath={"/favorites"}/>;

        if(USE_SUPERTOKENS) {
            supertokensRoutes = getSuperTokensRoutesForReactRouterDom(require("react-router-dom"));
            homeDom =
                <EmailPassword.EmailPasswordAuth>
                    <Home newAssessmentPath={"/need_job_reqs"} completeFollowupPath={"/saved_difficulties"}
                          revisePath={"/revise_difficulties"} favoritesPath={"/favorites"}/>
                </EmailPassword.EmailPasswordAuth>;

        }

        return (


            <Router>

                <div className="App">

                    {/*<Navbar bg="dark" variant="dark" expand="lg">*/}
                    {/*    <Navbar bg="light" variant="light" expand="lg">*/}
                    <Navbar>
                        {/*<Link to="/home"><Navbar.Brand>Work ACCESS</Navbar.Brand></Link>*/}
                        <Link to="/home" ><Navbar.Brand><img src="/work_access_sm.png"/></Navbar.Brand></Link>

                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">

                            {/*<NavDropdown title={ <MdMenu/> } id={"basic-nav-dropdown"} >*/}
                            {/*    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                            {/*    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
                            {/*    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                            {/*</NavDropdown>*/}

                            {/*<Nav className="ml-auto">*/}
                            {/*    <Link to="/home" tabindex={-1}><Nav.Link className="rounded-bottom" href="/home" >HOME</Nav.Link></Link>*/}
                            {/*    <Link to="/home" tabindex={-1}><Nav.Link className="rounded-bottom" href="/profile" >USER PROFILE</Nav.Link></Link>*/}
                            {/*    <Link to="/home" tabindex={-1}><Nav.Link className="rounded-bottom" href="/profile" >RESOURCES</Nav.Link></Link>*/}
                            {/*    <Link to="/home" tabindex={-1}><Nav.Link className="rounded-bottom" href="/profile" >ABOUT</Nav.Link></Link>*/}
                            {/*</Nav>*/}

                            {/*<Nav className="ml-auto">*/}
                            <Nav className="ml-auto"
                                 onSelectf={async (selectedKey) => {
                                     if (selectedKey === 'logout') {
                                         await this.signOut();
                                         window.location.href = "/";
                                     }
                                 }}
                            >
                                <Link to="/home" tabindex={-1}><Nav.Link className="rounded-bottom" href="/home" >HOME</Nav.Link></Link>
                                {/*Not sure if the the next three Links should be included*/}
                                {
                                    USE_SUPERTOKENS ?
                                    <Link to={USE_SUPERTOKENS ? "/auth" : "/home"} tabindex={-1}><Nav.Link
                                        className="rounded-bottom" href="/auth"
                                        eventKey={USE_SUPERTOKENS ? "logout" : ""}>
                                        {USE_SUPERTOKENS ? (this.state.isLoggedIn ? 'LOGOUT' : 'LOGIN') : "USER"}
                                    </Nav.Link></Link>
                                        : <div></div>
                                }
                                {/*<Link to="/home" tabindex={-1}><Nav.Link className="rounded-bottom" href="/profile" >ABOUT</Nav.Link></Link>*/}
                                {/*<Link to="/home" tabindex={-1}><Nav.Link className="rounded-bottom" href="/profile" >CONTACT</Nav.Link></Link>*/}
                                {/*<Nav.Link href="/profile"><MdMenu/></Nav.Link>*/}

                                <NavDropdown title={ "USER PROFILE" }   className="rounded-bottom">
                                    {/*<LinkContainer to="/saved_difficulties"><NavDropdown.Item>Previous Assessments</NavDropdown.Item></LinkContainer>*/}
                                    {/*<LinkContainer to="/favorites"><NavDropdown.Item>Favorites</NavDropdown.Item></LinkContainer>*/}
                                    {/*<LinkContainer to="/logout"><NavDropdown.Item>Logout</NavDropdown.Item></LinkContainer>*/}
                                    <NavDropdown.Item as={Link} to="/saved_difficulties">Previous Assessments</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/favorites">Favorites</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/logout">Logout</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title={ "RESOURCES" }   className="rounded-bottom">
                                    {/*<LinkContainer to="/instructions"><NavDropdown.Item>Work ACCESS Instructions</NavDropdown.Item></LinkContainer>*/}
                                    {/*<LinkContainer to="/experts"><NavDropdown.Item>Find Experts</NavDropdown.Item></LinkContainer>*/}
                                    {/*<LinkContainer to="/funding"><NavDropdown.Item>Find Funding</NavDropdown.Item></LinkContainer>*/}
                                    {/*<LinkContainer to="/accom_resources"><NavDropdown.Item>Other Accommodation Resources</NavDropdown.Item></LinkContainer>*/}
                                    {/*<LinkContainer to="/glossary"><NavDropdown.Item>Glossary</NavDropdown.Item></LinkContainer>*/}
                                    <NavDropdown.Item as={Link} to="/best_practices">Best Practices</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/experts">Find Experts</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="funding">Find Funding</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="accom_resources">Other Accommodation Resources</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/glossary">Glossary</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown  title={ "ABOUT" }   className="rounded-bottom"
                                >

                                    {/*<LinkContainer to="/learn"><NavDropdown.Item>Learn about the project</NavDropdown.Item></LinkContainer>*/}
                                    {/*<LinkContainer to="/contact"><NavDropdown.Item>Contact us</NavDropdown.Item></LinkContainer>*/}
                                    <NavDropdown.Item as={Link} to="/learn">Learn about the project</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/contact">Contact us</NavDropdown.Item>
                                </NavDropdown>


                            </Nav>

                        </Navbar.Collapse>

                    </Navbar>
                    <hr />

                    <div>


                        {/* A <Switch> looks through its children <Route>s and
                          renders the first one that matches the current URL. */}
                        <Switch>
                             {/* This shows the login UI on "/auth" route */}
                            {supertokensRoutes}

                            <Route
                                path="/alt_logout"
                                element={
                                    (() => {
                                        this.signOut();
                                        return null; // Render nothing as we don't need a UI here.
                                    })()
                                }
                            />

                            <Route path="/nools/:taskIndex?/:gapIndex?/:stratIndex?" render={(props)=> {
                                return <NoolsDemo selectedTasks={this.state.activeSession.tasks}
                                                  selectedDifficulties={this.state.activeSession.difficulties}
                                                  selectedTechPrefs={this.state.activeSession.techPrefs}
                                                  selectedInteractionPrefs={this.state.activeSession.interactionPrefs}
                                                  selectedWorkAreaPrefs={this.state.activeSession.workAreaPrefs}
                                                  session={this.state.rulesEngine.session}
                                                  basePath={"/nools"}
                                                  // startPath={
                                                  //     // TODO here is where to adjust if a new entry point is necessary
                                                  //     // Also, this approach works for chaining together to other sequences
                                                  //     // like computer tech preference
                                                  //     !_.isNil(this.state.activeSession.difficultiesGroups) &&
                                                  //         this.state.activeSession.difficultiesGroups.length > 0 ?
                                                  //     this.state.activeSession.difficultiesGroups[0] :
                                                  //         "/need_difficulties"
                                                  // }
                                                  startPath={"/need_work_area_pref"}
                                                  taskIndex={props.match.params.taskIndex}
                                                  gapIndex={props.match.params.gapIndex}
                                                  stratIndex={props.match.params.stratIndex}
                                                  favorites={this.state.favorites}
                                                  storeFavorite={(favorite, rawHTML) => {
                                                      let cfav = _.clone(favorite);
                                                      cfav.rule.rawHTML = rawHTML;
                                                      console.log("favorite to store: ")
                                                      console.log(favorite);
                                                      console.log("favorites to add to: ");
                                                      console.log(this.state.favorites);

                                                      let faves = this.state.favorites;

                                                      //let faves = [...this.state.favorites];

                                                      // let faves = _.clone(this.state.favorites);
                                                      //
                                                      // if(_.has(faves, favorite.rule.solution_number))
                                                      //     delete faves[favorite.rule.solution_number];
                                                      // else
                                                      //   faves[favorite.rule.solution_number] = favorite;
                                                      //
                                                      // this.setState({favorites: faves})

                                                      let doDelete = false;

                                                      if(_.has(faves, cfav.rule.solution_number))
                                                          doDelete = true;

                                                      this.setAndStoreFavorite(cfav, doDelete);
                                                  }}
                                />
                            }}>
                            </Route>

                            <Route path="/onboard">
                                <OnBoard onCompletePath={"/login"} />
                            </Route>

                            {/*<Route path="/logout">*/}
                            {/*    <LogIn onLogInPath={"/home"} />*/}
                            {/*</Route>*/}

                            <Route path="/profile">
                                <Profile onLogInPath={"/profile"} onCompletePath={"/"} />
                            </Route>

                            <Route path="/storage_admin">
                                <StorageAdmin
                                    onClearFavorites={() => {this.clearFavorites();}}
                                    onClearHistory={() => {this.clearSessions();}}
                                />
                            </Route>

                            {/*<Route path="/experts">*/}
                            {/*    <FindExperts previous='/home'/>*/}
                            {/*</Route>*/}

                            <Route path="/glossary">
                                <Glossary previous='/home'/>
                            </Route>

                            <Route path="/experts">
                                <Experts previous='/home' />
                            </Route>

                            <Route path="/funding">
                                <Funding previous='/home'/>
                            </Route>

                            <Route path="/learn">
                                <LearnAboutProject previous='/home'/>
                            </Route>

                            <Route path="/learn2">
                                <LearnAboutProject previous='/home' useAlt={true}/>
                            </Route>

                            <Route path="/contact">
                                <ContactUs previous='/home'/>
                            </Route>

                            <Route path="/accom_resources">
                                <OtherResources previous='/home'/>
                            </Route>

                            <Route path="/best_practices">
                                <BestPractices previous='/home'/>
                            </Route>

                            <Route path="/home">
                                {homeDom}
                            </Route>

                            <Route path="/saved_difficulties" >
                                {/*<NeedDifficultiesProfile prev="/home" next={"/mob_diff"} progress={0}*/}
                                {/*                         postMountCallback={()=>{console.log("ON ENTER!!!!!!")}}  />*/}
                                <PreviousSessions
                                    // sessions={this.state.sessions} activeSession={this.state.activeSession}
                                    sessions={this.getSessions()} activeSession={this.getActiveSession()}
                                    makeSessionActive={(val, nextAction) => {this.makeSessionActive(val, nextAction)}}
                                    deleteSession={(val) => {this.clearSession(val)}}
                                />
                            </Route>

                            <Route path="/favorites" >
                                <Favorites favorites={this.state.favorites}/>
                            </Route>


                            <Route path="/need_tech_pref" >
                                <AdditionalFactorsTechnology

                                    selectedItems={this.state.activeSession.techPrefs}

                                    prev={
                                        // TODO here is where to adjust if a new entry point is necessary
                                        // Also, this approach works for chaining together to other sequences
                                        // like computer tech preference
                                        !_.isNil(this.state.activeSession.tasks) &&
                                        this.state.activeSession.tasks.length > 0 ?
                                            this.state.activeSession.taskGroups.slice(-1)[0] :
                                            null
                                    }

                                    // prev="/job_req_org"
                                    next={"/need_interaction_pref"}
                                    progress={14/progDenom}

                                    postMountCallback={()=>{
                                        //console.log("CLEARING!!!");
                                        //this.startNewActiveSession();
                                    }}

                                    updateConfig={(e) => this.updateSelectedTechPrefs(e) }
                                />

                            </Route>


                            <Route path="/need_interaction_pref" >
                                <AdditionalFactorsInteraction

                                    selectedItems={this.state.activeSession.interactionPrefs}


                                    prev="/need_tech_pref"
                                    next={"/need_work_area_pref"}
                                    progress={14/progDenom}

                                    postMountCallback={()=>{
                                        //console.log("CLEARING!!!");
                                        //this.startNewActiveSession();
                                    }}

                                    updateConfig={(e) => this.updateSelectedInteractionPrefs(e) }
                                />

                            </Route>


                            <Route path="/need_work_area_pref" >
                                <AdditionalFactorsWorkArea

                                    selectedItems={this.state.activeSession.workAreaPrefs}

                                    prev="/need_interaction_pref"
                                    next={"/nools"}
                                    progress={15/progDenom}

                                    postMountCallback={()=>{
                                        //console.log("CLEARING!!!");
                                        //this.startNewActiveSession();
                                    }}

                                    updateConfig={(e) => this.updateSelectedWorkAreaPrefs(e) }
                                />

                            </Route>



                            <Route path="/need_difficulties" >
                                <NeedDifficultiesProfile

                                    selectedItems={this.state.activeSession.difficultiesGroups}
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    prev="/need_job_reqs"
                                    next={"/mob_diff"}
                                    progress={1/progDenom}

                                    postMountCallback={()=>{
                                        //console.log("CLEARING!!!");
                                        //this.startNewActiveSession();
                                    }}

                                    updateConfig={(e) => this.updateSelectedDifficultiesGroups(e) }

                                    updateSelectedDifficulties={(e) => this.updateSelectedDifficulties(e) }
                                />

                            </Route>


                            // TODO This route is a bit of a hack entry point.
                            // now that task goes first, this needs to change!

                                <Route path="/revise_difficulties">
                                    <DifficultiesIndividual selectedItems={this.state.activeSession.difficulties} prev="/saved_difficulties" next={"/dex_diff"} section={0} progress={1/progDenom} updateConfig={(e) => this.updateSelectedDifficulties(e) }/>

                                    {/*<NeedDifficultiesProfile prev="/home" next={"/mob_diff"} progress={0}*/}
                                    {/*                         postMountCallback={()=>{}} />*/}
                                </Route>


                            // TODO DELME test route!
                            <Route path={"/test/:test_id"} render={(props) => (
                                <div>TESTID: {props.match.params.test_id}</div>
                            )}>

                            </Route>

                            {/*<Route path="/difficulties">*/}
                            {/*    <Difficulties2 prev="/need_difficulties" next={"/job_requirements"} updateConfig={(e) => this.updateSelectedDifficulties(e) }/>*/}
                            {/*</Route>*/}
                            <Route path="/mob_diff">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/need_difficulties" next={"/dex_diff_dom_reach"} section={0}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>


                            <Route path="/mob_support">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/mob_diff" next={"/dex_diff_dom_reach"} section={12}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>

                            {/*<Route path="/dex_diff">*/}
                            {/*    <DifficultiesIndividual*/}
                            {/*        selectedJobGroups={this.state.activeSession.taskGroups}*/}
                            {/*        selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}*/}
                            {/*        selectedItems={this.state.activeSession.difficulties} prev="/mob_diff" next={"/see_diff"} section={1}*/}
                            {/*        minProgress={2/progDenom}*/}
                            {/*        maxProgress={8/progDenom}*/}
                            {/*        updateConfig={(e) => this.updateSelectedDifficulties(e) }/>*/}
                            {/*</Route>*/}

                            <Route path="/dex_diff_dom_reach">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/mob_diff" next={"/dex_diff_nondom_reach"} section={8}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>


                            <Route path="/dex_diff_nondom_reach">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/dex_diff_dom_reach" next={"/dex_diff_dom_grasp"} section={9}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>



                            <Route path="/dex_diff_dom_grasp">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/dex_diff_nondom_reach" next={"/dex_diff_nondom_grasp"} section={10}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>


                            <Route path="/dex_diff_nondom_grasp">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/dex_diff_dom_grasp" next={"/see_diff"} section={11}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>


                            <Route path="/see_diff">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/dex_diff" next={"/hear_diff"} section={2}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>

                            <Route path="/hear_diff">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/see_diff" next={"/comm_pref"} section={3}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>

                            <Route path="/comm_pref">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/hear_diff" next={"/speak_diff"} section={13}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>

                            <Route path="/speak_diff">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/hear_diff" next={"/cog_diff"} section={4}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>

                            <Route path="/cog_diff">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/speak_diff" next={"/anxiety_diff"} section={5}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>


                            <Route path="/anxiety_diff">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/cog_diff" next={"/other_diff"} section={6}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>

                            <Route path="/other_diff">
                                <DifficultiesIndividual
                                    selectedJobGroups={this.state.activeSession.taskGroups}
                                    selectedGroups={this.state.activeSession.difficultiesGroups} startPath={indvDiffStartPath} endPath={indvDiffEndPath}
                                    selectedItems={this.state.activeSession.difficulties} prev="/anxiety_diff" next={"/nools"} section={7}
                                    minProgress={2/progDenom}
                                    maxProgress={8/progDenom}
                                    updateConfig={(e) => this.updateSelectedDifficulties(e) }/>
                            </Route>



                          {/*<Route path="/difficulties_more_details">*/}
                          {/*  <DifficultiesMore prev="/difficulties" next={"/job_requirements"}/>*/}
                          {/*</Route>*/}

                          <Route path="/need_job_reqs">
                              <NeedJobReqsProfile
                                  selectedItems={this.state.activeSession.taskGroups}
                                  prev="/home" next={"/need_difficulties"} progress={0}
                                                       postMountCallback={async ()=>{
                                                           console.log("CLEARING!!!");
                                                           await this.startNewActiveSession();}}
                                  updateConfig={(e) => this.updateSelectedTaskGroups(e) }
                              />
                          </Route>

                            <Route path="/job_req_fac/:mid_task?" render={(props)=> {

                                return <JobReqFaq
                                    taskRulesEngine={this.state.tasksRuleEngine}
                                    jobGroup={"facility_access"}
                                    jobGroupUrl={"job_req_fac"}
                                    subJobGroup={props.match.params.mid_task}
                                    selectedDifficulties={this.state.activeSession.difficulties}
                                    selectedDifficultiesGroups={this.state.activeSession.difficultiesGroups}
                                    selectedGroups={this.state.activeSession.taskGroups} startPath={"/need_job_reqs"}
                                    endPath={indvJobReqsEndPath}
                                    selectedTaskItems={this.state.activeSession.tasks}
                                    selectedMidTaskItems={this.state.activeSession.midTasks}
                                    prev="/need_job_reqs"
                                    next={"/job_req_comm"} section={0}
                                    minProgress={9 / progDenom}
                                    maxProgress={13 / progDenom}
                                    updateTaskConfig={(e) => this.updateSelectedTasks(e)}
                                    updateMidTaskConfig={(e)=> this.updateSelectedMidTasks(e)}
                                />;
                            }}></Route>



                            <Route path="/job_req_workstation/:mid_task?" render={(props)=> {
                                return <JobReqOrg
                                    taskRulesEngine={this.state.tasksRuleEngine}
                                    jobGroup={"workstation"}
                                    jobGroupUrl={"job_req_workstation"}
                                    subJobGroup={props.match.params.mid_task}
                                    selectedDifficulties={this.state.activeSession.difficulties}
                                    selectedDifficultiesGroups={this.state.activeSession.difficultiesGroups}
                                    selectedGroups={this.state.activeSession.taskGroups} startPath={"/need_job_reqs"}
                                    endPath={indvJobReqsEndPath}
                                    selectedTaskItems={this.state.activeSession.tasks}
                                    selectedMidTaskItems={this.state.activeSession.midTasks}
                                    prev="/job_req_comm"
                                    next={"/job_req_equip"} section={2}
                                    minProgress={9 / progDenom}
                                    maxProgress={13 / progDenom}
                                    updateTaskConfig={(e) => this.updateSelectedTasks(e)}
                                    updateMidTaskConfig={(e)=> this.updateSelectedMidTasks(e)}
                                />

                            }}></Route>


                            <Route path="/job_req_org/:mid_task?" render={(props)=> {
                                return <JobReqOrg
                                    taskRulesEngine={this.state.tasksRuleEngine}
                                    jobGroup={"organization"}
                                    jobGroupUrl={"job_req_org"}
                                    subJobGroup={props.match.params.mid_task}
                                    selectedDifficulties={this.state.activeSession.difficulties}
                                    selectedDifficultiesGroups={this.state.activeSession.difficultiesGroups}
                                    selectedGroups={this.state.activeSession.taskGroups} startPath={"/need_job_reqs"}
                                    endPath={indvJobReqsEndPath}
                                    selectedTaskItems={this.state.activeSession.tasks}
                                    selectedMidTaskItems={this.state.activeSession.midTasks}
                                    prev="/job_req_comm"
                                    next={"/job_req_equip"} section={2}
                                    minProgress={9 / progDenom}
                                    maxProgress={13 / progDenom}
                                    updateTaskConfig={(e) => this.updateSelectedTasks(e)}
                                    updateMidTaskConfig={(e)=> this.updateSelectedMidTasks(e)}
                                />

                            }}></Route>



                            <Route path="/job_req_comm/:mid_task?" render={(props)=> {
                                return <JobReqComm
                                    taskRulesEngine={this.state.tasksRuleEngine}
                                    jobGroup={"communication"}
                                    jobGroupUrl={"job_req_comm"}
                                    subJobGroup={props.match.params.mid_task}
                                    selectedDifficulties={this.state.activeSession.difficulties}
                                    selectedDifficultiesGroups={this.state.activeSession.difficultiesGroups}
                                    selectedGroups={this.state.activeSession.taskGroups} startPath={"/need_job_reqs"}
                                    endPath={indvJobReqsEndPath}
                                    selectedTaskItems={this.state.activeSession.tasks}
                                    selectedMidTaskItems={this.state.activeSession.midTasks}
                                    prev="/job_req_fac"
                                    next={"/job_req_org"} section={1}
                                    minProgress={9 / progDenom}
                                    maxProgress={13 / progDenom}
                                    updateTaskConfig={(e) => this.updateSelectedTasks(e)}
                                    updateMidTaskConfig={(e)=> this.updateSelectedMidTasks(e)}
                                />;
                            }}></Route>


                            <Route path="/job_req_equip/:mid_task?" render={(props)=> {
                                return <JobReqComm
                                    taskRulesEngine={this.state.tasksRuleEngine}
                                    jobGroup={"equipment"}
                                    jobGroupUrl={"job_req_equip"}
                                    subJobGroup={props.match.params.mid_task}
                                    selectedDifficulties={this.state.activeSession.difficulties}
                                    selectedDifficultiesGroups={this.state.activeSession.difficultiesGroups}
                                    selectedGroups={this.state.activeSession.taskGroups} startPath={"/need_job_reqs"}
                                    endPath={indvJobReqsEndPath}
                                    selectedTaskItems={this.state.activeSession.tasks}
                                    selectedMidTaskItems={this.state.activeSession.midTasks}
                                    prev="/job_req_fac"
                                    next={"/job_req_org"} section={1}
                                    minProgress={9 / progDenom}
                                    maxProgress={13 / progDenom}
                                    updateTaskConfig={(e) => this.updateSelectedTasks(e)}
                                    updateMidTaskConfig={(e)=> this.updateSelectedMidTasks(e)}
                                />;
                            }}></Route>


                            <Route path="/job_req_wellbeing/:mid_task?" render={(props)=> {
                                return <JobReqComm
                                    taskRulesEngine={this.state.tasksRuleEngine}
                                    jobGroup={"wellbeing"}
                                    jobGroupUrl={"job_req_wellbeing"}
                                    subJobGroup={props.match.params.mid_task}
                                    selectedDifficulties={this.state.activeSession.difficulties}
                                    selectedDifficultiesGroups={this.state.activeSession.difficultiesGroups}
                                    selectedGroups={this.state.activeSession.taskGroups} startPath={"/need_job_reqs"}
                                    endPath={indvJobReqsEndPath}
                                    selectedTaskItems={this.state.activeSession.tasks}
                                    selectedMidTaskItems={this.state.activeSession.midTasks}
                                    prev="/job_req_fac"
                                    next={"/job_req_org"} section={1}
                                    minProgress={9 / progDenom}
                                    maxProgress={13 / progDenom}
                                    updateTaskConfig={(e) => this.updateSelectedTasks(e)}
                                    updateMidTaskConfig={(e)=> this.updateSelectedMidTasks(e)}
                                />;
                            }}></Route>



                            {/*<Route path="/job_req_workplace/:mid_task?" render={(props)=> {*/}
                            {/*    return <JobReqWorkplace*/}
                            {/*        taskRulesEngine={this.state.tasksRuleEngine}*/}
                            {/*        jobGroup={"workplace"}*/}
                            {/*        jobGroupUrl={"job_req_workplace"}*/}
                            {/*        subJobGroup={props.match.params.mid_task}*/}
                            {/*        selectedDifficulties={this.state.activeSession.difficulties}*/}
                            {/*        selectedDifficultiesGroups={this.state.activeSession.difficultiesGroups}*/}
                            {/*        selectedGroups={this.state.activeSession.taskGroups} startPath={"/need_job_reqs"}*/}
                            {/*        endPath={indvJobReqsEndPath}*/}
                            {/*        selectedTaskItems={this.state.activeSession.tasks}*/}
                            {/*        selectedMidTaskItems={this.state.activeSession.midTasks}*/}
                            {/*        prev="/job_req_comm"*/}
                            {/*        next={"/job_req_equip"} section={2}*/}
                            {/*        minProgress={9 / progDenom}*/}
                            {/*        maxProgress={13 / progDenom}*/}
                            {/*        updateTaskConfig={(e) => this.updateSelectedTasks(e)}*/}
                            {/*        updateMidTaskConfig={(e)=> this.updateSelectedMidTasks(e)}*/}
                            {/*    />*/}

                            {/*}}></Route>*/}


                            {/*<Route path="/job_req_tools">*/}
                            {/*    <JobReqEquip*/}
                            {/*        taskRulesEngine={this.state.tasksRuleEngine}*/}
                            {/*        jobGroup={"using_tools"}*/}
                            {/*        jobGroupUrl={"job_req_tools"}*/}
                            {/*        selectedDifficulties={this.state.activeSession.difficulties}*/}
                            {/*        selectedDifficultiesGroups={this.state.activeSession.difficultiesGroups}*/}
                            {/*        selectedGroups={this.state.activeSession.taskGroups} startPath={"/need_job_reqs"} endPath={indvJobReqsEndPath}*/}
                            {/*        selectedItems={this.state.activeSession.tasks} prev="/job_req_org" next={"/need_difficulties"} section={3}*/}
                            {/*        minProgress={9/progDenom}*/}
                            {/*        maxProgress={13/progDenom}*/}
                            {/*        updateConfig={(e) => this.updateSelectedTasks(e) }/>*/}
                            {/*</Route>*/}



                            {/*<Route path="/job_req_comp">*/}
                            {/*    <JobRequirementsIndividual selectedItems={this.state.activeSession.tasks} prev="/job_req_tool" next={"/nools"} section={4} progress={11/progDenom}  updateConfig={(e) => this.updateSelectedTasks(e) }/>*/}
                            {/*</Route>*/}

                            {/*<Route path="/job_requirements">*/}
                            {/*    <JobRequirements prev="/difficulties" next={"/nools"} updateConfig={(e) => this.updateSelectedTasks(e) }/>*/}
                            {/*</Route>*/}

                            <Route path="/job_requirements_more">
                                <JobRequirementsMore prev="/job_requirements" next={"/assessment"}/>
                            </Route>

                            <Route path="/assessment">
                                <ExampleAssessment prev="/job_requirements_more" next={"/home"} solutionUrl={"/detailed_solution"}/>
                            </Route>

                            <Route path="/solution">
                                <Solution prev="/assessment" solutionUrl={"/detailed_solution"}/>
                            </Route>

                            <Route path="/detailed_solution">
                                <SolutionDetailed prev="/assessment"/>
                            </Route>

                            <Route path="/">
                                <div>
                                    <Route exact path="/">
                                        {/*{!this.state.showLogIn ? <Redirect to="/onboard"/> : ''}*/}
                                        {!this.state.showLogIn ? <Redirect to="/home"/> : ''}
                                    </Route>
                                    {/*<LogInForm show={this.state.showLogIn} onHide={() => {*/}
                                    {/*    console.log('close');*/}
                                    {/*    this.setState({showLogIn: false})*/}
                                    {/*}}/>*/}
                                </div>
                            </Route>
                        </Switch>
                    </div>


                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px'}}>


                    <div className="footer">

                        <a href="https://www.gatech.edu/">Georgia Institute of Technology</a><br />
                        <ul>
                            <li>
                                <a href="https://cidi.gatech.edu/">CIDI</a><br />
                            </li>
                            <li>
                                <a href="https://research.gatech.edu/ipat">IPaT</a>
                            </li>
                        </ul>

                    </div>



                    <div className="footer">


                        Work ACCESS was funded by grant #90DPEM0001 from the National Institute on Disability, <br />
                        Independent Living and Rehabilitation Research (NIDILRR), a Center within the Administration <br />
                        for Community Living, U.S. Dept. of Health and Human Services. The contents of this tool do <br />
                        not necessarily represent the policy or endorsement of NIDILRR, ACL, or HHS. <br />


                    </div>


                    <div className="footer">

                            <img src='/gt-logo-sm.png' alt='GT'/><br />
                            © 2020 Georgia Institute of Technology
                    </div>



                </div>

            </Router>




        );
    }
}

export default App;
