

export default class Accommodation {

    constructor() {
        // this.m1 = false;
        // this.m2 = false;
        // this.m3 = false;
        // this.m4 = false;
        // this.m5 = false;
        // this.m6 = false;
        //
        // this.u00 = false;
        // this.u01 = false;
        // this.u02 = false;
        // this.u03 = false;
        // this.u04 = false;
        //
        // this.u10 = false;
        // this.u11 = false;
        // this.u12 = false;
        // this.u13 = false;
        // this.u14 = false;
        //
        // this.u20 = false;
        // this.u21 = false;
        // this.u22 = false;
        // this.u23 = false;
        // this.u24 = false;
        //
        // this.u30 = false;
        // this.u31 = false;
        // this.u32 = false;
        // this.u33 = false;
        // this.u34 = false;
        //
        // this.u40 = false;
        // this.u41 = false;
        // this.u42 = false;
        // this.u43 = false;
        // this.u44 = false;
        //
        // this.h1 = false;
        // this.h2 = false;
        // this.h3 = false;
        //
        // this.v1 = false;
        // this.v2 = false;
        // this.v3 = false;
        // this.v4 = false;
        //
        // this.s1 = false;
        // this.s2 = false;
        // this.s3 = false;
        //
        // this.c1 = false;
        // this.c2 = false;
        // this.c3 = false;
        // this.c4 = false;
        // this.c5 = false;
        //
        // this.t = ''

        //this.t = [];
    }

    get(k) {
        if (k in this)
            return this[k];
        else
            return null;
    }

    set(k, v) {
        this[k] = v;
        return this;
    }
}
