import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';




import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';

import {FaAdversal} from 'react-icons/fa';
import {FaHeart} from 'react-icons/fa';
import {IoIosBook} from 'react-icons/io';

import NavPanel from './NavPanel';
import {Link} from "react-router-dom";



const SolutionsPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    solutionUrl: PropTypes.string.isRequired
}




class Solutions extends Component {

    constructor(props) {
        super(props);


        this.state = {

        }

    }


    render() {

        let props = this.props;


        console.log(props);

        let AccordionHeaderToggle = (props) => {
            const [expanded, setExpanded] = useState(false);

            return  <Accordion.Toggle {...props} as={Card.Header} onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}} >
                {expanded ? "+ " : "+ "}
                {props.children}
            </Accordion.Toggle>
        };


        let diffTypes = [
            ['Mobility Aid',['Walking Aid such as a cane or walker', 'Manual Wheelchair', 'Power Wheelchair', 'Scooter']],

            ];


        let cards = diffTypes.map((diffType, index) => {
            return <Card key={`accordion-card-${index}`}>
                <AccordionHeaderToggle  eventKey={index}>{diffType[0]}</AccordionHeaderToggle>
                <Accordion.Collapse eventKey={index} >
                    <Card.Body>
                        <Form>
                            {diffType[1].map(
                                (opt, j) => {
                                    return <div key={`default-checkbox-${index}-${j}`} className='mb-3'>
                                        <Form.Check
                                            type='checkbox'
                                            id='default-checkbox'
                                            label={opt}
                                            />
                                    </div>;
                                }
                            )}
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        });


        let frm = <div style={{flex: 1}}>

            <Row style={{padding: "10px"}}>
                <Col>
                    <Link to={props.solutionUrl}>
                    <Card>
                        <Card.Header>
                            <h4>Reading</h4>
                        </Card.Header>
                        <Card.Body style={{textAlign: 'center'}}>
                            <p style={{fontSize: '1000%'}}><IoIosBook/></p>
                        </Card.Body>
                        <Card.Footer>
                            <h4><FaHeart/>&nbsp;Tracking/Visual Processing Issues</h4>
                            <h5>Present the info. in a sound related format</h5>
                        </Card.Footer>
                    </Card>
                    </Link>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <h4>Reading</h4>
                        </Card.Header>
                        <Card.Body style={{textAlign: 'center'}}>
                            <p style={{fontSize: '1000%'}}><IoIosBook/></p>
                        </Card.Body>
                        <Card.Footer>
                            <h4><FaHeart/>&nbsp;Tracking/Visual Processing Issues</h4>
                            <h5>Provide a cue to track what has been read</h5>
                        </Card.Footer>
                    </Card>

                </Col>
            </Row>
            <Row style ={{padding: '10px'}}>
                <Col>
                    <Card>
                        <Card.Header>
                            <h4>Reading</h4>
                        </Card.Header>
                        <Card.Body style={{textAlign: 'center'}}>
                            <p style={{fontSize: '1000%'}}><IoIosBook/></p>
                        </Card.Body>
                        <Card.Footer>
                            <h4><FaHeart/>&nbsp;Tracking/Visual Processing Issues</h4>
                            <h5>Change the text color/contrast</h5>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <h4>Reading</h4>
                        </Card.Header>
                        <Card.Body style={{textAlign: 'center'}}>
                            <p style={{fontSize: '1000%'}}><IoIosBook/></p>
                        </Card.Body>
                        <Card.Footer>
                            <h4><FaHeart/>&nbsp;Tracking/Visual Processing Issues</h4>
                            <h5>Increase text spacing</h5>
                        </Card.Footer>
                    </Card>

                </Col>
            </Row>
        </div>;


        return (

            <NavPanel title="We have found the following solutions for you..." content={frm} {...props}/>

        );
    }


}


Solutions.propTypes = SolutionsPropTypes;

export default Solutions;
