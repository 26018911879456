import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';




import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';



const JobRequirementsPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    updateConfig: PropTypes.func,
}




class JobRequirements extends Component {

    constructor(props) {
        super(props);


        this.state = {
            selected:[]
        }

    }


    render() {

        let props = this.props;


        console.log(props);

        let AccordionHeaderToggle = (props) => {
            const [expanded, setExpanded] = useState(false);

            return  <Accordion.Toggle {...props} as={Card.Header} onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}} >
                {expanded ? "+ " : "+ "}
                {props.children}
            </Accordion.Toggle>
        };



        let diffTypes = [
            {
                name: 'Facility Access',
                levels: [
                    {code: 'T0100', text: 'Door - T0100'},
                    {code: 'T0101', text: 'Door - T0101'},
                    {code: 'T0102', text: 'Door - T0102'},
                    {code: 'T0103', text: 'Door - T0103'},
                    {code: 'T0104', text: 'Door - T0104'},
                    {code: 'T0200', text: 'Building Levels - T0100'},
                    {code: 'T0201', text: 'Building Levels  - T0101'},
                    {code: 'T0202', text: 'Building Levels  - T0102'},
                    {code: 'T0203', text: 'Building Levels  - T0103'},
                    {code: 'T0204', text: 'Building Levels  - T0104'},

                ]
            },
            {
                name: 'Workplace',
                levels: [
                    {code: 'T0500', text: 'Workplace - T0500'},
                    {code: 'T0501', text: 'Workplace - T0501'},
                    {code: 'T0502', text: 'Workplace - T0502'},
                    {code: 'T0503', text: 'Workplace - T0503'},
                    {code: 'T0504', text: 'Workplace - T0504'},
                    {code: 'T0505', text: 'Workplace - T0505'},
                    {code: 'T0506', text: 'Workplace - T0506'},
                    {code: 'T0507', text: 'Workplace - T0507'},
                ]
            },
            {
                name: 'Communication',
                levels: [
                    {code: 'T0600', text: 'Communication - T0600'},
                    {code: 'T0601', text: 'Communication - T0601'},
                    {code: 'T0602', text: 'Communication - T0602'},
                ]
            },
            {
                name: 'Using Tools',
                levels: [
                    {code: 'T0900', text: 'Using Tools - T0900'},
                    {code: 'T0901', text: 'Using Tools - T0901'},
                    {code: 'T0902', text: 'Using TOols - T0902'},
                ]
            },
            {
                name: 'Computer',
                levels: [
                    {code: 'T1000', text: 'Workplace - T1000'},
                    {code: 'T1001', text: 'Workplace - T1001'},
                    {code: 'T1002', text: 'Workplace - T1002'},
                    {code: 'T1003', text: 'Workplace - T1003'},
                    {code: 'T1004', text: 'Workplace - T1004'},
                    {code: 'T1005', text: 'Workplace - T1005'},
                    {code: 'T1006', text: 'Workplace - T1006'},
                    {code: 'T1007', text: 'Workplace - T1007'},
                    {code: 'T1008', text: 'Workplace - T1008'},
                ]
            },
        ];



        let cards = diffTypes.map((diffType, index) => {
            return <Card key={`accordion-card-${index}`}>
                <AccordionHeaderToggle  eventKey={index}>{diffType.name}</AccordionHeaderToggle>
                <Accordion.Collapse eventKey={index} >
                    <Card.Body>
                        <Form>
                            {diffType.levels.map(
                                (opt, j) => {
                                    return <div key={`default-checkbox-${index}-${j}`} className='mb-3'>
                                        <Form.Check
                                            type='checkbox'
                                            id='default-checkbox'
                                            //label={opt}
                                            label={opt.text}
                                            value={opt.code}
                                            //checked={this.state.checkboxChecked}
                                            onChange={(e) => this.handleChange(e, this.props.updateConfig)}

                                            />
                                    </div>;
                                }
                            )}
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        });


        let frm = <div>
            <p>Our initial system will cover: 1) Moving around the workplace, 2) Using a workstation, 3) Face-to-Face and Remote (e.g., Phone) Communication, 4) Reading/Writing, 5) Using a Computer, 6) Time Management, and 7) Learning and Remembering Work Tasks.  What else would you like us to cover?</p>
            <Accordion defaultActiveKey={'accordion-card-0'}>
            {cards}
        </Accordion>
        </div>;


        return (

            <NavPanel title="My job requires..." content={frm} {...props}/>

        );
    }



    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if(!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        }
        else
        {
            sel.push(evt.target.value);
        }

        if(updateConfig)
        {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }


}


JobRequirements.propTypes = JobRequirementsPropTypes;

export default JobRequirements;
