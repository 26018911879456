import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';


import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import _ from 'lodash';
import {GetAllLevels} from "./DiffTypes";

const NeedDifficultiesProfilePropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    progress: PropTypes.number,
    postMountCallback: PropTypes.func,
    updateConfig: PropTypes.func,
    updatedSelectedDifficulties: PropTypes.func,
    selectedItems: PropTypes.array.isRequired,
    selectedJobGroups: PropTypes.array.isRequired
}




class NeedDifficultiesProfile extends Component {

    constructor(props) {
        super(props);


        this.state = {
            selected:this.props.selectedItems ? this.props.selectedItems : [],
            showModal: false,
            modalText: '',
            modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(name, txt) {
        this.setState({showModal: true, modalTitle: name, modalText: txt});
    }




    componentDidMount() {
        console.log("componentDidMount");

        if(this.props.postMountCallback)
            this.props.postMountCallback();


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if( !_.isEqual(this.props.selectedItems, prevProps.selectedItems))
            this.setState({selected:this.props.selectedItems ? this.props.selectedItems : []})
    }


    render() {


        console.log("SELECT_ITEMS: " + this.props.selectedItems);
        console.log("state selected: " + this.state.selected);

        let props = this.props;

        let diffType =
            {
                name: 'Which abilities are impacted by the work environment?',
                levels: [
                    {code: '/mob_diff', text: 'Sitting, standing, or moving around', descript: 'The employee has difficulty sitting, standing, or physically moving around (including difficulty due to pain or fatigue). '},
                    {code: '/dex_diff_dom_reach', text: 'Reaching, grasping, or pinching', descript: 'The employee has significant difficulty reaching, grasping, pinching, or controlling hand/finger motion. '},
                    {code: '/see_diff', text: 'Seeing, even with glasses or contacts', descript: 'The employee has difficulty or cannot see, even WITH glasses or contacts. '},
                    {code: '/hear_diff', text: 'Hearing', descript: 'The employee has difficulty or cannot hear.  NOTE: Some people with hearing loss also have difficulty with speaking clearly. If that is the case, please also select a functional limitation under the speech section.'},
                    {code: '/speak_diff', text: 'Speaking', descript: 'The employee has significant difficulty producing speech or thinking of the right words to say. '},
                    {code: '/cog_diff', text: 'Reading, remembering, or planning', descript: 'The employee has significant difficulty with remembering things, processing information, expressing thoughts or appropriate behaviors, or perceiving information. '},
                    {code: '/anxiety_diff', text: 'Managing anxiety', descript: 'The employee has significant problems with managing anxiety, emotions, or other issues related to mental health. '},
                    {code: '/other_diff', text: 'Other health issues (e.g., fatigue)', descript: 'The employee has health issues that impact stamina and result in fatigue.'},
                    {code: '/undisclosed_diff', text: 'Prefer not to disclose', descript: 'We will try to match accommodations to the problems that you report.  However, you will need to review a longer list of potential problems and the match may not fit as well. '}
                ]
            }


        let frm =
            <div>
                <h2 class="nav-sub-heading">{diffType.name}</h2>
                <Form>
                    {diffType.levels.map(
                        (opt, j) => {
                            return <div style={{display: 'flex', flexDirection: 'row'}} key={`default-checkbox-${this.props.section}_${j}`} className='mb-3'>
                                <Form.Check
                                    type='checkbox'
                                    id={`default-checkbox-${opt.code}`}
                                    label={opt.text}
                                    value={opt.code}
                                    checked={this.props.selectedItems.includes(opt.code)}
                                    //checked={this.state.checkboxChecked}
                                    onChange={(e) => this.handleChange(e, this.props.updateConfig, this.props.updateSelectedDifficulties)}
                                />

                                <div style={{display: 'flex'}} >
                                    <div style={{cursor:'pointer'}} onClick={
                                        (e)=>{
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            this.showModal(opt.text, opt.descript);}}>
                                        <MdInfo/>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flex:1}}></div>

                            </div>;
                        }
                    )}
                </Form>
            </div>








        //let frm = <div>We need to first collect some information about your job requirements. Click next to continue...</div>




        return (

            <div>

                <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>

                            {this.state.modalTitle}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.modalText}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.closeModal()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>




                <NavPanel title="Step 2: Select Difficulties" content={frm} progress={this.props.progress} prev={this.findPrev()}
                          next={this.findNext()}/>

            </div>

        );
    }


    findNext()
    {
        // old next: next={this.props.selectedItems.length > 0 ? this.props.selectedItems[0] : null}
        if(this.props.selectedItems.length <= 0)
            return null;

        if(this.props.selectedItems.includes('/undisclosed_diff'))
        {
            if(this.props.selectedJobGroups.length === 0) {
                console.log("NO selected job groups to continue!");
                return null;
            }
            else
                return this.props.selectedJobGroups[0];
        }
        else
        {
            return this.props.selectedItems[0];
        }
        //this.props.selectedItems[0] : null


    }

    handleChange(evt, updateConfig, updateSelectedDifficulties) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if(evt.target.value === '/undisclosed_diff' && evt.target.checked)
        {
            sel = [];

            let allDiffs = GetAllLevels();
            console.log("ALL_DIFFS:");
            console.log(allDiffs);
            // user doesn't want to say, so just allow all, but we have to set them for the user
            updateSelectedDifficulties(allDiffs);

        }

        if(!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        }
        else
        {
            if(evt.target.value !== '/undisclosed_diff') {
                const i2 = sel.indexOf('/undisclosed_diff');
                if (i2 > -1) {
                    sel.splice(i2, 1);
                }
            }
            else
            {
                // unset them because now we will actually ask the user
                updateSelectedDifficulties([]);
            }

            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        if(updateConfig)
        {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }


    findPrev()
    {
        return this.props.prev;
        // if( _.isNil(this.props.selectedJobReqs) || this.props.selectedJobReqs.length <= 0 )
        //     return null;
        //
        //
        // return this.props.selectedJobReqs[this.props.selectedJobReqs.length-1];
    }



}


NeedDifficultiesProfile.propTypes = NeedDifficultiesProfilePropTypes;

export default NeedDifficultiesProfile;
