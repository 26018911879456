import React, {Component, useState} from 'react';


import PropTypes from 'prop-types';


import {
    Form,
    Button,
    Modal,
    Container,
    Row,
    Col,
    Card,
    Accordion,
    AccordionCollapse,
    AccordionToggle
} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import _ from 'lodash';

const ExpertsPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    //progress: PropTypes.number,
    postMountCallback: PropTypes.func,
    previous: PropTypes.string,
    //selectedItems: PropTypes.array.isRequired
}


class Experts extends Component {

    constructor(props) {
        super(props);


        this.state = {
            //selected:this.props.selectedItems ? this.props.selectedItems : [],
            //showModal: false,
            //modalText: '',
            //modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(name, txt) {
        this.setState({showModal: true, modalTitle: name, modalText: txt});
    }


    componentDidMount() {
        console.log("componentDidMount");

        if (this.props.postMountCallback)
            this.props.postMountCallback();


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // if( !_.isEqual(this.props.selectedItems, prevProps.selectedItems))
        //     this.setState({selected:this.props.selectedItems ? this.props.selectedItems : []})
    }


    render() {


        let props = this.props;


        let frm =
            <div>
                <a href="https://www.at3center.net/stateprogram" target="_blank">National Assistive Technology Act Technical Assistance and Training (AT3)
                    Center</a>

                <p>
                    The Assistive Technology (AT) Act Programs in each state have specialists who can provide
                    information about assistive technology. They will sometimes conduct AT assessments.
                </p>

                <a href="https://askjan.org/" target="_blank">Job Accommodation Network (JAN)</a>

                <p>
                    JAN provides free consulting services on worksite accommodation solutions.
                </p>


                <a href="http://www.resna.org/" target="_blank">RESNA (Rehabilitation Engineering & AT Society of N. America)</a>

                <p>
                    RESNA provides a list of credentialed Assistive Technology Providers in each state.
                </p>

                <a href="https://rsa.ed.gov/about/states" target="_blank">State Vocational Rehabilitation Agencies</a>

                <p>
                    Each state has an agency to provide vocational rehabilitation (VR) services. Some have a separate
                    agency that provides services to individuals who are blind or have visual impairments. This includes
                    workplace accommodation consultation. Services are provided to individuals when they are seeking
                    employment, however, some states will reopen cases when a person may need new accommodations because
                    their abilities or job tasks have changed.
                </p>

                <p>
                    Other professional organizations with membership lists:
                </p>
                <a href="https://aptaapps.apta.org/APTAPTDirectory/FindAPTDirectory.aspx" target="_blank">American Physical Therapy Association (APTA)</a>
                <a href="https://www.asha.org/profind/" target="_blank">American Speech-Language-Hearing Association (ASHA)</a> – for audiologists or
                speech-language pathologists (SLPs)

            </div>


        //let frm = <div>We need to first collect some information about your job requirements. Click next to continue...</div>


        return (

            <div>

                <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>

                            {this.state.modalTitle}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.modalText}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.closeModal()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                <NavPanel hideInactiveNavButtons={true} title="Find Experts" content={frm}
                    prev={this.props.previous ? this.props.previous : undefined}
                />

            </div>

        );
    }


    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if (!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        } else {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        if (updateConfig) {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }


    // findPrev()
    // {
    //
    //     if(_.isNil(this.props.selectedJobReqs) || this.props.selectedJobReqs.length <= 0) {
    //         console.log("No job reqs to go back to");
    //         return null;
    //     }
    //     else {
    //         console.log("Go to last of job reqs");
    //         let ret = this.props.selectedJobReqs.slice(-1)[0];
    //         console.log(ret);
    //         return ret;
    //     }
    // }


}


Experts.propTypes = ExpertsPropTypes;

export default Experts;
