import React, {Component} from 'react';


import PropTypes from 'prop-types';

import {MdArrowBack, MdArrowForward} from 'react-icons/md';


import {Form, Button, Modal, Container, Row, Col, Card, ProgressBar} from 'react-bootstrap';

import {Link} from "react-router-dom";

import _ from 'lodash';

const NavPanelPropTypes = {

    //something: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired,
    prev: PropTypes.string,
    next: PropTypes.string,
    isLast: PropTypes.bool,
    progress: PropTypes.number,
    hideInactiveNavButtons: PropTypes.bool,
}




class NavPanel extends Component {

    constructor(props) {
        super(props);


        this.state = {

        }

    }


    render() {

        let props = this.props;

        //let btnPrev = props.prev ? <Link to={props.prev}><Button variant="primary">⬅ Previous</Button></Link> : '';
        //let btnNext = props.next ? <Link to={props.next}><Button variant="primary">Next ➡</Button></Link> : '';

        // let btnPrev = props.prev ? <Link role="button" className="nav-link" to={props.prev}>BACK</Link> : <span className="nav-link placeholder" aria-hidden="true">BACK</span>;
        // let btnNext = props.next ? <Link role="button" className="nav-link" to={props.next}>NEXT</Link> : <span className="nav-link placeholder" aria-hidden="true">NEXT</span>;

        let btnPrev;
        if (props.prev) {
            btnPrev = <Link role="button" className="nav-link" to={props.prev}>BACK</Link>;
        } else if (props.hideInactiveNavButtons) {
            btnPrev = <span className="nav-link placeholder" aria-hidden="true">BACK</span>; // Occupy space but invisible
        } else {
            btnPrev = <span className="nav-link disabled" aria-hidden="true">BACK</span>; // Visible but non-interactive
        }

        let btnNext;
        if (props.next) {
            btnNext = <Link role="button" className="nav-link" to={props.next}>NEXT</Link>;
        } else if (props.hideInactiveNavButtons) {
            btnNext = <span className="nav-link placeholder" aria-hidden="true">NEXT</span>; // Occupy space but invisible
        } else {
            btnNext = <span className="nav-link disabled" aria-hidden="true">NEXT</span>; // Visible but non-interactive
        }

        let btnFeedback = <a role="button" className="nav-link" href="https://gatech.co1.qualtrics.com/jfe/form/SV_9NY2CDfNNDJFQl8" target="_blank">Provide Feedback</a>

        console.log(props);

        let now = _.isUndefined( this.props.progress) ? 0 : this.props.progress;

        const progressInstance = _.isUndefined( this.props.progress) || this.props.progress===-1 ? <div></div> : <ProgressBar now={now} label={`${Math.round(now)}%`}  />; //srOnly

        return (

            <Row className="nav-panel" style={{justifyContent: 'center', backgroundColor: 'transparent'}}>

                <Col xs={8}>
                    <Card style={{backgroundColor: 'white', border: "0px"}}>
                        <Card.Header style={{backgroundColor: 'white'}}><h1 class="nav-heading">{props.title}</h1></Card.Header>
                        <Card.Body style={{overflow: 'scroll', height: '380px', textAlign: 'left', backgroundColor: 'transparent'}}>
                            {progressInstance}
                            {props.content}
                        </Card.Body>

                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    {btnPrev}
                                </div>
                                <div style={{}}>
                                    {btnNext}
                                </div>
                            </div>
                            <div style={{}}>
                                {btnFeedback}
                            </div>

                        </div>
                    </Card>

                    {/*<Row style={{padding: '10px'}}>*/}
                    {/*    <Col>*/}
                    {/*        {btnPrev}*/}
                    {/*    </Col>*/}
                    {/*    <Col>*/}
                    {/*        {btnNext}*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Col>

            </Row>

        );
    }


}


NavPanel.propTypes = NavPanelPropTypes;

export default NavPanel;
