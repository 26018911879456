import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';




import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';



const Difficulties2PropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    updateConfig: PropTypes.func,
}




class Difficulties2 extends Component {

    constructor(props) {
        super(props);


        this.state = {
            selected: [],
        }

    }


    render() {

        let props = this.props;


        console.log(props);

        let AccordionHeaderToggle = (props) => {
            const [expanded, setExpanded] = useState(false);

            return  <Accordion.Toggle {...props} as={Card.Header} onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}} >
                {expanded ? "+ " : "+ "}
                {props.children}
            </Accordion.Toggle>
        };


        let diffTypes = [
            {
                name: 'Sitting, standing, or moving around',
                levels: [
                    {code: 'M1', text: 'Walks fine but difficulty with stairs, bending, sitting, standing'},
                    {code: 'M2', text: 'No mobility aid, but difficulty walking and/or climbing stairs'},
                    {code: 'M3', text: 'Uses walking aid such as cane or walker'},
                    {code: 'M4', text: 'Uses manual wheelchair'},
                    {code: 'M5', text: 'Uses power wheelchair'},
                    {code: 'M6', text: 'Uses scooter'}
                ]
            },
            {
                name: 'Reaching, grasping, or pinching',
                levels: [
                    {code: 'UD1', text: 'Dominant Hand: Difficulty with repetitive motion, strength, endurance or pain'},
                    {code: 'UD2', text: 'Dominant Hand: Difficulty reaching/lifting'},
                    {code: 'UD3', text: 'Dominant Hand: Difficulty controlling things with hand and fingers'},
                    {code: 'UD4', text: 'Dominant Hand: Very limited use of arm and hand'},
                    {code: 'UN1', text: 'Non-Dominant Hand: Difficulty with repetitive motion, strength, endurance or pain'},
                    {code: 'UN2', text: 'Non-Dominant Hand: Difficulty reaching/lifting'},
                    {code: 'UN3', text: 'Non-Dominant Hand: Difficulty controlling things with hand and fingers'},
                    {code: 'UN4', text: 'Non-Dominant Hand: Very limited use of arm and hand'}
                ]
            },
            {
                name: 'Seeing, even with glasses/contacts',
                levels: [
                    {code: 'V1', text: 'Can see clearly but sensitive to light'},
                    {code: 'V2', text: 'Can see clearly but difficult to distinguish colors'},
                    {code: 'V3', text: 'Can see somewhat, but has difficulty seeing details such as newspaperprint'},
                    {code: 'V4', text: 'Is blind or very limited use vision'}
                ]
            },
            {
                name: 'Hearing',
                levels: [
                    {code: 'H1', text: 'Can usually hear conversational speech clearly when using his/her personal assisted listening device'},
                    {code: 'H2', text: 'Is able to hear somewhat, but has difficulty hearing conversational speech clearly'},
                    {code: 'H3', text: 'Is deaf or has very limited usable hearing, even with the use of an assisted listening device'}
                ]
            },
            {
                name: 'Speaking',
                levels: [
                    {code: 'S1', text: 'Speaks clearly, but has significant difficulty thinking of the right words to say'},
                    {code: 'S2', text: 'Is able to think of the right words to say, and his/her voice is not usually understood'},
                    {code: 'S3', text: 'Is unable to speak'}
                ]
            },
            {
                name: 'Reading, remembering, or planning',
                levels: [
                    {code: 'C1', text: 'Sustain or divide attention'},
                    {code: 'C2', text: 'Remember short- or long-term'},
                    {code: 'C3', text: 'Regulate or express emotions'},
                    {code: 'C4', text: 'Organize, plan, or manage time'},
                    {code: 'C5', text: 'Perform calculations'},
                    {code: 'C6', text: 'Read or write'},
                    {code: 'C7', text: 'Understand verbal directions'}
                ]
            }
        ];


        let cards = diffTypes.map((diffType, index) => {
            return <Card key={`accordion-card-${index}`}>
                <AccordionHeaderToggle  eventKey={index}>{diffType.name}</AccordionHeaderToggle>
                <Accordion.Collapse eventKey={index} >
                    <Card.Body>
                        <Form>
                            {diffType.levels.map(
                                (opt, j) => {
                                    return <div key={`default-checkbox-${index}-${j}`} className='mb-3'>
                                        <Form.Check
                                            type='checkbox'
                                            id='default-checkbox'
                                            label={opt.text}
                                            value={opt.code}
                                            //checked={this.state.checkboxChecked}
                                            onChange={(e) => this.handleChange(e, this.props.updateConfig)}
                                        />

                                    </div>;
                                }
                            )}
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        });

        let frm = <Accordion >
            {cards}
        </Accordion>;

        // let diffTypes = [
        //     'Sit, stand, or move around',
        //     'Reach, grasp, or pinch',
        //     'See, even with glasses/contacts',
        //     'Hear',
        //     'Speak',
        //     'Read, remember, or plan – what we want people to click on'
        // ];
        //
        //
        // let cards = diffTypes.map(
        //                         (opt, j) => {
        //                             return <div key={`default-checkbox-${j}`} className='mb-3'>
        //                                 <Form.Check
        //                                     type='checkbox'
        //                                     id='default-checkbox'
        //                                     label={opt}
        //                                 />
        //                             </div>;
        //                         }
        // );
        //
        //
        //
        // let frm = <Form>{cards}</Form>


        return (

            <NavPanel title="I need supports for..." content={frm} {...props}/>

        );
    }

    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if(!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        }
        else
        {
            sel.push(evt.target.value);
        }

        if(updateConfig)
        {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }


}


Difficulties2.propTypes = Difficulties2PropTypes;

export default Difficulties2;
