import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';


import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import _ from 'lodash';

const ContactUsPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    progress: PropTypes.number,
    postMountCallback: PropTypes.func,
    previous: PropTypes.string,
    // selectedItems: PropTypes.array.isRequired
}




class ContactUs extends Component {

    constructor(props) {
        super(props);


        this.state = {
            // selected:this.props.selectedItems ? this.props.selectedItems : [],
            // showModal: false,
            // modalText: '',
            // modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(name, txt) {
        this.setState({showModal: true, modalTitle: name, modalText: txt});
    }




    componentDidMount() {
        console.log("componentDidMount");

        if(this.props.postMountCallback)
            this.props.postMountCallback();


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // if( !_.isEqual(this.props.selectedItems, prevProps.selectedItems))
        //     this.setState({selected:this.props.selectedItems ? this.props.selectedItems : []})
    }


    render() {


        let props = this.props;


        let frm =
            <div>
                <div>
                    <p>This tool was developed by the Center for Inclusive Design and Innovation (CIDI) and the Institute for People and Technology (IPaT) at Georgia Tech.</p>

                    <p>If you have questions or feedback about the project, please email: <a href="mailto:workaccess@design.gatech.edu">workaccess@design.gatech.edu</a></p>
                </div>

            </div>


        //let frm = <div>We need to first collect some information about your job requirements. Click next to continue...</div>


        return (

            <div>

                <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                            <Modal.Header closeButton>
                                <Modal.Title>

                                    {this.state.modalTitle}

                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                {this.state.modalText}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.closeModal()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>




                    <NavPanel hideInactiveNavButtons={true} title="Contact Us" content={frm}
                              prev={this.props.previous ? this.props.previous : undefined}/>
        
                />
            </div>

        );
    }


    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if (!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        } else {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        if (updateConfig) {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }




    // findPrev()
    // {
    //
    //     if(_.isNil(this.props.selectedJobReqs) || this.props.selectedJobReqs.length <= 0) {
    //         console.log("No job reqs to go back to");
    //         return null;
    //     }
    //     else {
    //         console.log("Go to last of job reqs");
    //         let ret = this.props.selectedJobReqs.slice(-1)[0];
    //         console.log(ret);
    //         return ret;
    //     }
    // }



}


ContactUs.propTypes = ContactUsPropTypes;

export default ContactUs;
