const DiffTypes = [
    {
        name: "Sit, stand, or move around",
        prompt: "Describe the employee’s ability to sit, stand, or move around",
        levels: [
            {code: 'L1', text: 'Has difficulty sitting for 30+ minutes', longText: 'The employee has difficulty sitting for sustained periods (e.g., 30 minutes or longer)'},
            {code: 'L2', text: 'Has difficulty standing for 30+ minutes', longText: 'The employee has difficulty standing for sustained periods (e.g., 30 minutes of longer)'},
            {code: 'L3', text: 'Has difficulty standing up', longText: 'The employee has difficulty standing up from a seated position'},
            {code: 'L4', text: 'Has difficulty with balance', longText: 'The employee has difficulty balancing'},
            {code: 'L5', text: 'Has difficulty bending', longText: 'The employee has difficulty bending'},
            // {code: 'M6', text: 'Has difficulty lifting', longText: 'The employee has difficulty lifting objects'},
            {code: 'L7', text: 'Has difficulty or cannot go up/down steps', longText: 'The employee has difficulty going up or down steps'},
            {code: 'L8', text: 'Has difficulty or cannot walk', longText: 'The employee has difficulty walking'},
            // {code: 'L8a', text: '\u00A0\u00A0\u00A0\u00A0None (Diff. walking but no mob. aid)', longText: ''},
            // {code: 'L8b', text: '\u00A0\u00A0\u00A0\u00A0Walking aid (e.g., cane, walker)', longText: ''},
            // {code: 'L8c', text: '\u00A0\u00A0\u00A0\u00A0Manual wheelchair', longText: ''},
            // {code: 'L8d', text: '\u00A0\u00A0\u00A0\u00A0Power wheelchair', longText: ''},
            // {code: 'L8e', text: '\u00A0\u00A0\u00A0\u00A0Scooter', longText: ''},
        ]
    },
    {
        name: "Reach, grasp, or pinch",
        prompt: "",
        levels: [
            {code: 'UD1', text: 'Dominant Hand: Difficulty with repetitive motion, strength, endurance or pain', longText:''},
            {code: 'UD2', text: 'Dominant Hand: Difficulty reaching/lifting', longText:''},
            {code: 'UD3', text: 'Dominant Hand: Difficulty controlling things with hand and fingers', longText:''},
            {code: 'UD4', text: 'Dominant Hand: Very limited use of arm and hand', longText:''},
            {code: 'UN1', text: 'Non-Dominant Hand: Difficulty with repetitive motion, strength, endurance or pain', longText:''},
            {code: 'UN2', text: 'Non-Dominant Hand: Difficulty reaching/lifting', longText:''},
            {code: 'UN3', text: 'Non-Dominant Hand: Difficulty controlling things with hand and fingers', longText:''},
            {code: 'UN4', text: 'Non-Dominant Hand: Very limited use of arm and hand', longText:''}
        ]
    },
    {
        name: "Seeing, even with glasses or contacts",
        prompt: "Describe the employee’s ability to see",
        levels: [
            {code: 'V1', text: 'Has light sensitivity', longText:'The employee can see clearly, but is sensitive to light'},
            {code: 'V2', text: 'Has difficulty telling colors apart', longText:'The employee can see clearly, but has difficulty distinguishing colors (am color-blind)'},
            {code: 'V3', text: 'Has difficulty seeing details', longText:'The employee can see somewhat, but has difficulty seeing details (e.g., newsprint), due to problems with visual acuity or blurred vision'},
            {code: 'V4', text: 'Does not have a full field of vision', longText:'The employee can see somewhat, but has a limited field of vision'},
            {code: 'V5', text: 'Is blind or has limited vision', longText:'The employee is blind or has very limited usable vision'},
            {code: 'V6', text: 'Is deaf-blind', longText:'The employee is deaf-blind'}
        ]
    },
    {
        name: "Hear",
        prompt: "Describe the employee's ability to hear",
        levels: [
            {code: 'H1', text: 'Has noise sensitivity', longText:'The employee can hear clearly, but is sensitive to noise'},
            {code: 'H2', text: 'Has ringing in the ears (tinnitus)', longText:'The employee can hear, but has ringing in the ears (tinnitus)'},
            {code: 'H3', text: 'Has difficulty hearing speech, unless uses hearing aid', longText:'The employee can hear conversational speech clearly, but only when using my personal hearing aid or other assisted listening device.'},
            {code: 'H4', text: 'Has difficulty hearing speech, even with hearing aid', longText:'The employee can hear somewhat, but has difficulty hearing conversational speech clearly'},
            {code: 'H5', text: 'Is deaf or has limited hearing', longText:'The employee is deaf or has very limited usable hearing, even with the use of a hearing aid or other assisted listening device'},
            {code: 'H6', text: 'Is deaf-blind', longText:'The employee is deaf-blind'}
        ]
    },
    {
        name: "Speak",
        prompt: "Describe the employee's ability to speak",
        levels: [
            {code: 'S1', text: 'Has difficulty thinking of the right words to say', longText:'The employee speaks clearly, but has significant difficulty thinking of the right words to say'},
            {code: 'S2', text: 'Stutters', longText:'The employee has difficulty with stuttering'},
            {code: 'S3', text: 'Has a soft or hoarse voice', longText:'The employee’s speech is not usually understood due to a soft or hoarse voice'},
            {code: 'S4', text: 'Has difficulty with articulation', longText:'The employee’s speech is not usually understood due to difficulties with articulation (including deaf-related)'},
            {code: 'S5', text: 'Is unable to speak', longText:'The employee is unable to speak'}
        ]
    },
    {
        name: "Read, remember, plan",
        prompt: "Describe the employee's ability to read, remember, plan",
        levels: [
            {code: 'C1', text: 'Has difficulty sustaining or dividing attention', longText:'The employee has difficulty sustaining, shifting, or dividing attention'},
            {code: 'C2', text: 'Has difficulty remembering short- or long-term', longText:'The employee has significant difficulty with short-term or long-term memory'},
            {code: 'C3', text: 'Has difficulty regulating or expressing emotions', longText:'The employee has difficulty regulating emotions or expressing appropriate emotions'},
            {code: 'C4', text: 'Has difficulty organizing, planning, or managing time', longText:'The employee has difficulty with organization, planning, or time management'},
            {code: 'C5', text: 'Has difficulty performing calculations', longText:'The employee has difficulty with simple and complex calculations'},
            {code: 'C6', text: 'Has difficulty reading or writing', longText:'The employee has difficulty with reading or expressing written language'},
            {code: 'C7', text: 'Has difficulty understanding verbal directions', longText:'The employee has difficulty understanding verbal directions, especially multiple step instructions'},
            {code: 'C8', text: 'Has difficulty with social awareness/picking up on cues', longText: 'The employee has difficulty with social awareness or picking up on social cues.'}
        ]
    },
    {
        name: "Anxiety",
        prompt: "Describe the employee's anxiety or stress",
        levels: [
            {code: 'A1', text: 'Significant anxiety or stress', longText:'The employee has significant problems with managing anxiety, stress or other issues related to mental health.'},
           ]
    },
    {
        name: "Other Health Issues",
        prompt: "Describe the employee's other health issues that impact stamina and results in fatigue",
        levels: [
            {code: 'W1', text: 'Decreased stamina (fatigue)', longText:'The employee has difficulty with decreased stamina resulting in fatigue'},
            {code: 'W2', text: 'Pain', longText: 'The employee has difficulty with pain'},
            {code: 'W3', text: 'Seizures', longText: 'The employee has difficulty with seizures'},
            {code: 'W4', text: 'Temperature sensitivity', longText: 'The employee has difficulty with temperature sensitivity'},
            {code: 'W5', text: 'Reduced immunity', longText: 'The employee has reduced immunity (possibly requiring telework)'},
            {code: 'W6', text: 'Chemical sensitivity', longText: 'The employee has chemical sensitivity'},
        ]
    },
    {
        name: "Dominant Reach",
        prompt: "Describe the employee's ability to reach with the arm currently used the most",
        levels: [
            {code: 'RD0', text: 'No difficulty', longText:''},
            {code: 'RD1', text: 'Mild difficulty (e.g., due to repetitive motion, strength, endurance, or pain)', longText:''},
            {code: 'RD2', text: 'Moderate to significant difficulty', longText:''},
            {code: 'RD3', text: 'Not able to use arm', longText:''},
        ]
    },
    {
        name: "Non-Dominant Reach",
        prompt: "Describe the employee's ability to reach with the arm currently used the least",
        levels: [
            {code: 'RN0', text: 'No difficulty', longText:''},
            {code: 'RN1', text: 'Mild difficulty (e.g., due to repetitive motion, strength, endurance, or pain)', longText:''},
            {code: 'RN2', text: 'Moderate to significant difficulty', longText:''},
            {code: 'RN3', text: 'Not able to use arm', longText:''},
        ]
    },
    {
        name: "Dominant Grasp/Pinch",
        prompt: "Describe the employee's ability to grasp, pinch, or control finger/hand motions for the hand currently used the most",
        levels: [
            {code: 'GD0', text: 'No difficulty', longText:''},
            {code: 'GD1', text: 'Mild difficulty (e.g., due to repetitive motion, strength, endurance, or pain)', longText:''},
            {code: 'GD2', text: 'Moderate to significant difficulty', longText:''},
            {code: 'GD3', text: 'Not able to use hand/fingers', longText:''},
        ]
    },
    {
        name: "Non-Dominant Grasp/Pinch",
        prompt: "Describe the employee's ability to grasp, pinch, or control finger/hand motions for the hand currently used the least",
        levels: [
            {code: 'GN0', text: 'No difficulty', longText:''},
            {code: 'GN1', text: 'Mild difficulty (e.g., due to repetitive motion, strength, endurance, or pain)', longText:''},
            {code: 'GN2', text: 'Moderate to significant difficulty', longText:''},
            {code: 'GN3', text: 'Not able to use hand/fingers', longText:''},
        ]
    },
    {
        name: "Mobility Support",
        prompt: "Describe the employee’s mobility support",
        levels: [
            {code: 'L8a', text: 'None (Difficulty walking but no mobility aid)', longText: ''},
            {code: 'L8b', text: 'Walking aid (e.g., cane, walker)', longText: ''},
            {code: 'L8c', text: 'Manual wheelchair', longText: ''},
            {code: 'L8d', text: 'Power wheelchair', longText: ''},
            {code: 'L8e', text: 'Scooter', longText: ''},
        ]
    },

    {
        name: "Communication Preference",
        prompt: "What is the employee's preferred mode of communication",
        levels: [
            {code: 'Ha', text: 'Speaking/Listening', longText: ''},
            {code: 'Hb', text: 'Sign Language (ASL) and Fingerspelling', longText: ''},
            {code: 'Hc', text: 'Cued Speech', longText: ''},
            {code: 'Hd', text: 'Tactile Sign Language', longText: ''},

        ]
    },

];

const DexTypes = {
    levels: [
        'U1',
        'U2',
        'U3',
        'U4',
        'U5',
        'U6',
        'U7',
        'U8',
        'U9',
        'U10',
        'U11',
        'U12',
        'U13',
        'U14',
        'U15',
        'U16',
        'U17',
        'U18'
    ]
};

const GetAllLevels = ()=>
{
    let levels = [];

    DiffTypes.forEach((e, i) => {
        let diff = DiffTypes[i];
        if(!diff.name.includes('Dominant') && !diff.name.includes('Reach, grasp, or pinch')) {
            diff.levels.forEach((e2, j) => {
                levels.push(e2.code);
            });
        }
    });

    DexTypes.levels.forEach((e, i) => {
        levels.push(e);
    });

    if(levels.length <= 0)
        console.log("BAD! levels has no values!");

    return levels;
}


export {DiffTypes, DexTypes, GetAllLevels};
