import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';


import {withRouter} from 'react-router-dom';


import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import {MdHelp, MdInfo} from 'react-icons/md';

import NavPanel from './NavPanel';
import _ from "lodash";

import {DiffTypes} from "./DiffTypes";

const DifficultiesIndividualPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    updateConfig: PropTypes.func,
    section: PropTypes.number.isRequired,
    minProgress: PropTypes.number,
    maxProgress: PropTypes.number,
    selectedItems: PropTypes.array.isRequired,
    selectedGroups: PropTypes.array.isRequired,

    selectedJobGroups: PropTypes.array.isRequired,

    startPath: PropTypes.string.isRequired,
    endPath: PropTypes.string.isRequired,
}




class DifficultiesIndividualBasic extends Component {

    constructor(props) {
        super(props);


        this.state = {
            selected: this.props.selectedItems ? this.props.selectedItems : [],
            showModal: false,
            modalShortText: "",
            modalLongText: "",
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(shortText, longText) {
        this.setState({showModal: true, modalShortText: shortText, modalLongText: longText});
    }



    render() {

        let props = this.props;

        console.log(props);

        let prev = this.findPrev();
        let next = this.findNext();

        console.log("prev: " + prev);
        console.log("next: " + next);
        console.log("selgrps: " + this.props.selectedGroups);




        let AccordionHeaderToggle = (props) => {
            const [expanded, setExpanded] = useState(false);

            return  <Accordion.Toggle {...props} as={Card.Header} onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}} >
                {expanded ? "+ " : "+ "}
                {props.children}
            </Accordion.Toggle>
        };


        let diffTypes = DiffTypes;
        // let diffTypes = [
        //     {
        //         name: "Sit, stand, or move around",
        //         prompt: "Describe the employee’s ability to sit, stand, or move around",
        //         levels: [
        //             {code: 'M1', text: 'Has difficulty sitting for 30+ minutes', longText: 'The employee has difficulty sitting for sustained periods (e.g., 30 minutes or longer)'},
        //             {code: 'M2', text: 'Has difficulty standing for 30+ minutes', longText: 'The employee has difficulty standing for sustained periods (e.g., 30 minutes of longer)'},
        //             {code: 'M3', text: 'Has difficulty standing up', longText: 'The employee has difficulty standing up from a seated position'},
        //             {code: 'M4', text: 'Has difficulty with balance', longText: 'The employee has difficulty balancing'},
        //             {code: 'M5', text: 'Has difficulty bending', longText: 'The employee has difficulty bending'},
        //             {code: 'M6', text: 'Has difficulty lifting', longText: 'The employee has difficulty lifting objects'},
        //             {code: 'M7', text: 'Has difficulty or cannot go up/down steps', longText: 'The employee has difficulty going up or down steps'},
        //             {code: 'M8', text: 'Has difficulty or cannot walk', longText: 'The employee has difficulty walking'},
        //         ]
        //     },
        //     {
        //         name: "Reach, grasp, or pinch",
        //         prompt: "",
        //         levels: [
        //             {code: 'UD1', text: 'Dominant Hand: Difficulty with repetitive motion, strength, endurance or pain', longText:''},
        //             {code: 'UD2', text: 'Dominant Hand: Difficulty reaching/lifting', longText:''},
        //             {code: 'UD3', text: 'Dominant Hand: Difficulty controlling things with hand and fingers', longText:''},
        //             {code: 'UD4', text: 'Dominant Hand: Very limited use of arm and hand', longText:''},
        //             {code: 'UN1', text: 'Non-Dominant Hand: Difficulty with repetitive motion, strength, endurance or pain', longText:''},
        //             {code: 'UN2', text: 'Non-Dominant Hand: Difficulty reaching/lifting', longText:''},
        //             {code: 'UN3', text: 'Non-Dominant Hand: Difficulty controlling things with hand and fingers', longText:''},
        //             {code: 'UN4', text: 'Non-Dominant Hand: Very limited use of arm and hand', longText:''}
        //         ]
        //     },
        //     {
        //         name: "Seeing, even with glasses or contacts",
        //         prompt: "Describe the employee’s ability to see",
        //         levels: [
        //             {code: 'V1', text: 'Has light sensitivity', longText:'The employee can see clearly, but is sensitive to light'},
        //             {code: 'V2', text: 'Has difficulty telling colors apart', longText:'The employee can see clearly, but has difficulty distinguishing colors (am color-blind)'},
        //             {code: 'V3', text: 'Has difficulty seeing details', longText:'The employee can see somewhat, but has difficulty seeing details (e.g., newsprint), due to problems with visual acuity or blurred vision'},
        //             {code: 'V4', text: 'Does not have a full field of vision', longText:'The employee can see somewhat, but has a limited field of vision'},
        //             {code: 'V5', text: 'Is blind or has limited vision', longText:'The employee is blind or has very limited usable vision'},
        //             {code: 'V6', text: 'Is deaf-blind', longText:'The employee is deaf-blind'}
        //         ]
        //     },
        //     {
        //         name: "Hear",
        //         prompt: "Describe the employee's ability to hear",
        //         levels: [
        //             {code: 'H1', text: 'Has noise sensitivity', longText:'The employee can hear clearly, but is sensitive to noise'},
        //             {code: 'H2', text: 'Has ringing in the ears (tinnitus)', longText:'The employee can hear, but has ringing in the ears (tinnitus)'},
        //             {code: 'H3', text: 'Has difficulty hearing speech, unless uses hearing aid', longText:'The employee can hear conversational speech clearly, but only when using my personal hearing aid or other assisted listening device.'},
        //             {code: 'H4', text: 'Has difficulty hearing speech, even with hearing aid', longText:'The employee can hear somewhat, but has difficulty hearing conversational speech clearly'},
        //             {code: 'H5', text: 'Is deaf or has limited hearing', longText:'The employee is deaf or has very limited usable hearing, even with the use of a hearing aid or other assisted listening device'},
        //             {code: 'H6', text: 'Is deaf-blind', longText:'The employee is deaf-blind'}
        //         ]
        //     },
        //     {
        //         name: "Speak",
        //         prompt: "Describe the employee's ability to speak",
        //         levels: [
        //             {code: 'S1', text: 'Has difficulty thinking of the right words to say', longText:'The employee speaks clearly, but has significant difficulty thinking of the right words to say'},
        //             {code: 'S2', text: 'Stutters', longText:'The employee has difficulty with stuttering'},
        //             {code: 'S3', text: 'Has a weak voice', longText:'The employee’s speech is not usually understood due to a weak voice'},
        //             {code: 'S4', text: 'Has difficulty with articulation', longText:'The employee’s speech is not usually understood due to difficulties with articulation (including deaf-related)'},
        //             {code: 'S5', text: 'Is unable to speak', longText:'The employee is unable to speak'}
        //         ]
        //     },
        //     {
        //         name: "Read, remember, plan",
        //         prompt: "Describe the employee's ability to read, remember, plan",
        //         levels: [
        //             {code: 'C1', text: 'Has difficulty sustaining or dividing attention', longText:'The employee has difficulty sustaining, shifting, or dividing attention'},
        //             {code: 'C2', text: 'Has difficulty remembering short- or long-term', longText:'The employee has significant difficulty with short-term or long-term memory'},
        //             {code: 'C3', text: 'Has difficulty regulating or expressing emotions', longText:'The employee has difficulty regulating emotions or expressing appropriate emotions'},
        //             {code: 'C4', text: 'Has difficulty organizing, planning, or managing time', longText:'The employee has difficulty with organization, planning, or time management'},
        //             {code: 'C5', text: 'Has difficulty performing calculations', longText:'The employee has difficulty with simple and complex calculations'},
        //             {code: 'C6', text: 'Has difficulty reading or writing', longText:'The employee has difficulty with reading or expressing written language'},
        //             {code: 'C7', text: 'Has difficulty understanding verbal directions', longText:'The employee has difficulty understanding verbal directions, especially multiple step instructions'}
        //         ]
        //     },
        //     {
        //         name: "Dominant Reach",
        //         prompt: "Describe the employee's ability to reach with the arm currently used the most",
        //         levels: [
        //             {code: 'RD0', text: 'No difficulty', longText:''},
        //             {code: 'RD1', text: 'Mild difficulty (e.g., due to repetitive motion, strength, endurance, or pain)', longText:''},
        //             {code: 'RD2', text: 'Moderate to significant difficulty', longText:''},
        //             {code: 'RD3', text: 'Not able to use arm', longText:''},
        //         ]
        //     },
        //     {
        //         name: "Non-Dominant Reach",
        //         prompt: "Describe the employee's ability to reach with the arm currently used the least",
        //         levels: [
        //             {code: 'RN0', text: 'No difficulty', longText:''},
        //             {code: 'RN1', text: 'Mild difficulty (e.g., due to repetitive motion, strength, endurance, or pain)', longText:''},
        //             {code: 'RN2', text: 'Moderate to significant difficulty', longText:''},
        //             {code: 'RN3', text: 'Not able to use arm', longText:''},
        //         ]
        //     },
        //     {
        //         name: "Dominant Grasp/Pinch",
        //         prompt: "Describe the employee's ability to grasp, pinch, or control finger/hand motions for the hand currently used the most",
        //         levels: [
        //             {code: 'GD0', text: 'No difficulty', longText:''},
        //             {code: 'GD1', text: 'Mild difficulty (e.g., due to repetitive motion, strength, endurance, or pain)', longText:''},
        //             {code: 'GD2', text: 'Moderate to significant difficulty', longText:''},
        //             {code: 'GD3', text: 'Not able to use hand/fingers', longText:''},
        //         ]
        //     },
        //     {
        //         name: "Non-Dominant Grasp/Pinch",
        //         prompt: "Describe the employee's ability to grasp, pinch, or control finger/hand motions for the hand currently used the least",
        //         levels: [
        //             {code: 'GN0', text: 'No difficulty', longText:''},
        //             {code: 'GN1', text: 'Mild difficulty (e.g., due to repetitive motion, strength, endurance, or pain)', longText:''},
        //             {code: 'GN2', text: 'Moderate to significant difficulty', longText:''},
        //             {code: 'GN3', text: 'Not able to use hand/fingers', longText:''},
        //         ]
        //     },
        //
        // ];


        let diffType = diffTypes[this.props.section];


        let radioMode = this.props.section >= 8 && this.props.section <= 12;


        let frm =
            <div>
                <h2 class="nav-sub-heading">{diffType.prompt}</h2>
                <Form onClick={()=>{}}>
                    {diffType.levels.map(
                        (opt, j) => {
                            return <div style={{display: 'flex', flexDirection: 'row'}} key={`default-checkbox-${this.props.section}_${j}`} className='mb-3'>
                                <Form.Check
                                    style={{display: 'flex'}}
                                    type={ radioMode ? 'radio' : 'checkbox'}
                                    id={`default-checkbox-${opt.code}`}
                                    //label={<div onClick={(e)=>{e.stopPropagation();e.nativeEvent.stopImmediatePropagation(); this.showModal(opt.text);}}>{opt.text}<MdHelp/></div>}
                                    label={opt.text}
                                    value={opt.code}
                                    checked={this.props.selectedItems.includes(opt.code)}
                                    //checked={this.state.checkboxChecked}
                                    onChange={ radioMode ?
                                        (e) => this.handleRadioChange(e, diffType.levels, this.props.updateConfig) :
                                        (e) => this.handleChange(e, this.props.updateConfig)}
                                />
                                <div style={{display: 'flex'}} >
                                    <div style={{cursor:'pointer'}} onClick={(e)=>{this.showModal(opt.text, opt.longText);}}>
                                        <MdInfo/>
                                    </div>
                                </div>
                            <div style={{display: 'flex', flex:1}}></div>
                            </div>;
                        }
                    )}
                </Form>
            </div>





        return (

            <div>


                <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>

                            {this.state.modalShortText}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.modalLongText}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.closeModal()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                <NavPanel title={'Step 2: Select Difficulties (continued)' /*diffType.name*/} content={frm}
                          progress={this.findProgress()}
                          prev={this.findPrev()} next={this.findNext()} />

            </div>

        );
    }




    findProgress()
    {
        if( _.isNil(this.props.selectedGroups) || this.props.selectedGroups.length <= 0 )
            return this.props.minProgress;

        let i = this.props.selectedGroups.findIndex((e) => {
                //console.log("testing: " + e + " versus " + this.props.location.pathname);
                return e.trim() === this.props.location.pathname.trim();
            }
        );

        if(i < 0) return this.props.minProgress;


        return this.props.minProgress +  (this.props.maxProgress-this.props.minProgress)*(i)/(this.props.selectedGroups.length);

    }


    findPrev()
    {
        if( _.isNil(this.props.selectedGroups) || this.props.selectedGroups.length <= 0 )
            return null;

        let i = -1;

        let pathToFind = this.props.location.pathname;

        if("/dex_diff_dom_reach" === this.props.location.pathname)
        {
            // do nothing
        }
        if("/dex_diff_nondom_reach" === this.props.location.pathname)
        {
            return "/dex_diff_dom_reach";
        }
        if("/dex_diff_dom_grasp" === this.props.location.pathname)
        {
            return "/dex_diff_nondom_reach";
        }

        if("/dex_diff_nondom_grasp" === this.props.location.pathname)
        {
            return "/dex_diff_dom_grasp";
        }

        if("/mob_support" === this.props.location.pathname)
        {
            return "/mob_diff";
        }

        if("/comm_pref" === this.props.location.pathname)
        {
            return "/hear_diff";
        }

        i = this.props.selectedGroups.findIndex((e) => {
                //console.log("testing: " + e + " versus " + this.props.location.pathname);
                return e.trim() === pathToFind.trim();
            }
        );

        if(i < 0) return null;

        if(i === 0)
            return this.props.startPath;

        return this.props.selectedGroups[i-1];
    }

    findNext()
    {
        if( _.isNil(this.props.selectedGroups) || this.props.selectedGroups.length <= 0 )
            return null;

        console.log("DifficultiesIndividual:findNext():selectedGroups=");
        console.log(this.props.selectedGroups);

        let i = -1;

        let pathToFind = this.props.location.pathname;

        if("/dex_diff_dom_reach" === this.props.location.pathname)
        {
            return "/dex_diff_nondom_reach";
        }
        if("/dex_diff_nondom_reach" === this.props.location.pathname)
        {
            return "/dex_diff_dom_grasp";
        }
        if("/dex_diff_dom_grasp" === this.props.location.pathname)
        {
            return "/dex_diff_nondom_grasp";
        }

        if("/dex_diff_nondom_grasp" === this.props.location.pathname)
        {
            // pretend we didn't go to sub level
            pathToFind = "/dex_diff_dom_reach";
        }

        if("/mob_support" === this.props.location.pathname)
        {
            // pretend we didn't go to sub level
            pathToFind = "/mob_diff";
        }

        if("/mob_diff" === this.props.location.pathname)
        {
            console.log("IN MOB_DIFF and: ");
            console.log(this.props.selectedItems);
            let l8Index = this.props.selectedItems.findIndex((e) => e === 'L8');
            if(l8Index >= 0) {
                // go to sub level selections
                return "/mob_support";
            }
        }

        if("/hear_diff" === this.props.location.pathname)
        {
            return "/comm_pref";
        }

        if("/comm_pref" === this.props.location.pathname)
        {
            //pretend we didn't go to sub-level
            pathToFind =  "/hear_diff";
        }

        i = this.props.selectedGroups.findIndex((e) => e === pathToFind);

        if(i < 0) return null;

        if(i === this.props.selectedGroups.length-1) {
            //return this.props.endPath;

            if(this.props.selectedJobGroups.length === 0) {
                console.log("NO selected job groups to continue!");
                return null;
            }
            else
                return this.props.selectedJobGroups[0];

        }

        return this.props.selectedGroups[i+1];
    }


    updateDexterity(sel)
    {
        //let codes = Array(18).fill().map((element, index) => "U"+(1+index).toString());

        let RD0 = sel.includes("RD0");
        let RD1 = sel.includes("RD1");
        let RD2 = sel.includes("RD2");
        let RD3 = sel.includes("RD3");

        let RN0 = sel.includes("RN0");
        let RN1 = sel.includes("RN1");
        let RN2 = sel.includes("RN2");
        let RN3 = sel.includes("RN3");

        let GD0 = sel.includes("GD0");
        let GD1 = sel.includes("GD1");
        let GD2 = sel.includes("GD2");
        let GD3 = sel.includes("GD3");

        let GN0 = sel.includes("GN0");
        let GN1 = sel.includes("GN1");
        let GN2 = sel.includes("GN2");
        let GN3 = sel.includes("GN3");

        // let U1 = RD1 || (RD2 && RN1) || (RD3 && RN1);
        // let U2 = RD2 || (RD3 && RN2);
        // let U3 = RD3 && RN3;
        // let U4 = (RD0 && RN1) || (RD1 && RN0);
        // let U5 = (RD1 + RN1);
        // let U6 = (RD0 + RN2) || (RD1 && RN2) || (RD2 && RN0) || (RD2 && RN1);
        // let U7 = RD2 && RN2;
        // let U8 = RD3 || RN3;
        // let U9 = RD3 && RN3;
        // let U10 = GD1 || (GD2 && GN1) || (GD3 && GN1);
        // let U11 = GD2 || (GD3 && GN2);
        // let U12 = GD3 && GN3;
        // let U13 = (GD0 && GN1) || (GD1 && GN0);
        // let U14 = GD1 && GN1;
        // let U15 = (GD0 && GN2) || (GD1 && GN2) || (GD2 && GN0) || (GD2 && GN1);
        // let U16 = GD2 && GN2;
        // let U17 = GD3 || GN3;
        // let U18 = GD3 && GN3;

        let U = [
            RD1 || (RD2 && RN1) || (RD3 && RN1),
            RD2 || (RD3 && RN2),
            RD3 && RN3,
            (RD0 && RN1) || (RD1 && RN0),
            (RD1 && RN1),
            (RD0 && RN2) || (RD1 && RN2) || (RD2 && RN0) || (RD2 && RN1),
            RD2 && RN2,
            RD3 || RN3,
            RD3 && RN3,
            GD1 || (GD2 && GN1) || (GD3 && GN1),
            GD2 || (GD3 && GN2),
            GD3 && GN3,
            (GD0 && GN1) || (GD1 && GN0),
            GD1 && GN1,
            (GD0 && GN2) || (GD1 && GN2) || (GD2 && GN0) || (GD2 && GN1),
            GD2 && GN2,
            GD3 || GN3,
            GD3 && GN3];

        U.forEach((e, i) => {
            let code = "U" + (i + 1).toString();

            if (!e) {
                const j = sel.indexOf(code);
                if (j > -1) {
                    sel.splice(j, 1);
                }
            } else
            {
                const index = sel.indexOf(code);
                if (index < 0) {
                    sel.push(code);
                }
            }

        });
    }

    handleRadioChange(evt, diffTypeLevels, updateConfig) {

        console.log("radio checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        // clear all for radio interaction
        diffTypeLevels.forEach(e => {
           let code = e.code;
           const i = sel.indexOf(code);
           if(i > -1) {
               sel.splice(i, 1);
           }
        });

        if(!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        }
        else
        {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        this.updateDexterity(sel);

        if(updateConfig)
        {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }


    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if(!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        }
        else
        {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        if(updateConfig)
        {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }


}


DifficultiesIndividualBasic.propTypes = DifficultiesIndividualPropTypes;

const DifficultiesIndividual = withRouter(DifficultiesIndividualBasic);

export default DifficultiesIndividual;
