import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';


import {withRouter} from 'react-router-dom';

import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import job_groups from "./jobs";

import _ from 'lodash';

const JobRequirementsIndividualPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    updateTaskConfig: PropTypes.func,
    updateMidTaskConfig: PropTypes.func,
    //section: PropTypes.number.isRequired,
    jobGroup: PropTypes.string.isRequired,
    jobGroupUrl: PropTypes.string.isRequired,
    subJobGroup: PropTypes.string,
    minProgress: PropTypes.number,
    maxProgress: PropTypes.number,
    selectedTaskItems: PropTypes.array.isRequired,
    selectedMidTaskItems: PropTypes.object.isRequired,
    selectedGroups: PropTypes.array.isRequired,
    selectedDifficulties: PropTypes.array.isRequired,
    selectedDifficultiesGroups: PropTypes.array.isRequired,
    startPath: PropTypes.string.isRequired,
    endPath: PropTypes.string.isRequired,

    taskRulesEngine: PropTypes.object.isRequired,
    taskResults: PropTypes.array.isRequired,
    dataReady: PropTypes.bool.isRequired,
}


function smartStartCase(str) {
    const smallWords = new Set(['a', 'an', 'and', 'as', 'at', 'but', 'by', 'for', 'if', 'in', 'nor', 'of', 'on', 'or', 'so', 'the', 'to', 'up', 'yet']);

    return str.split(' ').map((word, index) => {
        // Convert the word to lower case
        const lowerWord = word.toLowerCase();

        // Capitalize the first and last word regardless
        if (index === 0 || index === str.split(' ').length - 1) {
            return _.capitalize(lowerWord);
        }

        // If the word is in the smallWords set, return it as lower case
        if (smallWords.has(lowerWord)) {
            return lowerWord;
        }

        // Otherwise, capitalize the word
        return _.capitalize(lowerWord);
    }).join(' ');
}


class JobRequirementsIndividualBasic extends Component {

    constructor(props) {
        super(props);


        //let jobTypes = ["facility_access", "communication", "organization", "using_equipment"];
        //let jobType = jobTypes[this.props.section];


        this.state = {
            selectedTasks:this.props.selectedTaskItems ? this.props.selectedTaskItems : [],
            selectedMidTasks:this.props.selectedMidTaskItems ? this.props.selectedMidTaskItems : {},
            showModal: false,
            //jobType: jobType,
            modalText: '',
            modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(title, txt) {
        this.setState({showModal: true, modalTitle: title, modalText: txt});
    }




    // componentDidMount() {
    //     // let jobTypes = ["facility_access", "communication", "organization", "using_equipment"];
    //     // let jobType = jobTypes[this.props.section];
    //
    //
    //     console.log("XXXXXXXX");
    //     console.log("XXXXXXXX");
    //     console.log("jobReqIndivid:componentDidMount");
    //     console.log(this.state.jobType);
    //     console.log("XXXXXXXX");
    //     console.log("XXXXXXXX");
    //
    //     this.props.taskRulesEngine.selectedDifficulties = this.props.selectedDifficulties;
    //     this.props.taskRulesEngine.selectedJobGroup = this.state.jobType;
    //     this.props.taskRulesEngine.updateRulesEngine();
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //
    //     // let jobTypes = ["facility_access", "communication", "organization", "using_equipment"];
    //     // let jobType = jobTypes[this.props.section];
    //
    //
    //     console.log("XXXXXXXX");
    //     console.log("XXXXXXXX");
    //     console.log("jobReqIndivid:componentDidMount");
    //     console.log(this.state.jobType);
    //     console.log("XXXXXXXX");
    //     console.log("XXXXXXXX");
    //
    //     this.props.taskRulesEngine.selectedDifficulties = this.props.selectedDifficulties;
    //     this.props.taskRulesEngine.selectedJobGroup = this.state.jobType;
    //     this.props.taskRulesEngine.updateRulesEngine();
    // }



    jobURLToVar(url) {
        if(url === "job_req_comm")
            return "communication";
        if(url === "job_req_fac")
            return "facility_access";
        if(url === "job_req_org")
            return "organization";
        if(url === "job_req_tools")
            return "using_tools";
        if(url === "job_req_workplace")
            return "workplace";

        return "";
    }

    render() {

        let props = this.props;

        console.log(props);

        let prev = this.findPrev();
        let next = this.findNext();

        console.log("prev: " + prev);
        console.log("next: " + next);
        console.log("selgrps: " + this.props.selectedGroups);


        let AccordionHeaderToggle = (props) => {
            const [expanded, setExpanded] = useState(false);

            return  <Accordion.Toggle {...props} as={Card.Header} onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}} >
                {expanded ? "+ " : "+ "}
                {props.children}
            </Accordion.Toggle>
        };



        let diffTypes = [
            {
                name: 'Facility Access',
                levels: [
                    {code: 'T0100', text: 'Moving Through the Workplace - T0100'},
                    {code: 'T0101', text: 'Unlocking and Using Doors - T0101'},
                    {code: 'T0102', text: 'Moving Between Floors - T0102'},
                    {code: 'T0103', text: 'Navigating the Workplace - T0103'},
                    {code: 'T0104', text: 'Using the Restroom - T0104'},
                    {code: 'T0105', text: 'Washing Hands - T0105'},
                    // {code: 'T0200', text: 'Building Levels - T0100'},
                    // {code: 'T0201', text: 'Building Levels  - T0101'},
                    // {code: 'T0202', text: 'Building Levels  - T0102'},
                    // {code: 'T0203', text: 'Building Levels  - T0103'},
                    // {code: 'T0204', text: 'Building Levels  - T0104'},

                ]
            },
            // {
            //     name: 'Workplace',
            //     levels: [
            //         {code: 'T0500', text: 'Workplace - T0500'},
            //         {code: 'T0501', text: 'Workplace - T0501'},
            //         {code: 'T0502', text: 'Workplace - T0502'},
            //         {code: 'T0503', text: 'Workplace - T0503'},
            //         {code: 'T0504', text: 'Workplace - T0504'},
            //         {code: 'T0505', text: 'Workplace - T0505'},
            //         {code: 'T0506', text: 'Workplace - T0506'},
            //         {code: 'T0507', text: 'Workplace - T0507'},
            //     ]
            // },
            // {
            //     name: 'Using a Workstation',
            //     levels: [
            //         {code: 'T0500', text: 'Siting/Standing at a Workstation - T0500'},
            //         {code: 'T0501', text: 'Accessing/Storing Materials - T0501'},
            //     ]
            // },
            {
                name: 'Communication',
                levels: [
                    {code: 'T0600', text: 'Communicating In-Person (1-2 People) - T0600'},
                    {code: 'T0601', text: 'Communicating Remotely (Phone/Text) - T0601'},
                    {code: 'T0602', text: 'Participating in In-Person Meetings - T0602'},
                    {code: 'T0603', text: 'Participating in Remote Meetings - T0603'},
                    {code: 'T4501', text: 'Reading and Writing Documents - Pages difficult to turn - T4501'},
                    {code: 'T4502', text: 'Reading and Writing Documents - Paper slides while writing - T4502'},
                    {code: 'T4503', text: 'Reading and Writing Documents - Handwriting causes discomfort - T4503'},
                    {code: 'T4504', text: 'Reading and Writing Documents - Handwriting requires too much fine motor coordination - T4504'},
                    {code: 'T4505', text: 'Reading and Writing Documents - Writing is not legible - T4505'},
                    {code: 'T4506', text: 'Reading and Writing Documents - Standard pen is too difficult to hold - T4506'},
                    {code: 'T4507', text: 'Reading and Writing Documents - Handwriting is too slow - T4507'},
                    {code: 'T4508', text: 'Reading and Writing Documents - Text is too small - T4508'},
                    {code: 'T4509', text: 'Reading and Writing Documents - Only part of the page is visible - T4509'},
                    {code: 'T4510', text: 'Reading and Writing Documents - Documents cannot be seen - T4510'},
                    {code: 'T4511', text: 'Reading and Writing Documents - Writing areas (form blanks) are not visible - T4511'},
                    {code: 'T4512', text: 'Reading and Writing Documents - Employee has difficulty with literacy - T4512'},
                    {code: 'T4601', text: 'Composing Text - Writing contains misspellings or grammar mistakes - T4601'},
                    {code: 'T4602', text: 'Composing Text - Employee has difficulty thinking of the right words to use - T4602'},
                    {code: 'T4603', text: 'Composing Text - Writing is not organized - T4603'},
                ]
            },
            {
                name: 'Organization',
                levels: [
                    {code: 'T3101', text: 'Difficulty remembering appointments or meetings'},
                    {code: 'T3102', text: 'Difficulty with time management (e.g., getting things done on time)'},
                    {code: 'T3103', text: 'Difficulty using standard time management systems'},
                    {code: 'T3104', text: 'Difficulty separating or balancing work and home activities when teleworking'},
                    {code: 'T3202', text: 'Difficulty remembering specific information needed to complete a task'},
                    {code: 'T3203', text: 'Difficulty accessing on-the-job training'},
                    {code: 'T3301', text: 'Difficulty remaining focused on task'},

                ]
            },
            {
                name: 'Using Equipment',
                levels: [
                    {code: 'T0900', text: 'Using a Computer - T0900'},
                    {code: 'T0901', text: 'Using a Phone (see Communication) - T0901'},
                ]
            },
            // {
            //     name: 'Computer',
            //     levels: [
            //         {code: 'T1000', text: 'Workplace - T1000'},
            //         {code: 'T1001', text: 'Workplace - T1001'},
            //         {code: 'T1002', text: 'Workplace - T1002'},
            //         {code: 'T1003', text: 'Workplace - T1003'},
            //         {code: 'T1004', text: 'Workplace - T1004'},
            //         {code: 'T1005', text: 'Workplace - T1005'},
            //         {code: 'T1006', text: 'Workplace - T1006'},
            //         {code: 'T1007', text: 'Workplace - T1007'},
            //         {code: 'T1008', text: 'Workplace - T1008'},
            //     ]
            // },
        ];

        //let diffType = diffTypes[this.props.section];

        let tasks = this.props.taskRulesEngine.tasks;
        //let sel = this.props.taskRulesEngine.results;
        let sel = this.props.taskResults;

        console.log("tasks");
        console.log(JSON.stringify(tasks));

        console.log("selgroup");
        console.log(this.props.taskRulesEngine.selectedJobGroup);

        console.log("seldiff");
        console.log(this.props.selectedDifficulties);

        console.log("sel:");
        console.log(sel);

        let diffTypeSet = _.pick(tasks, sel);

        console.log("diffTypes");
        console.log(diffTypeSet);

        let diffType = Object.values(diffTypeSet);

        console.log("diffType");
        console.log(diffType);

        console.log("mid task items:");
        console.log(JSON.stringify(this.props.selectedMidTaskItems));

        // "T3101": {
        //     "gap": "Difficulty remembering appointments or meetings",
        //         "job_group": "Organization",
        //         "prob_bit": 1,
        //         "task": "Planning the Work Day",
        //         "task_bit": 31,
        //         "task_code": "T3101",
        //         "trigger": "(a.organization && ((a.C1 || a.C2 || a.C4)) || ((a.C1 || a.C2 || a.C4)) || ((a.C1 || a.C2 || a.C4)) || ((a.C1 || a.C2 || a.C4)) || ((a.C1 || a.C2 || a.C4)) || ((a.C1 || a.C2 || a.C4)))"
        // },


        let sel_job_group = job_groups[this.props.jobGroup];

        console.log('sel_job_group is: ');
        console.log(sel_job_group);
        console.log('looking for: ');
        console.log(this.props.jobGroup);


        let job_tasks = sel_job_group.tasks;

        let title = _.isNil(this.props.subJobGroup) ? 'Step 3: Select Job Activity':'Step 4: What is the Specific Problem?';

        let subTitlePart = _.isNil(this.props.subJobGroup) ? smartStartCase(this.props.jobGroup) : smartStartCase(this.props.subJobGroup.replaceAll("_", " "));

        let subTitleText = _.isNil(this.props.subJobGroup) ? 'Earlier, you said that you want to explore tasks related to "' +
            subTitlePart + '." Which tasks present a difficulty?' : 'You said that you have difficulty with "' + subTitlePart +
            '." What is the specific problem that you are having with that task?';

        let frm =
            <div>

                {/*<h2 class="nav-sub-heading">{_.startCase(this.props.jobGroup)}{*/}
                {/*    _.isNil(this.props.subJobGroup) ? '': '/'+_.startCase(this.props.subJobGroup.replaceAll("_", " "))*/}
                {/*}</h2>*/}

                <h2 className="nav-sub-heading">{subTitleText}</h2>


                <Form>
                    {
                        _.isNil(this.props.subJobGroup) ?


                            Object.keys(job_tasks).map(
                                (opt, j) => {
                                    return <div style={{display: 'flex', flexDirection: 'row'}}
                                                key={`default-checkbox-${this.props.jobGroup}_${j}`}
                                                className='mb-3'>
                                        <Form.Check
                                            type='checkbox'
                                            id={`default-checkbox-${job_tasks[opt].task_var}`}
                                            label={job_tasks[opt].task}
                                            value={job_tasks[opt].task_var}
                                            checked={
                                                _.isNil(this.props.selectedMidTaskItems[this.props.jobGroup]) ? false :
                                                this.props.selectedMidTaskItems[this.props.jobGroup].includes(opt)}
                                            //checked={this.state.checkboxChecked}
                                            onChange={(e) => this.handleMidTaskChange(e, this.props.updateMidTaskConfig)}
                                        />

                                        {/* TODO This could display tool tips! */}
                                        {/*<div style={{display: 'flex'}}>*/}
                                        {/*    <div style={{cursor: 'pointer'}} onClick={(e) => {*/}
                                        {/*        e.stopPropagation();*/}
                                        {/*        e.nativeEvent.stopImmediatePropagation();*/}
                                        {/*        this.showModal('todo');*/}
                                        {/*    }}>*/}
                                        {/*        <MdInfo/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div style={{display: 'flex', flex: 1}}></div>

                                    </div>;
                                })

                            :

                            diffType.length === 0 ?
                            <p>
                                {
                                    "Unfortunately, there currently are not any problems in the knowledgebase for this Job Activity and the previously identified Difficulties. You may continue if you have other Job Activities selected that you are interested in. Otherwise, consider backing up and choosing different options."
                                    //"Unfortunately your selections did not find any matches for solutions.  We are continuing to expand our database, but for now, we recommend you back up and choose some different options."
                                    //"No matches found. Consider revising previous selections. Or click 'Next' to continue with selections."
                                }
                            </p>
                            :
                            diffType.map(
                                (opt, j) => {
                                    return <div style={{display: 'flex', flexDirection: 'row'}}
                                                key={`default-checkbox-${this.props.jobGroup}-${this.props.subJobGroup}_${j}`}
                                                className='mb-3'>
                                        <Form.Check
                                            type='checkbox'
                                            id={`default-checkbox-${opt.task_code}`}
                                            label={opt.gap}
                                            value={opt.task_code}
                                            checked={this.props.selectedTaskItems.includes(opt.task_code)}
                                            //checked={this.state.checkboxChecked}
                                            onChange={(e) => this.handleTaskChange(e, this.props.updateTaskConfig)}
                                        />

                                        {/*<div style={{display: 'flex'}}>*/}
                                        {/*    <div style={{cursor: 'pointer'}} onClick={(e) => {*/}
                                        {/*        e.stopPropagation();*/}
                                        {/*        e.nativeEvent.stopImmediatePropagation();*/}
                                        {/*        this.showModal(opt.gap);*/}
                                        {/*    }}>*/}
                                        {/*        <MdInfo/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div style={{display: 'flex', flex: 1}}></div>*/}

                                    </div>;
                                }
                            )
                    }
                </Form>
                {
                diffType.length === 0 ? "" :
                <div>
                    <p>
                        Unable to find the choice you want?
                        <span
                            tabIndex="0"
                            role="button"
                            aria-label="More Information"
                            style={{cursor: 'pointer', display: 'inline-flex', alignItems: 'center', marginLeft: '5px'}}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                this.showModal(
                                    "Can't find desired choice?",
                                    "We may not have developed that section yet or do not have solutions for the combination of tasks and disability that you selected. Please use the back button to try another combination."
                                );
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    this.showModal(
                                        "Can't find desired choice?",
                                        "We may not have developed that section yet or do not have solutions for the combination of tasks and disability that you selected. Please use the back button to try another combination."
                                    );
                                }
                            }}

                        >
                            <MdInfo/>
                        </span>
                    </p>
                </div>
                }
            </div>



        if(!this.props.dataReady) {
            return (
                <NavPanel title={title} content={<div></div>} progress={this.findProgress()}
                          prev={this.findPrev()} next={this.findNext()}
                />);
        }
        else
            return (

            <div>

                <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>

                            {this.state.modalTitle}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.modalText}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.closeModal()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                <NavPanel title={title} content={frm} progress={this.findProgress()}
                    prev={this.findPrev()} next={this.findNext()}
                />

            </div>

        );
    }

    findProgress()
    {
        console.log("FIND_PROGRESS");
        console.log("min_prog: " + this.props.minProgress);
        console.log("max_prog: " + this.props.maxProgress);
        console.log(this.props.selectedGroups);
        //console.log(this.props.location.pathname.trim());

        let jobGroupUrl = "/"+this.props.jobGroupUrl.trim()
        console.log(jobGroupUrl);

        if( _.isNil(this.props.selectedGroups) || this.props.selectedGroups.length <= 0 )
            return this.props.minProgress;

        let i = this.props.selectedGroups.findIndex((e) => {
                //console.log("testing: " + e + " versus " + this.props.location.pathname);
                return e.trim() === jobGroupUrl;
            }
        );

        if(i < 0)
        {
            console.log("can't find progress path");
            return this.props.minProgress;
        }

        console.log("i pos is: " + i);

        let baseFrac = (this.props.maxProgress-this.props.minProgress)/(this.props.selectedGroups.length);

        let baseVal =  this.props.minProgress +  baseFrac*i;//(this.props.maxProgress-this.props.minProgress)*(i)/(this.props.selectedGroups.length);

        let extraVal = 0;

        let midTaskItems = !_.isNil(this.props.selectedMidTaskItems) ? this.props.selectedMidTaskItems[this.props.jobGroup] : null;

        console.log("midTaskItems");
        console.log(midTaskItems);
        console.log(this.props.subJobGroup);

        if(!_.isNil(midTaskItems) && !_.isNil(this.props.subJobGroup))
        {
            let j = midTaskItems.findIndex((e) => {
                    //console.log("testing: " + e + " versus " + this.props.location.pathname);
                    return e.trim() === this.props.subJobGroup;
                }
            );

            if(j < 0)
            {
                console.log("can't find subjobgroup");
            }
            else
            {
                extraVal = (j+1) * baseFrac / midTaskItems.length;

                console.log("extraVal: " + extraVal);
            }
        }


        return baseVal + extraVal;
    }


    findPrev()
    {
        console.log("curr path: " + this.props.location.pathname);
        console.log("curr job group url: " + this.props.jobGroupUrl);
        console.log(this.props.selectedGroups);



        let midTaskItems = !_.isNil(this.props.selectedMidTaskItems) ? this.props.selectedMidTaskItems[this.props.jobGroup] : null;

        if(_.isNil(this.props.subJobGroup))
        {
            // need to try to go to previous group's last sub job group

            if( _.isNil(this.props.selectedGroups)  )
                return null;

            let i = this.props.selectedGroups.findIndex((e) => e.includes(this.props.jobGroupUrl));

            if(i < 0) {
                console.log("Need to go to last of abilities");
                return this.props.startPath;
            }

            let newGroupURL = "";
            let newGroup = "";
            let newMidTaskItems = null;

            let found = false;
            while(!found && i > 0) {
                i = i - 1;
                newGroupURL =  this.props.selectedGroups[i].substr(1);
                newGroup = this.jobURLToVar(newGroupURL );

                console.log("newgroup::"+newGroup);

                let selMTItems = this.props.selectedMidTaskItems;

                if(!_.isNil(selMTItems)) {
                    console.log(selMTItems);
                    console.log(selMTItems[newGroup]);

                    newMidTaskItems = selMTItems[newGroup];
                }

                if(!_.isNil(newMidTaskItems) && newMidTaskItems.length > 0)
                {
                    found = true;
                }
            }

            if(!found)
            {
                console.log("Need to go to last of abilities (2)");
                console.log("Some difficulties");
                console.log(this.props.selectedDifficultiesGroups);
                console.log(this.props.selectedDifficulties);
                //return this.props.startPath;
                return this.props.selectedDifficultiesGroups[this.props.selectedDifficultiesGroups.length-1];
            }

            return "/" + newGroupURL + "/" + newMidTaskItems[newMidTaskItems.length-1];
        }



        let pth = this.props.location.pathname;
        pth = pth.substring(1);
        let midTaskFromURL = pth.substring(pth.indexOf('/') + 1);

        //console.log("midTaskFromURL::" + midTaskFromURL);

        let i = midTaskItems.findIndex((e) => e === midTaskFromURL);

        if(i < 0) return null;

        if(i === 0) {

            console.log("NEED TO ROLL BACK TO BASE JOB GROUP!");

            return "/" + this.props.jobGroupUrl;

        }

        return midTaskItems[i-1];





        // if( _.isNil(this.props.selectedGroups) || this.props.selectedGroups.length <= 0 )
        //     return null;
        //
        // let i = this.props.selectedGroups.findIndex((e) => {
        //     //console.log("testing: " + e + " versus " + this.props.location.pathname);
        //     return e.trim() === this.props.location.pathname.trim();
        // }
        //     );
        //
        // console.log("I is: " + i);
        //
        // if(i === 0) {
        //
        //     if(_.isNil(this.props.selectedDifficultiesGroups) || this.props.selectedDifficultiesGroups.length <= 0) {
        //         console.log("No difficulties groups to go back to");
        //         return null;
        //     }
        //     else {
        //         console.log("Go to last of difficulties groups");
        //         let ret = this.props.selectedDifficultiesGroups.slice(-1)[0];
        //         console.log(ret);
        //         return ret;
        //     }
        // }
        //
        // if(i <= 0)
        //     return this.props.startPath;
        //
        // return this.props.selectedGroups[i-1];
    }

    findNext()
    {
        let midTaskItems = !_.isNil(this.props.selectedMidTaskItems) ? this.props.selectedMidTaskItems[this.props.jobGroup] : null;

        if(_.isNil(this.props.subJobGroup))
        {
            console.log("Going to more specific...");
            console.log(midTaskItems);

            if( _.isNil(midTaskItems) || midTaskItems.length <= 0 )
                return null;

            return this.props.jobGroupUrl + "/" + midTaskItems[0];

        }

        if( _.isNil(midTaskItems) || midTaskItems.length <= 0 )
            return null;

        let pth = this.props.location.pathname;
        pth = pth.substring(1);
        let midTaskFromURL = pth.substring(pth.indexOf('/') + 1);

        //console.log("midTaskFromURL::" + midTaskFromURL);

        let i = midTaskItems.findIndex((e) => e === midTaskFromURL);

        if(i < 0) return null;

        if(i === midTaskItems.length-1) {
            console.log("END OF CURRENT SUB JOB LIST REACHED!");
            console.log(this.props.endPath);
            console.log(this.props.selectedGroups);

            let j = this.props.selectedGroups.findIndex((e) => e.includes(this.props.jobGroupUrl));

            if(j < 0) return null;

            if(j === this.props.selectedGroups.length-1) {
                console.log("END OF ALL JOBS");
                return this.props.endPath;
            }

            return this.props.selectedGroups[j+1];

        }

        return midTaskItems[i+1];



        // if( _.isNil(this.props.selectedGroups) || this.props.selectedGroups.length <= 0 )
        //     return null;
        //
        // let i = this.props.selectedGroups.findIndex((e) => e === this.props.location.pathname);
        //
        // if(i < 0) return null;
        //
        // if(i === this.props.selectedGroups.length-1)
        //     return this.props.endPath;
        //
        // return this.props.selectedGroups[i+1];
    }



    handleMidTaskChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = null;

        if(_.isNil(this.state.selectedMidTasks[this.props.jobGroup]))
            sel = [];
        else
            sel = this.state.selectedMidTasks[this.props.jobGroup].slice(0);

        if(!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        }
        else
        {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        var newObj = _.cloneDeep(this.state.selectedMidTasks);

        console.log("newObj pre");
        console.log(JSON.stringify(newObj));
        newObj[this.props.jobGroup] = sel;
        console.log("newObj post");
        console.log(JSON.stringify(newObj));

        if(updateConfig)
        {
            updateConfig(newObj);
        }

        this.setState(
            {
                selectedMidTasks: newObj
            }
        );

    }




    handleTaskChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selectedTasks.slice(0);

        if(!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        }
        else
        {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        if(updateConfig)
        {
            updateConfig(sel);
        }

        this.setState(
            {
                selectedTasks: sel
            }
        );

    }


}


JobRequirementsIndividualBasic.propTypes = JobRequirementsIndividualPropTypes;

const JobRequirementsIndividual = withRouter(JobRequirementsIndividualBasic);

export default JobRequirementsIndividual;
