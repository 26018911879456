import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';




import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {Link} from "react-router-dom";



const OnBoardPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    onCompletePath: PropTypes.string.isRequired,

}




class OnBoard extends Component {

    constructor(props) {
        super(props);


        this.state = {

        }

    }


    render() {

        let props = this.props;


        console.log(props);


        let frm = <div style={{flex: 1}}>

            <div  >

                <Row style={{padding: '10px'}}>

                    <Col></Col>
                    <Col>
                    <Form>

                        <Form.Group controlId="formBasicText">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            {/*<Form.Text className="text-muted">*/}
                            {/*    Enter your first name.*/}
                            {/*</Form.Text>*/}
                        </Form.Group>

                        <Form.Group controlId="formBasicText">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            {/*<Form.Text className="text-muted">*/}
                            {/*    Enter your last name.*/}
                            {/*</Form.Text>*/}
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                            {/*<Form.Text className="text-muted">*/}
                            {/*    Enter your email address.*/}
                            {/*</Form.Text>*/}
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" />
                            {/*<Form.Text className="text-muted">*/}
                            {/*    Enter your password.*/}
                            {/*</Form.Text>*/}
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Re-enter Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" />
                            {/*<Form.Text className="text-muted">*/}
                            {/*    Confirm your password.*/}
                            {/*</Form.Text>*/}
                        </Form.Group>


                        <Link to={props.onCompletePath}>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        </Link>
                    </Form>
                    </Col>
                <Col></Col>
                </Row>


            </div>
        </div>;


        return (

            <NavPanel title="Sign up for an account" content={frm} {...props}/>

        );
    }


}


OnBoard.propTypes = OnBoardPropTypes;

export default OnBoard;
