import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';




import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';

import {FaAdversal} from 'react-icons/fa';
import {FaHeart} from 'react-icons/fa';
import {IoIosBook} from 'react-icons/io';

import NavPanel from './NavPanel';
import {Link} from "react-router-dom";



const SolutionDetailedPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired

}




class SolutionDetailed extends Component {

    constructor(props) {
        super(props);


        this.state = {

        }

    }


    render() {

        let props = this.props;


        console.log(props);

        let AccordionHeaderToggle = (props) => {
            const [expanded, setExpanded] = useState(false);

            return  <Accordion.Toggle {...props} as={Card.Header} onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}} >
                {expanded ? "+ " : "+ "}
                {props.children}
            </Accordion.Toggle>
        };


        let diffTypes = [
            ['Mobility Aid',['Walking Aid such as a cane or walker', 'Manual Wheelchair', 'Power Wheelchair', 'Scooter']],

            ];


        let cards = diffTypes.map((diffType, index) => {
            return <Card key={`accordion-card-${index}`}>
                <AccordionHeaderToggle  eventKey={index}>{diffType[0]}</AccordionHeaderToggle>
                <Accordion.Collapse eventKey={index} >
                    <Card.Body>
                        <Form>
                            {diffType[1].map(
                                (opt, j) => {
                                    return <div key={`default-checkbox-${index}-${j}`} className='mb-3'>
                                        <Form.Check
                                            type='checkbox'
                                            id='default-checkbox'
                                            label={opt}
                                            />
                                    </div>;
                                }
                            )}
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        });


        let frm = <div style={{flex: 1}}>


            <div style={{fontSize: '500%'}}><IoIosBook/></div>
            <FaHeart/>

            <h4>Problem</h4>
            <p>Tracking / visual processing issues</p>

            <h4>Solution</h4>
            <p>Present the information in a sound-related format </p>

            <h4>Use a dedicated screen reading program</h4>
            <p>Use electronic documents and a dedicated screen reading program on the computer which features word-by-word highlighting as the words are read aloud.  Other features to look for include adjustable reading rate, font size adjustment, and choice of voices.  The employee will likely want to use headphones to avoid disturbing other workers [for open office situation]. It is important that the employee be trained on how to use the software. This type of solution addresses computer access issues as well as print access.
            </p>
            <p>Expert consult: Might be helpful</p>
            <p>To learn more about this type of solution: LINK</p>
            <p>For product examples: LINK</p>

        </div>;


        return (

            <NavPanel title="We have found the following solutions for you..." content={frm} {...props}/>

        );
    }


}


SolutionDetailed.propTypes = SolutionDetailedPropTypes;

export default SolutionDetailed;
