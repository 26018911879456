import React, {Component} from 'react';


import PropTypes from 'prop-types';




import {Form, Button, Modal, Container, Row, Col} from 'react-bootstrap';


const LogInFormPropTypes = {

    //something: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
}




class LogInForm extends Component {

    constructor(props) {
        super(props);


        this.state = {

        }

    }


    render() {

        let props = this.props;

        console.log(props);

        return (

            <div>



                <Modal
                    {...props}

                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered


                >
                    <Modal.Header style={{ justifyContent: 'center', backgroundColor: 'blue', color: 'white'}}>
                    {/*    <Modal.Header variant="dark">*/}
                        <Modal.Title id="contained-modal-title-vcenter" >
                            Work ACCESS
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{  textAlign: 'center'}}>

                            <Row>
                                <Col>
                        <p>
                            Welcome to Work ACCESS!
                        </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                        <Button onClick={props.onHide}>Sign Up</Button>
                                </Col>
                            </Row>
                        <Row><Col><br /></Col></Row>
                            <Row>
                            <Col>
                        <Button onClick={props.onHide}>Log In</Button>
                            </Col>
                            </Row>

                    </Modal.Body>

                </Modal>


                {/*<Form>*/}
                {/*    <Form.Group controlId="formBasicEmail">*/}
                {/*        <Form.Label>Email address</Form.Label>*/}
                {/*        <Form.Control type="email" placeholder="Enter email" />*/}
                {/*        <Form.Text className="text-muted">*/}
                {/*            We'll never share your email with anyone else.*/}
                {/*        </Form.Text>*/}
                {/*    </Form.Group>*/}

                {/*    <Form.Group controlId="formBasicPassword">*/}
                {/*        <Form.Label>Password</Form.Label>*/}
                {/*        <Form.Control type="password" placeholder="Password" />*/}
                {/*    </Form.Group>*/}
                {/*    <Form.Group controlId="formBasicCheckbox">*/}
                {/*        <Form.Check type="checkbox" label="Check me out" />*/}
                {/*    </Form.Group>*/}
                {/*    <Button variant="primary" type="submit">*/}
                {/*        Submit*/}
                {/*    </Button>*/}
                {/*</Form>*/}

            </div>

        );
    }


}


LogInForm.propTypes = LogInFormPropTypes;

export default LogInForm;
