import Accommodation from "./Accommodation";
import nools from "nools";
import work_rules from "./tasks";
import _ from "lodash";

export default class WorkACCESSTasksRulesEngine {

    constructor() {

        this.tasks = work_rules;
        this.sesson = null;
        this.facts = null;
        this.selectedDifficulties = [];
        this.selectedJobGroup = "";
        this.selectedSubJobGroup = "";
        this.results = [];

        this.updateListeners = []

        this.initRulesEngine();

    }

    addUpdateListener(cbk)
    {
        if(!this.updateListeners.includes(cbk))
            this.updateListeners.push(cbk);
    }

    removeUpdateListener(cbk)
    {
        this.updateListeners = this.updateListeners.filter(v => v !== cbk);
    }

    callUpdateListeners()
    {
        this.updateListeners.forEach(sub => sub());
    }


    initRulesEngine() {
        console.log("initTasksRulesEngine() - TASKS");

        nools.deleteFlows();

        var flow = nools.flow("Tasks", function (flow) {

            let rules = work_rules;

            //console.log(rules);

            const keys = Object.keys(rules);

            for (const key of keys) {
                //console.log(key)
                flow.rule(key, [Accommodation, "a", rules[key]['trigger']], function (facts) {
                });
            }

        });



        var fired = [];
        var accommodation = new Accommodation();
        //accommodation.set('U14', true).set('U22', true).set('M1', true).set('t', 'T01.3');

        console.log(accommodation);

        var session = flow.getSession(accommodation);

        this.session = session;
        this.facts = accommodation;

        // .on("fire", function (name) {
        //     fired.push(name);
        // })
        // .match()
        // .then(function(){
        //     console.log(fired);
        // });

        session
            .on("fire", (name) => {
                console.log("pushing");
                console.log(name);
                fired.push(name);
            })
            .match()
            .then(() => {
                console.log(fired);
                console.log("setting state");
                this.setState({results: fired}, () => {
                    console.log("results set!");
                    this.callUpdateListeners();
                });
            });

    }



    updateRulesEngine() {

        if(!_.isNil(this.facts))
            this.session.retract(this.facts);

        let accommodation = new Accommodation();

        let runOptions = (options) => {
            if (!_.isNil(options)) {
                for (const o of options) {
                    if(o === "")
                        continue;
                    console.log("NOOLS: SETTING: " + o);
                    accommodation.set(o, true);
                }
            }
        }


        console.log("TASK RULES: incoming diffs");
        console.log(typeof this.selectedDifficulties);
        console.log(this.selectedDifficulties);

        let sdiff = this.selectedDifficulties;

        let udOpts = sdiff.filter(d => d.includes("UD"));
        let unOpts = sdiff.filter(d => d.includes("UN"));
        let noUOpts = sdiff.filter(d => !d.includes("UD") && !d.includes("UN"));

        let udNumStrs = udOpts.map(s => s.substring(2, s.length));
        let unNumStrs = unOpts.map(s => s.substring(2, s.length));

        if(udNumStrs.length === 0)
            udNumStrs = udNumStrs.concat("0");

        if(unNumStrs.length === 0)
            unNumStrs = unNumStrs.concat("0");

        console.log(udNumStrs);
        console.log(unNumStrs);

        let uPairings = udNumStrs.flatMap(d => unNumStrs.map(v => "U" + d + v));

        console.log("upairings");
        console.log(uPairings);

        let repairedDiffs = noUOpts.concat(uPairings);


        // console.log("incoming tasks");
        // console.log(this.props.selectedTasks);
        //
        // console.log("incoming tech prefs");
        // console.log(this.props.selectedTechPrefs);
        //
        // let tp = this.props.selectedTechPrefs;
        // let repairedTechPrefs = [];
        //
        // if(!tp.includes("nCMP"))
        //     repairedTechPrefs.push("CMP");
        // if(!tp.includes("nPHE"))
        //     repairedTechPrefs.push("PHE");
        // if(!tp.includes("PRT"))
        //     repairedTechPrefs.push("nPRT");
        //
        // console.log("repaired tech prefs");
        // console.log(repairedTechPrefs);

        //runOptions(this.props.selectedDifficulties);
        runOptions(repairedDiffs);

        runOptions([this.selectedJobGroup]);

        runOptions([this.selectedSubJobGroup]);


        //runOptions(this.props.selectedTasks);
        //unOptions(repairedTechPrefs);

        // let toptions = _.isNil(this.state.selectedTOption) ? [] : this.state.selectedTOption.map( (t) => t.value);
        //
        // console.log("T options unpacked");
        // console.log(toptions);
        //
        // accommodation.set('t', toptions);

        console.log("Tasks: New settings for nools");
        console.log(accommodation);


        this.facts = accommodation;

        this.session.assert(accommodation);

        console.log("Facts");
        console.log(this.session.getFacts());


        let fired = [];

        this.session
            .on("fire", (name) => {
                // console.log("pushing");
                // console.log(name);
                fired.push(name);
                // console.log("push list so far: ");
                // console.log(fired);
            });


        let find_matches = () =>
            this.session
                .match()
                .then(() => {
                    console.log("TASK Rules match is finished! Going to set state now!");
                    console.log(fired);
                    //this.setState({results: fired}, () => {});
                    this.results = fired;

                    this.callUpdateListeners();
                });

        // this.state.session.match()
        //     .then(function(){
        //         console.log("THEN");
        //         //console.log(fired);
        //     });

        //this.setState({results: []}, find_matches);

        this.results = [];
        find_matches();
    }




}
