import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';




import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';



const DifficultiesMorePropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
}




class DifficultiesMore extends Component {

    constructor(props) {
        super(props);


        this.state = {

        }

    }


    render() {

        let props = this.props;


        console.log(props);

        let AccordionHeaderToggle = (props) => {
            const [expanded, setExpanded] = useState(false);

            return  <Accordion.Toggle {...props} as={Card.Header} onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}} >
                {expanded ? "+ " : "+ "}
                {props.children}
            </Accordion.Toggle>
        };


        let diffTypes = [
            ['Mobility Aid',['Walking Aid such as a cane or walker', 'Manual Wheelchair', 'Power Wheelchair', 'Scooter']],

            ];


        let cards = diffTypes.map((diffType, index) => {
            return <Card key={`accordion-card-${index}`}>
                <AccordionHeaderToggle  eventKey={index}>{diffType[0]}</AccordionHeaderToggle>
                <Accordion.Collapse eventKey={index} >
                    <Card.Body>
                        <Form>
                            {diffType[1].map(
                                (opt, j) => {
                                    return <div key={`default-checkbox-${index}-${j}`} className='mb-3'>
                                        <Form.Check
                                            type='checkbox'
                                            id='default-checkbox'
                                            label={opt}
                                            />
                                    </div>;
                                }
                            )}
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        });


        let frm = <Accordion defaultActiveKey={'accordion-card-0'}>
            {cards}
        </Accordion>;

        // let frm = <Accordion >
        //     {cards}
        // </Accordion>;


        // let diffTypes = [
        //     'Sustain or divide attention',
        //     'Remember short- or long-term',
        //     'Regulate or express emotions',
        //     'Organize, plan, or manage time',
        //     'Perform calculations',
        //     'Read or write – what we want people to click on',
        //     'Understand verbal directions'
        // ];
        //
        //
        // let cards = diffTypes.map(
        //     (opt, j) => {
        //         return <div key={`default-checkbox-${j}`} className='mb-3'>
        //             <Form.Check
        //                 type='checkbox'
        //                 id='default-checkbox'
        //                 label={opt}
        //             />
        //         </div>;
        //     }
        // );
        //
        //
        // let frm = <Form>{cards}</Form>


        return (

            <NavPanel title="Tell us more about your..." content={frm} {...props}/>

        );
    }


}


DifficultiesMore.propTypes = DifficultiesMorePropTypes;

export default DifficultiesMore;
