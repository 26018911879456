const job_groups = {
    "facility_access": {
        "job": "Facility Access",
        "job_var": "facility_access",
        "tasks": {
            "moving_through_the_workplace": {
                "task_var": "moving_through_the_workplace",
                "task": "Moving Through the Workplace",
                "task_bit": 1,
                "gaps": [
                    "Lights along path are difficult to turn on",
                    "Path is difficult to see",
                    "Path is sloped or uneven",
                    "Path is not wide enough",
                    "Path is obstructed",
                    "Path is too slippery",
                    "Path offers resistance to movement",
                    "Route is too long",
                    "Path provides visual distractions"
                ]
            },
            "unlocking_and_using_doors": {
                "task_var": "unlocking_and_using_doors",
                "task": "Unlocking and Using Doors",
                "task_bit": 2,
                "gaps": [
                    "Correct door key is difficult to identify",
                    "Door lock is difficult to locate",
                    "Door lock is difficult to reach",
                    "Key is difficult to grip, hold",
                    "Key is difficult to turn, push, pull",
                    "Door access button or key reader is difficult to locate",
                    "Door access button or key reader is difficult to reach",
                    "Door access button is difficult to operate due to size, shape, or force",
                    "Door key card reader is difficult to operate due to shape or swiping movement",
                    "Unlocking process is difficult to remember",
                    "Door is difficult to see",
                    "Door handle is difficult to operate (e.g., grip, turn)",
                    "Door handle is difficult to reach",
                    "Door is too heavy",
                    "Door closes too fast",
                    "Door opening is too narrow",
                    "Threshold is too high"
                ]
            },
            "moving_between_floors": {
                "task_var": "moving_between_floors",
                "task": "Moving Between Floors",
                "task_bit": 3,
                "gaps": [
                    "Person cannot use stairs due to mobility limitations",
                    "Stair rise is too high",
                    "Stairs are not deep enough",
                    "Existing ramp is too steep",
                    "Existing ramp is too long, without adequate level landings",
                    "Existing ramp is too narrow",
                    "Stairs or ramp are too slippery",
                    "Staircase / individual steps are difficult to see",
                    "Stair or ramp handrail is not available",
                    "Stair or ramp handrail is difficult to reach and pull / push on",
                    "Stair or ramp handrail is difficult to grip",
                    "Stair or ramp handrail is difficult to see",
                    "Elevator / arrival of elevator is difficult to locate",
                    "In the elevator, arrival at a particular floor is difficult to recognize",
                    "Elevator controls are difficult to see",
                    "Elevator controls are difficult to operate due to location, size, or force",
                    "Elevator is difficult to enter / exit due to size"
                ]
            },
            "navigating_the_workplace": {
                "task_var": "navigating_the_workplace",
                "task": "Navigating the Workplace",
                "task_bit": 4,
                "gaps": [
                    "The path is difficult to follow",
                    "Locations are difficult to identify"
                ]
            },
            "using_the_restroom": {
                "task_var": "using_the_restroom",
                "task": "Using the Restroom",
                "task_bit": 5,
                "gaps": [
                    "Path is too slippery",
                    "Toilet is difficult to approach",
                    "Toilet or urinal is difficult to locate",
                    "Toilet is difficult to transfer on / off",
                    "Flush mechanism is difficult to operate / reach",
                    "Toilet tissue is difficult to acquire (locate, reach, grip)"
                ]
            },
            "washing_hands": {
                "task_var": "washing_hands",
                "task": "Washing Hands",
                "task_bit": 6,
                "gaps": [
                    "Restroom sink is difficult to locate",
                    "Sink is difficult to reach",
                    "Sink controls are difficult to operate",
                    "Soap dispenser is difficult to locate",
                    "Soap dispenser is difficult to reach",
                    "Soap dispenser is difficult to operate",
                    "Paper towel dispenser or hand dryer is difficult to locate",
                    "Paper towel dispenser or hand dryer is difficult to reach",
                    "Hand dryer control is difficult to operate",
                    "Paper towel dispenser is difficult to operate"
                ]
            }
        }
    },
    "workstation": {
        "job": "Workstation",
        "job_var": "workstation",
        "tasks": {
            "getting_inout_of_work_chair": {
                "task_var": "getting_inout_of_work_chair",
                "task": "Getting in/out of Work Chair",
                "task_bit": 21,
                "gaps": [
                    "It is difficult to get in/out of the work chair because it moves too easily",
                    "The office chair is difficult to pull out or slide in",
                    "The sitting / standing process is difficult",
                    "It is difficult to transfer to a work chair because the chair's armrests get in the way"
                ]
            }
        }
    },
    "organization": {
        "job": "Organization",
        "job_var": "organization",
        "tasks": {
            "planning_the_work_day": {
                "task_var": "planning_the_work_day",
                "task": "Planning the Work Day",
                "task_bit": 31,
                "gaps": [
                    "Difficulty remembering appointments or meetings",
                    "Difficulty with time management (e.g., getting things done on time)",
                    "Difficulty using standard time management systems",
                    "Difficulty separating or balancing work and home activities when teleworking"
                ]
            },
            "learning_remembering_how_to_perform_specific_tasks_or_procedures": {
                "task_var": "learning_remembering_how_to_perform_specific_tasks_or_procedures",
                "task": "Learning / Remembering How to Perform Specific Tasks or Procedures",
                "task_bit": 32,
                "gaps": [
                    "Difficulty identifying / remembering steps in an assigned task",
                    "Difficulty remembering specific information needed to complete a task",
                    "Difficulty accessing on-the-job training"
                ]
            },
            "staying_on_task": {
                "task_var": "staying_on_task",
                "task": "Staying on Task",
                "task_bit": 33,
                "gaps": [
                    "Difficulty remaining focused on task"
                ]
            }
        }
    },
    "communication": {
        "job": "Communication",
        "job_var": "communication",
        "tasks": {
            "hearing_speech": {
                "task_var": "hearing_speech",
                "task": "Hearing Speech",
                "task_bit": 41,
                "gaps": [
                    "Employee is not able to understand the speech of communication partners in face-to-face communication"
                ]
            },
            "producing_speech": {
                "task_var": "producing_speech",
                "task": "Producing Speech",
                "task_bit": 42,
                "gaps": [
                    "Employee's speech is slurred or distorted",
                    "Employee's voice is too soft or weak",
                    "Employee's voice is hoarse",
                    "Employee has difficulties with stuttering"
                ]
            },
            "understanding_communication": {
                "task_var": "understanding_communication",
                "task": "Understanding Communication",
                "task_bit": 43,
                "gaps": [
                    "The employee has difficulty understanding what is said",
                    "Employee has difficulty staying focused upon what is being said",
                    "Employee has difficulty remembering what has been said"
                ]
            },
            "expressing_ideas_and_thoughts": {
                "task_var": "expressing_ideas_and_thoughts",
                "task": "Expressing Ideas and Thoughts",
                "task_bit": 44,
                "gaps": [
                    "Employee has difficulty spontaneously composing statements or questions",
                    "Employee has difficulty spontaneously answering questions",
                    "Employee does not know what question to ask when in need of help (or how to ask for help)",
                    "Employee has trouble coming up with words they want to say",
                    "Employee has difficulty engaging in a reciprocal discussion",
                    "Employee has difficulty staying on topic",
                    "Employee has difficulty giving information in an organized manner"
                ]
            },
            "socially_situationally_appropriate_communication": {
                "task_var": "socially_situationally_appropriate_communication",
                "task": "Socially / Situationally Appropriate Communication",
                "task_bit": 45,
                "gaps": [
                    "Employee makes comments that are not related to the context of the conversation",
                    "Employee often misunderstands comments made by others",
                    "Employee has difficulty offering help or demonstrating cooperation",
                    "Employee's conversation is sometimes disrespectful to the environment or communication partner",
                    "Employee does not recognize the reactions of the conversation partner and speaks inappropriately"
                ]
            },
            "reading_writing_documents": {
                "task_var": "reading_writing_documents",
                "task": "Reading & Writing Documents",
                "task_bit": 46,
                "gaps": [
                    "Pages are difficult to hold / turn",
                    "Paper slides while writing",
                    "Handwriting causes discomfort / pain",
                    "Handwriting requires too much fine motor coordination",
                    "Writing is not legible",
                    "Standard pen is too difficult to hold",
                    "Handwriting is too slow",
                    "Text is too small",
                    "Only part of the page is visible",
                    "Documents cannot be seen",
                    "Writing areas (form blanks) are not visible",
                    "Employee has difficulty with literacy"
                ]
            },
            "composing_text": {
                "task_var": "composing_text",
                "task": "Composing Text",
                "task_bit": 47,
                "gaps": [
                    "Writing contains misspellings or grammar mistakes",
                    "Employee has difficulty thinking of the right words to use",
                    "Writing is not organized"
                ]
            }
        }
    },
    "equipment": {
        "job": "Equipment",
        "job_var": "equipment",
        "tasks": {
            "using_a_computer": {
                "task_var": "using_a_computer",
                "task": "Using a Computer",
                "task_bit": 61,
                "gaps": [
                    "Keyboard is too far away",
                    "Cannot hit keys at same time (e.g., Shift-Letter)",
                    "Cannot reach all of the keys",
                    "Key press requires too much force",
                    "Unwanted keys selected (e.g., from tremor)",
                    "Typing causes hand / wrist / arm pain or fatigue",
                    "Typing is too slow",
                    "Hands-free typing method is needed",
                    "Difficulty changing focus between keyboard and screen",
                    "Key labels are difficult to see",
                    "Key layout is confusing",
                    "Mouse is positioned too far away",
                    "Mouse is difficult to grasp",
                    "Mouse use causes hand/ wrist/ arm pain",
                    "Mouse requires too much motion / strength",
                    "Cursor cannot be positioned with sufficient precision",
                    "Device buttons are too hard to push or hold down",
                    "Hands-free cursor control method is needed",
                    "Mouse cursor is difficult to track",
                    "Difficulty using a mouse to make menu selections",
                    "Tracking / visual processing issues make it difficult  to read the screen",
                    "Only part of the screen is visible due to visual field deficit",
                    "Color, contrast, and/or glare makes screen difficult to see or causes eye strain",
                    "Small size makes screen difficult to see or causes eye strain",
                    "Information cannot be seen at all"
                ]
            }
        }
    },
    "handling_materials": {
        "job": "Handling Materials",
        "job_var": "handling_materials",
        "tasks": {
            "carrying_materials": {
                "task_var": "carrying_materials",
                "task": "Carrying Materials",
                "task_bit": 73,
                "gaps": [
                    "Items are too heavy / bulky to carry",
                    "Person has difficulty with balance when carrying",
                    "Person does not have free hands (e.g., using mobility aid)"
                ]
            }
        }
    },
    "wellbeing": {
        "job": "Well-Being",
        "job_var": "wellbeing",
        "tasks": {
            "maintaining_wellbeing": {
                "task_var": "maintaining_wellbeing",
                "task": "Maintaining Well-Being",
                "task_bit": 81,
                "gaps": [
                    "Managing Anxiety, Stress, or Emotions"
                ]
            }
        }
    }
}
export default job_groups;