import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';


import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import _ from 'lodash';

const AdditionalFactorsWorkAreaPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    progress: PropTypes.number,
    postMountCallback: PropTypes.func,
    selectedItems: PropTypes.array.isRequired
}




class AdditionalFactorsWorkArea extends Component {

    constructor(props) {
        super(props);


        this.state = {
            selected:this.props.selectedItems ? this.props.selectedItems : [],
            showModal: false,
            modalText: '',
            modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(name, txt) {
        this.setState({showModal: true, modalTitle: name, modalText: txt});
    }




    componentDidMount() {
        console.log("componentDidMount");

        if(this.props.postMountCallback)
            this.props.postMountCallback();


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if( !_.isEqual(this.props.selectedItems, prevProps.selectedItems))
            this.setState({selected:this.props.selectedItems ? this.props.selectedItems : []})
    }


    render() {


        console.log("SELECT_ITEMS: " + this.props.selectedItems);
        console.log("state selected: " + this.state.selected);

        let props = this.props;

        let diffType =
            {
                name: 'The work area is:',
                levels: [
                    {code: 'ADA', text: 'Also used by customers (ADA Title III)', descript: 'N/A'},
                    {code: 'MLT', text: 'Multiple work areas', descript: 'N/A'},
                    {code: 'NSY', text: 'Noisy', descript: 'N/A'},
                    {code: 'OPN', text: 'Open office setting (multiple people in same room)', descript: 'N/A'},
                    {code: 'SHD', text: 'Shared work area (others use the same space/equipment)', descript: 'N/A'},
                    {code: 'STR', text: 'In a leased building', descript: 'N/A'},

                    // {code: 'SML', text: 'Interacting with a small group (1-2 others)', descript: 'N/A'},
                    // {code: 'LRG', text: 'Interacting with a large group (3+ others)', descript: 'N/A'},
                    // {code: 'ONS', text: 'Onsite communication/meetings', descript: 'N/A'},
                    // {code: 'REM', text: 'Remote communication/meetings', descript: 'N/A'},
                ]
            }


        let frm =
            <div>
                <h2 class="nav-sub-heading">{diffType.name}</h2>
                <Form>
                    {diffType.levels.map(
                        (opt, j) => {
                            return <div style={{display: 'flex', flexDirection: 'row'}} key={`default-checkbox-${this.props.section}_${j}`} className='mb-3'>
                                <Form.Check
                                    type='checkbox'
                                    id={`default-checkbox-${opt.code}`}
                                    label={opt.text}
                                    value={opt.code}
                                    checked={this.props.selectedItems.includes(opt.code)}
                                    //checked={this.state.checkboxChecked}
                                    onChange={(e) => this.handleChange(e, this.props.updateConfig)}
                                />

                                <div style={{display: 'flex'}} >
                                    <div style={{cursor:'pointer'}} onClick={(e)=>{e.stopPropagation();e.nativeEvent.stopImmediatePropagation(); this.showModal(opt.text, opt.descript);}}>
                                        <MdInfo/>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flex:1}}></div>

                            </div>;
                        }
                    )}
                </Form>
            </div>








        //let frm = <div>We need to first collect some information about your job requirements. Click next to continue...</div>




        return (

            <div>

                        <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                            <Modal.Header closeButton>
                                <Modal.Title>

                                    {this.state.modalTitle}

                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                {this.state.modalText}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.closeModal()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>




                    <NavPanel title="Step 5: Please provide us with some more details about yourself, the task, and your work area." content={frm} progress={this.props.progress} prev={this.props.prev}
                              next={this.props.next}/>

            </div>

        );
    }



    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if(!evt.target.checked) {

            // remove the PRT, MOB, etc.
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }

            // add the nPRT, nMOB, etc.
            const index2 = sel.indexOf(evt.target.value);
            if (index2 < 0) {
                sel.push('n' + evt.target.value);
            }
        }
        else
        {
            // remove the nPRT, nMOB, etc.
            const index2 = sel.indexOf('n' + evt.target.value);
            if (index2 > -1) {
                sel.splice(index2, 1);
            }

            // add the PRT, MOB, etc.
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }

        }

        if(updateConfig)
        {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }



    //
    // OLD_handleChange(evt, updateConfig) {
    //
    //     console.log("checked! " + evt.target.value + " is " + evt.target.checked);
    //
    //     var sel = this.state.selected.slice(0);
    //
    //     if(!evt.target.checked) {
    //         const index = sel.indexOf(evt.target.value);
    //         if (index > -1) {
    //             sel.splice(index, 1);
    //         }
    //     }
    //     else
    //     {
    //         const index = sel.indexOf(evt.target.value);
    //         if (index < 0) {
    //             sel.push(evt.target.value);
    //         }
    //     }
    //
    //     if(updateConfig)
    //     {
    //         updateConfig(sel);
    //     }
    //
    //     this.setState(
    //         {
    //             selected: sel
    //         }
    //     );
    //
    //     //this.setState({ checkboxChecked: evt.target.checked });
    // }
    //





}


AdditionalFactorsWorkArea.propTypes = AdditionalFactorsWorkAreaPropTypes;

export default AdditionalFactorsWorkArea;
