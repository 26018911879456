import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';




import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';



const DifficultiesPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
}




class Difficulties extends Component {

    constructor(props) {
        super(props);


        this.state = {

        }

    }


    render() {

        let props = this.props;


        console.log(props);

        let AccordionHeaderToggle = (props) => {
            const [expanded, setExpanded] = useState(false);

            return  <Accordion.Toggle {...props} as={Card.Header} onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}} >
                {expanded ? "+ " : "+ "}
                {props.children}
            </Accordion.Toggle>
        };


        let diffTypes = [
            ['Sitting, standing, or moving around',['Walking', 'Climbing', 'Bending', 'Sitting down', 'Standing', 'I have difficulty but use a mobility aid.']],
            ['Reaching, grasping, or pinching',['Walking', 'Climbing']],
            ['Seeing, even with glasses/contacts',['Walking', 'Climbing']],
            ['Hearing',['Walking', 'Climbing']],
            ['Speaking',['Walking', 'Climbing']],
            ['Reading, remembering, or planning',[            'Sustain or divide attention',
                'Remember short- or long-term',
                'Regulate or express emotions',
                'Organize, plan, or manage time',
                'Perform calculations',
                'Read or write',
                'Understand verbal directions']]
            ];


        let cards = diffTypes.map((diffType, index) => {
            return <Card key={`accordion-card-${index}`}>
                <AccordionHeaderToggle  eventKey={index}>{diffType[0]}</AccordionHeaderToggle>
                <Accordion.Collapse eventKey={index} >
                    <Card.Body>
                        <Form>
                            {diffType[1].map(
                                (opt, j) => {
                                    return <div key={`default-checkbox-${index}-${j}`} className='mb-3'>
                                        <Form.Check
                                            type='checkbox'
                                            id='default-checkbox'
                                            label={opt}
                                            />
                                    </div>;
                                }
                            )}
                        </Form>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        });

        let frm = <Accordion >
            {cards}
        </Accordion>;

        // let diffTypes = [
        //     'Sit, stand, or move around',
        //     'Reach, grasp, or pinch',
        //     'See, even with glasses/contacts',
        //     'Hear',
        //     'Speak',
        //     'Read, remember, or plan – what we want people to click on'
        // ];
        //
        //
        // let cards = diffTypes.map(
        //                         (opt, j) => {
        //                             return <div key={`default-checkbox-${j}`} className='mb-3'>
        //                                 <Form.Check
        //                                     type='checkbox'
        //                                     id='default-checkbox'
        //                                     label={opt}
        //                                 />
        //                             </div>;
        //                         }
        // );
        //
        //
        //
        // let frm = <Form>{cards}</Form>


        return (

            <NavPanel title="I need supports for..." content={frm} {...props}/>

        );
    }


}


Difficulties.propTypes = DifficultiesPropTypes;

export default Difficulties;
