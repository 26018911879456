import React, {Component} from 'react';

import _ from 'lodash';

// This function takes a component...
function withRulesEngine(WrappedComponent, RulesEngine) {
    // ...and returns another component...
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.handleChange = this.handleChange.bind(this);

            if(_.isNil(RulesEngine))
                console.log("RulesEngine cannot be null!");

            //let jobTypes = ["facility_access", "communication", "organization", "using_equipment"];
            this.state = {
                dataReady: false,
                data: RulesEngine.results
            };
        }

        componentDidMount() {
            console.log("HOC did mount");
            // ... that takes care of the subscription...
            RulesEngine.addUpdateListener(this.handleChange);

            RulesEngine.selectedDifficulties = this.props.selectedDifficulties;
            RulesEngine.selectedJobGroup = this.props.jobGroup;
            RulesEngine.selectedSubJobGroup = this.props.subJobGroup;
            this.setState({dataReady: false}, () => {
                RulesEngine.updateRulesEngine();
            });
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            console.log("HOC did update");
            let needsUpdate = false;
            if (!_.isEqual(this.props.selectedDifficulties, prevProps.selectedDifficulties)) {
                RulesEngine.selectedDifficulties = this.props.selectedDifficulties;
                needsUpdate = true;
                console.log("hoc diff changed");
            }

            // TODO possible for this to not be true but componentDidUpdate() not called?
            if (RulesEngine.selectedJobGroup !== this.props.jobGroup) {
                RulesEngine.selectedJobGroup = this.props.jobGroup;
                needsUpdate = true;
                console.log("hoc job group changed");
            }

            if (RulesEngine.selectedSubJobGroup !== this.props.subJobGroup) {
                RulesEngine.selectedSubJobGroup = this.props.subJobGroup;
                needsUpdate = true;
                console.log("hoc sub job group changed");
            }

            if (needsUpdate)
            {
                console.log("hoc needs update");
                this.setState({dataReady: false}, () => {
                    RulesEngine.updateRulesEngine();
                });
            }
        }

        componentWillUnmount() {
            RulesEngine.removeUpdateListener(this.handleChange);
        }

        handleChange() {

            console.log("HOC got new results!")

            this.setState({
                data: RulesEngine.results,
                dataReady: true
            });
        }

        render() {

            console.log("HOC is rendering!");

            // ... and renders the wrapped component with the fresh data!
            // Notice that we pass through any additional props
            return <WrappedComponent taskResults={this.state.data} dataReady={this.state.dataReady} {...this.props} />;
        }
    };
}

export default withRulesEngine;
