import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';


import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import _ from 'lodash';

const AdditionalFactorsInteractionPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    progress: PropTypes.number,
    postMountCallback: PropTypes.func,
    selectedItems: PropTypes.array.isRequired
}




class AdditionalFactorsInteraction extends Component {

    constructor(props) {
        super(props);


        this.state = {
            selected:this.props.selectedItems ? this.props.selectedItems : [],
            showModal: false,
            modalText: '',
            modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(name, txt) {
        this.setState({showModal: true, modalTitle: name, modalText: txt});
    }




    componentDidMount() {
        console.log("componentDidMount");

        if(this.props.postMountCallback)
            this.props.postMountCallback();


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if( !_.isEqual(this.props.selectedItems, prevProps.selectedItems))
            this.setState({selected:this.props.selectedItems ? this.props.selectedItems : []})
    }


    render() {


        console.log("SELECT_ITEMS: " + this.props.selectedItems);
        console.log("state selected: " + this.state.selected);

        let props = this.props;

        let diffType =
            {
                name: 'To complete the task, the employee prefers:',
                levels: [
                    {code: 'SML', text: 'Interacting with a small group (1-2 others)', descript: 'N/A'},
                    {code: 'LRG', text: 'Interacting with a large group (3+ others)', descript: 'N/A'},
                    {code: 'ONS', text: 'Onsite communication/meetings', descript: 'N/A'},
                    {code: 'REM', text: 'Remote communication/meetings', descript: 'N/A'},

                ]
            }


        let frm =
            <div>
                <h2 class="nav-sub-heading">{diffType.name}</h2>
                <Form>
                    {diffType.levels.map(
                        (opt, j) => {
                            return <div style={{display: 'flex', flexDirection: 'column'}} key={`default-add-fac-form-${this.props.section}_${j}`} className='mb-3'>

                                {/*<Form.Check*/}
                                {/*    type='checkbox'*/}
                                {/*    id={`default-checkbox-${opt.code}`}*/}
                                {/*    label={opt.text}*/}
                                {/*    value={opt.code}*/}
                                {/*    checked={this.props.selectedItems.includes(opt.code)}*/}
                                {/*    //checked={this.state.checkboxChecked}*/}
                                {/*    onChange={(e) => this.handleChange(e, this.props.updateConfig)}*/}
                                {/*/>*/}
                                <div style={{display: 'flex', flexDirection: 'row'}} key={`default-add-fac-form-label-${this.props.section}_${j}`} >
                                    <Form.Label>{opt.text}</Form.Label>

                                    <div style={{display: 'flex'}} >
                                        <div style={{cursor:'pointer'}} onClick={(e)=>{e.stopPropagation();e.nativeEvent.stopImmediatePropagation(); this.showModal(opt.text, opt.descript);}}>
                                            <MdInfo/>
                                        </div>
                                    </div>
                                </div>

                                <Form.Check
                                    type='radio'
                                    id={`default-radio-${opt.code}`}
                                    label="Yes"
                                    value={
                                        JSON.stringify(
                                        {
                                            "base": opt.code,
                                            "type": "yes"
                                        }
                                        )
                                    }
                                    checked={
                                    this.props.selectedItems.includes(opt.code) &&
                                        !this.props.selectedItems.includes("n"+opt.code)
                                }
                                    //checked={this.state.checkboxChecked}
                                    onChange={(e) => this.handleChange(e, this.props.updateConfig)}
                                />

                                <Form.Check
                                    type='radio'
                                    id={`default-radio-${opt.code}`}
                                    label="No"
                                    value={
                                        JSON.stringify(
                                        {
                                            "base": opt.code,
                                            "type": "no"
                                        }
                                        )

                                    }
                                    checked=
                                        {
                                            this.props.selectedItems.includes("n"+opt.code) &&
                                            !this.props.selectedItems.includes(opt.code)
                                        }
                                    //checked={this.state.checkboxChecked}
                                    onChange={(e) => this.handleChange(e, this.props.updateConfig)}
                                />

                                <Form.Check
                                    type='radio'
                                    id={`default-radio-${opt.code}`}
                                    label="No Preference"
                                    value={
                                        JSON.stringify(
                                        {
                                            "base": opt.code,
                                            "type": "dc"
                                        }
                                        )
                                    }
                                    checked={
                                        this.props.selectedItems.includes("n"+opt.code) &&
                                        this.props.selectedItems.includes(opt.code)
                                    }
                                    //checked={this.state.checkboxChecked}
                                    onChange={(e) => this.handleChange(e, this.props.updateConfig)}
                                />


                                <div style={{display: 'flex', flex:1}}></div>

                            </div>;
                        }
                    )}
                </Form>
            </div>








        //let frm = <div>We need to first collect some information about your job requirements. Click next to continue...</div>




        return (

            <div>

                        <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                            <Modal.Header closeButton>
                                <Modal.Title>

                                    {this.state.modalTitle}

                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                {this.state.modalText}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.closeModal()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>




                    <NavPanel title="Step 5: Please provide us with some more details about yourself, the task, and your work area." content={frm} progress={this.props.progress}
                              prev={this.props.prev}
                              next={this.props.next}/>

            </div>

        );
    };


    handleChange(evt, updateConfig) {


        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var jsontarget = JSON.parse(evt.target.value)

        // clone
        var sel = this.state.selected.slice(0);

        var remdex = sel.indexOf( jsontarget["base"]);
        if (remdex > -1) {
            sel.splice(remdex, 1);
        }
        remdex = sel.indexOf('n' + jsontarget["base"]);
        if (remdex > -1) {
            sel.splice(remdex, 1);
        }

        if(evt.target.checked) {

            // remove the PRT, MOB, etc.
            // const index = sel.indexOf(targTag);
            // if (index > -1) {
            //     sel.splice(index, 1);
            // }

            var targTag = "";
            if(jsontarget["type"]==="yes") {
                targTag = jsontarget["base"]
                // add the nPRT, nMOB, etc.
                const index2 = sel.indexOf(targTag);
                if (index2 < 0) {
                    sel.push(targTag);
                }
            }
            else if(jsontarget["type"]==="no") {
                targTag = "n" + jsontarget["base"]
                // add the nPRT, nMOB, etc.
                const index2 = sel.indexOf(targTag);
                if (index2 < 0) {
                    sel.push(targTag);
                }
            }
            else { //don't care
                targTag = jsontarget["base"]
                // add the nPRT, nMOB, etc.
                var index2 = sel.indexOf(targTag);
                if (index2 < 0) {
                    sel.push(targTag);
                }

                targTag = "n" + jsontarget["base"]
                // add the nPRT, nMOB, etc.
                index2 = sel.indexOf(targTag);
                if (index2 < 0) {
                    sel.push(targTag);
                }
            }
        }
        // else
        // {
        //     // remove the nPRT, nMOB, etc.
        //     const index2 = sel.indexOf('n' + evt.target.value);
        //     if (index2 > -1) {
        //         sel.splice(index2, 1);
        //     }
        //
        //     // add the PRT, MOB, etc.
        //     const index = sel.indexOf(evt.target.value);
        //     if (index < 0) {
        //         sel.push(evt.target.value);
        //     }
        //
        // }

        if(updateConfig)
        {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }





    // findPrev()
    // {
    //
    //     if(_.isNil(this.props.selectedJobReqs) || this.props.selectedJobReqs.length <= 0) {
    //         console.log("No job reqs to go back to");
    //         return null;
    //     }
    //     else {
    //         console.log("Go to last of job reqs");
    //         let ret = this.props.selectedJobReqs.slice(-1)[0];
    //         console.log(ret);
    //         return ret;
    //     }
    // }



}


AdditionalFactorsInteraction.propTypes = AdditionalFactorsInteractionPropTypes;

export default AdditionalFactorsInteraction;
