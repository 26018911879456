import React, {Component, useState} from 'react';


import PropTypes from 'prop-types';


import {
    Form,
    Button,
    Modal,
    Container,
    Row,
    Col,
    Card,
    Accordion,
    AccordionCollapse,
    AccordionToggle
} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import _ from 'lodash';

const FundingPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    //progress: PropTypes.number,
    postMountCallback: PropTypes.func,
    previous: PropTypes.string,
    //selectedItems: PropTypes.array.isRequired
}


class Funding extends Component {

    constructor(props) {
        super(props);


        this.state = {
            //selected:this.props.selectedItems ? this.props.selectedItems : [],
            //showModal: false,
            //modalText: '',
            //modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(name, txt) {
        this.setState({showModal: true, modalTitle: name, modalText: txt});
    }


    componentDidMount() {
        console.log("componentDidMount");

        if (this.props.postMountCallback)
            this.props.postMountCallback();


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // if( !_.isEqual(this.props.selectedItems, prevProps.selectedItems))
        //     this.setState({selected:this.props.selectedItems ? this.props.selectedItems : []})
    }


    render() {


        let props = this.props;


        let frm =
            <div>
                <a href="https://www.uschamber.com/workforce/employer-guide-to-tax-credits-for-hiring-employees-with-disabilities" target="_blank">Business Tax Credits & Deductions for Employment of People with Disabilities</a>
                <p>
                Workplace accommodations are most often paid for by the employer.  Businesses that hire people with disabilities and businesses that remove architectural barriers to accommodate an employee may be eligible for tax credits.  Small businesses can apply for a credit of up to $5000 to cover the expense of accommodations.
                </p>
                <a href="https://rsa.ed.gov/about/states" target="_blank">State Vocational Rehabilitation Agencies</a>
                <p>
                Each state provides services to help people with disabilities obtain employment.  This may include assistance with developing career plans, obtaining job training, and finding employment.  In some instances, the agency may help with funding workplace accommodations or the home/transportation modifications needed to get ready to go to work.
                </p>
                <a href="https://choosework.ssa.gov/library/faq-plan-to-achieve-self-support" target="_blank">Plan for Achieving Self Support (PASS)</a>
                <p>
                Some people with disabilities receive Medicaid, and they risk losing these health benefits if they earn too much.  PASS plans allow an individual to set aside part of their earnings to pay for expenses related to their work goal, including assistive technology that employers and vocational rehabilitation may not fund (e.g., an accessible vehicle).
                </p>

                <b>Alternative Financing Programs for AT</b>
                <p>
                The local/state alternative financing programs help individuals with disabilities obtain assistive technology equipment and services to help them improve or maintain independence and/or become more productive members of the community.  Many of these programs will help individuals obtain low interest loans to purchase equipment.
                </p>
                <ul>
                    <li><a href="https://askjan.org/solutions/Alternative-Financing-Programs.cfm"  target="_blank">Alternative Financing Programs list (from the Job Accommodation Network)</a></li>
                <li><a href="https://at3center.net/state-at-programs/" target="_blank">List of State AT Resources (from the AT3 Center)</a></li>
                </ul>

                    <a href="https://www.hearingloss.org/hearing-help/financial-assistance/state-telephone-programs/" target="_blank">Telecommunications Equipment Distribution Programs</a>
                <p>
                State telephone programs, known as Telecommunications Equipment Distribution Programs (TEDPs), provide free or low-cost telephone equipment to qualified individuals to accommodate their hearing loss and improve phone access.
                </p>

                <a href="https://www.icanconnect.org/" target="_blank">National Deaf-Blind Equipment Distribution Program / iCanConnect</a>
                <p>
                The National Deaf-Blind Equipment Distribution Program provides equipment needed to make telecommunications, advanced communications, and the Internet accessible to low-income individuals who are deafblind.
                </p>
            </div>


        //let frm = <div>We need to first collect some information about your job requirements. Click next to continue...</div>


        return (

            <div>

                <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>

                            {this.state.modalTitle}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.modalText}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.closeModal()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                <NavPanel hideInactiveNavButtons={true} title="Find Funding" content={frm}
                    prev={this.props.previous ? this.props.previous : undefined}
                />

            </div>

        );
    }


    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if (!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        } else {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        if (updateConfig) {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }


    // findPrev()
    // {
    //
    //     if(_.isNil(this.props.selectedJobReqs) || this.props.selectedJobReqs.length <= 0) {
    //         console.log("No job reqs to go back to");
    //         return null;
    //     }
    //     else {
    //         console.log("Go to last of job reqs");
    //         let ret = this.props.selectedJobReqs.slice(-1)[0];
    //         console.log(ret);
    //         return ret;
    //     }
    // }


}


Funding.propTypes = FundingPropTypes;

export default Funding;
