import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';




import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';



const JobRequirementsMorePropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
}




class JobRequirementsMore extends Component {

    constructor(props) {
        super(props);


        this.state = {

        }

    }


    render() {

        let props = this.props;


        console.log(props);

        let AccordionHeaderToggle = (props) => {
            const [expanded, setExpanded] = useState(false);

            return  <Accordion.Toggle {...props} as={Card.Header} onClick={() => {setExpanded(!expanded)}} style={{cursor: 'pointer'}} >
                {expanded ? "+ " : "+ "}
                {props.children}
            </Accordion.Toggle>
        };


        // let diffTypes = [
        //     ['Moving around your workplace',['I have difficulty with wayfinding or navigation', 'I have difficulty moving through my workplace']],
        //     ['Moving between levels',['Ascending/descending stairs', 'Ascending/descending an existing ramp', 'Using the elevator']],
        //     ['Using doors',['A', 'B']],
        //     ['Using the restroom',['A', 'B']],
        //     ];
        //
        //
        // let cards = diffTypes.map((diffType, index) => {
        //     return <Card key={`accordion-card-${index}`}>
        //         <AccordionHeaderToggle  eventKey={index}>{diffType[0]}</AccordionHeaderToggle>
        //         <Accordion.Collapse eventKey={index} >
        //             <Card.Body>
        //                 <Form>
        //                     {diffType[1].map(
        //                         (opt, j) => {
        //                             return <div key={`default-checkbox-${index}-${j}`} className='mb-3'>
        //                                 <Form.Check
        //                                     type='checkbox'
        //                                     id='default-checkbox'
        //                                     label={opt}
        //                                     />
        //                             </div>;
        //                         }
        //                     )}
        //                 </Form>
        //             </Card.Body>
        //         </Accordion.Collapse>
        //     </Card>
        // });
        //
        //
        // let frm = <Accordion defaultActiveKey={'accordion-card-0'}>
        //     {cards}
        // </Accordion>;

        let diffTypes = [
            'Tracking / visual processing',
            'Literacy',
            'Remembering information presented visually'
        ];


        let cards = diffTypes.map(
                                (opt, j) => {
                                    return <div key={`default-checkbox-${j}`} className='mb-3'>
                                        <Form.Check
                                            type='checkbox'
                                            id='default-checkbox'
                                            label={opt}
                                        />
                                    </div>;
                                }
        );



        let frm = <div>
            <p>Only problems relevant for a person who has cognitive difficulties related to reading text are displayed.  A very different list would be shown for a person who is blind or who has trouble with grasp.</p>
            <Form>
                {cards}
            </Form>
        </div>;

        return (

            <NavPanel title="I am having problems with..." content={frm} {...props}/>

        );
    }


}


JobRequirementsMore.propTypes = JobRequirementsMorePropTypes;

export default JobRequirementsMore;
