import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';




import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';


import PreviousSessionsTable from "./PreviousSessionsTable";

const PreviousSessionsPropTypes = {

    progress: PropTypes.number,
    sessions: PropTypes.array,
    activeSession: PropTypes.object,
    postMountCallback: PropTypes.func,
    makeSessionActive: PropTypes.func.isRequired,
    deleteSession: PropTypes.func.isRequired,
}




class PreviousSessions extends Component {

    constructor(props) {
        super(props);


        this.state = {

        }

    }


    componentDidMount() {
        if(this.props.postMountCallback)
            this.props.postMountCallback();
    }


    render() {

        let props = this.props;

        let sessions = this.props.sessions.slice()
        let activeSession = this.props.activeSession

        // Special case to make sure active session is in the list
        let ind = sessions.findIndex(s => s.id === activeSession.id);
        if(ind > -1)
        {
            console.log("active session update at pos: " + ind);
            sessions[ind] = activeSession;
        }
        else
        {
            console.log("active session add");
            sessions.push(activeSession);
        }


        let frm = <PreviousSessionsTable sessions={sessions} makeSessionActive={this.props.makeSessionActive} deleteSession={this.props.deleteSession}/>


        return (

            <NavPanel hideInactiveNavButtons={true} title="Select a session" content={frm} progress={this.props.progress} {...props}/>

        );
    }


}


PreviousSessions.propTypes = PreviousSessionsPropTypes;

export default PreviousSessions;
