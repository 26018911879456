import Accommodation from "./Accommodation";
import nools from "nools";
import work_rules from "./out";

export default class WorkACCESSRulesEngine {

    constructor() {

        this.sesson = null;
        this.facts = null;

        this.initRulesEngine();

    }



    initRulesEngine() {
        console.log("initRulesEngine()");

        nools.deleteFlows();

        var flow = nools.flow("Hello World", function (flow) {

            let rules = work_rules;

            //console.log(rules);

            const keys = Object.keys(rules);

            for (const key of keys) {
                //console.log(key)
                flow.rule(key, [Accommodation, "a", rules[key]['trigger']], function (facts) {
                });
            }

        });



        var fired = [];
        var accommodation = new Accommodation();
        //accommodation.set('U14', true).set('U22', true).set('M1', true).set('t', 'T01.3');

        console.log(accommodation);

        var session = flow.getSession(accommodation);

        this.session = session;
        this.facts = accommodation;

        // .on("fire", function (name) {
        //     fired.push(name);
        // })
        // .match()
        // .then(function(){
        //     console.log(fired);
        // });

        session
            .on("fire", (name) => {
                console.log("pushing");
                console.log(name);
                fired.push(name);
            })
            .match()
            .then(() => {
                console.log(fired);
                console.log("setting state");
                this.setState({results: fired}, () => {
                    console.log("results set!")
                });
            });

    }

}
