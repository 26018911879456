import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';


import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import _ from 'lodash';

const GlossaryPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    //progress: PropTypes.number,
    postMountCallback: PropTypes.func,
    previous: PropTypes.string,
    //selectedItems: PropTypes.array.isRequired
}




class Glossary extends Component {

    constructor(props) {
        super(props);


        this.state = {
            //selected:this.props.selectedItems ? this.props.selectedItems : [],
            //showModal: false,
            //modalText: '',
            //modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(name, txt) {
        this.setState({showModal: true, modalTitle: name, modalText: txt});
    }




    componentDidMount() {
        console.log("componentDidMount");

        if(this.props.postMountCallback)
            this.props.postMountCallback();


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // if( !_.isEqual(this.props.selectedItems, prevProps.selectedItems))
        //     this.setState({selected:this.props.selectedItems ? this.props.selectedItems : []})
    }


    render() {


        let props = this.props;


        let frm =
            <div>

                <h2 id="adapted">Adapted</h2>
                <p>Adapted means that something has been changed to fit the needs of the user.  For example, a grip on a tool might be adapted to make it easier to hold.</p>

                <h2 id="ballchair">Ball chair</h2>
                <p>A ball chair is an exercise ball in a base that holds the ball in place while you sit on it.  The user needs to make small posture adjustments in order to balance, which can burn off energy and strengthen core muscles, but which can also be tiring over time.</p>

                <h2 id="beveled">Beveled</h2>
                <p>Beveled means having a sloping edge rather than a squared one.</p>

                <h2 id="exercycle">Exercycle</h2>
                <p>An exercycle is a stationary bike used for exercise.</p>

                <h2 id="orientation">Orientation</h2>
                <p>Orientation refers to the position of an object in relation to the person using it.</p>

                <h2 id="proximitycard">Proximity card or contactless smartcard reader</h2>
                <p>A proximity card or contactless smartcard reader is a type of security system.  With this system, a person uses a key card that can be read without inserting it into a reader device.  Instead, depending on the technology used, they may tap the key card on the device or simply get close to it.</p>

                <h2 id="sitstand">Sit-stand workstation</h2>
                <p>A sit-stand workstation can be raised to a height appropriate for standing workers or lowered to a height appropriate for seated workers.  Assuming that the employee wants to change their work position throughout the day, pay attention to how easy it is to adjust the workstation height .</p>

                <h2 id="strikeplate">Strike plate</h2>
                <p>A strike plate is the metal plate secured to a door jam that has a hole for the bolt of the door.  Locking the door can be difficult if the strike plate is not lined up properly with the bolt.</p>

                <h2 id="threshhold">Threshold</h2>
                <p>A threshold is a strip forming the bottom of a doorway.</p>

                <h2 id="turningradius">Turning radius</h2>
                <p>The turning diameter / radius describes the amount of space needed to turn a wheelchair or scooter around in a complete circle.  This measurement is use in some architectural guidelines.  The person using the wheelchair needs a circular obstacle-free space that is equal to the size of the turning diameter (twice the size of the turning radius).</p>

            </div>








        //let frm = <div>We need to first collect some information about your job requirements. Click next to continue...</div>




        return (

            <div>

                        <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                            <Modal.Header closeButton>
                                <Modal.Title>

                                    {this.state.modalTitle}

                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                {this.state.modalText}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.closeModal()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>




                    <NavPanel hideInactiveNavButtons={true} title="Glossary" content={frm}
                              prev={this.props.previous ? this.props.previous : undefined}

                    />

            </div>

        );
    }


    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if(!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        }
        else
        {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        if(updateConfig)
        {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }





    // findPrev()
    // {
    //
    //     if(_.isNil(this.props.selectedJobReqs) || this.props.selectedJobReqs.length <= 0) {
    //         console.log("No job reqs to go back to");
    //         return null;
    //     }
    //     else {
    //         console.log("Go to last of job reqs");
    //         let ret = this.props.selectedJobReqs.slice(-1)[0];
    //         console.log(ret);
    //         return ret;
    //     }
    // }



}


Glossary.propTypes = GlossaryPropTypes;

export default Glossary;
