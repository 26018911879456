import React, {Component, useState} from 'react';


import PropTypes from 'prop-types';


import {
    Form,
    Button,
    Modal,
    Container,
    Row,
    Col,
    Card,
    Accordion,
    AccordionCollapse,
    AccordionToggle
} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import _ from 'lodash';

const BestPracticesPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    //progress: PropTypes.number,
    postMountCallback: PropTypes.func,
    previous: PropTypes.string,
    //selectedItems: PropTypes.array.isRequired
}


class BestPractices extends Component {

    constructor(props) {
        super(props);


        this.state = {
            //selected:this.props.selectedItems ? this.props.selectedItems : [],
            //showModal: false,
            //modalText: '',
            //modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(name, txt) {
        this.setState({showModal: true, modalTitle: name, modalText: txt});
    }


    componentDidMount() {
        console.log("componentDidMount");

        if (this.props.postMountCallback)
            this.props.postMountCallback();


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // if( !_.isEqual(this.props.selectedItems, prevProps.selectedItems))
        //     this.setState({selected:this.props.selectedItems ? this.props.selectedItems : []})
    }


    render() {


        let props = this.props;


        let frm =
            <div>
                <p><b><u>What you can expect from the tool</u></b></p>
                <p>Work ACCESS can help you and your employer find accommodations – ways to help you do your work
                    better, easier, and with less difficulty. You may be looking for accommodations for a new problem.
                    You may already have accommodations, but using Work ACCESS may give you new, helpful ideas to try.
                    We hope that you and your employer or supervisor can use Work ACCESS together and talk about
                    suggestions that can make a difference for you.</p>

                <p>All of Work ACCESS’ accommodation ideas have been suggested and checked by workplace accommodation
                    experts. However, each employee is unique, and workplaces differ regarding tasks and organization.
                    When possible, we provide several options, so you can choose which solution(s) will best fit your
                    situation.</p>

                <p>Work ACCESS suggests product types and features, rather than specific products. This allows us to
                    keep the tool up-to-date. When possible, we have included links to products that contain the
                    features we suggest.</p>

                <p>In some cases, Work ACCESS may recommend a strategy, policy change, or workspace reorganization in
                    addition to or instead of a product.</p>

                <p>Sometimes a Work ACCESS suggestion may be complex or affect health or safety. In those cases, we will
                    let you know when to call for the help of an expert. Our Resources section will tell you how to find
                    experts to assist you.</p>

                <p><b><u>Best practice tips</u></b></p>
                <ol>
                    <li><b>Work with your employer / your employee to pick solutions.</b> The best accommodation
                        experiences happen when everyone gets involved. Employees can share what has worked (or not
                        worked) in the past. Employers can make sure that new solutions can fit into the business’
                        practices and procedures. Good communication can make a huge difference in the success of an
                        accommodation.
                    </li>

                    <li><b>Look for opportunities to try out suggestions from Work ACCESS.</b> It may be helpful to try
                        out an accommodation before buying it. Then you can see if it will work for you and whether
                        another option might work better. If the accommodation involves computer software, look for
                        demonstration (free/low cost) versions that can be tried out. More expensive technology can
                        sometimes be borrowed from the company or through the state Assistive Technology Act Program
                        (see Resources list).
                    </li>

                    <li><b>The cheapest solutions are not always the best.</b> But there is hope! Research has shown
                        that the majority of accommodations cost $600 or less. If a solution is more expensive, be sure
                        to explore the funding resources that we have included in the Resources list.
                    </li>

                    <li><b>Don’t be afraid to make changes that help solutions work better for you.</b> Every person is
                        unique, and workplaces are all different, so no one solution will fit every situation. For
                        example, your workplace probably uses its own terms for things. If you are using technology to
                        help you communicate or write, those words could be added. You could add reminders and prompts
                        to help use a new system. You could also make changes to the colors, size, volume, and other
                        settings of a new system to make it work for you.
                    </li>

                    <li><b>Training may be needed.</b> We have tried to suggest which products and solutions might have
                        a learning curve for new users. Don’t be afraid to ask for the help of an expert if you have
                        questions or get stuck.
                    </li>

                    <li><b>Accommodation is an ongoing process.</b> After you start using a Work ACCESS solution, be
                        sure to plan a follow-up with your supervisor or employer to make sure that the accommodation is
                        working as you expected. Remember too that accommodations may need to change as your abilities,
                        work setting, and job tasks change. New technology may provide better, more helpful options.
                        From time-to-time you should review these solutions to make sure that they are still working for
                        you.
                    </li>

                </ol>

            </div>


        //let frm = <div>We need to first collect some information about your job requirements. Click next to continue...</div>


        return (

            <div>

                <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>

                            {this.state.modalTitle}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.modalText}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.closeModal()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                <NavPanel hideInactiveNavButtons={true} title="Best Practices for Using Work ACCESS" content={frm}
                    prev={this.props.previous ? this.props.previous : undefined}
                />

            </div>

        );
    }


    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if (!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        } else {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        if (updateConfig) {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }


    // findPrev()
    // {
    //
    //     if(_.isNil(this.props.selectedJobReqs) || this.props.selectedJobReqs.length <= 0) {
    //         console.log("No job reqs to go back to");
    //         return null;
    //     }
    //     else {
    //         console.log("Go to last of job reqs");
    //         let ret = this.props.selectedJobReqs.slice(-1)[0];
    //         console.log(ret);
    //         return ret;
    //     }
    // }


}


BestPractices.propTypes = BestPracticesPropTypes;

export default BestPractices;
