import React, {Component, useState} from 'react';


import PropTypes from 'prop-types';


import {
    Form,
    Button,
    Modal,
    Container,
    Row,
    Col,
    Card,
    Accordion,
    AccordionCollapse,
    AccordionToggle
} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import _ from 'lodash';

const OtherResourcesPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    //progress: PropTypes.number,
    postMountCallback: PropTypes.func,
    previous: PropTypes.string,
    //selectedItems: PropTypes.array.isRequired
}


class OtherResources extends Component {

    constructor(props) {
        super(props);


        this.state = {
            //selected:this.props.selectedItems ? this.props.selectedItems : [],
            //showModal: false,
            //modalText: '',
            //modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(name, txt) {
        this.setState({showModal: true, modalTitle: name, modalText: txt});
    }


    componentDidMount() {
        console.log("componentDidMount");

        if (this.props.postMountCallback)
            this.props.postMountCallback();


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // if( !_.isEqual(this.props.selectedItems, prevProps.selectedItems))
        //     this.setState({selected:this.props.selectedItems ? this.props.selectedItems : []})
    }


    render() {


        let props = this.props;


        let frm =
            <div>
                <h2>Where can I find more information about accommodations?</h2>

                <a href="https://at3center.net/state-at-programs/" target="_blank">Listing of State AT Act Programs (AT3 Center)</a>
                <p>
                The Assistive Technology (AT) Act Programs in each state have specialists who can provide information about assistive technology.  They will sometimes conduct AT assessments and/or loan equipment. They may also have information about acquiring used equipment.
                </p>

                <a href="https://askjan.org" target="_blank">Job Accommodation Network (JAN)</a>
                <p>
                JAN provides extensive online resources related to accommodating employees and provides a free consulting service.
                </p>

                <a href="https://www.peatworks.org" target="_blank">Partnership on Employment & Accessible Technology (PEAT)</a>
                <p>
                PEAT’s focus is on making workplace technology, including new and emerging technologies, accessible to all employees.  The resource center is funded by ODEP.
                </p>

                <a href="https://askearn.org" target="_blank">Employer Assistance and Resource Network on Disability Inclusion (EARN)</a>
                <p>
                The Employer Assistance and Resource Network on Disability Inclusion (EARN) offers information and resources to help organizations of all sizes recruit, hire, retain, and advance people with disabilities; build inclusive workplace cultures; and meet diversity, equity, inclusion, and accessibility (DEIA) goals. The resource center is funded by ODEP.
                </p>

                <a href="https://www.assistive-tech.net" target="_blank">AT Network</a>
                <p>
                The AT Network is a national resource for information about assistive technology of all types.  It includes a database of products, discussion forums, and articles about AT.
                </p>


                <h2>Where can I go to try (borrow) assistive technology before I invest in it?</h2>

                <a href="https://at3center.net/state-at-programs/" target="_blank">Listing of State AT Act Programs (AT3 Center)</a>
                <p>
                The AT Act Programs in each state provide demonstrations of assistive technology, and may loan out equipment for people to try.
                </p>

                <p>
                <b>Local library systems</b> may have assistive technology available related to reading and/or computer use.
                </p>

                <p>
                <b>Limited-use software demos</b> are provided by some software companies so that potential users can try out their products.
                </p>

                <h2>
                What are my legal rights to accommodations?
                </h2>


                <a href="https://adata.org" target="_blank">ADA National Network</a>

                <p>The ten regional ADA information centers in the ADA National Network provide training and answer questions about the Americans with Disabilities Act and other disability legislation.
                </p>
                <ul>
                    <li>Phone: 1-800-949-4232</li>
                </ul>

                <a href="https://www.dol.gov/general/topic/disability" target="_blank">Department of Labor (DOL) Disability Resources</a>
                <p>The <a href="https://www.eeoc.gov/eeoc-disability-related-resources" target="_blank">Equal Employment Opportunity Commission (EEOC)</a> is part of the Department of Labor.  DOL and EEOC have resources that help job applicants, employees, employers, medical providers, and others understand disability discrimination in the workplace.  This includes a listing of the relevant legislation impacting the employment of people with disabilities.
                </p>
                <ul>
                    <li><a href="https://www.eeoc.gov/laws/guidance/ada-your-responsibilities-employer" target="_blank">ADA: Your Responsibilities as an Employer</a></li>
                    <li><a href="https://www.eeoc.gov/laws/guidance/your-employment-rights-individual-disability" target="_blank">ADA: Your Employment Rights as an Individual with a Disability</a></li>
                </ul>


                <u>Job Accommodation Network</u>

                <ul>
                    <li><a href="https://askjan.org/publications/individuals/employee-guide.cfm" target="_blank">Employees' Practical Guide to Negotiating and Requesting Reasonable Accommodations Under the Americans with Disabilities Act</a></li>
                    <li><a href="https://askjan.org/publications/employers/employers-guide.cfm" target="_blank">Employers' Practical Guide to Negotiating and Requesting Reasonable Accommodations Under the Americans with Disabilities Act</a></li>
                </ul>

                <u>Should I disclose that I have a disability?</u>

                <p>
                The Americans with Disabilities Act requires employers to provide reasonable accommodations, but only to employees who disclose that they have a disability.  Each employee needs to decide whether to disclose (especially if they have a hidden disability), decide when to disclose (e.g., before or after a job offer), and how to disclose.  The following resources discuss these challenging questions.
                </p>

                <ul>
                    <li><a href="https://files.fisher.osu.edu/careers-recruiting/public/2020-04/Disability%20Disclosure%20for%20Students_1.pdf" target="_blank">Disability Disclosure</a> (from Ohio State University)</li>
                    <li><a href="https://www.rit.edu/careerservices/students/job-search/disclosing-disability" target="_blank">Disclosing Disability</a> (from Rochester Institute of Technology)</li>
                    <li><a href="https://transitiontn.org/student/work/needs-and-rights/disability-disclosure/" target="_blank">Disability Disclosure in the Workplace</a> (role-playing scenarios from Transition Tennessee)</li>
                    <li><a href="https://www.understood.org/en/articles/disability-disclosure-how-your-company-benefits" target="_blank">Disability disclosure: How your company benefits</a></li>
                </ul>


                <h2>Are there job boards to match potential employees to employers interested in hiring people with disabilities?</h2>

                <p>We found several sites.</p>

                <ul>
                    <li><a href="https://abilityjobs.com/" target="_blank">Abilityjobs</a></li>
                    <li><a href="https://abilitylinks.org/" target="_blank">Abilitylinks</a></li>
                    <li><a href="https://www.careeronestop.org/ResourcesFor/WorkersWithDisabilities/job-search.aspx" target="_blank">Careeronestop</a> (listing of job posting sites)</li>
                    <li><a href="http://ourability.com/" target="_blank">OurAbility</a></li>
                    <li><a href="https://thinkbeyondthelabel.dejobs.org" target="_blank">Think Beyond the Label Jobs Board</a></li>
                    <li><a href="https://www.workability.one" target="_blank">Workability</a> (specifically for neurodiverse employees)</li>
                </ul>

            </div>


        //let frm = <div>We need to first collect some information about your job requirements. Click next to continue...</div>


        return (

            <div>

                <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>

                            {this.state.modalTitle}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.modalText}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.closeModal()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                <NavPanel hideInactiveNavButtons={true}  title="Other Accommodation Resources" content={frm}
                    prev={this.props.previous ? this.props.previous : undefined}
                />

            </div>

        );
    }


    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if (!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        } else {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        if (updateConfig) {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }


    // findPrev()
    // {
    //
    //     if(_.isNil(this.props.selectedJobReqs) || this.props.selectedJobReqs.length <= 0) {
    //         console.log("No job reqs to go back to");
    //         return null;
    //     }
    //     else {
    //         console.log("Go to last of job reqs");
    //         let ret = this.props.selectedJobReqs.slice(-1)[0];
    //         console.log(ret);
    //         return ret;
    //     }
    // }


}


OtherResources.propTypes = OtherResourcesPropTypes;

export default OtherResources;
