import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';


import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import _ from 'lodash';

const LearnAboutProjectPropTypes = {

    //something: PropTypes.bool.isRequired,
    //title: PropTypes.string.isRequired,
    //content: PropTypes.element.isRequired
    progress: PropTypes.number,
    postMountCallback: PropTypes.func,
    previous: PropTypes.string,
    useAlt: PropTypes.bool,
    // selectedItems: PropTypes.array.isRequired
}




class LearnAboutProject extends Component {

    constructor(props) {
        super(props);


        this.state = {
            // selected:this.props.selectedItems ? this.props.selectedItems : [],
            // showModal: false,
            // modalText: '',
            // modalTitle: '',
        }

    }


    closeModal() {
        this.setState({showModal: false});
    }

    showModal(name, txt) {
        this.setState({showModal: true, modalTitle: name, modalText: txt});
    }




    componentDidMount() {
        console.log("componentDidMount");

        if(this.props.postMountCallback)
            this.props.postMountCallback();


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // if( !_.isEqual(this.props.selectedItems, prevProps.selectedItems))
        //     this.setState({selected:this.props.selectedItems ? this.props.selectedItems : []})
    }


    render() {


        let props = this.props;


        let altFrm =
            <div>
                <div>
                    <p>
                    Work ACCESS is an online assessment tool to help employers and employees make informed choices when choosing workplace accommodations.
                    </p>
                    <p>
                    The majority of workplace accommodations are determined by employers and/or the employees themselves.  Unfortunately, these groups lack information about the full range of accommodation options that are available, and lack information about what options work best in which situations.
                    </p>
                        <p>
                    Work ACCESS is addressing this need.  The tool collects information about the person, job tasks, and work setting and offers suggestions for accommodations.  The tool:
                        </p>
                    <p>
                            <ul>
                                <li>Includes a range of accommodations, including universal features, assistive technologies, and strategies;</li>
                                    <li>Covers a range of functional limitations;</li>
                                        <li>Covers a variety of job tasks, but particularly tasks common to jobs conducted in an office environment;</li>
                                            <li>Prioritizes recommendations based on individual, environmental, activity, and external factors; as well as evidence of effectiveness;</li>
                                                <li>Warns users when a situation is more complicated and experts need to be involved; and</li>
                                                    <li>Links to external resources for product information and for help with funding and implementation.</li>
                            </ul>
                    </p>

                            <p>
                    In addition, the Work ACCESS project is:
                                <ol>
                                    <li>Using crowd sourcing to develop the expert system;</li>
                                    <li>Incorporating best practices for accommodating employees from published research, and from feedback from system users on accommodation effectiveness;</li>
                                    <li>Developing an accessible web interface and mobile app; and</li>
                                    <li>Evaluating how people utilize the system in the field.</li>
                                </ol>
                            </p>
                                <p>
                    The Work ACCESS project is funded by a grant #90DPEM0001 from the National Institute on Disability, Independent Living, and Rehabilitation Research (NIDILRR).  NIDILRR is a Center within the Administration for Community Living (ACL), Department of Health and Human Services (HHS).
                                </p>
                                    <p>
                    We invite you to use the tool, for free, in exchange for your feedback on the tool and its recommendations.
                                    </p>
                </div>

            </div>



        let frm =
            <div>
                <div>
                    <p>Most workplace accommodations are determined not by trained service providers, but by employers and/or the employees themselves.  Unfortunately, these groups lack information about the full range of accommodation options that are available, and lack information about what options work best in which situations.</p>

                    <p><b>Work ACCESS</b> is addressing this need by creating an easy-to-use workplace-accommodation assessment system that helps employers and employees make intelligent choices about the most appropriate accommodations.  The app collects information about personal, task, and environmental factors and determines potential solutions (e.g., assistive technologies, strategies, universal features).  The system’s decision trees are based on crowd sourcing, research on accommodation best practices, and the reported accommodation outcomes of system users.</p>


                </div>

            </div>




        //let frm = <div>We need to first collect some information about your job requirements. Click next to continue...</div>




        return (

            <div>

                        <Modal class={"modal-dialog smodal-90"} show={this.state.showModal} onHide={() => this.closeModal()}>
                            <Modal.Header closeButton>
                                <Modal.Title>

                                    {this.state.modalTitle}

                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                {this.state.modalText}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.closeModal()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>




                    <NavPanel hideInactiveNavButtons={true} title="Learn About This Project" content={this.props.useAlt ? altFrm : frm}
                              prev={this.props.previous ? this.props.previous : undefined}/>

            </div>

        );
    }


    handleChange(evt, updateConfig) {

        console.log("checked! " + evt.target.value + " is " + evt.target.checked);

        var sel = this.state.selected.slice(0);

        if (!evt.target.checked) {
            const index = sel.indexOf(evt.target.value);
            if (index > -1) {
                sel.splice(index, 1);
            }
        } else {
            const index = sel.indexOf(evt.target.value);
            if (index < 0) {
                sel.push(evt.target.value);
            }
        }

        if (updateConfig) {
            updateConfig(sel);
        }

        this.setState(
            {
                selected: sel
            }
        );

        //this.setState({ checkboxChecked: evt.target.checked });
    }




    // findPrev()
    // {
    //
    //     if(_.isNil(this.props.selectedJobReqs) || this.props.selectedJobReqs.length <= 0) {
    //         console.log("No job reqs to go back to");
    //         return null;
    //     }
    //     else {
    //         console.log("Go to last of job reqs");
    //         let ret = this.props.selectedJobReqs.slice(-1)[0];
    //         console.log(ret);
    //         return ret;
    //     }
    // }



}


LearnAboutProject.propTypes = LearnAboutProjectPropTypes;

export default LearnAboutProject;
