import React, {Component,useState} from 'react';


import PropTypes from 'prop-types';


import {withRouter} from 'react-router-dom';

import {Form, Button, Modal, Container, Row, Col, Card, Accordion, AccordionCollapse, AccordionToggle} from 'react-bootstrap';


import NavPanel from './NavPanel';
import {MdHelp, MdInfo} from "react-icons/md";

import job_groups from "./jobs";

import _ from 'lodash';

const StorageAdminPropTypes = {

    onClearFavorites: PropTypes.func.isRequired,
    onClearHistory: PropTypes.func.isRequired,
}




class StorageAdminBasic extends Component {

    constructor(props) {
        super(props);


        this.state = {

        }

    }





    render() {

        let props = this.props;

        console.log(props);





        let frm =
            <div>
                {/*<h4>{diffType.name}</h4>*/}
                <h2 class="nav-sub-heading">{"Storage Admin"}</h2>

                <Button onClick={props.onClearFavorites}>Clear Favorites</Button>
                <Button onClick={props.onClearHistory}>Clear History</Button>
            </div>




            return (
                <NavPanel title={"Storage Admin"} content={frm}
                />);
    }



}


StorageAdminBasic.propTypes = StorageAdminPropTypes;

const StorageAdmin = withRouter(StorageAdminBasic);

export default StorageAdmin;
